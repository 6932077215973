import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { Badge, Card, CardBody, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getUsersList } from 'store/user/actions';
import avatarMale from "../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../assets/images/users/female-profile-pic.jpg"
import CreateUser from './components/CreateUser';
import { Link } from 'react-router-dom';
import { resetPwd, switchStateUser } from 'store/user/services';
import Swal from 'sweetalert2'
import { Oval } from  'react-loader-spinner'
import { useQuery } from 'react-query';
import DataTableUsersList from './components/dataTable';
import LoadingComponent from 'shared/LoadingComponent';

export default function UsersList() {

    const dispatch = useDispatch();

    
    //console.log("users______________________________",users)
   
    const getUsers = async () => {
        return dispatch(await getUsersList()).payload.usersList
    }


    const users =useQuery('users', getUsers,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
     //console.log("cashe---------------",users.isLoading,users.isFetching,users?.data?.length)
    // useEffect(async () => {
    //     await getList()
    //     $('#mydatatable').DataTable()
    // }, [])

    return (
        <div className="page-content">


{users.isLoading && (
                <LoadingComponent/>
    )}

{!users.isLoading && (
                <div  >
            <MetaTags>
                <title>User list</title>
            </MetaTags>
            <div className="container-fluid" style={{height:800}}>
                <Breadcrumbs title="Users" breadcrumbItem="User List" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Display List</CardTitle>
                                <CreateUser />
                               <DataTableUsersList data={users?.data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div> </div>)}
        </div>
    )
}
