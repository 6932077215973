import React, { useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
// import Select from "react-select";
import { Autocomplete, CircularProgress, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import  { SelectChangeEvent } from '@mui/material/Select';
import { styled, lighten, darken } from "@mui/system"


function NotifStockOut(props) {

    const { storeOptions, setSelectedStores, productOptions, setSelectedproducts ,selectedStores,selectedProducts} = props

//     const memoDataStores=useMemo(()=>storeOptions?.length>0 &&<Select
//     placeholder="Stores..."
//     options={storeOptions}
//     isMulti={true}
//     classNamePrefix="select2-selection"
//     onChange={(e) => { setSelectedStores(e) }}
// />,[storeOptions])

const memoDataStores=useMemo(()=>storeOptions?.length>0 && storeOptions.sort((a, b) => -b.label.localeCompare(a.label)),[storeOptions])

// const memoDataProducts=useMemo(()=>productOptions?.length>0 &&<Select
// placeholder="Products..."
// options={memoDataStores}
// isMulti={true}

// classNamePrefix="select2-selection"
// onChange={(e) => { setSelectedproducts(e) }}
// />,[productOptions])
const memoDataProducts=useMemo(()=>productOptions?.length>0 && productOptions.sort((a, b) => -b.label.localeCompare(a.label)),[productOptions])

  
    return (
    memoDataProducts?.length>0 && memoDataStores?.length>0 &&
        <Row>
            <Col>
                <Label style={{ marginTop: "1rem" }}>Concerned stores</Label>

        <Autocomplete
            size="small"
            id="stores"
            multiple
            onChange={(event, newValue)=>setSelectedStores(newValue)}
            options={memoDataStores}
            //groupBy={option => option?.label?.toUpperCase()}
            // getOptionLabel={option => option?.label}
            // sx={{ width: 300 }}
            // getOptionSelected={(option, value) => option.id === value.id}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stores"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!memoDataStores ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            
            renderOption={(props, option) => <div key={option.value}>
                <li {...props}>{option.label}</li>
                {/* <h6 style={{color:'grey'}} {...props} key={option.id}>{option.address}</h6> */}
            </div>}
            // renderGroup={params => (
            //   <li key={params.key}>
            //     <GroupHeader>{params.group}</GroupHeader>
            //     <GroupItems>{params.children}</GroupItems>
            //   </li>
            // )}
            loading={!memoDataStores}
          />
            </Col>
            <Col>
                <Label style={{ marginTop: "1rem" }}>Concerned products</Label>
                {/* {memoDataProducts} */}
                <Autocomplete
                multiple
            size="small"
            id="products"
            onChange={(event, newValue)=>setSelectedproducts(newValue)}
            options={memoDataProducts}
            //groupBy={option => option?.label?.toUpperCase()}
            // getOptionLabel={option => option?.label}
            // sx={{ width: 300 }}
            // getOptionSelected={(option, value) => option.id === value.id}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label="Products"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!memoDataProducts ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            
            renderOption={(props, option) => <div key={option.value}>
                <li {...props}>{option.label}</li>
                {/* <h6 style={{color:'grey'}} {...props} key={option.id}>{option.address}</h6> */}
            </div>}
            // renderGroup={params => (
            //   <li key={params.key}>
            //     <GroupHeader>{params.group}</GroupHeader>
            //     <GroupItems>{params.children}</GroupItems>
            //   </li>
            // )}
            loading={!memoDataProducts}
          />
            </Col>
        </Row>
    )
}
function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
export default NotifStockOut