import { getLocationHistoryDataByStore, getLocationStores } from "./service";

export const GET_LOCALISATION_HISTORY = "GET_LOCALISATION_HISTORY"

/**
 * 
 * @returns Order list
 */
export const getLocationHistoryDataAsync = async (userId,from, to) => {
  try {
    const res = await getLocationHistoryDataByStore(userId,from, to);
    return {
      type: GET_LOCALISATION_HISTORY,
      payload: { localisationHistory: res },
    }
  } catch (error) {
    return error
  }
}

// /**
//  * 
//  * @returns Order list
//  */
// export const getLocationStoresAsync = async (userId) => {
//   try {
//     const res = await getLocationStores(userId);
//     return {
//       type: GET_LOCALISATION_HISTORY,
//       payload: { localisationHistory: res },
//     }
//   } catch (error) {
//     return error
//   }
// }