import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"

const Gallery = props => {
  const { pictures, } = props

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  // if(isGalleryZoom){
  //   console.log("----------------------7ell",pictures)
  // }

  const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com"
  return (
    <div className="zoom-gallery">
      <Link
        to="#"
        onClick={() => {
            setisGalleryZoom(true),setphotoIndex(0)
        }}
        style={{ fontSize: "1.5rem" }}
      >
        <i className="bx bx-images" id="gallery" />
        <UncontrolledTooltip placement="top" target="gallery">
          Gallery
        </UncontrolledTooltip>
      </Link>
      {isGalleryZoom && (
        <Lightbox
          mainSrc={URL + pictures[photoIndex]?.path}
          nextSrc={URL + pictures[(photoIndex + 1) % pictures?.length]?.path}
          prevSrc={
            URL +
            pictures[(photoIndex + pictures?.length - 1) % pictures.length]
              ?.path
          }
          onCloseRequest={() => {
            setisGalleryZoom(false)
          }}
          onMovePrevRequest={() => {
            setphotoIndex(
              (photoIndex + pictures?.length - 1) % pictures?.length
            )
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % pictures?.length)
          }}
        />
      )}
    </div>
  )
}

export default Gallery
