import React, { useEffect, useMemo, useState } from 'react'
import { Button, FormGroup, Modal } from 'reactstrap'
import { getReferencedProductsByStore } from 'store/referencedProduct/services'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { findStockSetting, upsertStock } from 'store/stock/services'
import { useSelector } from 'react-redux'
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
const UpdateStock = (props) => {

    const { store, visitId, stockModal, setStockModal, tog_stock } = props
    const userId = useSelector(state => state.User?.user?.id)
    const [rfp, setRfp] = useState([])
    const [stockSetting, setStockSetting] = useState({})

    const [newRfp, setNewRfp] = useState([])
    const getRfp = (storeId) => {
        getReferencedProductsByStore(storeId).then((data) => {
            let list=[]
            data.forEach(element => {
                let qte=element.product.stocks[element.product.stocks.length-1].quantity;
                let stock=false;
                if(element.product.stocks.length>0)
                    stock=element.product.stocks[element.product.stocks.length-1].stockOut; 
                list.push({ product: element.product, store: element.store, storeId: storeId, productId: element.product.id, userId: userId, quantity: qte, stockOut: stock, visitId: visitId })
            }); 
            setRfp(list)
            $('#datatable').DataTable({
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]]
            })
        })
        
    }

    const handleUpdate = (id, e) => {
        //console.log("id product: ",id)
        
        if (stockSetting.stockManagement === "StockOut") {
            // setRfp((rfp) =>
            //     rfp.map((element) => {
            //         return element.productId === id ? { ...element, stockOut: !element.stockOut, visitId: visitId } : element;
            //     })
            // );
            setRfp((rfp) =>
                rfp.map((element) => {
                   if(element.productId === id ) {
                        

                    let find=false
                    if(newRfp.length===0){
                        
                        newRfp.push({ ...element, stockOut: !element.stockOut, visitId: visitId })
                        
                    }else{
                        find=newRfp.find(( prod ) => prod.product.id === id)
                        if(find){
                            let index = newRfp.indexOf(newRfp.find(( prod ) => prod.product.id === id));
                            //console.log(index)
                            if (index > -1) { // only splice array when item is found
                                newRfp.splice(index, 1); // 2nd parameter means remove one item only
                            }
                            
                        }else{
                            newRfp.push({ ...element, stockOut: !element.stockOut, visitId: visitId })
                        }
                    }
                    
                    
                        return { ...element, stockOut: !element.stockOut, visitId: visitId }
                   }else{
                        return element
                   }
                    
                })
            );
        } else if (stockSetting.stockManagement === "Quantity") {
            // setRfp((rfp) =>
            //     rfp.map((element) => {
            //         return element.productId === id ? { ...element, quantity: e.target.value, stockOut: null, visitId: visitId } : element;
            //     })
            // );
        }
        
    };

    useEffect(() => {
        if (stockModal) {
            findStockSetting().then((data) => {
                setStockSetting(data[0]);
            })
            setRfp([])
            getRfp(store.id)
            
            
        }
    }, [stockModal])

    const updateStock = () => {
        //console.log("newRfp: ",newRfp)
        if(stockSetting.stockManagement === "StockOut")
        {
            newRfp.forEach((ele)=>{
                ele.id=0;
                return ele;
            })
            
            upsertStock(newRfp)
            .then(() => {
                tog_stock()
            })
        }
        else
        upsertStock(newRfp)
            .then(() => {
                tog_stock()
            })

            
    }  

    const memoData=useMemo(()=>rfp.length>0&&rfp?.map((product, index) => {
        product.product.stocks.forEach(element1 => {
            //console.log("product: ",element1)
            if(element1.productId==product.product.id)
            {
                product.id=element1.id;
            }
        });
        //console.log(product.stockOut)
        
        return (
            <Tr key={index} style={{ backgroundColor: product.stockOut ? ("#DE5555") : (""), color: product.stockOut ? ("white") : ("") }}>
                <Td>
                    <img
                        src={URL+product.product.path}
                        style={{ width: "4rem", height: "4rem" }} 
                    />
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.label} </p>
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.brand?.name} </p>
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.category?.name} </p>
                </Td>
                <Td>
                    {(stockSetting.stockManagement === "StockOut") ? (
                        <div style={{ width: "1%", margin: "auto" }}>
                            <input
                                type="checkbox"
                                style={{ cursor: "pointer", marginTop: "1.5rem" }}
                                className="form-check-input"
                                checked={product.stockOut}
                                onChange={(e) => { handleUpdate(product.product.id, e) }}
                            />
                        </div>
                    ) : (
                        <input
                            value={product.quantity}
                            style={{ marginTop: "1.3rem" }}
                            type="number"
                            onChange={(e) => { handleUpdate(product.product.id, e) }}
                        />
                    )}
                </Td>
            </Tr>
        )
    }),[rfp])
    return (
        <Modal
            isOpen={stockModal}
            size="xl"
            toggle={() => { tog_stock() }}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Update stock for {store.name}
                </h5>
                <button
                    type="button"
                    onClick={() => { setStockModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                    <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                    >
                        <Table
                            id={"datatable"}
                            className="table table-bordered"
                        >
                            <Thead>
                                <Tr>
                                    <Th>Image</Th>
                                    <Th>Label</Th>
                                    <Th>Brand</Th>
                                    <Th>Category</Th>
                                    <Th>{(stockSetting.stockManagement === "StockOut") ? ("StockOut") : ("Quantity")}</Th>   
                                </Tr>
                            </Thead>
                            <Tbody>
                                {/* {console.log("rfp: ",rfp)} */}
                                {memoData }
                            </Tbody>
                        </Table>
                    </div>
                </div>
                <FormGroup className="mb-0" style={{ float: 'right', marginTop: "2rem" }}>
                    <Button
                        type="button"
                        color="primary"
                        className="ms-1"
                        onClick={() => { updateStock() }}
                    >
                        Confirm
                    </Button>
                </FormGroup>
            </div>
        </Modal>
    )
}
export default UpdateStock