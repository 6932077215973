import React, { useEffect, useState } from 'react'
import {  Thead, Tbody, Tr, Th, Td ,Table} from "react-super-responsive-table"
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Box, Checkbox } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import { AutoSizer, Column } from 'react-virtualized-reactv17';
import { Col, Row } from 'reactstrap';
import TablePaginationActions from 'components/paginationMui';
import logoDark from "../../../assets/images/store.png"

const StoreList = (props) => {

    const { chosenStores, setChosenStores } = props
    const [stores, setStores] = useState([])

   useEffect(()=>{
    setStores([...chosenStores])
   },[chosenStores])

   const URL = process.env.NODE_ENV === "development"
   ? "http://localhost:3303"
   : "https://www.devtest21-api.g2m-tn.com";
   
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const headerRowRenderer = ({
        className,
        columns,
        style
      }) => (
        <div
          className={className+' bg-light '}
          role='row'
          style={style}
        >
          {columns} 
        </div>
      )
      const headerRenderer = ({
        columnData,
        dataKey,
        disableSort,
        label,
        sortBy,
        sortDirection
      }) => (
        <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} </div>
      );
      function filterData(params) {
        //console.log('list_____________________',params)
        if(!filter){
          setFilter(filter=> true)
        }
        if(desc){
          const list=stores.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
          setDesc(desc=> !desc)
          setStores(stores => [...list])
        }else{
          const list=stores.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
          setDesc(desc=> !desc)
          setStores(stores => [...list])
        }
        
      }
      const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)
  
    const sort_by = (field, reverse, primer) => {
  
      const key = primer ?
        function(x) {
          return primer(x[field])
        } :
        function(x) {
          return x[field]
        };
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }
    return (
        <div className="table-rep-plugin" style={{ marginTop: "0rem", marginBottom: "0rem" }}>
            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
                style={{height:'90%'}}
            >
               <Box sx={{ height: 400, width: '100%', }}>
               <Table
                    id="storesmydatatable"
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Address</Th>
                            <Th>Governorate</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {chosenStores?.map((store, index) => (
                            <Tr key={index}>
                                <Td> <img src={URL+store.path} onError={(e)=>e.target.src=logoDark} style={{ width: "3rem", height: "3rem", }} /> </Td>
                                <Td> {store.name } </Td>
                                <Td> {store.address} </Td>
                                <Td> {store.governorate} </Td>
                                <Td>
                                    <i
                                        className="mdi mdi-trash-can text-danger font-size-20 me-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { setChosenStores(chosenStores.filter((store, i) => i !== index)) }}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
              
                                 {/* <AutoSizer>
      {({height, width}) => (
        <>

        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={width}
        headerRowRenderer={headerRowRenderer}
        height={height-70}
        headerHeight={30}
        rowHeight={50}
        rowCount={stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          <Column label="Image" dataKey="path" width={500}  cellRenderer={({cellData}) => <img src={URL+cellData} style={{ width: "4rem", height: "4rem", }} />}/>             
          <Column label="name" dataKey="name"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Address" dataKey="address"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData}</h6>}/>
          
          <Column label="Governorate" dataKey="governorate"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Email" dataKey="email"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Phone_number" dataKey="phone_number"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="StoreGrp" dataKey="Actions"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <Td>
                                    <i
                                        className="mdi mdi-trash-can text-danger font-size-20 me-2"
                                        style={{ cursor: "pointer" }}
                                        //onClick={() => { setChosenStores(chosenStores.filter((rowData, i) => i !== index)) }}
                                    />
                                </Td>}/>
       </Table>
              <Row>
              <Col >
              <TableFooter>
              <TableRow>
                <TablePagination
                  component="div"
                  style={{width:1100}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={stores.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
              </Col>
              
             </Row>
             </>
      )} 
     
     
    </AutoSizer>    */}
    </Box>
            </div>
        </div>
    )
}
export default StoreList