import React, { useState } from 'react';
import { CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import Question from './Question';

const SubCategories = (props) => {

    const { subCategories, index, responses, setResponses } = props
    const [verticalActiveTab, setVerticalActiveTab] = useState(0)

    const toggleVertical = (tab) => {
        if (verticalActiveTab !== tab) {
            setVerticalActiveTab(tab)
        }
    }

    return (
        <TabPane tabId={index}>
            <Row style={{ marginTop: "3rem" }}>
                <Col sm="12">
                    <CardText className="mb-0">
                        <Row>
                            <Col md="2">
                                <Nav pills className="flex-column">
                                    {subCategories.map((subCategory, index) => {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ active: verticalActiveTab === index })}
                                                    onClick={() => { toggleVertical(index) }}
                                                >
                                                    {subCategory.name}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </Col>
                            <Col md="10">
                                <TabContent activeTab={verticalActiveTab} className="text-muted mt-4 mt-md-0">
                                    {subCategories.map((subCategory, ind) => {
                                        return (
                                            <TabPane tabId={ind} key={ind}>
                                                {subCategory.questions.sort((a, b) => { return b.id - a.id }).map((question, i) => 
                                                (
                                                    <Question
                                                        index={i}
                                                        question={question}
                                                        responses={responses}
                                                        setResponses={setResponses}
                                                    />
                                                ))}
                                            </TabPane>
                                        )
                                    })}
                                </TabContent>
                            </Col>
                        </Row>
                    </CardText>
                </Col>
            </Row>
        </TabPane>
    )
}

export default SubCategories;
