import { TextField } from '@mui/material'
import {React ,useState }from 'react'
const  DateNumbers=({number}) =>{
    let color=""
    //console.log("nnnnnnnnnnnnnnnnnnnnnnnnnn",number)
if(number<0){
    color ="red"
}else{
    color="blue"
}
    
  return isNaN(number) || (!number) ? (
    // <TextField style={{color:'gray'}}> non pointé</TextField>
    <div style={{color:'gray'}}>non pointé</div>
  ): (
    <div style={{color:color}}>{number}</div>
  )
}

export default DateNumbers