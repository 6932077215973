import { List, ListItem } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { element } from 'prop-types';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import ApexRadial from './ApexRadial';

function TodaySupervisorPerfDetail(props) {

    const { visits } = props
    const [data, setData] = useState([])




    function groupByDay(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { day } = curr;
                const currentItems = acc[day];
                return {
                    ...acc,
                    [day]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        if(visits?.length>0){
            //console.log("visit_________________",Object.values(groupByDay(visits)))
            setData(Object.values(groupByDay(visits)))
        }
    }, [visits])

    //console.log(data)
    return (
        <Row  >
            {visits?.length>0&&<Col xl={3} lg={3} md={4} xs={12}>
                <h6 style={{ marginTop: "5rem" }}>{visits[0]?.user?.first_name + " " + visits[0]?.user?.last_name}</h6>
            </Col>}

            <List component={Stack} direction={'row'} style={{


                padding: 0

            }}>

                {data?.length>0 &&data?.map((elements, index) => {
                    let realisedVisits = 0
                    let plannedVisits = 0
                    
                    elements?.forEach(element => {
                        //console.log(element);
                        // if (element?.planned) {
                            
                        //     plannedVisits++
                        // }
                        if (element?.displays?.length>0) {
                            plannedVisits++
                            realisedVisits=element?.displays?.length
                        }
                        //realisedVisits = realisedVisits + element?.surveyResponses?.length
                    });
                    return (

                        <ListItem key={index} >
                            <Col key={index} style={{


                                width: 120

                            }}>
                                <ApexRadial
                                    plannedVisits={plannedVisits}
                                    realisedVisits={realisedVisits}
                                    day={new Date(elements[0]?.day).getDate()}
                                    month={new Date(elements[0]?.day).getMonth() + 1}
                                    time={dayjs(elements[0]?.day).format("HH:mm:ss")}
                                />
                            </Col>
                        </ListItem>

                    )
                })}

            </List>
        </Row >

    )
}

export default TodaySupervisorPerfDetail