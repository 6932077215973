import React, { useEffect, useMemo, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import CreateSurvey from './createComponents/CreateSurvey';
import { useDispatch } from 'react-redux';
// import { getSurveysAsync } from 'store/survey/actions';
import SurveyDetail from './detailComponents/SurveyDetail';
import LoadingComponent from 'shared/LoadingComponent';
import { useQuery } from 'react-query';
import { getSurveys } from 'store/survey/services';

const SurveyParams = () => {

    // const dispatch = useDispatch();
    const [surveys, setSurveys] = useState([])

    const getSurveysAsync = async () => {
        // setSurveys(dispatch(await getSurveysAsync()).payload.surveys)
       return await getSurveys()
        
    }

    const {data,status,isLoading,isFetching} =useQuery('surveys', getSurveysAsync,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
      //console.log('outside',data)
    useEffect(async () => {
        if(data?.length>0 && !isLoading){
            //console.log('inside',data)
            setSurveys(data)
        }
        
    }, [data]);

    const memoData=useMemo(()=>surveys.length>0 && surveys.map((survey, index) => (
        <SurveyDetail
            key={index}
            survey={survey}
        />
    )),[surveys])
    
    return (
        <div className="page-content" >
            <MetaTags>
                <title>Questionnaires</title>
            </MetaTags>
            <Container fluid >
                <Breadcrumbs title="Paramétrages" breadcrumbItem="liste des questionnaires" />
                <CreateSurvey />
                {isLoading && (
                    <LoadingComponent />
                )}
                {surveys.length > 0 && !isLoading &&(
                    <Row>
                        
                        {memoData}
                    </Row>
                ) }

            </Container>
        </div>
    )
}

export default SurveyParams;
