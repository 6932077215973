import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getReferencedProductsByStore } from 'store/referencedProduct/services';
import TablePaginationActions from 'components/paginationMui';
import { Col, Row } from 'reactstrap';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { AvField } from 'availity-reactstrap-validation';
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17';
import { Checkbox } from '@mui/material';
const StoreProducts = (props) => {

    const { storeId } = props
    const [modal_standard, setmodal_standard] = useState(false)
    const [rfp, setRfp] = useState([])
    const [data, setData] = useState([])

    const getRfp = (storeId) => {
        getReferencedProductsByStore(storeId).then((data) => {
            if(data?.length>0){
              // console.log('hey')
              setRowsPerPage(data?.length)
              setData(data)
              setRfp(data);
            }
        })
    }

    const tog_standard = () => {
        setmodal_standard(!modal_standard)
        if (!modal_standard) {
            getRfp(storeId)
            // setTimeout(() => {
            //     $('#detaildatatable').DataTable()
            // }, 200);
        }
    }
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)

    const URL = process.env.NODE_ENV === "development"
          ? "http://localhost:3303"
          : "https://www.devtest21-api.g2m-tn.com";

          const [storeGrp, setStoreGrp] = useState([]);

          const [checkedAll, setCheckedAll] = React.useState(false);

          const handleSearch = (search) => {
            //setCancelB(false)
            //console.log("search_______________________",search)
            if(search){
            const list=data.filter((element)=>{
              
                
                return (
                  (element?.product?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
                  || (element?.product?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.product?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.product?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        )
              
            })
            setRfp(rfp=>[...list])
          }else{
            setRfp(rfp=>[...data])
          }
          };
          const headerRenderer = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => (
            <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} </div>
          );
          const headerRowRenderer = ({
            className,
            columns,
            style
          }) => (
            <div
              className={className+' bg-light '}
              role='row'
              style={style}
            >
              {columns} 
            </div>
          )
          function filterData(params) {
            //console.log('list_____________________',params)
            if(!filter){
              setFilter(filter=> true)
            }
            if(params==="label"){
                //console.log('list_____________________',params)

                if(desc){
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()>b.product.label.toUpperCase())-(a.product.label.toUpperCase()<b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()<b.product.label.toUpperCase())-(a.product.label.toUpperCase()>b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
              else if(params==="brand"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.brand.name.toUpperCase()>b.product.brand.name.toUpperCase())-(a.product.brand.name.toUpperCase()<b.product.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.brand.name.toUpperCase()<b.product.brand.name.toUpperCase())-(a.product.brand.name.toUpperCase()>b.product.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }else if(params==="category"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.category.name.toUpperCase()>b.product.category.name.toUpperCase())-(a.product.category.name.toUpperCase()<b.product.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.category.name.toUpperCase()<b.product.category.name.toUpperCase())-(a.product.category.name.toUpperCase()>b.product.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }else if (params==="typology"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.typology>b.product.typology)-(a.product.typology<b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.typology<b.product.typology)-(a.product.typology>b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
            
          }
          const headerRendererCheckbox = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => {
            //console.log("r_____________________",columnData)
            return      (
            <Checkbox
            
            //color="secondary"
            //style={{color:'white'}}
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={tableSelected}
            onChange={()=> handleCheck() }
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />    )};

    return (
        <>
            <i
                className="mdi mdi-eye-minus-outline"
                style={{ fontSize: "23px", float: "right", cursor: "pointer", color: "#556EE6" }}
                onClick={() => { tog_standard() }}
            />
            <Modal
                isOpen={modal_standard}
                size="xl"
                toggle={() => { tog_standard() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Store products
                    </h5>
                    <button
                        type="button"
                        onClick={() => { setmodal_standard(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                        {rfp.length>0 &&<div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                            style={{height:600,}}
                        >
                            {/* <Table
                                id={"detaildatatable"}
                                className="table table-striped table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Image</Th>
                                        <Th>Label</Th>
                                        <Th>Brand</Th>
                                        <Th>Category</Th>
                                        <Th>Typology</Th>
                                        <Th>Available</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {rfp?.map((element, index) => {
                                        return (
                                            <Tr key={index}>
                                                    <Td>
                                                        <img
                                                            src={element.product.path}
                                                            style={{ width: "4rem", height: "4rem" }}
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <p style={{ marginTop: "1.5rem" }}> {element.product.label} </p>
                                                    </Td>
                                                    <Td>
                                                        <p style={{ marginTop: "1.5rem" }}> {element.product.brand?.name} </p>
                                                    </Td>
                                                    <Td>
                                                        <p style={{ marginTop: "1.5rem" }}> {element.product.category?.name} </p>
                                                    </Td>
                                                    <Td>
                                                        <p style={{ marginTop: "1.5rem" }}> {element.product.typology} </p>
                                                    </Td>
                                                    <Td>
                                                        <div className="form-check form-switch form-switch-md mb-3" >
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: "pointer", marginTop: "1rem" }}
                                                                className="form-check-input"
                                                                id="enabletooltip"
                                                                checked={element.available}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                    </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table> */}
                            <AutoSizer>

{({height, width}) => (
  <>
  <input
              className="mb-1"
             size={25}
              placeholder="search"
              type="text"
              onChange={(e) => { handleSearch(e.target.value) }}
          />
  {/* <TextField placeholder='search...' style={{alignSelf:'flex-end',width:200,}} size='small' onChange={(e)=>handleSearchStores(e.target.value)}/> */}

  <Table
  gridStyle={{
    direction: 'inherit'
  }}
  width={width}
  headerRowRenderer={headerRowRenderer}
  height={height-70}
  headerHeight={30}
  rowHeight={50}
  rowCount={rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
  rowGetter={({index}) => rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
  >    
    <Column label="Image" dataKey="path" width={width}  cellRenderer={({cellData,rowData}) => <img src={URL+rowData.product.path} style={{ width: "4rem", height: "4rem", }} />}/>                                       
    <Column label="label" dataKey="label"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.label}</h6>}/>
    <Column label="Brand" dataKey="brand"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6 >{rowData?.product?.brand?.name}</h6>}/>
    <Column label="Category" dataKey="category"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.category?.name}</h6>}/>
    <Column label="Typology" dataKey="typology"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData.product?.typology}</h6>}/>

    <Column label="Actions"    width={width}  dataKey="Action"    cellRenderer={({cellData,rowData}) =>  
    
    <div className="form-check form-switch form-switch-md mb-3" >
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: "pointer", marginTop: "1rem" }}
                                                                className="form-check-input"
                                                                id="enabletooltip"
                                                                checked={rowData.available}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
    }/> 

    
 </Table>
        
       </>
)} 


</AutoSizer>  

          <TablePagination
            //component="div"
            //style={{width:1100}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: rfp.length }]}
            colSpan={3}
            count={rfp.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
            // SelectProps={{
            //   inputProps: {
            //     'aria-label': 'rows per page',
            //   },
            //   native: true,
            // }}
          />

                        </div>}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default StoreProducts
