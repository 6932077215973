import { api } from "helpers/consts";

/**
 * PromotionType Api's
 */

/**
 * @returns PromotionType list
 */
export const getPromotion = async () => {
    const res = await api.get(`/promotion`);
    return res.data.data
};

/**
 * @returns PromotionType list
 */
export const getPromotionByDate = async (from, to) => {
    const res = await api.get(`/promotion/${from}/${to}`);
    return res.data.data
};


/**
 * 
 * @param {Promotion} promotion 
 * @returns 
 */
export const upsertPromotion = async (promotion,store,product) => {
//console.log(promotion,store,product)
    // const formData = new FormData();

    // formData.append('promotion', JSON.stringify(promotion))
    
    
    // formData.append('store', JSON.stringify(store))
    // formData.append('product', JSON.stringify(product))
    const res = await api.post(`/promotion`,{
        promotion:promotion,
        store:store,
        product:product } );
    return res
};


/**
 * @returns Promotion list
 */
export const updatePromotionsStatus = async (notifications) => {
    //console.log("data_____________________________",{promotions:notifications})
    const res = await api.post(`/promotion/updateStatus`, {promotions:notifications});
    return res
}