import { isUndefined } from 'lodash';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import MButton from '@mui/material/Button';
import { Button } from 'reactstrap';

function PieChart({nbStock,nbStockOut}) {
  let taux=''
    //console.log("nbStock: ",nbStock," nbStockOut: ",nbStockOut)
      if(isUndefined(nbStock) ||isUndefined(nbStockOut)){
         taux= 0 +' / '+0
      }else {
         taux= nbStockOut +' / '+nbStock
      }
      
      let series= 0
      if((isNaN(nbStockOut) || isNaN(nbStock) || ((nbStockOut==0) && (nbStock==0)) || isUndefined(nbStock) ||isUndefined(nbStockOut))){
        series= 0
        
    }else{

      series= Number(parseFloat(((nbStockOut*100)/nbStock)).toFixed(2))
    }

      let  options= {
          chart: {
            width:'200%',
            type: 'radialBar',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
               hollow: {
                margin: 0,
                size: '75%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }
              },
              track: {
                //background: '#fff',
                strokeWidth: '100%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35
                }
              },
          
              dataLabels: {
                show: true,
                name: {
                  offsetY: -5,
                  show: true,
                  color: '#E8483A',
                  fontSize: '12px'
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val);
                  },
                  color: '#E8483A',
                  fontSize: '14px',
                  show: true,
                  offsetY: -5,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#ABE5A1'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: [taux],
        }

  return (
    <h6 variant="outlined" color='dark'  style={{fontSize:'14px',color:"Highlight"}}>{taux}</h6>
        //<ReactApexChart  options={options} series={[series]} width='125' type="radialBar"/>
        
  )
}

export default PieChart