import React, { useEffect, useRef, useState } from 'react'
import MButton from '@mui/material/Button';
import MetaTags from 'react-meta-tags';
import { Row, Card, CardBody, } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { useDispatch } from 'react-redux';
import { getVisitList, getVisitPerformanceAsync } from 'store/visit/actions';
import { getUsersList } from 'store/user/actions';
import "assets/Performance/src/styles/performance.css"
import { Box, LinearProgress, } from '@mui/material';
import { getOrdersAsync } from 'store/order/actions';
import { getDisplayListAsync } from 'store/display/actions'
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import ReportAreaChart from './components/ReportAreaChart';
// import Alert from '@mui/material/Alert';
import { getStocksAsync } from 'store/stock/actions';
import DataTable from './components/dataTable';
import FullWidthTabs from './components/fullWidhTab';
import PassedTimeChart from './components/passedTimeChart';
import VisiteChart from './components/visiteChart';
import { useQuery } from 'react-query';
import LoadingComponent from 'shared/LoadingComponent';



//////////////////////////
function Performance() {

  const dispatch = useDispatch();

  //visit list
  const getVisit = async () => {
    return dispatch(await getVisitPerformanceAsync()).payload.visitPerf
  }
 
  const {data,status,isLoading,isFetching} =useQuery('performance', getVisit,{
    refetchOnWindowFocus:false,
    //refetchInterval:0
     //staleTime:'infinity',
    // initialData:undefined
    //refetchOnMount:false
    cacheTime:7200000
  })
  
  //console.log("cashe---------------",{isLoading,isFetching},data?.length)
  // useEffect(async () => {

  //   await getVisit()

  // }, [])

  //scroll down
  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <Row>
            {/* <CardTitle>Performance List </CardTitle> */}
            <MButton  css={{ backgroundColor: 'blue' }} color='primary' aria-describedby='chart' ><EqualizerSharpIcon /> &nbsp; Performance Chart</MButton>
          </Row>



        </CardBody>
      </Card>
      {isLoading && (
        <div>
          <LoadingComponent/>
        </div>
      )}

      {!isLoading && (
        <div><MetaTags>
          <title>Performance list</title>
        </MetaTags>
          <div className="container-fluid">



            <Box sx={{ width: '100%', marginBottom: 10 ,height:'auto' }}>

              <DataTable data={data} />

              <FullWidthTabs
              
              chart1={<ReportAreaChart data={data} />}
              chart2={<PassedTimeChart data={data} />}
              chart3={<VisiteChart data={data} />}
            />
            </Box>
            




          </div>
        </div>



      )}
    </div>
  )
}

export default Performance