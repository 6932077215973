import { getStores, getStoresFilter } from "./services";

export const GET_STORES = "GET_STORES"
export const GET_STORE = "GET_STORE"
export const GET_STORES_FILTER = "GET_STORES_FILTER"

/**
 * 
 * @returns stores list
 */
export const getStoresAsync = async () => {
  try {
    const res = await getStores();
    return {
      type: GET_STORES,
      payload: { stores: res },
    }
  } catch (error) {
    return error
  }

}

/**
 * 
 * @returns stores list
 */
export const getStoresFilterAsync = async () => {
  try {
    const res = await getStoresFilter();
    return {
      type: GET_STORES_FILTER,
      payload: { storesFilter: res },
    }
  } catch (error) {
    return error
  }

}