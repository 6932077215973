import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

import { styled, lighten, darken } from "@mui/system"

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: '#049DD9',
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten('#049DD9', 0.85)
      : darken('#6553A6', 0.8),
}))

const GroupItems = styled("ul")({
  padding: 0,
})


function TableStore(props) {
  const { dataStore ,setSelectedStores} = props
  const [stores, setStores] = useState([])
  

//   const [storeGroups, setStoreGroups] = useState([])

//   function groupBy(items) {
//     return items.reduce((acc, curr) => {
//         if (curr.storeGroup) {
//             const { name } = curr?.storeGroup;
//             const currentItems = acc[name];
  
//             return {
//                 ...acc,
//                 [name]: currentItems ? [...currentItems, curr] : [curr]
//             };
//         }
//         return acc;
//     }, {});
//   }
function handleSelectedStore(params) {
    //console.log("selection______________________",params)
    setSelectedStores(params.id)
}

  useEffect(() => {
    let data=dataStore.map((elem)=> {
            return {label:elem.name,storeGroup:''+elem?.storeGroup?.name?.toString().toUpperCase(),id:elem.id,address:elem?.address}
        })
    
    //console.log("groups____________________:",sorted)
    setStores(data)
  }, [dataStore])


 
  return (
    <div>
        {
            stores.length>0 && <Autocomplete
            size="small"
            id="grouped-demo"
            onChange={(event, newValue)=>handleSelectedStore(newValue)}
            options={stores.sort((a, b) => -b.storeGroup.localeCompare(a.storeGroup))}
            groupBy={option => option?.storeGroup?.toUpperCase()}
            // getOptionLabel={option => option?.label}
            // sx={{ width: 300 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => <TextField {...params} label="Select store" 
            />}
            
            renderOption={(props, option) => <div key={option.id}>
                <li {...props}>{option.label}</li>
                <h6 style={{color:'grey'}} {...props} key={option.id}>{option.address}</h6>
            </div>}
            renderGroup={params => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        }
    </div>
  )
}

export default TableStore
