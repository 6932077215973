import React, { useEffect, useMemo, useState } from 'react'
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import StoreModal from './components/StoreModal';
import { getReferencedProductsByStore } from 'store/referencedProduct/services';
import TablePaginationActions from 'components/paginationMui';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { AvField } from 'availity-reactstrap-validation';
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17';
import { Checkbox } from '@mui/material';
import logoDark from "../../../assets/images/product.png"

const SecondPart = (props) => {

    const { chosenProducts, setChosenProducts } = props
    const dispatch = useDispatch();
    const [products, setProducts] = useState([])
    const [data, setData] = useState([])

    const [selectedStore, setSelectedStore] = useState(null)

    const getProducts = async () => {
      let data=dispatch(await getProductsAsync()).payload.products
        setData(data)
        setRowsPerPage(data?.length)
        setProducts(data);
    }

    const updateChosenProducts = (product) => {
        setChosenProducts(chosenProducts => [...chosenProducts, product])
    }

    useEffect(async () => {
        await getProducts()
        // $('#mydatatable').DataTable()
    }, [])

    useEffect(() => {
        if (selectedStore) {
            getReferencedProductsByStore(selectedStore).then((data) => {
                data.forEach(element => {
                    setChosenProducts(chosenProducts => [...chosenProducts, element.product])
                });
            })
        }
    }, [selectedStore])
//----------------------------------
const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)

    const URL = process.env.NODE_ENV === "development"
          ? "http://localhost:3303"
          : "https://www.devtest21-api.g2m-tn.com";

          const [storeGrp, setStoreGrp] = useState([]);

          const [checkedAll, setCheckedAll] = React.useState(false);

          const handleSearch = (search) => {
            //setCancelB(false)
            if(search){
            const list=data.filter((element)=>{
              
                //console.log("search_______________________",search)

                return (
                  (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
                  || (element?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        )
              
            })
            setRowsPerPage(list.length)
            setProducts(products=>[...list])
          }else{
            setRowsPerPage(data.length)
            setProducts(products=>[...data])
          }
          };
          const headerRenderer = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => (
            <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} </div>
          );
          const headerRowRenderer = ({
            className,
            columns,
            style
          }) => (
            <div
              className={className+' bg-light '}
              role='row'
              style={style}
            >
              {columns} 
            </div>
          )
          function filterData(params) {
            //console.log('list_____________________',params)
            if(!filter){
              setFilter(filter=> true)
            }
            if(params==="label"){
                //console.log('list_____________________',params)

                if(desc){
                  const list=data.sort((a,b)=> (a.label.toUpperCase()>b.label.toUpperCase())-(a.label.toUpperCase()<b.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.label.toUpperCase()<b.label.toUpperCase())-(a.label.toUpperCase()>b.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }
              }
              else if(params==="brand"){
                if(desc){
                  const list=data.sort((a,b)=> (a.brand.name.toUpperCase()>b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()<b.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.brand.name.toUpperCase()<b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()>b.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }
              }else if(params==="category"){
                if(desc){
                  const list=data.sort((a,b)=> (a.category.name.toUpperCase()>b.category.name.toUpperCase())-(a.category.name.toUpperCase()<b.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.category.name.toUpperCase()<b.category.name.toUpperCase())-(a.category.name.toUpperCase()>b.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }
              }else if (params==="typology"){
                if(desc){
                  const list=data.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
                  setDesc(desc=> !desc)
                  setProducts(products => [...list])
                }
              }
            
          }
          const rowRenderer = ({
            rowData,
            className,
            columns,
            style
          }) => {
            //console.log('f_________________________',rowData)
           return (
            <div
            key={rowData.id}
              className={className}
              role='row'
              style={{...style,backgroundColor:chosenProducts.find(el => el.id === rowData.id)?"#BCF0A9":''}}
            >
              {columns} 
            </div>
          )}

          const memoProducts=useMemo(()=>chosenProducts?.length>0 &&chosenProducts.map((product, index) => (
            <Col lg="2" key={index}>
                <Link to="#" className="text-body d-flex align-items-center">
                    <span className="me-auto">{product.label}</span>
                    <i
                        className="mdi mdi-trash-can text-danger font-size-16 me-2"
                        onClick={() => { setChosenProducts(chosenProducts.filter((prduct, i) => i !== index)) }}
                    />
                </Link>
            </Col>
        )),[chosenProducts])
    return (
        <>
        <input
              className="mb-1"
             size={25}
              placeholder="search"
              type="text"
              onChange={(e) => { handleSearch(e.target.value) }}
          />
            {products.length>0&&<div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                <div
                    // className="table-responsive mb-0"
                    // data-pattern="priority-columns"
                    style={{height:600,}}
                >
                    {/* <Table
                        id="mydatatable"
                        className="table table-bordered"
                    >
                        <Thead>
                            <Tr>
                                <Th>Image</Th>
                                <Th>Label</Th>
                                <Th>Brand</Th>
                                <Th>Category</Th>
                                <Th>Typology</Th>
                                <Th>Actons</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {products?.map((product, index) => {
                                return (
                                    <Tr key={index} style={{backgroundColor: chosenProducts.find(el => el.id === product.id)?("#BCF0A9"):('')}}>
                                        <Td>
                                            <img
                                                src={product.path}
                                                style={{ width: "4rem", height: "4rem" }}
                                            />
                                        </Td>
                                        <Td>
                                            <p style={{ marginTop: "1.5rem" }}> {product.label} </p>
                                        </Td>
                                        <Td>
                                            <p style={{ marginTop: "1.5rem" }}> {product.brand?.name} </p>
                                        </Td>
                                        <Td>
                                            <p style={{ marginTop: "1.5rem" }}> {product.category?.name} </p>
                                        </Td>
                                        <Td>
                                            <p style={{ marginTop: "1.5rem" }}> {product.typology} </p>
                                        </Td>
                                        <Td>
                                            {!chosenProducts.find(obj => obj.id === product.id) &&
                                                <div style={{ marginTop: "1.5rem" }}>
                                                    <Link
                                                        to="#"
                                                        onClick={() => { updateChosenProducts(product) }}
                                                    >
                                                        select...
                                                    </Link>
                                                </div>
                                            }
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table> */}
                                                <AutoSizer>

{({height, width}) => (
  <>
  
  {/* <TextField placeholder='search...' style={{alignSelf:'flex-end',width:200,}} size='small' onChange={(e)=>handleSearchStores(e.target.value)}/> */}

  <Table
  gridStyle={{
    direction: 'inherit'
  }}
  width={width}
  rowRenderer={rowRenderer}
  headerRowRenderer={headerRowRenderer}
  height={height-70}
  headerHeight={30}
  rowHeight={80}
  rowCount={products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
  rowGetter={({index}) => products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
  >    
    <Column label="Image" dataKey="path" width={width}  cellRenderer={({cellData,rowData}) => <img src={URL+rowData.path} onError={(e) => { e.target.src = logoDark }} style={{ width: "4rem", height: "4rem", }} />}/>                                       
    <Column label="label" dataKey="label"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.label}</h6>}/>
    <Column label="Brand" dataKey="brand"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6 >{rowData?.brand?.name}</h6>}/>
    <Column label="Category" dataKey="category"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.category?.name}</h6>}/>
    <Column label="Typology" dataKey="typology"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData.typology}</h6>}/>

    <Column label="available"    width={width}  dataKey="available"    cellRenderer={({cellData,rowData}) =>  
    
    {return !chosenProducts.find(obj => obj.id === rowData.id) &&
        <div style={{ marginTop: "1.5rem",color:'red' }}>
            <Link
            
                to="#"
                onClick={() => { updateChosenProducts(rowData) }}
            >
                select...
            </Link>
        </div>
    }
    }/> 

    
 </Table>

          

       </>
)} 


</AutoSizer> 
                </div>
                <TablePagination
            component="div"
            //style={{width:1100}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: products.length }]}
            colSpan={3}
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
            
          />
            </div>}
            <div style={{ marginTop: "2rem" }}>
                <StoreModal
                    selectedStore={selectedStore}
                    setSelectedStore={setSelectedStore}
                />
            </div>
            <Row style={{ marginTop: "4rem" }}>
                {memoProducts}
            </Row>
        </>
    )
}

export default SecondPart
