import React, { useCallback, useEffect, useState, useRef } from 'react'
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import { getStoresAsync } from "store/pos/actions";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Link } from 'react-router-dom';
import {
    DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridPrintExportMenuItem,
    GridSearchIcon,
} from '@mui/x-data-grid';
import { Autocomplete, Box, Chip, FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Popover, Select, Stack, TextField, Typography } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import { Row, Card, CardBody, Col, Button } from "reactstrap"
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import { getStoreGroupsAsync } from 'store/storeGroup/actions'
import StoresTable from './StoresTable';

import { Collapse } from 'reactstrap'
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'

//Export
const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

const JsonExportMenuItem = (props) => {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                const jsonString = getJson(apiRef);
                const blob = new Blob([jsonString], {
                    type: 'text/json',
                });

                exportBlob(blob, 'DataGrid_demo.json');

                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export JSON
        </MenuItem>
    );
};

JsonExportMenuItem.propTypes = {
    hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem />
        <JsonExportMenuItem />
    </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
        <GridSearchIcon align='right' />
    </GridToolbarContainer>
);


function SelectStore(props) {

    const [storeGroups, setStoreGroups] = useState([])

    // const getStoreGroups = async () => {
    //     setStoreGroups(dispatch(await getStoreGroupsAsync()).payload.storeGroups)
    // }
    const columns = [
        { field: 'id', headerName: 'id', width: 50},
        { field: 'path', headerName: 'Image', width: 200, height: 1050, editable: false, renderCell: params => <img width={90} src={params.row.path} /> },
        { field: 'name', headerName: 'Name', width: 200, editable: true, renderCell: params => <Button variant="outlined" color='dark'style={{fontSize: '15px'}}> {params.row.name}</Button> },
        { field: 'address', headerName: 'Adress', align: 'left', width: 200, renderCell: params => <Button css={{ backgroundColor: 'blue' }} color='primary' style={{fontSize: '15px'}}>{params.row.address}</Button> },
        { field: 'governorate', headerName: 'Governorate', align: 'left', editable: true, width: 200, renderCell: params => <Button variant="outlined" color='light'  style={{fontSize: '15px'}}>{params.row.governorate}</Button> },
        { field: 'phone_number', headerName: 'Phone_number', align: 'left', width: 200, renderCell: params => <Button variant="outlined" color='light'   style={{fontSize: '15px'}}> {params.row.phone_number}</Button> },
        { field: 'email', headerName: 'Email', align: 'left', width: 200, renderCell: params => <Button variant="outlined" color='light'   style={{fontSize: '15px'}}> {params.row.email}</Button> },
        //{ field: 'typology', headerName: 'Typology', align: 'left', width: 200, renderCell: params => <Button variant="outlined" color='light'   style={{fontSize: '15px'}}> {params.row.typology}</Button> },
        // //{ field: 'storeGrp', headerName: 'StoreGrp', align: 'left', editable: true, width: 150, renderCell: params => showCellStoreGrp(params.row.storeGrp) },
        // //{ field: 'gouvernement', headerName: 'Gouvernement',align:'center', width: 100 ,renderCell:params=><DateNumbers number={params.row.tempPasse}/>},

        //{ field: 'action', headerName: 'Actions', align: 'center', width: 100, editable: false, renderCell: params => <Button variant="outlined" color='primary' onClick={()=>selectedItem(params.row.path)}>Select...</Button> },

    ];
    const [col, setCol] = useState(0)
    //table options
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ['label'],
    });

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);

    }, []);

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const { chosenStores, setChosenStores,dataStores } = props
    const dispatch = useDispatch();
    //const [products, setProducts] = useState([])
    const [stores, setStores] = useState([])
    const [ListIds, setListIds] = useState([])

    // const getStores = async () => {
    //     setStores(dispatch(await getStoresAsync()).payload.stores)
    // }
    // const getProducts = async () => {
    //     setProducts(dispatch(await getProductsAsync()).payload.products);
    // }


    function deleteSelected(id,storeId){
        //console.log("delete_______________________",storeId)

        setChosenStores(chosenStores.filter((prduct, i) => i !== id))
        setListIds(ListIds.filter((p,i) => i !== id))

        let list=storeGroups.map((elem)=>{

                elem?.stores.map(store=> {

                    if(store.id===storeId){
                        //console.log("stores_______________________",store?.id)

                        store.selected=false
                    
                    }
                } )
                return elem
        })
        //setStoreGroups(storeGroups=>[...list])
    }

    
    // function selectedItem(ids) {
    //     //console.log(ids);
    //     const selectedIDs = new Set(ids);
    //     const selectedRowData = stores.filter((row) =>
    //         selectedIDs.has(row.id));
    //     //console.log(selectedIDs);
    //     setChosenStores(selectedRowData)
    //     setListIds(ids)
    // }
    //console.log(storeGroups)
    useEffect( () => {
        setStoreGroups([...dataStores])
        //await getStores()
        //getStoreGroups()
        

    }, [dataStores])

    const ItemContainer = styled.div`
    padding: 0rem;
    width: 33%;
    display: flex;
    flex: none;
    align-content: stretch;
    box-sizing: border-box;
  
    @media (max-width: 1024px) {
      width: 50%;
    }
  
    @media (max-width: 300px) {
      width: 100%;
    }
  `
  
  const ItemWrapper = styled.div`
    flex: 1;
    text-align: center;
    font-size: 20%;
    padding: 0.1rem 0.1rem;
    border: 1px solid var(gray);
    white-space: nowrap;
  `
  
  const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;`
    return (
        <>
            <Row >
                {/* {chosenStores.map((store, index) => (
                    <Col lg="2" key={index}>
                        <Link to="#" className="text-body d-flex align-items-center">
                            <span className="me-auto">{store.name}</span>
                            <i
                                className="mdi mdi-trash-can text-danger font-size-16 me-2"
                                onClick={() => { deleteSelected(index) }}
                            />
                        </Link>
                    </Col>
                ))} */}
                {chosenStores.length>0 && <VirtuosoGrid
                    style={{ height: 100,width:'100%' }}
                    data={chosenStores}
                    totalCount={chosenStores.length}
                    overscan={0}
                    components={{
                        Item: ItemContainer,
                        List: ListContainer,
                        ScrollSeekPlaceholder: ({ height, width, index }) => (
                        <ItemContainer>
                            <ItemWrapper>{'-'}</ItemWrapper>
                        </ItemContainer>
                        ),
                    }}
                    itemContent={(index,elem) => <ItemWrapper>
                        <Chip
                        key={elem.id}
                        label={elem?.name}
                        style={{fontSize:15}}
                        //onClick={handleClick}
                        onDelete={()=>deleteSelected(index,elem.id)}
                        deleteIcon={<DeleteIcon style={{color:'red'}}/>}
                        variant="outlined"
                    /></ItemWrapper>}
                    // scrollSeekConfiguration={{
                    //     enter: velocity => Math.abs(velocity) > 200,
                    //     exit: velocity => Math.abs(velocity) < 30,
                    //     change: (_, range) => console.log({ range }),
                    // }}
                    />}
            </Row>
            {storeGroups.map((group, index) => (
                <div className="accordion-item" key={index} >
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={col === index + 1 ? ("accordion-button fw-medium") : ("accordion-button fw-medium collapsed")}
                            type="button"
                            style={{ cursor: "pointer" }}
                            onClick={() => { if (col !== index + 1) { setCol(index + 1) } else { setCol(0) } }}
                        >
                            {group.name}
                        </button>
                    </h2>
                    <Collapse isOpen={col === index + 1} className="accordion-collapse">
                        <StoresTable
                            key={index}
                            i={index}
                            data={group?.stores}
                            chosenStores={chosenStores}
                            setChosenStores={setChosenStores}
                            setCol={setCol}
                        />
                    </Collapse>
                </div>
            ))}
        </>
    )
}

export default SelectStore