import { Breadcrumbs } from '@mui/material'
import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import MButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreatePromotion from './components/CreatePromotion';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: '99%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "hidden",
    overflowY: "scroll"
    
};

function Promotion() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="page-content">
            <MetaTags>
                <title>Promotion</title>
            </MetaTags>
            <Container fluid>
                <Breadcrumbs title="Promotion" breadcrumbitem="Promotion" />
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <CardTitle>Promotion</CardTitle>
                            </Col>
                            <Col align="right">
                                <MButton css={{ backgroundColor: 'blue' }} color='primary' aria-describedby='chart' onClick={handleOpen}><AddAlertOutlinedIcon /> &nbsp; Promotion</MButton>

                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style }} display={'flex'} flexDirection={'column'} >
                    <Row >
                        <Col>
                            <h3 id="child-modal-title">Create Promotion</h3>
                        </Col>
                        <Col align="right">

                            <CloseOutlinedIcon onClick={handleClose} style={{ cursor: "pointer", fontSize: "1.3rem" }} />
                        </Col>
                    </Row>

                    <Row>
                    <CreatePromotion setOpen={setOpen}/>
                    </Row>


                </Box>
            </Modal>
        </div>
    )
}

export default Promotion