import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

import MButton from '@mui/material/Button';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
//import FullWidthTabs from './fullWidhTab';



function DataChart({data}) {

    const dataList=data.sort((a,b) => a.day - b.day)
    //console.log(dataList)
    //list totale commande
    const order=[]
    const stockOut=[]
    const nbrVisitP=[]
    const nbrVisitE=[]
    const [showData,setShowData]=useState(false)
    //list marchandizer
    const listMarchandizer=[]
    const users=[]
    //console.log("stock ",data)
    useEffect(()=>{
        dataList.map((d,index)=>{
            
            order.push(d.order)
            stockOut.push(d.stockOut)
            listMarchandizer.push(dayjs(d.day).format('YYYY-MM-DD'))
            users.push(d.user)
        })
        //console.log(order)
    },[])

    // chart options

const areaChartOptions =  {
    chart: {
        height: 350,
        type: 'rangeArea',
      },
      
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    dataLabels: {
        enabled: true
    },
    stroke: {
        //curve: 'smooth',
        width: [3,2,1]
    },
    fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
    grid: {
        strokeDashArray: 40
    },
    plotOptions: {
        // bar: {
        //     distributed: true
        //   }
      },

    grid: {
        strokeDashArray: 40
    },
    xaxis: {
        
        categories:listMarchandizer,
        borderColor: '#999',
        yAxisIndex: 0,
        tickAmount: 6,
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: true
        },
        label: {
            show: true,
            text: 'Rally',
            style: {
              color: "#fff",
              background: '#775DD0'
            }
          }
    },
    yaxis: [
        {
        title: {
            text: "Stock Out",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        max: 1,
        min:0,
        show: true,
        showForNullSeries: true,
        // stroke: {
        //     curve: 'smooth',
        // },
        axisBorder: {
            show: true,
            color: '#008FFB',
            offsetX: 0,
            offsetY: 0,
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#008FFB',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },

        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    
    {
        show: true,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Order"
        },
        max: Math.max(...order).toPrecision(2),
        min:0,
        axisBorder: {
            show: true,
            color: '#0CF02A',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#0CF02A',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },

    {
        show: false,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Users"
        },
        
        axisBorder: {
            show: true,
            color: '#FF1654',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#FF1654',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    // {
    //     show: true,
    //     showForNullSeries: true,
    //     opposite: false,
    //     title: {
    //         text: "Taux de ruptures"
    //     },
    //     max: Math.max(...ruptures)+Math.max(...ruptures)/2,
    //     min:0,
    //     axisBorder: {
    //         show: true,
    //         color: '#D998E3',
    //         offsetX: 0,
    //         offsetY: 0
    //     },
    //     axisTicks: {
    //         show: true,
    //         borderType: 'solid',
    //         color: '#D998E3',
    //         width: 6,
    //         offsetX: 0,
    //         offsetY: 0
    //     },
    //     crosshairs: {
    //         show: true,
    //         position: 'back',
    //         stroke: {
    //             color: '#b6b6b6',
    //             width: 1,
    //             dashArray: 0,
    //         },
    //     },
    // },
   ],

    
};
    
    const theme = useTheme();

    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);
    //const [options2, setOptions2] = useState(areaChartOptions2);

    //toggle series :
    function toggleSeries(){
        setShowData(true)
    }

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            
            colors: ['#008FFB', '#0CF02A', '#FF1654','#D998E3','#D998E3'],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                },
                type: 'string',
                categories: listMarchandizer,
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },

            tooltip: {
                theme: 'dark',
                followCursor: true,
                //shared: false,
                //intersect: true,

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                paddingBottom:5
              },

            title: {
                text: 'Output Graph',
                align: 'center',
                margin: 0,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                fontSize:  '20px',
                fontWeight:  'bold',
                color:  '#263238'
                },
              },

        }));

    }, [primary, secondary, line, theme]);

    const [series] = useState([
        {
            name: 'Stock Out',
            data: stockOut,
            type:"line",
    
        },
        {
            name: 'Order',
            data: order,
            type:"bar",
    
        },

        
    ]);
  return (
    <Box sx={{ height: 530, width: '100%',}} >
    <ReactApexChart options={options} height={530} width={'100%'} series={series}  />
    </Box>
  )
}

export default DataChart