import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useEffect } from 'react';

function Maps(props) {

  const { lat, lng } = props;
  //console.log("coordonné ",lat,lng)
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  useEffect(()=>{
    //console.log("thuffick_____________",lat,lng)
    if(lat>0 && lng>0){
      setLatitude(lat)
      setLongitude(lng)
    }
  },[lat,lng])

  return (
   latitude>0 && longitude >0 ? <div>
      <Map
      //yesIWantToUseGoogleMapApiInternals
      // center={{
      //   lat: latitude,
      //   lng: longitude,
      // }}
      google={props.google}
      zoom={8}
      
      //centerAroundCurrentLocation
      initialCenter={{
        lat: latitude,
        lng: longitude,
      }}
    >
      <Marker 
                lat={latitude}
                lng={longitude} 
                name={'Current location'}/>
    </Map>
    </div>: ''
  )
}

export default GoogleApiWrapper({
   //apiKey: "AIzaSyDyIUz248P92vDZF_JALddrNxCcT5nu86o"
  //apiKey: "AIzaSyCFBTspt-i0CI5ZNfCo77KR_zBucGgqNiU"
  apiKey: "AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8"
  
})(Maps)