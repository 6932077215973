import React, { useEffect, useState } from 'react'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { MetaTags } from 'react-meta-tags';
import { Row, Card, CardBody,Col } from "reactstrap"
import MButton from '@mui/material/Button';
import { Box, } from '@mui/material';
import { getProductsAsync } from "store/product/actions";
import { useDispatch } from "react-redux";
import DataTableProducts from './components/DataTableProducts';
import LinearProgress from '@mui/material/LinearProgress';
import { getStocksAsync } from 'store/stock/actions'
import dayjs from 'dayjs';
import { getProductsOutput } from 'store/product/services';
import { useQuery } from 'react-query';
function OutputProduct() {
  const dispatch = useDispatch();
  const [loader, setLoder] = useState(false)
  const [products, setProducts] = useState([])
  const [stocks, setStocks] = useState([])
  const List=[]
  //products


  const {data,status,isLoading,isFetching} =useQuery('outputProducts', getProductsOutput,{
    refetchOnWindowFocus:false,
    //refetchInterval:0
     //staleTime:'infinity',
    // initialData:undefined
    //refetchOnMount:false
    cacheTime:7200000
  })
  
  //console.log("cashe---------------",{isLoading,isFetching},data?.length)
// useEffect(async () => {
//   await getProducts()
  
//   }, [])


  return (
    <div className="page-content">
                    <Card>
                        <CardBody>
                            <Row>
                                <MButton css={{backgroundColor:'blue'}} color='primary' aria-describedby='chart' ><Inventory2OutlinedIcon/> &nbsp; Output Products</MButton>
                            </Row>
                        </CardBody>
                    </Card>
    {isLoading && (
                    <div>
                      <Box sx={{ width: '300%',height:'200%' }}>
                     
      <LinearProgress />
    </Box>
                    </div>
        )}
            
            {!isLoading && (
            <div><MetaTags>
                    <title>Output Products</title>
                </MetaTags>
                <div>
                    
                   

    <Box sx={{ height: 1030, width: '100%' ,marginBottom:20}}>
      

                      <DataTableProducts data={data}  />
                        
    </Box>

                </div>
              </div>
                
                
                
                )}
            </div>
  )
}

export default OutputProduct