import React, { useEffect, useState } from 'react'
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { Button, Card, CardFooter, CardImg, Col, Modal, Row, UncontrolledTooltip } from 'reactstrap'
import ImageUpload from 'shared/ImageUpload';
import MutipleImagesUpload from 'shared/MutipleImagesUpload';
import Form from './Form';
import Maps from '../../../shared/Maps';
import { upsertStore,upsertCompany, upsertLocation } from "store/pos/services";
import { Link } from 'react-router-dom';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
//import Mb from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { AvField } from 'availity-reactstrap-validation';
import queryClient from 'queryClient';

const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";

export default function UpdateModal(props) {

    const { currentStore, getList, storeGroups } = props;
    const [screen, setScreen] = useState("update")
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [selectedFile, setselectedFile] = useState(null)
    const [selectedFileCompany, setselectedFileCompany] = useState(null)
    const [selectedFiles, setselectedFiles] = useState([])
    const [store, setStore] = useState(currentStore)
    const [errorMessage, setErrorMessage] = useState(false)
    const [customValues, setCustomValues] = useState([])
    const [lat, setLat] = useState(store?.lat)
    const [lng, setLng] = useState(store?.lat)
    const [loading, setLoading] = useState(false)

    
    useEffect(()=>{
        if(modal_fullscreen){
            setStore(currentStore)
            //console.log("data____________________________",currentStore)
        }

    },[currentStore,modal_fullscreen])

    function resetLocation(){
        setStore({...store,lat:0,lng:0})
        // store.lat=null
        // store.lng=null
        setLat(0)
        setLng(0)
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },1000)
    }

    const updateStore = async () => {
        // console.log("store id: ",store.id)
        // console.log("lat & lng: ",lat,lng)
        let id=store.id
        
       await upsertStore(store, selectedFile,
            //selectedFileCompany,
             selectedFiles, customValues).then(() => {
                
            //getList()
            queryClient.invalidateQueries('stores')
            tog_fullscreen()
        })
        .catch((err) => {
            setErrorMessage(err.response.data.message)
            setTimeout(() => { setErrorMessage(false) }, 3000);
        })
        // if(lat && lng){
        //      upsertLocation(id,lat,lng)
        //  }
        //  if(selectedFileCompany){
        //      upsertCompany(id,selectedFileCompany)
           
        //  }
        
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        document.body.classList.add("no_padding")
    }

    function success(pos) {
        let crd = pos.coords;
        setStore({
            ...store,
            lat: crd.latitude,
            lng: crd.longitude
        })
    }

    // useEffect(() => {
    //   console.log("store_____________",store)
    // }, [store])
    

    return (
        <>
            <Link to="#" onClick={() => { tog_fullscreen() }} style={{ fontSize: "1.5rem" }}>
                <i className="bx bx-edit-alt" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                </UncontrolledTooltip>
            </Link>
            <Modal
                //size="xl"
                style={{padding:'5px 25px 5px 10px'}}
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Update Store
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
               {store && storeGroups&& <div className="modal-body">
                    <Row>
                        <Col xs="12">
                            <AvForm
                                onValidSubmit={() => { updateStore() }}
                            >
                                <Form
                                    screen={screen}
                                    store={store}
                                    setStore={setStore}
                                    storeGroups={storeGroups}
                                />
                                <LoadingButton loading={loading} color="error" onClick={()=>resetLocation()} variant="outlined">
                                    <WrongLocationIcon/> &nbsp; Reset Location 
                                    </LoadingButton> 

                                {store&&<Row style={{ marginTop: "1rem" }}>
                                <Col sm="6">
                                <AvField
                                    className="col-2"
                                    name="lat"
                                    label="Lat"
                                    placeholder="Lat"
                                    type="number"
                                    errorMessage="Lat is required."
                                    validate={{
                                        required: { value: false },
                                        
                                    }}
                                    value={store.lat}
                                    onChange={(e) => { setStore({...store,lat:parseFloat(e.target.value)}) }}
                                />
                                
                                </Col>
                                <Col sm="6">
                                <AvField
                                    className="col-6"
                                    name="lng"
                                    label="Lng"
                                    placeholder="Lng"
                                    type="number"
                                    errorMessage="Lng is required."
                                    validate={{
                                        required: { value: false },
                                    }}
                                    value={store.lng}
                                    onChange={(e) => {  setStore({...store,lng:parseFloat(e.target.value)}) }}
                                />
                                
                                </Col>
                                
                                    {/* <Button
                                        className="waves-effect waves-effect waves-light btn-outline"
                                        color="primary"
                                        outline
                                        style={{ width: "98%", height: "3rem", fontSize: "1.3rem", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }}
                                        type="button"
                                        onClick={() => { navigator.geolocation.getCurrentPosition(success) }}
                                    >
                                        <i className="bx bxs-map" data-toggle="modal"></i>
                                        {" "} update location 
                                    </Button> */}
                                </Row>}
                                {store.lat&& store.lng &&
                                    <Row style={{ height: "19rem", width: "100%" ,marginTop:10}}>
                                        <Maps lat={store.lat} lng={store.lng} />
                                    </Row>
                                }
                                <Row style={{ marginTop: "2rem" }}>
                                    <h5 className="mb-3">Store picture :</h5>
                                    <Col sm={6}>
                                        <ImageUpload setselectedFile={setselectedFile} />
                                    
                                    </Col>
                                    <Col>
                                        {(store.path || selectedFile?.preview) &&
                                            <img
                                                style={{ width: "auto", height: "14rem" }}
                                                data-dz-thumbnail=""
                                                className="avatar-sm rounded bg-light"
                                                alt={store.name}
                                                src={(store.path && !selectedFile?.preview) ? URL+store.path : selectedFile?.preview}
                                            />
                                        }
                                    </Col>
                                    
                                </Row>
                                
                                {/* ****nouveau***** */}
                                {/* <Row style={{ marginTop: "2rem" }}>
                                <h5 className="mb-3">Company picture :</h5>
                                    <Col sm={6}>
                                        <ImageUpload setselectedFile={setselectedFileCompany} />
                                    </Col>
                                    <Col>
                                        {(store.company || selectedFileCompany?.preview) &&
                                            <img
                                                style={{ width: "auto", height: "14rem" }}
                                                data-dz-thumbnail=""
                                                className="avatar-sm rounded bg-light"
                                                alt={selectedFileCompany ? (selectedFileCompany.name) : (store.id)}
                                                src={(store.company && !selectedFileCompany?.preview) ? (store.company) : (selectedFileCompany?.preview)}
                                            />
                                        }
                                    </Col>
                                    
                                </Row> */}
                                <Row style={{ marginTop: "2rem" }}>
                                    <h5 className="mb-3">images :</h5>
                                    {selectedFiles.map((picture, index) => (
                                        <Col key={index} xl="1" lg="1">
                                            <Card>
                                                <CardImg
                                                    src={picture.preview}
                                                    alt="Skote"
                                                    style={{ cursor: "pointer", marginLeft: "1rem", marginBottom: "1rem" }}
                                                    className="rounded avatar-xl"
                                                />
                                                <CardFooter>
                                                    <button
                                                        type="button"
                                                        style={{ marginTop: "-1.5rem", width: "7.6rem", marginLeft: "-0.3rem" }}
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => { setselectedFiles(selectedFiles.filter((q, i) => i !== index)); }}
                                                    >
                                                        <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                                                        Delete
                                                    </button>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    ))}
                                    <MutipleImagesUpload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                                </Row>
                                {errorMessage &&
                                    <ErrorAlert errorMessage={errorMessage} />
                                }
                                <div className="d-flex flex-wrap gap-2" style={{ float: "right", marginTop: "1rem" }}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn waves-effect waves-light"
                                    >
                                        Update store
                                    </Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                </div>}
            </Modal>
        </>
    )
}
