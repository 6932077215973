import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import './style.css'
import MButton from '@mui/material/Button';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import FullWidthTabs from './fullWidhTab';

// ==============================|| REPORT AREA CHART ||============================== //


const VisiteChart = ({data}) => {

    const dataList=data
    //console.log(dataList)
    //list totale commande
    const ruptures=[]
    const visite=[]
    const nbrVisitP=[]
    const nbrVisitE=[]
    const [showData,setShowData]=useState(false)
    //list marchandizer
    const listMarchandizer=[]
    dataList.map((d,index)=>{
        //console.log("stock ",d.nbStockOut,d.nbStock)
        if(isNaN(d.performance)||(isNaN(d.nbStockOut) && isNaN(d.nbStock))){
            visite.push(0)


        }else if(d.performance>100){
            
            visite.push(100)
        }else{
            visite.push(d.performance)
        }

        if((isNaN(d.nbStockOut) || isNaN(d.nbStock) || ((d.nbStockOut==0) && (d.nbStock==0)))){
            ruptures.push(0)
            
        }else{

            ruptures.push(Number(parseFloat(((d.nbStockOut*100)/d.nbStock)).toFixed(2)))
        }
        nbrVisitP.push(d.nbrVisitP)
        nbrVisitE.push(d.nbrVisitE)
        listMarchandizer.push(d.name)

    })

    // chart options

const areaChartOptions =  {
    chart: {
        height: 350,
        type: 'rangeArea',
      },
      
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    dataLabels: {
        enabled: true
    },
    stroke: {
        //curve: 'smooth',
        width: [3,2,1]
    },
    fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
    grid: {
        strokeDashArray: 40
    },
    plotOptions: {
        // bar: {
        //     distributed: true
        //   }
      },

    
    xaxis: {
        
        categories:listMarchandizer,
        borderColor: '#999',
        yAxisIndex: 0,
        tickAmount: 6,
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: true
        },
        label: {
            show: true,
            text: 'Rally',
            style: {
              color: "#fff",
              background: '#775DD0'
            }
          }
    },
    yaxis: [
        {
        title: {
            text: "Visite Performance",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        max: 100,
        min:0,
        show: true,
        showForNullSeries: true,
        // stroke: {
        //     curve: 'smooth',
        // },
        axisBorder: {
            show: true,
            color: '#008FFB',
            offsetX: 0,
            offsetY: 0,
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#008FFB',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },

        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    
    {
        show: true,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Visite planifié et non effectué"
        },
        max: Math.max(nbrVisitP).toPrecision(2),
        min:0,
        axisBorder: {
            show: true,
            color: '#0CF02A',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#0CF02A',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },

    {
        show: true,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Visite  effectué et non planifié"
        },
        max: Math.max(nbrVisitE).toPrecision(2),
        min:0,
        axisBorder: {
            show: true,
            color: '#FF1654',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#FF1654',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    {
        show: true,
        showForNullSeries: true,
        opposite: false,
        title: {
            text: "Taux de ruptures"
        },
        max: Math.max(...ruptures)+Math.max(...ruptures)/2,
        min:0,
        axisBorder: {
            show: true,
            color: '#D998E3',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#D998E3',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
   ],

    
};
    
    const theme = useTheme();

    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);
    //const [options2, setOptions2] = useState(areaChartOptions2);

    //toggle series :
    function toggleSeries(){
        setShowData(true)
    }


      useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            
            colors: ['#008FFB', '#0CF02A', '#FF1654','#D998E3','#D998E3'],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                },
                type: 'string',
                categories: listMarchandizer,
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },

            tooltip: {
                theme: 'dark',
                followCursor: true,
                //shared: false,
                //intersect: true,

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                paddingBottom:20
              },

            title: {
                text: 'Visit Graph',
                align: 'center',
                margin: 20,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                fontSize:  '20px',
                fontWeight:  'bold',
                color:  '#263238'
                },
              },

        }));

    }, [primary, secondary, line, theme]);

    const [series] = useState([
        {
            name: 'Visite performance (%)',
            data: visite,
            type:"line",

        },
     
        {
            name: 'Visite planifié et non effectué',
            data: nbrVisitP,
            type: 'column',
        },
        {
            name: 'Visite  effectué et non planifié',
            data: nbrVisitE,
            type: 'column',
        },
        {
            name: 'Taux de ruptures',
            data: ruptures,
            type: 'area',
        },
        
    ]);
  

    return (
        <ReactApexChart options={options}  series={series}  />
    )
};

export default VisiteChart;


///////tab content/////

