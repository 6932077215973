import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Box, Checkbox,  FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Popover, Select, TablePagination, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import MIButton from '@mui/material/Button';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import pptxgen from "pptxgenjs";
// import image from 'assets/images/layouts/Image1.jpg'
// import image2 from 'assets/images/layouts/Image2.jpg'
// import image3 from 'assets/images/layouts/Image3.jpg'
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportPresentation from './ExportPresentation';
import { isUndefined } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import { exportPresentationAllAsync, exportPresentationByStoreAsync } from 'store/stock/services';

import { TableVirtuoso,VirtuosoGrid } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { getStoresAsync, getStoresFilterAsync } from 'store/pos/actions';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import Swal from 'sweetalert2';
import { getStoreGroupsFilterAsync } from 'store/storeGroup/actions';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import logo from "../../../assets/images/store.png"

function ExportData({dataStores}) {

    const [url, setUrl] = useState('')
    const dispatch = useDispatch();

    //filter Date start end
    const [dateStart, setDateStart] = useState(dayjs());
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);

    const [data, setData] = useState([]);
    const [storeGrp, setStoreGrp] = useState([]);
    const [listGrp, setListGrp] = useState([]);

    const [cancelB, setCancelB] = useState(false);
    const [times, setTimes] = useState(0);

   
    useEffect(()=>{
      
        //console.log("name_______________________",dataStores?.length)
      if(dataStores?.length>0){
        setRowsPerPage(dataStores?.length)
      setStores([...dataStores])
      setData([...dataStores])}
      
 
    },[dataStores])
    
    const getStoreGrp = async () => {
      return dispatch(await getStoreGroupsFilterAsync()).payload.storeGroupsFilter
  }

  

    function downloadBase64File(contentType, base64Data, fileName) {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
 }
    const handleSearch = async () => {
        //console.log("data________________________________",dateStart.format('YYYY-MM-DD'))
        
        if(selectedStores.length===0){
        //     setCancelB(true)
        // await exportPresentationAllAsync(dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD')).then(()=>{
        //     setCancelB(false)
        // })
        Swal.fire(
            'Waiting',
            'select store...',
            'error'
        )
        }else if(selectedStores.length>500){

            Swal.fire(
                'Waiting',
                'stores limit is 500...',
                'warning'
            )
        }
        else{
            setCancelB(true)
            let pos=0
            let fileExportaion=[]
            for (let i = 0; i <= selectedStores.length; i++) {
                if((pos%100===0) && pos<selectedStores.length){
                    let compressedData=selectedStores.slice(pos,pos+100)
                    //console.log("compressed___________________________",compressedData.length)
                    await exportPresentationByStoreAsync(compressedData,dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD')).then((data)=>{
                        if(data!="Empty"){
                          fileExportaion.push(data)
                        }
                    })
                }
                pos++
                
            }
            //console.log('export________________________________',fileExportaion[0])
            if(fileExportaion.length>0){
              fileExportaion.forEach((elem)=>{
                downloadBase64File('application/vnd.openxmlformats-officedocument.presentationml.presentation',elem.code,JSON.stringify(elem.filename))
  
              })
              setCancelB(false);
              Swal.fire(
                  'Selected stores',
                  'file downloaded successfuly...',
                  'success'
              )
            }else{
              setCancelB(false);
              Swal.fire(
                  'Selected stores',
                  'No data available in stores...',
                  'warning'
              )
            }

            
        }

    };
    const handleChangeStart = (newValue) => {

        setDateStart(newValue);
        //setCancelB(false)
    };
    const handleChangeEnd = (newValue) => {

        setDateEnd(dateEnd=> newValue);
        //setCancelB(false)
    };
    //console.log(stores)
    useEffect( ()=>{
       getStoreGrp().then((data)=>{
        let list=[]
        data.map((elem)=> {
           list.push(elem.name)
          });
          setListGrp([...list])
       })

         
    },[])

    
    /////////////////////////////////////////////
    const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearchStores = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",data.length,stores.length)
    if(search){
    const list=data.filter((element)=>{
      
        
        return (
          (element?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.address?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.governorate?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.email?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.phone_number?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )

)
      
    })
    setRowsPerPage(list.length)
    setStores(stores=>[...list])
  }else{
    setRowsPerPage(data.length)
    setStores(stores=>[...data])
  }
  
  };

  function filterData(params) {
    //console.log('list_____________________',params)
    if(!filter){
      setFilter(filter=> true)
    }
    if(params!="storeGrp" ){
      
      if(desc){
        const list=data.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }else{
        const list=data.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }
    }
    else{
      if(desc){
        const list=stores.sort((a,b)=> (a?.storeGroup?.name>b?.storeGroup?.name)-(a?.storeGroup?.name<b?.storeGroup?.name))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }else{
        const list=stores.sort((a,b)=> (a?.storeGroup?.name<b?.storeGroup?.name)-(a?.storeGroup?.name>b?.storeGroup?.name))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }
    }
    
  }
 

const rowCount=stores.length;



/////////////////////////////////////////////////////////table virtuoso
const columnsVirtuso = [
  {
    width: 80,
    label: '',
    dataKey: 'Selection',
    //numeric: true,
  },
  {
    width: 80,
    label: 'Image',
    dataKey: 'path',
  },
  {
    width: 160,
    label: 'name',
    dataKey: 'name',
  },
  {
    width: 110,
    label: 'storeGrp',
    dataKey: 'storeGrp',
  },
  {
    width: 200,
    label: 'Address',
    dataKey: 'address',
  },
  {
    width: 120,
    label: 'Governorate',
    dataKey: 'governorate',
  },
 
  {
    width: 190,
    label: 'Email',
    dataKey: 'email',
  },
  {
    width: 180,
    label: 'Phone_Number',
    dataKey: 'phone_number',
  },
//   {
//     width: 180,
//     label: 'Action',
//     dataKey: 'Action',
//     //numeric: true,
//   },
 
  
];

const [rowsPerPage, setRowsPerPage] = React.useState(1000);
const [page, setPage] = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const [checkedAll, setCheckedAll] = React.useState(false);

function fixedHeaderContent() {
  return (
    <TableRow sx={{height:10}}>
      {columnsVirtuso.map((column) => 
        {
          if(column.dataKey==="Action" ||column.dataKey==="path"  ){
            return  <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white",height:10 }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
            {column.label}
          </TableCell>
          }else if(column.dataKey==="Selection"){
            return  <TableCell
            key={column.dataKey}
            variant="head"
            //align={'justify'}
            style={{ width: column.width,color:"white", }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
            <Checkbox
              //color="secondary"
              style={{color:'white'}}
              //indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={checkedAll}
              onChange={()=>onSelectAllClick('All')}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          }else{
            return  <TableCell
            onClick={()=>filterData(column.dataKey)}
            key={column.dataKey}
            variant="head"
            height={10}
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white",height:10 }}
            sx={{
              backgroundColor: '#4F6973',height:10
            }}
          >
              <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>

          </TableCell>
          }
        }
    )}
    </TableRow>
  );
}
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} selected={_item?.selected} />,
  TableBody: React.forwardRef((props, ref) => (
  <>
  <TableBody {...props} ref={ref} />
  

  </>)),
  // TableFooter :React.forwardRef((props, ref) => 
  
  
  // )
};


function onSelectAllClick(params) {
    //console.log("onSelectAllClick______________________",params)
   if(params==="All"){
    if(checkedAll){

        let list= stores.map((elem)=>{
            elem.selected=false
            return elem
    
        })
        setSelectedStores(selectedStores=>[])
        setStores(stores=>[...list])
    }else{
        let list= stores.map((elem)=>{
            elem.selected=true
            return elem
    
        })
        setSelectedStores(selectedStores=>[...list])

        setStores(stores=>[...list])
    }
    setCheckedAll(checkedAll=>!checkedAll)

   }else{
    let listSelected=[...selectedStores]
    let list= stores.map((elem)=>{
        if(elem.id===params){
            if(elem?.selected){
                elem.selected=false
                listSelected=listSelected.filter((el)=>el.id!==elem.id)
            }else{
                elem.selected=true
                listSelected.push(elem)

            }
        }
        return elem

    })
    
    setSelectedStores(selectedStores=>[...listSelected])

    setStores(stores=>[...list])
   }
}

const handleDelete = (param) => {
    //console.info('You clicked the delete icon.');
    let listSelected=selectedStores.filter((el)=>el.id!==param)
    let list= stores.map((elem)=>{
        if(param===elem.id){
            elem.selected=false
        }
        return elem

    })
    
    setSelectedStores(selectedStores=>[...listSelected])

    setStores(stores=>[...list])
  };

function rowContent(_index, row) {
  return (
    <React.Fragment >
     {columnsVirtuso.map((column) => {
        //console.log("column______________________",row?.selected)
          if(column.dataKey==="path"){
           return (<TableCell
          key={column.dataKey}
          align={column.numeric || false ? 'right' : 'left'}
          >
            <img src={URL+row[column.dataKey]} onError={(e) => {
     e.target.src = logo // some replacement image
     
     //e.target.style = 'display:none' // inline styles in html format
  }} style={{ width: "4rem", height: "4rem" }} />
          </TableCell>)
          } else if(column.label==="Action") {
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              
              </TableCell>)
          }else if(column.label==="") {
            return (<TableCell padding="checkbox" key={column.dataKey}>
            <Checkbox
               style={{marginLeft:10}} 
              color="primary"
              //indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={row?.selected}
              onChange={()=>onSelectAllClick(row.id)}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>)
          }else if(column.label==="storeGrp") {
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row?.storeGroup?.name}
              </TableCell>)
          }else{
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row[column.dataKey]}
              </TableCell>)
          }
      }
       
      )}
    </React.Fragment>
  );
}

const ItemContainer = styled.div`
  padding: 0.1rem;
  width: 33%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`

const ItemWrapper = styled.div`
  flex: 1;
  text-align: center;
  font-size: 60%;
  padding: 0.1rem 0.1rem;
  border: 1px solid var(gray);
  white-space: nowrap;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;`
  const URL =  process.env.NODE_ENV === "development"
    ? "http://localhost:3303"
    : "https://www.devtest21-api.g2m-tn.com";


    const handleStoreGrp = (event,value) => {
      //console.log("_____________________",value)
      setStoreGrp(value);
      if(value.length>0 ){ 
    

        let filtredList=data?.filter((elem)=> {
          // console.log("filter_______________________________",filtredList.length)
          if(elem?.storeGroup?.name && value?.includes(elem.storeGroup.name)){
            
          return elem;
          }  
        })
        // console.log("_____________________",value)
        setStores(stores=>[...filtredList])
        setRowsPerPage(filtredList.length)
      }else{
        setStores(stores=>[...data])
        setRowsPerPage(data.length)

      }
    };



    return (
        <div className="container-fluid">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='row m-3 d-flex justify-content-evenly'>

                    <DatePicker
                        views={['day']}
                        label="From"
                        value={dateStart}
                        inputFormat="YYYY-MM-DD"
                        onChange={handleChangeStart}
                        renderInput={(params) => <TextField {...params} />}
                        className="col-2 "
                    />
                    <DatePicker
                        views={['day']}
                        label="To"
                        value={dateEnd}
                        inputFormat="YYYY-MM-DD"

                        onChange={handleChangeEnd}
                        renderInput={(params) => <TextField  {...params} />}
                        className="col-2"
                    />
                    <MIButton className="col-2" color='error' style={{width:250,height:55,fontSize:14}} variant="contained" onClick={handleSearch} size="large">
                        <GetAppIcon /> &nbsp;Export Presentation
                    </MIButton>
                </div>

            </LocalizationProvider>

            {cancelB && (
                <div>
                    <Box sx={{ width: '300%', height: '200%' }}>
                        <LinearProgress css={{ height: '100px' }} className='mb-3' />
                        <h3>Downloading...</h3>
                    </Box>
                    <Stack spacing={2}>
                        {/* For variant="text", adjust the height via font-size */}
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%', height: 100, backgroundColor: '#D3D3D3' }} />
                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton variant="rectangular" width={'100%'} height={500} sx={{ backgroundColor: '#D3D3D3' }} />
                    </Stack>

                </div>
            )}
                <hr/>
                
                {selectedStores.length>0 && <VirtuosoGrid
                    style={{ height: 100,width:'100%' }}
                    data={selectedStores}
                    totalCount={selectedStores.length}
                    overscan={10}
                    components={{
                        Item: ItemContainer,
                        List: ListContainer,
                        ScrollSeekPlaceholder: ({ height, width, index }) => (
                        <ItemContainer>
                            <ItemWrapper>{'-'}</ItemWrapper>
                        </ItemContainer>
                        ),
                    }}
                    itemContent={(index,elem) => <ItemWrapper>
                        <Chip
                        key={elem.id}
                        label={elem?.name}
                        style={{fontSize:15}}
                        //onClick={handleClick}
                        onDelete={()=>handleDelete(elem.id)}
                        deleteIcon={<DeleteIcon style={{color:'red'}}/>}
                        variant="outlined"
                    /></ItemWrapper>}
                    // scrollSeekConfiguration={{
                    //     enter: velocity => Math.abs(velocity) > 200,
                    //     exit: velocity => Math.abs(velocity) < 30,
                    //     change: (_, range) => console.log({ range }),
                    // }}
                    />}
          <Row>
          <Col className='col-8'>
                <TextField placeholder='search...' style={{padding:1,marginBottom:10,marginTop:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearchStores(e.target.value)}/>

      </Col>
      <Col>

      {listGrp?.length>0  &&
      <FormControl sx={{ m: 1.5, width: 300, }} size="small">

      <Autocomplete
        size='small'
        onChange={handleStoreGrp}
        value={storeGrp}
        multiple
        id="storeGrp"
        options={[...new Set(listGrp)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Store Group"
            placeholder="Store Group"
          />
        )}
      />
       
      </FormControl>
      }
      </Col>
          </Row>
            {
                
              stores?.length>0 &&  <Box sx={{ height: 730, width: '100%' }}>

                    <TableVirtuoso
                        data={stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        selected
                        
                    />      
                    <TablePagination
                    component="div"
                    //style={{width:1100}}
                    rowsPerPageOptions={[10, 50,100,1000, { label: 'All', value: stores.length}]}
                    //colSpan={3}
                    count={stores.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    //ActionsComponent={TablePaginationActions}
                    />
                </Box>
                
            }
            
        </div>
    )
}

export default ExportData