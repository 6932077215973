import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Container,
  Badge,
} from "reactstrap"
import { getVisitByUser } from "store/visit/services"
import { v4 } from "uuid"
import CalendarBody from "./components/CalendarBody"
import CopyVisits from "./components/CopyVisits"
import { Table, Thead, Tr, Th, Tbody } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useSelector } from "react-redux"

import { Modal } from "reactstrap"

import Select from "react-select"

import Swal from "sweetalert2"

import { getUsersByRole } from "store/user/services"

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import TextField from "@mui/material/TextField"
import { getPlannigDailyAsync } from "store/plannigDaily/actions"

import { useDispatch } from "react-redux"

import { ajoutermission, getMission } from "store/mission/services"
import LoadingComponent from "shared/LoadingComponent"
import { Autocomplete } from "@mui/material"

const MonthCalendar = props => {
  const { merchandisers, selectedMerchandiser, setCalendarType } = props
  const connectedUser = useSelector(state => state.User?.user)
  const [curr, setCurr] = useState(new Date())
  const [days, setDays] = useState([])
  const [data, setData] = useState([])
  const [visits, setVisits] = useState([])
  const [modal_standard, setmodal_standard] = useState(false)
  const dispatch = useDispatch()
  const { userOptions, setSelectedUsers } = props

  const [selectedMerchandiserr, setSelectedMerchandiserr] = useState([])
  const [, setSelectedMerchandiser] = useState([])
  const [merchandiserss] = useState([])

  const [StartDate, setStartDate] = useState(Date())
  const [EndDate, setEndDate] = useState(Date())
  const [nbreJour, setNbreJour] = useState([])
  const [complete, setComplete] = useState(false)

  const [mission, setmission] = useState(undefined)

  const upserMissionAsync = async () => {
    let events = []
    events.push({
      start: new Date(StartDate).setHours(new Date(StartDate).getHours() + 1),
      end: new Date(EndDate).setHours(new Date(EndDate).getHours() + 1),
      userId: selectedMerchandiserr.value,
      text: mission,
    })
    //console.log(selectedMerchandiserr)
    ajoutermission(events).then(() => {
      Swal.fire("Add!", "mission add successfully.", "success").then(() => {
        window.location.reload()
      })
    })
  }

  useEffect(() => {
    getUsersByRole("merchandiser").then(data => {
      data.forEach(element => {
        merchandiserss.push({
            id: element.id,
          value: element.id,
          label: element.first_name + " " + element.last_name,
        })
      })
    })
  }, [])

  useEffect(() => {
    if (connectedUser?.role?.name === "merchandiser") {
      setSelectedMerchandiser(connectedUser.id)
    }
  }, [connectedUser])

  const lastDays = () => {
    let days = new Date(curr.getFullYear(), curr.getMonth(), 0).getDate()
    let pos = new Date(curr.getFullYear(), curr.getMonth(), 0).getDay()
    let lastDays = []
    let diff = days - pos + 1
    while (diff <= days) {
      lastDays.push({
        name: diff,
        date: new Date(curr.getFullYear(), curr.getMonth() - 1, diff),
        items: [],
      })
      diff++
    }
    return lastDays
  }

  const monthDays = () => {
    let days = new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate()
    let currDays = []
    let currEl = 1

    while (days >= currEl) {
      currDays.push({
        name: currEl,
        date: new Date(curr.getFullYear(), curr.getMonth(), currEl),
        items: [],
      })
      currEl++
    }
    return currDays
  }

  const nextDays = () => {
    let days = new Date(curr.getFullYear(), curr.getMonth() + 1, 1).getDay()
    let nextDays = []
    let diff = 7 - days + 1
    let start = 1
    while (start <= diff) {
      nextDays.push({
        name: start,
        date: new Date(curr.getFullYear(), curr.getMonth() + 1, start),
        items: [],
      })
      start++
    }
    return nextDays
  }

  const getVisits = async () => {
    setComplete(complete => true)
    //console.log('date_____________________________',days[0])
    await getVisitByUser(
      selectedMerchandiser,
      days[0]?.date,
      days[days?.length - 1]?.date
    ).then(async visits => {
      setVisits(visits)
      setDays(lastDays().concat(monthDays()).concat(nextDays()))

      const missions = await getMission(selectedMerchandiser)
      days?.forEach(day => {
        visits?.forEach(visit => {
          if (
            new Date(visit.day).getDate() === new Date(day.date).getDate() &&
            new Date(visit.day).getMonth() === new Date(day.date).getMonth()
          ) {
            day.items.push({ id: v4(), visit: visit, mission: false })
          }
        })
        visits.sort((a, b) => {
          return a.order - b.order
        })
        missions?.forEach(visit => {
          if (
            new Date(visit.createdAt).getDate() ===
              new Date(day.date).getDate() &&
            new Date(visit.createdAt).getMonth() ===
              new Date(day.date).getMonth()
          ) {
            day.items.push({
              id: v4(),
              visit: {
                store: {
                  name: visit.text,
                },
              },
              mission: true,
            })
          }
        })
      })
      setData(days)
    })
    setComplete(complete => false)
  }

  const changeMonth = async direction => {
    if (direction === "next") {
      setCurr(new Date(curr.setMonth(curr.getMonth() + 1)))
    } else {
      setCurr(new Date(curr.setMonth(curr.getMonth() - 1)))
    }
  }

  useEffect(() => {
    getVisits()
  }, [selectedMerchandiser, new Date(days[0]?.date).toString()])

  useEffect(() => {
    setDays(lastDays().concat(monthDays()).concat(nextDays()))
  }, [curr])

  function tog_standard() {
    setmodal_standard(!modal_standard)
    if (!modal_standard) {
    }
  }
  return (
    <Card>
      <CardBody>
        <Row style={{ marginBottom: "1rem" }}>
          <Col lg="5" xl="5">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic example"
              style={{ float: "left" }}
            >
              <Button
                color="primary"
                onClick={async () => {
                  changeMonth("prev")
                }}
              >
                <i className="bx bx-chevron-left" style={{ fontSize: 20 }} />
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  setCurr(new Date())
                }}
              >
                Aujourd'hui
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  changeMonth("next")
                }}
              >
                <i className="bx bx-chevron-right" style={{ fontSize: 20 }} />
              </Button>
            </div>
          </Col>
          <Col>
            <h3 style={{ marginTop: "0.5rem" }}>
              {curr.toUTCString().slice(7, 16)}
            </h3>
          </Col>
          <Col>
            <div
              className="btn-group"
              role="group"
              aria-label="Basic example"
              style={{ float: "right", marginLeft: "1rem" }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setCalendarType("week")
                }}
              >
                Semaine
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setCalendarType("month")
                }}
              >
                Mois
              </Button>
            </div>

            {connectedUser?.role?.name != "merchandiser" && (
              <Button
                color="primary"
                className="ms-1 btn btn-primary"
                onClick={() => {
                  tog_standard()
                }}
              >
                Ajouter Mission
              </Button>
            )}

            <Modal
              isOpen={modal_standard}
              size="xl"
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Ajouter Une Mission
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="table-rep-plugin"
                  style={{ marginTop: "2rem", paddingLeft: "30%;" }}
                >
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                    style={{ paddingLeft: "30%" }}
                  >
                    <Row style={{ width: "80%", marginTop: "2%" }}>
                      <Col>
                        <Label style={{ marginTop: "1rem" }}>
                          Choose marchandisers
                        </Label>{" "}
                      </Col>
                      <Col>
                        {/* <Select
                          placeholder="Marchandisers..."
                          options={merchandiserss}
                          isMulti={false}
                          value={userOptions}
                          classNamePrefix="select2-selection"
                          onChange={e => {
                            setSelectedMerchandiserr(e)
                          }}
                        /> */}
                        <Autocomplete
                          size="small"
                          id="marchandisers"
                          onChange={(event, newValue) =>
                            newValue && setSelectedMerchandiser(newValue)
                          }
                          options={[...new Set(merchandisers)].sort(
                            (a, b) => -b.label.localeCompare(a.label)
                          )}
                          //groupBy={option => option?.storeGroup?.toUpperCase()}
                          // getOptionLabel={option => option?.label}
                          // sx={{ width: 300 }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Select merchandiser..."
                            />
                          )}
                          renderOption={(props, option) => (
                            <div key={option.id}>
                              <li {...props}>{option.label}</li>
                            </div>
                          )}
                          // renderGroup={params => (
                          //   <li key={params.key}>
                          //     <GroupHeader>{params.group}</GroupHeader>
                          //     <GroupItems>{params.children}</GroupItems>
                          //   </li>
                          // )}
                        />
                      </Col>
                    </Row>

                    <Row style={{ width: "80%", marginTop: "2%" }}>
                      <Col>
                        <Label style={{ marginTop: "1rem" }}>Date debut </Label>
                      </Col>
                      <Col>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={StartDate}
                            inputFormat="EEEE dd MMM yyyy"
                            style={{ backgroundColor: "red" }}
                            onChange={newValue => {
                              setStartDate(newValue)
                            }}
                            disableMaskedInput
                            renderInput={params => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Col>
                    </Row>

                    <Row style={{ width: "80%", marginTop: "2%" }}>
                      <Col>
                        <Label style={{ marginTop: "1rem" }}>Date fin</Label>
                      </Col>
                      <Col>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            style={{ backgroundColor: "red" }}
                            value={EndDate}
                            disableMaskedInput
                            inputFormat="EEEE dd MMM yyyy"
                            onChange={newValue => {
                              setEndDate(newValue)
                            }}
                            renderInput={params => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Col>
                    </Row>

                    <Row style={{ width: "80%", marginTop: "2%" }}>
                      <Col>
                        <fieldset>
                          <legend> Écrivez votre mission </legend>

                          <textarea
                            id="story"
                            name="story"
                            rows="5"
                            cols="65"
                            placeholder="votre mission"
                            value={mission}
                            onChange={e => setmission(e.target.value)}
                          ></textarea>
                        </fieldset>
                      </Col>

                      <Button
                        style={{
                          height: "90%",
                          width: "20%",
                          color: "#fff",
                          backgroundColor: "#556ee6",
                          borderColor: "#556ee6",
                          marginLeft: "80%",
                          padding: "2%",
                        }}
                        onClick={() => {
                          upserMissionAsync()
                        }}
                      >
                        Envoyer{" "}
                      </Button>
                    </Row>
                  </div>
                </div>
              </div>
            </Modal>

            {connectedUser?.role?.name === "admin" && (
              <CopyVisits
                merchandisers={merchandisers}
                selectedMerchandiser={selectedMerchandiser}
                visits={visits}
                curr={curr}
              />
            )}
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="table table-bordered mb-0">
            <Thead>
              <Tr>
                <Th>Lundi</Th>
                <Th>Mardi</Th>
                <Th>Mercredi</Th>
                <Th>Jeudi</Th>
                <Th>Vendredi</Th>
                <Th>Samedi</Th>
                <Th>Dimanche</Th>
              </Tr>
            </Thead>
            {!complete ? (
              <CalendarBody
                data={data}
                setData={setData}
                getVisits={getVisits}
                selectedMerchandiser={selectedMerchandiser}
              />
            ) : (
              <LoadingComponent />
            )}
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}
export default MonthCalendar
