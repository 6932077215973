import React, { useCallback, useEffect, useState,useRef, useMemo } from 'react'
import { Button } from "reactstrap"
import { TablePagination, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { isValidDate } from '@fullcalendar/core';
import { isUndefined } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from 'pages/Performance/components/tabPanel';
import DataChart from './DataChart';
import { useDownloadExcel } from 'react-export-table-to-excel';
import GetAppIcon from '@mui/icons-material/GetApp';
import MButton from '@mui/material/Button';
import dayjs from 'dayjs';


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function DataTableVisit({data,lastVisit}) {
  //console.log(data)
    // const [visit, setVisit] = useState()
    
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(100);
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };


      const theme = useTheme();
    const [value, setValue] = useState(0);
    useEffect(()=>{
      setValue(0)
      },[data])
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'Users table',
      sheet: 'Users'
  })

  const memoData=useMemo(()=>data?.length>0 &&data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
    <StyledTableRow key={row.id+Math.random(0,100000)}>
      <StyledTableCell component="th" scope="row">
      <Button  variant="outlined" color={row.user? 'primary' :'light'} size='large' style={{fontSize:'15px'}}> {!isUndefined(row.user) ? row.user: 'Not Found'} </Button>
      </StyledTableCell>   

      <StyledTableCell align="left"><Button  variant="outlined" color={row.stockOut>0  ?'danger':'secondary'} size='large' style={{fontSize:'15px'}}> {row.stockOut}</Button></StyledTableCell>
      <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {row.order}</Button></StyledTableCell>
      <StyledTableCell align="left"><Button  variant="outlined" color={row.day===lastVisit? 'success':'light'} size='large' style={{fontSize:'15px'}}> {dayjs(row.day).format("YYYY-MM-DD HH:mm:ss")}</Button></StyledTableCell>
      <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {isValidDate(row.start) ? row.start:"Not Pointed"}</Button></StyledTableCell>
      <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {isValidDate(row.end) ? row.end:"Not Pointed"}</Button></StyledTableCell>

    </StyledTableRow>
  )),[data])
  return (
    <div>
    {/* <Paper sx={{ width: '100%' }}>
      <TableContainer  sx={{
        height: 600    
      }}>
      <Table sx={{ minWidth: 700,height: "max-content" }} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
          
            <StyledTableCell>User</StyledTableCell>
            <StyledTableCell align="left">StockOut</StyledTableCell>
            <StyledTableCell align="left">Order</StyledTableCell>
            <StyledTableCell align="left">Day</StyledTableCell>
            <StyledTableCell align="left">Start</StyledTableCell>
            <StyledTableCell align="left">End</StyledTableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.userId}>
              <StyledTableCell component="th" scope="row">
              <Button  variant="outlined" color={row.user? 'primary' :'light'} size='large' style={{fontSize:'15px'}}> {!isUndefined(row.user) ? row.user: 'Not Found'}</Button>
              </StyledTableCell>   

              <StyledTableCell align="left"><Button  variant="outlined" color={row.stockOut>0  ?'danger':'secondary'} size='large' style={{fontSize:'15px'}}> {row.stockOut}</Button></StyledTableCell>
              <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {row.order}</Button></StyledTableCell>
              <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {row.day}</Button></StyledTableCell>
              <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {row.start}</Button></StyledTableCell>
              <StyledTableCell align="left"><Button  variant="outlined" color='light' size='large' style={{fontSize:'15px'}}> {isValidDate(row.end) ? row.end:"Not Defined"}</Button></StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> 
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper> */}
    <Box sx={{ bgcolor: 'background.paper',height: 600, width: '100%' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{ bgcolor: 'DarkCyan'}}
          >
            <Tab label="Output" {...a11yProps(0)} />
            <Tab label="Chart" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value}  index={0} dir={theme.direction}>
          <TableContainer  sx={{
        height: 450   
      }}>
      <Table  sx={{ minWidth: 700,height: "max-content" }} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
          
            <StyledTableCell>User</StyledTableCell>
            <StyledTableCell align="left">StockOut</StyledTableCell>
            <StyledTableCell align="left">Order</StyledTableCell>
            <StyledTableCell align="left">Day</StyledTableCell>
            <StyledTableCell align="left">Start</StyledTableCell>
            <StyledTableCell align="left">End</StyledTableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {memoData}
        </TableBody>
      </Table>
    </TableContainer> 
    <TablePagination
        rowsPerPageOptions={[5, 50,100, { label: 'All', value: data?.length }]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <MButton css={{backgroundColor:'blue'}} onClick={onDownload} color='primary' aria-describedby='chart' style={{fontSize:'15px'}} className=''><GetAppIcon size='large'/> &nbsp; Export</MButton> */}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
          <DataChart data={data}/>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  )
}

export default DataTableVisit