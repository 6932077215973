import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import DashboardAdmin from './DashboardAdmin';
import { useDispatch, useSelector } from 'react-redux';
import DashboardSupervisor from './DashboardSupervisor';
import { getVisitDashboardAsync } from 'store/visit/actions';
import { useQuery } from 'react-query';
import { getVisitDashboard, getVisitDashboardCaching } from 'store/visit/services';

//import { socket } from 'helpers/consts';
//import { getDisplayListAsync } from 'store/display/actions'

const Dashboard = props => {
  const dispatch = useDispatch();
  const connectedUser = useSelector(state => state.User.user)
  
  // const getVisit = async () => {
  //   setVisit(dispatch(await getVisitList()).payload.visits)
  // }
  //const [displays, setDisplays] = useState([])

  // const getDisplayList = async () => {
  //   setDisplays(dispatch(await getDisplayListAsync()).payload.displayList)
  // }
  const [visits, setVisits] = useState([])
  // const getVisit =  () => {
  //   //console.log("get user______________",connectedUser)
  //   // getVisits().then((data) => {
  //   //   setVisits(data)
  //   // })
    
  //    return getVisitDashboard()
  // }
  

  const {data,status,isLoading,isFetching} =useQuery('getVisitDashboardCaching', getVisitDashboardCaching,{
    refetchOnWindowFocus:false,
    //refetchInterval:0
     //staleTime:'infinity',
    // initialData:undefined
    //refetchOnMount:false
    cacheTime:7200000
  })
  
  //console.log("cashe---------------",{isLoading,isFetching})
  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t("Dashboard")} breadcrumbItem={props.t("Dashboard")} />
          {/* {isLoading && <p>
            loading...........
            </p>} */}
          {  (
           !isLoading && connectedUser?.role?.name === "merchandiser" ? (
              <DashboardSupervisor
                connectedUser={connectedUser}
                visits={data?.data?.data?.filter((visit) => visit.userId === connectedUser.id)}
              //display={displays}
                
              />
            ) : (
              <DashboardAdmin
                connectedUser={connectedUser}

                visits={data?.data?.data}
              />
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)