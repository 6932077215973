import { api } from "helpers/consts";

/**
 * 
 * @returns Localisation list
 */
export const getLocationHistoryDataByStore = async (userId,from, to) => {
    const res = await api.get(`/locationHistory/${userId}/${from}/${to}`);
    return res.data.data
};

/**
 * 
 * @returns Localisation list
 */
export const getLocationStores = async (userId) => {
    const res = await api.get(`/locationHistory/${userId}`);
    return res.data.data
};

