import {
    GET_LOCALISATION_HISTORY
  } from "./actions"
  
  const initialState = {
    localisationHistory: null,
  }
  
  const LocalistionHistory = (state = initialState, action) => {
    switch (action.type) {
      case GET_LOCALISATION_HISTORY:
        state = {
          ...state,
          localisationHistory: action.payload.localisationHistory
        }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default LocalistionHistory