import React, { memo, useCallback, useEffect, useState } from 'react'
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import { Box, gridClasses, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, TextField, MenuItem, Grid, TablePagination, IconButton, Autocomplete, FormControl } from '@mui/material';
import {
  DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
  GridColumnHeaders,
  GridRow,
  GridColumnHeaderSeparator,
} from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Paper from '@mui/material/Paper';
import MList from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';
import MuseumIcon from '@mui/icons-material/Museum';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardTitle, Button, UncontrolledTooltip } from "reactstrap"
import Gallery from 'shared/Gallery';
import {AutoSizer, Column,Table } from 'react-virtualized-reactv17';
import 'react-virtualized-reactv17/styles.css';
import { Td, Tr } from 'react-super-responsive-table';
import '../../../assets/scss/virtualizedTable.scss';
import { InputRow, LabeledInput } from 'assets/table Elements/LabeledInputs';
//virtuso table
import { TableVirtuoso } from 'react-virtuoso';
//import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import UpdateModal from './UpdateModal';
import DetailModal from './DetailModal';
import StoreHistory from './history/History';
import { getStoreGroupsFilterAsync } from 'store/storeGroup/actions';
import { useDispatch } from 'react-redux';
import logo from "../../../assets/images/store.png"



//Export
const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
    {/* <GridPrintExportMenuItem /> */}
    <JsonExportMenuItem />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <Col>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
        </Col>
        

        <GridToolbarQuickFilter  />
  </GridToolbarContainer>
);



const dataTableStore = ({ data,getList,storeGroups }) => {
  const [marchandizer, setMarchandizer] = useState([])
  const [stores, setStores] = useState([])

  const [loader, setLoader] = useState(true)
  const dispatch = useDispatch();

  const getStoreGrp = async () => {
    return dispatch(await getStoreGroupsFilterAsync()).payload.storeGroupsFilter
}
//   const columns = [
//     { field: 'id', headerName: 'id', width: 50 },
//     { field: 'path', headerName: 'Image', width: 80, renderCell: (params) => { return ( <img src={params.row?.path} style={{ width: "4rem", height: "4rem" }} />) } },
//     { field: 'name', headerName: 'Name', width: 120, editable: false, renderCell: (params) => { return (<h6 color='primary'>{params?.row?.name}</h6>) } },
//     { field: 'address', headerName: 'Address',  width: 300, renderCell: (params) => { return (<h6 color='primary'>{params.row.address}</h6>) } },
//     { field: 'governorate', headerName: 'Governorate',  width: 100, renderCell: (params) => { return (<h6 color='primary'>{params.row.governorate}</h6>) } },
//     { field: 'email', headerName: 'Email',  width: 100, renderCell: (params) => { return (<h6 color='primary'>{params.row.email}</h6>) } },
//     { field: 'phone_number', headerName: 'Phone number',  width: 150, renderCell: (params) => { return (<h6 color='primary'>{params.row.phone_number}</h6>) } },

//     { field: '', headerName: 'Actions',align:'center', width: 200, renderCell: (params) => { return (
//                         <Td>
//                                                                 <Row>
//                                                                     <Col xl="3" lg="3">
//                                                                         <UpdateModal
//                                                                             currentStore={params.row}
//                                                                             getList={getList}
//                                                                             storeGroups={storeGroups}
//                                                                         />
//                                                                     </Col>
//                                                                     <Col xl="3" lg="3">
//                                                                         <DetailModal
//                                                                             store={params.row}
//                                                                         />
//                                                                     </Col>
//                                                                     <Col xl="3" lg="3">
                                                                    
//                                                                         {params.row?.storePictures?.length > 0 &&
//                                                                             <Gallery
//                                                                                 pictures={params.row?.storePictures}
//                                                                             />
//                                                                         }
//                                                                     </Col>
//                                                                     <Col xl="3" lg="3">
//                                                                         <StoreHistory
//                                                                             storeId={params.row.id}
//                                                                         />
//                                                                     </Col>
//                                                                 </Row>
//                                                             </Td>
//   ) } },

    
//   //   { field: 'end', headerName: 'End Date', width: 100, renderCell: (params) => { return (<MButton aria-describedby='date' color='success' onClick={handleClickDate('left', params.row.end)}>end</MButton>) } },
//   //   // { field: 'nbStock', headerName: 'nbStock',align:'center', width: 100,renderCell:params=><DateNumbers number={params.row.nbStock} /> }, 
//   //   { field: 'nbStockOut', headerName: 'nbStockOut', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.nbStockOut} />) } },
// ];
  //popper stores
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [storeList, setStoreList] = useState(['']);


  const handleClick = (newPlacement, storeData) => (event) => {
    setAnchorEl(event.currentTarget);
    setStoreList(storeData)

  };

  //popper Top List
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const [storeListTop, setStoreListTop] = useState([]);

  const handleClickTop = (newPlacement, Data) => (event) => {
    setAnchorE2(event.currentTarget);
    setStoreListTop(Data)

  };

  //popper Date List
  const [anchorE3, setAnchorE3] = useState(null);
  const open3 = Boolean(anchorE3);
  const [dateList, setDateList] = useState([]);

  const handleClickDate = (newPlacement, Data) => (event) => {
    setAnchorE3(event.currentTarget);
    setDateList([...new Set(Data)])

  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
  };

  //filter Date start end
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const [cancelB, setCancelB] = useState(true);

  const handleFilter = () => {
    setMarchandizer([...data])
    setCancelB(true)
    setDateStart(dayjs())
    setDateEnd(dayjs())
  };


  const handleChangeStart = (newValue) => {
    //console.log(newValue)
    setDateStart(newValue);
    setCancelB(false)
  };
  const handleChangeEnd = (newValue) => {

    setDateEnd(newValue);
    setCancelB(false)
  };

  //table options
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ['Merchandiser'],
  });

  const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
    setPinnedColumns(updatedPinnedColumns);
  }, []);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);


  //filter list
  const filterList = () => {
    if (storeListTop.length > 0) {
      storeListTop?.reduce((acc, shot) => acc = acc > shot.temps ? acc : "")
    }
  }
  const [listGrp, setListGrp] = useState([]);

  useEffect(async () => {
    //console.log('data: ', data)
    let active = true;
    setRowsPerPage(data?.length)
    setMarchandizer([...data])
    setStores([...data])
    getStoreGrp().then((data)=>{
      let list=[]
      data.map((elem)=> {
        //console.log("name_______________________",elem.name)
         list.push(elem?.name)
        });
        setListGrp([...list])
     })
    // (async () => {
    //   setLoader(true)
    //   let list = []
    //   data.map(d => {
    //     for (let index = 0; index < 500; index++) {
    //       list.push(
    //         { id: Math.random(0, 100000), name: d.name, dateEntre: d.dateEntre, dateSortie: d.dateSortie },

    //       )

    //     }
    //   })
    //   setMarchandizer([...list])
    //   setLoader(false)
    // })()
    // return () => {
    //   active = false
    // }
    setLoader(false)
  }, [data])
  const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearch = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=stores.filter((element)=>{
      
        
        return (
          (element?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.address.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.governorate.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.storeGroup?.name.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.email.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.phone_number.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
)
      
    })
    setMarchandizer(marchandizer=>[...list])
    setRowsPerPage(list.length)
    
  }else{
    setMarchandizer(marchandizer=>[...stores])
    setRowsPerPage(stores.length)
  }
  };
  function filterData(params) {
    //console.log('list_____________________',params)
    if(!filter){
      setFilter(filter=> true)
    }
    if(params==='StoreGrp'){
      if(desc){
        const list=stores.sort((a,b)=> (a?.storeGroup?.name.toString().toLowerCase()>b?.storeGroup?.name.toString().toLowerCase())-(a?.storeGroup?.name.toString().toLowerCase()<b?.storeGroup?.name.toString().toLowerCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }else{
        const list=stores.sort((a,b)=> (a?.storeGroup?.name.toString().toLowerCase()<b?.storeGroup?.name.toString().toLowerCase())-(a?.storeGroup?.name.toString().toLowerCase()>b?.storeGroup?.name.toString().toLowerCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }
    }else{
      if(desc){
        const list=stores.sort(sort_by(params, false, (a) =>  a?.toLowerCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }else{
        const list=stores.sort(sort_by(params, true, (a) =>  a?.toLowerCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }
    }
    
  }
  // function rowRenderer({
  //   id, // Unique key within array of rows
  //   index, // Index of row within collection
  //   isScrolling, // The List is currently being scrolled
  //   isVisible, // This row is visible within the List (eg it is not an overscanned row)
  //   style, // Style object to be applied to row (to position it)
  // }) {
  //   return (
  //     <div key={id} style={style}>
       
      
      
  //       {/* {marchandizer[index].label} */}
  //     </div>
  //   );
  // }
  const headerRenderer = ({
    columnData,
    dataKey,
    disableSort,
    label,
    sortBy,
    sortDirection
  }) => { return (
    <div className='filter' onClick={()=>(dataKey!='path') &&(dataKey!='Action')&&filterData(dataKey)}> 
    {(dataKey!='path') &&(dataKey!='Action')&&filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} onClick={()=>filterData(dataKey)}></i>)}
      {(dataKey==='path') ||(dataKey==='Action')? label:dataKey}
     </div>
  )};
  const headerRowRenderer = ({
    className,
    columns,
    style
  }) => (
    <div
    
      className={className+' text-light rounded  '}
      role='row'
      style={{...style,backgroundColor:'#4F6973'}}
    >
      {columns} 
    </div>
  )


  
/////////////////////////////////////////////////////////table virtuoso
const columnsVirtuso = [
  // {
  //   width: 120,
  //   label: 'id',
  //   dataKey: 'id',
  //   //numeric: true,
  // },
  {
    width: 80,
    label: 'Image',
    dataKey: 'path',
  },
  {
    width: 150,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 200,
    label: 'Address',
    dataKey: 'address',
  },
  {
    width: 200,
    label: 'StoreGrp',
    dataKey: 'StoreGrp',
  },
  {
    width: 130,
    label: 'Governorate',
    dataKey: 'governorate',
  },
  {
    width: 190,
    label: 'Email',
    dataKey: 'email',
  },
  {
    width: 200,
    label: 'Phone Number',
    dataKey: 'phone_number',
  },
  {
    width: 150,
    label: 'Action',
    dataKey: 'Action',
    //numeric: true,
  },
 
  
];

const [rowsPerPage, setRowsPerPage] = React.useState(-1);
const [page, setPage] = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
// function fixedHeaderContent() {
//   return (
//     <TableRow>
//       {columnsVirtuso.map((column) => 
//         {
//           if(column.dataKey==="Action" || column.dataKey==="path"){
//             return  <TableCell
//             key={column.dataKey}
//             variant="head"
//             align={column.numeric || false ? 'right' : 'left'}
//             style={{ width: column.width,color:"white" }}
//             sx={{
//               backgroundColor: '#4F6973',
//             }}
//           >
//             {column.label}
//           </TableCell>
//           }else{
//             return  <TableCell
//             onClick={()=>filterData(column.dataKey)}
//             key={column.dataKey}
//             variant="head"
//             align={column.numeric || false ? 'right' : 'left'}
//             style={{ width: column.width,color:"white" }}
//             sx={{
//               backgroundColor: '#4F6973',
//             }}
//           >
//               <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>

//           </TableCell>
//           }
//         }
//     )}
//     </TableRow>
//   );
// }
// const VirtuosoTableComponents = {
//   Scroller: React.forwardRef((props, ref) => (
//     <TableContainer component={Paper} {...props} ref={ref} />
//   )),
//   Table: (props) => (
//     <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
//   ),
//   TableHead,
//   TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
//   TableBody: React.forwardRef((props, ref) => 
//   <TableBody {...props} ref={ref} />
//  ),
//   // TableFooter :React.forwardRef((props, ref) => 
  
  
//   // )
// };

// function rowContent(_index, row) {
//   return (
//     <React.Fragment>
//       {columnsVirtuso.map((column) => {
//         //console.log("column______________________",row)
//           if(column.dataKey==="path"){
//            return (<TableCell
//           key={column.dataKey}
//           align={column.numeric || false ? 'right' : 'left'}
//           >
//             <img src={row[column.dataKey]} style={{ width: "4rem", height: "4rem",marginTop:5 }} />
//           </TableCell>)
//           } else if(column.label==="Action") {
//             return (<TableCell
//               key={column.dataKey}
//               align={column.numeric || false ? 'right' : 'left'}
//               >
              
                                                                    
//                                                                     {row.storePictures.length >0 &&<Gallery
//                                                                                 pictures={row.storePictures}
//                                                                             />}
                                                                    
//               </TableCell>)
//           }else{
//             return (<TableCell
//               key={column.dataKey}
//               align={column.numeric || false ? 'right' : 'left'}
//               >
//               {row[column.dataKey]}
//               </TableCell>)
//           }
//       }
       
//       )}
//     </React.Fragment>
//   );
// }
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
      const [storeGrp, setStoreGrp] = useState([]);

    const handleStoreGrp = (event,value) => {
      setStoreGrp(value);
      
    };
    useEffect(()=>{
      

      if(storeGrp.length>0){ 
             let filtredList=data.filter((elem)=> {
               // console.log("filter_______________________________",filtredList.length)
               if(elem?.storeGroup?.name && storeGrp.includes(elem.storeGroup.name)){
                 
               return elem;
               }  
             })
             setMarchandizer(marchandizer=>[...filtredList])
           }else{
            setMarchandizer(marchandizer=>[...data])
   
           }
             
       },[storeGrp])
  return (
    <div style={{height:750}}>
      <Box sx={{ height: 550, width: '100%' }}>
      <Row>
          <Col className='col-8'>
          <TextField placeholder='search...' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

      </Col>
      <Col>

      {listGrp.length>0  &&
      <FormControl sx={{  width: 300, }} size="small">

      <Autocomplete
      size='small'
        onChange={handleStoreGrp}
        value={storeGrp}
        multiple
        id="storeGrp"
        options={[...new Set(listGrp)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Store Group"
            placeholder="Store Group"
          />
        )}
      />
       
      </FormControl>
      }
      </Col>
          </Row>
       {marchandizer.length>0&&
       <div style={{height:700}}>
        <AutoSizer>
      {({height, width}) => (
        <>
        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={width}
        headerRowRenderer={headerRowRenderer}
        height={height}
        headerHeight={50}
        rowHeight={100}
        rowCount={marchandizer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => marchandizer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          <Column label="Image" dataKey="path" width={500} headerRenderer={headerRenderer}  cellRenderer={({cellData}) => <img src={URL+cellData} onError={(e) => { e.target.src=logo }} style={{ width: "4rem", height: "4rem", }} />}/> 
          <Column label="name" dataKey="name"   width={1000} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Address" dataKey="address"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData}</h6>}/>
          <Column label="StoreGrp" dataKey="StoreGrp"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.storeGroup?.name}</h6>}/>
          <Column label="Governorate" dataKey="governorate"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Email" dataKey="email"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Phone_number" dataKey="phone_number"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>

          <Column label="Actions"    width={width}  dataKey="Action" headerRenderer={headerRenderer}   cellRenderer={({cellData,rowData}) =>  
          
          <Row>
                                                                    <Col xl="3" lg="3">
                                                                        <UpdateModal
                                                                            currentStore={rowData}
                                                                            getList={getList}
                                                                            storeGroups={storeGroups}
                                                                        />
                                                                    </Col>
                                                                    <Col xl="3" lg="3">
                                                                        <DetailModal
                                                                            store={rowData}
                                                                        />
                                                                    </Col>
                                                                    <Col xl="3" lg="3">
                                                                    
                                                                        {rowData?.storePictures?.length > 0 &&
                                                                            <Gallery
                                                                                pictures={rowData?.storePictures}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col xl="3" lg="3">
                                                                        <StoreHistory
                                                                            storeId={rowData.id}
                                                                        />
                                                                    </Col>
                                                                </Row>
          }/> 

          
       </Table>
       
             </>
      )} 
     
     
    </AutoSizer>
    
       </div>  } 
{/* 

<TableVirtuoso
    data={marchandizer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
    components={VirtuosoTableComponents}
    fixedHeaderContent={fixedHeaderContent}
    itemContent={rowContent}
  /> */}
{marchandizer.length>0&&<TablePagination
                  component="div"
                  //style={{width:1100}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: marchandizer.length }]}
                  colSpan={3}
                  count={marchandizer.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  //ActionsComponent={TablePaginationActions}
                />}
      </Box>

    </div>

  )
}


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default memo(dataTableStore)