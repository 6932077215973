import React, { useState } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Modal, UncontrolledTooltip } from 'reactstrap'
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getReferencedProductsByStore, upsertReferencedProducts } from 'store/referencedProduct/services';
import Swal from 'sweetalert2'
import TablePaginationActions from 'components/paginationMui';
import { Col, Row } from 'reactstrap';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { AvField } from 'availity-reactstrap-validation';
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17';
import { Checkbox } from '@mui/material';
const StoreDetail = (props) => {

    const { storeId } = props
    const [modal_standard, setmodal_standard] = useState(false)
    const [rfp, setRfp] = useState([])
    const [data, setData] = useState([])

    const getRfp = async (storeId) => {
        await getReferencedProductsByStore(storeId).then((dataRfp) => {
            //console.log("data__________",dataRfp)
            setRowsPerPage(dataRfp?.length)
            setData(dataRfp)
            setRfp(dataRfp);
            
        })
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        if (!modal_standard) {
            getRfp(storeId)
            // setTimeout(() => {
            //     $('#detaildatatable').DataTable()
            // }, 200);
        }
    }

    const updateRFP = (product) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update status!'
        }).then((result) => {
            if (result.isConfirmed) {
                upsertReferencedProducts([product]).then(() => {
                    getRfp(storeId)
                    Swal.fire(
                        'Updated!',
                        'Product updated successfully.',
                        'success'
                    )
                })
            }
        })
    }
    const [rowsPerPage, setRowsPerPage] = useState(-1);
    const [page, setPage] = useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    //console.log("rows_______",rowsPerPage)

    const URL = process.env.NODE_ENV === "development"
          ? "http://localhost:3303"
          : "https://www.devtest21-api.g2m-tn.com";

          const [storeGrp, setStoreGrp] = useState([]);

          const [checkedAll, setCheckedAll] = React.useState(false);

          const headerRenderer = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => (
            <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} </div>
          );
          const headerRowRenderer = ({
            className,
            columns,
            style
          }) => (
            <div
              className={className+' bg-light '}
              role='row'
              style={style}
            >
              {columns} 
            </div>
          )

          const [desc, setDesc] = useState(false)
          const [filter, setFilter] = useState(false)
        
          const sort_by = (field, reverse, primer) => {
        
            const key = primer ?
              function(x) {
                return primer(x[field])
              } :
              function(x) {
                return x[field]
              };
          
            reverse = !reverse ? 1 : -1;
          
            return function(a, b) {
              return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
            }
          }
          const handleSearch = (search) => {
            //setCancelB(false)
            //console.log("search_______________________",search)
            if(search){
            const list=data.filter((element)=>{
              
                
                return (
                  (element?.product?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
                  || (element?.product?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.product?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.product?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        )
              
            })
            setRfp(rfp=>[...list])
          }else{
            setRfp(rfp=>[...data])
          }
          };
          function filterData(params) {
            if(!filter){
              setFilter(filter=> true)
            }
            if(params==="label"){
                //console.log('list_____________________',params)

                if(desc){
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()>b.product.label.toUpperCase())-(a.product.label.toUpperCase()<b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()<b.product.label.toUpperCase())-(a.product.label.toUpperCase()>b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
              else if(params==="brand"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.brand.name.toUpperCase()>b.product.brand.name.toUpperCase())-(a.product.brand.name.toUpperCase()<b.product.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.brand.name.toUpperCase()<b.product.brand.name.toUpperCase())-(a.product.brand.name.toUpperCase()>b.product.brand.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }else if(params==="category"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.category.name.toUpperCase()>b.product.category.name.toUpperCase())-(a.product.category.name.toUpperCase()<b.product.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.category.name.toUpperCase()<b.product.category.name.toUpperCase())-(a.product.category.name.toUpperCase()>b.product.category.name.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }else if (params==="typology"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.typology>b.product.typology)-(a.product.typology<b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.typology<b.product.typology)-(a.product.typology>b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
            
            
          }

          const rowRenderer = ({
            rowData,
            className,
            columns,
            style
          }) => {
            //console.log('f_________________________',rowData)
           return (
            <div
              key={rowData.productId}
              className={className}
              role='row'
              style={{...style,backgroundColor:rowData.available?"#BCF0A9":''}}
            >
              {columns} 
            </div>
          )}
          

    return (
        <>
            <i
                className="mdi mdi-eye-minus-outline"
                style={{ fontSize: "25px", cursor: "pointer", color: "#556EE6" }}
                onClick={() => { tog_standard() }}
            />
            <Modal
                isOpen={modal_standard}
                size="xl"
                toggle={() => { tog_standard() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Store products
                    </h5>
                    <button
                        type="button"
                        onClick={() => { setmodal_standard(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {rfp.length>0 && <div className="table-rep-plugin" style={{ marginTop: "0rem" }}>
                        <div
                            // className="table-responsive mb-0"
                            // data-pattern="priority-columns"
                            style={{height:600,}}
                        >
                            {/* <Table
                                id={"detaildatatable"}
                                className="table table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Image</Th>
                                        <Th>Label</Th>
                                        <Th>Brand</Th>
                                        <Th>Category</Th>
                                        <Th>Typology</Th>
                                        <Th>Available</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {rfp?.map((product, index) => {
                                        return (
                                            <Tr key={index} style={{backgroundColor: product.available?("#BCF0A9"):('')}}>
                                                <Td>
                                                    <img
                                                        src={product.product.path}
                                                        style={{ width: "4rem", height: "4rem" }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <p style={{ marginTop: "1.5rem" }}> {product.product.label} </p>
                                                </Td>
                                                <Td>
                                                    <p style={{ marginTop: "1.5rem" }}> {product.product.brand?.name} </p>
                                                </Td>
                                                <Td>
                                                    <p style={{ marginTop: "1.5rem" }}> {product.product.category?.name} </p>
                                                </Td>
                                                <Td>
                                                    <p style={{ marginTop: "1.5rem" }}> {product.product.typology} </p>
                                                </Td>
                                                <Td>
                                                    <div className="form-check form-switch form-switch-md mb-3" >
                                                        <input
                                                            type="checkbox"
                                                            style={{ cursor: "pointer", marginTop: "1rem" }}
                                                            className="form-check-input"
                                                            id="enabletooltip"
                                                            checked={product.available}
                                                            onChange={() => { updateRFP({ ...product, available: !product.available }) }}
                                                        />
                                                        <UncontrolledTooltip placement="top" target="enabletooltip">
                                                            change status
                                                        </UncontrolledTooltip>
                                                    </div>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table> */}
                            <input
              className="mb-1"
             size={25}
              placeholder="search"
              type="text"
              onChange={(e) => { handleSearch(e.target.value) }}
          />
    <AutoSizer>

{({height, width}) => (
  <>
  
  {/* <TextField placeholder='search...' style={{alignSelf:'flex-end',width:200,}} size='small' onChange={(e)=>handleSearchStores(e.target.value)}/> */}

  <Table
  gridStyle={{
    direction: 'inherit'
  }}
  width={width}
  rowRenderer={rowRenderer}
  headerRowRenderer={headerRowRenderer}
  height={height-70}
  headerHeight={30}
  rowHeight={80}
  rowCount={rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
  rowGetter={({index}) => rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
  >    
    
    <Column label="Image" dataKey="path" width={width}  cellRenderer={({cellData,rowData}) => <img src={URL+rowData.product.path} style={{ width: "4rem", height: "4rem", }} />}/> 
      
                                      
    <Column label="label" dataKey="label"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.label}</h6>}/>
    <Column label="Brand" dataKey="brand"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6 >{rowData?.product?.brand?.name}</h6>}/>
    <Column label="Category" dataKey="category"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.category?.name}</h6>}/>
    <Column label="Typology" dataKey="typology"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData.product?.typology}</h6>}/>

    <Column label="Available"    width={width}  dataKey="Available"    cellRenderer={({cellData,rowData}) =>  
    

    <div className="form-check form-switch form-switch-md mb-3" >
        <input
            type="checkbox"
            style={{ cursor: "pointer", marginTop: "1rem" }}
            className="form-check-input"
            id="enabletooltip"
            checked={rowData.available}
            onChange={() => { updateRFP({ ...rowData, available: !rowData.available }) }}
        />
        <UncontrolledTooltip placement="top" target="enabletooltip">
            change status
        </UncontrolledTooltip>
    </div>

    }/> 

    
 </Table>
          

       </>
)} 


</AutoSizer> 
                        </div>
                        <TablePagination
            component="div"
            //style={{width:1100}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: rfp.length }]}
            //rowsPerPageOptions={[{ label: 'All', value: -1 },10, 50,100, ]}
            colSpan={3}
            count={rfp.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          //   ActionsComponent={TablePaginationActions}
          //   backIconButtonProps={{
          //     'aria-label': 'Previous Page',
          // }}
          // nextIconButtonProps={{
          //     'aria-label': 'Next Page',
          // }}
          />
                    </div>}
                </div>
            </Modal>
        </>
    )
}
export default StoreDetail