import { api } from "helpers/consts";

/**
 * 
 * @returns stores visits
 */
export const getStoreVisits = async (storeId, limit, offset) => {
    const res = await api.get(`/store/visits/${storeId}/${limit}/${offset}`)
    return res.data.data
}

/**
 * 
 * @returns stores list
 */
export const getStores = async () => {
    const res = await api.get(`/store`)
    return res.data.data
}

/**
 * 
 * @param {Store} store 
 * @returns created store
 */
export const upsertStore = async (store, file,
    // imgCompany, 
    files, customFieldValues) => {
    const formData = new FormData();
    //console.log("file: ",file,"imgCompany: ",imgCompany)
    formData.append('customFieldValues', JSON.stringify(customFieldValues))
    formData.append('store', JSON.stringify(store))
    formData.append('file', file)
    //************** */
    //formData.append('company', imgCompany)
    files.forEach((f) => {
        formData.append('files', f)
    })
    const res = await api.post(`/store`, formData)
    return res.data
}

/**
 * 
 * @param {String} id 
 * @returns update company
 */
 export const upsertCompany = async (id,imgCompany) => {
    const formData = new FormData();
    //************** */
    formData.append('company', imgCompany)

    const res = await api.put(`/store/${id}`,formData)
    return res
}

/**
 * 
 * @param {String} id 
 * @param {String} lat 
 * @param {String} lng
 * @returns update location
 */
 export const upsertLocation = async (id,lat,lng) => {
    //************** */

    const res = await api.put(`/store/${id}/${lat}/${lng}`)
    return res
}


/**
 * 
 * @returns stores Outputs
 */
export const getStoreOutput = async ( limit, offset) => {
    const res = await api.get(`/store/outputs/${limit}/${offset}`)
    return res.data.data
}


/**
 * 
 * @returns stores Filter list
 */
export const getStoresFilter = async () => {
    const res = await api.get(`/store/filter`)
    return res.data.data
}
