import React, { useMemo, useState } from 'react';
import { Collapse } from 'reactstrap';
import QuestionPart from './QuestionPart';

const SubCategoryPart = (props) => {

    const { subCategory } = props
    const [col1, setCol1] = useState(true)

    const memoData=useMemo(()=>subCategory?.questions&&subCategory.questions?.map((question, index) => (
        <QuestionPart
            key={index}
            question={question}
        />
    )),[subCategory])
    return (
        <div className="accordion" id="accordion">
            {subCategory&&<div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className={+ col1 ? ("accordion-button fw-medium collapsed") : ("accordion-button fw-medium")} type="button" onClick={() => { setCol1(!col1) }} style={{ cursor: "pointer" }}>
                        {subCategory.name}
                    </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                        {memoData}
                    </div>
                </Collapse>
            </div>}
        </div>
    )
}

export default SubCategoryPart;
