import React, { useCallback, useEffect, useState,useRef } from 'react'
import { Row, Card, CardBody,Col,Button } from "reactstrap"
import { Box, Modal, TablePagination, TextField, Typography } from '@mui/material';
import PieChart from 'pages/Performance/components/PieChart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Progress_bar from 'pages/Performance/components/progress';
import { useDownloadExcel } from 'react-export-table-to-excel';
import GetAppIcon from '@mui/icons-material/GetApp';
import MButton from '@mui/material/Button';

function DataTauxCategory({data,filterCategory,categoryList}) {
    const [family, setFamily] = useState([...categoryList])
//     const tableRef = useRef(null);
//   const { onDownload } = useDownloadExcel({
//     currentTableRef: tableRef.current,
//     filename: 'Users table',
//     sheet: 'Users'
// })
  var listCategory=[categoryList]
    //console.log(listCategory)
  
  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
   const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height:940,
    
  };
  return (
    <div>
         <Paper sx={{ width: '100%', }}>
   <TableContainer  sx={{
    height: 550    
  }}>
      <Table  sx={{ minWidth: 600,height: "max-content" }} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{fontSize:'20px'}} align="left">Category</StyledTableCell>
            <StyledTableCell align="left" style={{fontSize:'20px'}}>Taux</StyledTableCell>
            {filterCategory &&<StyledTableCell align="left" style={{fontSize:'20px'}}>Family Filter</StyledTableCell>}
            <StyledTableCell align="left" style={{fontSize:'20px'}}>Pourcentage</StyledTableCell>




          </TableRow>
        </TableHead>
        <TableBody>
          {data.length>0 && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => (
            <StyledTableRow key={row.category+Math.random(0,100000)}>
              <StyledTableCell component="th" scope="row">
                <Button  variant="outlined" color='primary'  style={{fontSize:'15px'}}> {row.category}</Button>
              </StyledTableCell>
              
              <StyledTableCell align="left"><Button  variant="outlined" color='dark' size='large' style={{fontSize:'15px'}}>  {row.nbStockOut} | {row.nbStock}</Button></StyledTableCell>
              {filterCategory &&<StyledTableCell align="left">{listCategory.map((e)=>{
                return (<Button  variant="outlined" color='light' size='large' style={{fontSize:'20px'}}>{e+' '}</Button>)
              })}</StyledTableCell>}

              <StyledTableCell align="left"> <Progress_bar  bgcolor="orange" progress={row.pourcentage}/></StyledTableCell>

              {/* <StyledTableCell align="left" ><Progress_bar bgcolor="orange" progress={row.pourcentage}  /></StyledTableCell> */}

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> 
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <MButton css={{backgroundColor:'blue'}} onClick={onDownload} color='primary' aria-describedby='chart' style={{fontSize:'15px'}}><GetAppIcon size='large'/> &nbsp; Export</MButton> */}
    </Paper>
    </div>
  )
}

export default DataTauxCategory