import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import { useDispatch } from 'react-redux'
import { getStoresAsync } from 'store/pos/actions'
import { getDisplayListAsync } from 'store/display/actions'
import UpdateStock from './UpdateStock'
import StockSettings from './StockSettings'
import { Oval } from  'react-loader-spinner'
import MButton from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import pptxgen from "pptxgenjs";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import image from 'assets/images/layouts/Image1.jpg'
import image2 from 'assets/images/layouts/Image2.jpg'
import image3 from 'assets/images/layouts/Image3.jpg'
import dayjs from 'dayjs'
import { findStockByStore, findStockDays, findStockSetting, getStoreStocks } from 'store/stock/services'
import { Autocomplete, Box, FormControl, TablePagination, TextField } from '@mui/material'
import {Table, AutoSizer, Column } from 'react-virtualized-reactv17'
import LoadingComponent from 'shared/LoadingComponent'
import { getStoreGroupsFilterAsync } from 'store/storeGroup/actions'
import logoDark from "../../../assets/images/store.png"
import StockHistory from './StockHistory'

const DataTableStockout = ({data}) => {

    const dispatch = useDispatch();
    const [stores, setStores] = useState([])
    const [storesData, setStoresData] = useState([])

    const [loader, setLoder] = useState(true)
    const [displayList, setDisplayList] = useState([])
    const [stockHistory, setStockHistory] = useState([])
    const [stockSetting, setStockSetting] = useState({})
    const [tableDays, setTableDays] = useState([])
    const listDays=[]
    const stocks=[]
    
    // const getStores = async () => {
    //     await  getStoreStocks().then((data)=>{
    //         setStores(data)
    //         setStoresData(data)

    //     })
    //     setLoder(false)
    // }

    const getStoreGrp = async () => {
      return dispatch(await getStoreGroupsFilterAsync()).payload.storeGroupsFilter
  }
    useEffect(async () => {
        // await getDisplayList()
        //await getStores()
        if(data?.length>0){
        setRowsPerPage(data?.length)
        setStores([...data])
        setStoresData([...data])
        getStoreGrp().then((data)=>{
          let list=[]
          data.map((elem)=> {
            //console.log("name_______________________",elem.name)
             list.push({name:elem?.name,id:elem?.id})
            });
            setListGrp([...list])
         })}
    }, [data])

    const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)
  
    const sort_by = (field, reverse, primer) => {
  
      const key = primer ?
        function(x) {
          return primer(x[field])
        } :
        function(x) {
          return x[field]
        };
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }
  
    const handleSearch = (search) => {
      //setCancelB(false)
      //console.log("search_______________________",search)
      if(search){
      const list=storesData.filter((element)=>{
        
          
          return (
            (element?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
            || (element?.address.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
            || (element?.governorate.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
            || (element?.email.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
            || (element?.phone_number.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
  )
        
      })
      setRowsPerPage(list.length)
      setStores(stores=>[...list])
    }else{
      setRowsPerPage(storesData.length)
      setStores(stores=>[...storesData])
    }
    };
    function filterData(params) {
      //console.log('list_____________________',params)
      if(!filter){
        setFilter(filter=> true)
      }
      if(desc){
        const list=storesData.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }else{
        const list=storesData.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setStores(stores => [...list])
      }
      
    }
    function rowRenderer({
      id, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
    }) {
      return (
        <div key={id} style={style}>
         
        
        
          {/* {stores[index].label} */}
        </div>
      );
    }
    const headerRenderer = ({
      columnData,
      dataKey,
      disableSort,
      label,
      sortBy,
      sortDirection
    }) => (
      <div className='filter' onClick={()=>(dataKey!='path') &&(dataKey!='Action')&&filterData(dataKey)}> 
          {(dataKey!='path') &&(dataKey!='Action')&&filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} onClick={()=>filterData(dataKey)}></i>)}
            {(dataKey==='path') ||(dataKey==='Action')? label:dataKey}
           </div>    );
    const headerRowRenderer = ({
      className,
      columns,
      style
    }) => (
      <div
      className={className+' text-light rounded h-90 '}
        role='row'
        style={{...style,backgroundColor:'#4F6973'}}
      >
        {columns} 
      </div>
    )
         

    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const URL =  process.env.NODE_ENV === "development"
    ? "http://localhost:3303"
    : "https://www.devtest21-api.g2m-tn.com";

    const [listGrp, setListGrp] = useState([]);
    const [storeGrp, setStoreGrp] = useState([]);

    const handleStoreGrp = (event,value) => {
      //console.log("filter_______________________________",value)
      setStoreGrp(value);
      
    };

    useEffect(()=>{
      

      if(storeGrp.length>0){ 
            //console.log("filter_______________________________",storeGrp)
             let filtredList=data.filter((elem)=> {
               let list=storeGrp.filter((key)=>key.id===elem?.storeGroupId)
               if(elem?.storeGroupId && list?.length>0){
                 
               return elem;
               }  
             })
             setStores(stores=>[...filtredList])
             setRowsPerPage(filtredList?.length)
           }else{
            setStores(stores=>[...data])
            setRowsPerPage(data?.length)
   
           }
             
       },[storeGrp])
    return (
         <>
                     <Row>
            <Col className='col-8'>
            <TextField placeholder='search...' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

            </Col>     
              <Col>
              {listGrp.length>0  &&
              <FormControl sx={{  width: 300, }} size="small">

              <Autocomplete
              size='small'
                onChange={handleStoreGrp}
                value={storeGrp}
                multiple
                id="storeGrp"
                options={[...new Set(listGrp)]}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Store Group"
                    placeholder="Store Group"
                  />
                )}
              />
              
              </FormControl>
      }
              </Col>
            </Row>
         {stores.length>0 &&<div>
 <div className="table-rep-plugin" style={{ marginTop: "1rem",marginBottom: "2rem",height: 690 }}>
                                
                                <Box sx={{ height: 600, width: '100%' }}>
       
            
      <AutoSizer>
      {({height, width}) => (
        <>
        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={width}
        headerRowRenderer={headerRowRenderer}
        height={height}
        headerHeight={50}
        rowHeight={100}
        rowCount={stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          <Column label="Image" dataKey="path" width={500} headerRenderer={headerRenderer}  cellRenderer={({cellData}) => <img src={URL+cellData} onError={(e)=> e.target.src = logoDark} style={{ width: "4rem", height: "4rem" }} />}/> 
          <Column label="name" dataKey="name"   width={1000} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Address" dataKey="address"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData}</h6>}/>
          <Column label="Governorate" dataKey="governorate"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Email" dataKey="email"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Phone_number" dataKey="phone_number"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>

          <Column label="Actions"    width={width}  dataKey="Action" headerRenderer={headerRenderer}    cellRenderer={({cellData,rowData}) =>  
          
            <>
            <StockHistory
                store={rowData}
                //displays={displayList}
               // stores={stores}
            />
            {/* <UpdateStock
                store={rowData}
            /> */}
            {/* <NewOrder
                store={rowData}
            /> */}
        </>
          }/> 

          
       </Table>
      
      </>
      )} 
    
    </AutoSizer>  
    
      </Box>
      
                        </div>
                        <TablePagination
        rowsPerPageOptions={[10, 50,100, { label: 'All', value: stores.length }]}
        component="div"
        count={stores.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
                        
        </div>}
        </>
    )
}

export default DataTableStockout;
