import React, { useEffect, useMemo, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Badge,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { getUsersByRole } from "store/user/services"
import { useSelector } from "react-redux"
import "bootstrap/dist/css/bootstrap.min.css"
import Swal from "sweetalert2"
import DayVisit from "../PlanningControl/components/DayVisit"
import UnplannedVisit from "../PlanningControl/components/UnplannedVisit"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useDispatch } from "react-redux"
import { getPlannigDailyAsync } from "store/plannigDaily/actions"
import { Autocomplete } from "@mui/material"

const PlanningControlDaily = props => {
  const dispatch = useDispatch()
  const { userOptions, setSelectedUsers } = props

  const connectedUser = useSelector(state => state.User?.user)
  const [merchandisers] = useState([])
  const [selectedMerchandiser, setSelectedMerchandiser] = useState([])
  const [StartDate, setStartDate] = useState(Date())
  const [EndDate, setEndDate] = useState(Date())
  const [nbreJour, setNbreJour] = useState([])
  useEffect(async () => {
    await getUsersByRole("merchandiser").then(data => {
      data.forEach(element => {
        merchandisers.push({
          id: element.id,
          value: element.id,
          label: element.first_name + " " + element.last_name,
        })
      })
    })
  }, [])

  useEffect(() => {
    if (connectedUser?.role?.name === "merchandiser") {
      //console.log('marchandiser_________________________________',selectedMerchandiser.length)

      setSelectedMerchandiser(selectedMerchandiser => [
        {
          id: connectedUser.id,
          value: connectedUser.id,
          label: connectedUser.first_name + " " + connectedUser.last_name,
        },
      ])
    }
  }, [connectedUser])

  const Search = async () => {
    var listUser = []
    //console.log('marchandiser_________________________________',selectedMerchandiser)
    selectedMerchandiser?.forEach(user => {
      listUser.push(user.value)
    })
    var d = new Date(StartDate)
    var startDate =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    var d1 = new Date(EndDate)
    var endDate =
      d1.getFullYear() + "-" + (d1.getMonth() + 1) + "-" + d1.getDate()
    const nbre = (new Date(endDate) - new Date(startDate)) / 86400000 + 1
    let list = []

    const data = dispatch(
      await getPlannigDailyAsync(
        listUser,
        startDate.toString(),
        endDate.toString()
      )
    ).payload

    if (nbre > 30)
      Swal.fire("dépasser limite!", "vous devez selectionner un mois!", "error")
    else {
      for (var i = 0; i < nbre; i++) {
        let date = new Date(
          new Date(startDate).setMilliseconds(
            new Date(startDate).getMilliseconds() + i * 86400000
          )
        )
        let month = ""
        let day = ""
        if ((date.getMonth() + 1).toString().length > 1)
          month = (date.getMonth() + 1).toString()
        else month = "0" + (date.getMonth() + 1).toString()
        if (date.getDate().toString().length > 1)
          day = date.getDate().toString()
        else day = "0" + date.getDate().toString()
        let fliter = date.getFullYear() + "-" + month + "-" + day
        let listeM = []
        selectedMerchandiser.forEach(user => {
          listeM.push({ title: user.label, liste: [] })
        })
        for (var j = 0; j < data.PlannigDailys.length; j++) {
          let listeAction = data.PlannigDailys[j]

          for (var h = 0; h < listeM.length; h++) {
            //last modified  (listeAction[0]?.user.first_name+" "+listeAction[0]?.user.last_name==listeM[h].title)
            if (
              listeAction?.user.first_name +
                " " +
                listeAction?.user.last_name ==
              listeM[h].title
            ) {
              // let result=data.PlannigDailys[j].filter(el=>el.day.indexOf(fliter)>-1)

              let result = data.PlannigDailys[j]
              //console.log("data____________",result)
              listeM[h].liste?.push(result)
            }
          }
          //console.log(list)
        }
        list.push({
          data: listeM,
          value: date.toDateString("EEEE dd MMM yyyy"),
        })
      }
      setNbreJour(list)
    }
  }

  const memoData = useMemo(
    () =>
      nbreJour.length > 0 &&
      nbreJour.map((jour, index) => {
        return (
          <Container key={index}>
            <Col
              style={{
                color: "#0D6EFD",
                backgroundColor: "rgba(0,3,5,.03)",
                border: "solid 0.1rem ",
                paddingTop: "2%",
                paddingBottom: "2%",
                fontSize: "20px",
                paddingInline: "5%",
                width: "240px",
                color: "#000",
              }}
            >
              <center>{jour.value}</center>{" "}
            </Col>
            {jour.data.map((mar, i) => {
              //console.log('index_________________________________',mar)
              return (
                <div key={i} className="row">
                  <div className="col-sm-3">
                    <div className="well">
                      <h5
                        style={{
                          writingMode: "vertical-rl",
                          border: "1px solid",
                          backgroundColor: "rgb(240, 240, 240)",
                          textOrientation: "mixed",
                          transform: "rotate(-180deg)",
                          color: "rgba(13, 110, 253, 1)",
                          fontSize: "20px",
                          padding: "15%",
                          marginLeft: "-5%",
                          marginTop: "25%",
                        }}
                      >
                        {mar.title}{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-9" style={{ padding: "5%" }}>
                    {mar.liste.map((action, indexMar) => {
                      if (action.planned) {
                        return <DayVisit key={indexMar} visit={action} />
                      } else {
                        return (
                          <UnplannedVisit
                            key={indexMar}
                            visit={action}
                            displays={action.displays}
                            stock={action.stocks}
                          />
                        )
                      }
                      /*let storeName=action.store.name
        let color="#dc3545";
        if (action.stocks.length > 0 && action.displays.length > 0) {
          color="#198754"
      } else if ((action.stocks.length === 0 && action.displays.length > 0) || (action.stocks.length > 0 && action.displays.length === 0)) {
        color="#ffc107"
      }
      console.log(color) 
      return (
        <Row>  
      <Col className="bg-primary p-2" style={{backgroundColor:color,color:"#fff",marginLeft:"-5%",marginTop:"10%",marginBottom:'2%',fontSize:'18px'}} md>{storeName}</Col>
      </Row>
      )*/
                    })}
                  </div>
                </div>
              )
            })}
          </Container>
        )
      }),
    [nbreJour]
  )

  return (
    <div className="page-content ">
      <MetaTags>
        <title>Planning review</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="Planning" breadcrumbItem="Planning review" />
        <Card>
          <CardBody>
            {(connectedUser?.role?.name === "admin" ||
              connectedUser?.role?.name === "super_admin") && (
              <Row style={{ width: "50%", margin: "auto" }}>
                <Col>
                  <Label style={{ marginTop: "1rem" }}>
                    Choose marchandisers
                  </Label>
                  <Autocomplete
                    size="small"
                    id="marchandisers"
                    multiple
                    onChange={(event, newValue) =>
                      {
                        //console.log("data",newValue)
                        setSelectedMerchandiser(newValue)}
                    }
                    options={merchandisers.sort(
                      (a, b) => -b.label.localeCompare(a.label)
                    )}
                    //groupBy={option => option?.storeGroup?.toUpperCase()}
                    // getOptionLabel={option => option?.label}
                    // sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={params => (
                      <TextField {...params} label="Select merchandiser..." />
                    )}
                    renderOption={(props, option) => (
                      <div key={option.id}>
                        <li {...props}>{option.label}</li>
                      </div>
                    )}
                    // renderGroup={params => (
                    //   <li key={params.key}>
                    //     <GroupHeader>{params.group}</GroupHeader>
                    //     <GroupItems>{params.children}</GroupItems>
                    //   </li>
                    // )}
                  />
                  {/* <Select
            placeholder="Marchandisers..."
            options={merchandisers}
            isMulti={true}
            value={userOptions}
            classNamePrefix="select2-selection"
            onChange={(e) => { setSelectedMerchandiser(e) }}
          /> */}
                </Col>
              </Row>
            )}
            <Row style={{ width: "50%", margin: "auto", marginTop: "8%" }}>
              <Col style={{ marginRight: "2%", marginBottom: "2%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date Start"
                    value={StartDate}
                    inputFormat="EEEE dd MMM yyyy"
                    onChange={newValue => {
                      setStartDate(newValue)
                    }}
                    disableMaskedInput
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>
              <Col>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date End"
                    value={EndDate}
                    inputFormat="EEEE dd MMM yyyy"
                    onChange={newValue => {
                      setEndDate(newValue)
                    }}
                    disableMaskedInput
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>

              <Col style={{ marginRight: "2%", marginBottom: "2%" }}>
                <Button
                  style={{
                    height: "90%",
                    width: "100%",
                    color: "#fff",
                    backgroundColor: "#556ee6",
                    borderColor: "#556ee6",
                  }}
                  onClick={() => {
                    selectedMerchandiser.length > 0 && Search()
                  }}
                >
                  search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <div className="cont scroll">{memoData}</div>
    </div>
  )
}

export default PlanningControlDaily
