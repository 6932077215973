import React, { useEffect, useState } from 'react'
import { Button, Card, CardFooter, Col, FormGroup, Label, Modal, Row, UncontrolledAlert } from 'reactstrap'
import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import Select from "react-select";
import MutipleImagesUpload from 'shared/MutipleImagesUpload';
import { getReportSubjects } from 'store/reportSubject/services';

function ReportComponent(props) {

    const { setReport, report } = props
    const [modal_standard, setmodal_standard] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([]);
    const [subjects] = useState([]);
    const [reportSubjects, setReportSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [reportText, setReportText] = useState("");

    const [errorMessage, setErrorMessage] = useState(null);

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    const validateReport = () => {
        if (selectedSubjects.length === 0) {
            setErrorMessage("Veuillez choisir au minimum un objet!")
            setTimeout(() => {
                setErrorMessage(null)
            }, 5000)
        } else {
            
            setReport({
                ...report,
                reportText: reportText,
                pictures: selectedFiles,
                reportSubjects: reportSubjects,
            });
            tog_standard()
        }
    }

    useEffect(() => {
        getReportSubjects()
            .then((data) => {
                data.forEach(element => {
                    subjects.push({ value: element.id, label: element.name })
                });
            })
    }, [])

    useEffect(() => {
        setReportSubjects([])
        if(selectedSubjects?.length>0){
            let list=[]
        selectedSubjects.forEach((element, index) => {
            list.push({ reportSubjectId: element.value })
        });
        setReportSubjects(list)
        }
    }, [selectedSubjects])
    
    return (
        <>
            <Button
                type="button"
                color="primary"
                className="ms-1"
                onClick={() => { tog_standard() }}
            >
                {selectedSubjects && report.reportText ? "Modifier le CR" : "Ajouter un CR"}

            </Button>
            <Modal
                isOpen={modal_standard}
                size="lg"
                toggle={() => { tog_standard() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Nouveau compte rendu
                    </h5>
                    <i className="bx bx-x"
                        onClick={() => setmodal_standard(false)}
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ cursor: "pointer", fontSize: "1.3rem" }}
                    />
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={() => { validateReport() }}>
                        <Label>Objets</Label>
                        <Select
                            placeholder="Objets..."
                            value={selectedSubjects}
                            isMulti={true}
                            onChange={(e) => { setSelectedSubjects(e); }}
                            options={subjects}
                            classNamePrefix="select2-selection"
                        />
                        <Label style={{ marginTop: "2rem" }}>Compte rendu</Label>
                        <AvField
                            className="mb-3"
                            name="Compte rendu"
                            placeholder="Compte rendu"
                            rows={5}
                            type="textarea"
                            errorMessage="Compte rendu obligatoire!"
                            validate={{ required: { value: true } }}
                            value={report.reportText}
                            onChange={(e) => { setReportText(e.target.value) }}
                        />
                        <Label style={{ marginTop: "2rem" }}>Images</Label>
                        <Row>
                            {selectedFiles.map((picture, index) => (
                                <Col key={index} xl={2} lg={2}>
                                    <Card>
                                        <img
                                            style={{ width: "auto", height: "10rem" }}
                                            data-dz-thumbnail=""
                                            className="avatar-sm rounded bg-light"
                                            alt={selectedFiles && picture.name}
                                            src={selectedFiles && picture.preview}
                                        />
                                        <CardFooter>
                                            <button
                                                type="button"
                                                style={{ width: "6.8rem", marginLeft: "-1.2rem" }}
                                                className="btn btn-danger waves-effect waves-light"
                                                onClick={() => { setselectedFiles(selectedFiles.filter((q, i) => i !== index)); }}
                                            >
                                                <i className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}
                                            </button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <MutipleImagesUpload setselectedFiles={setselectedFiles} />
                        {errorMessage &&
                            <UncontrolledAlert
                                style={{ marginTop: "1rem" }}
                                color="danger"
                                className="alert-dismissible fade show"
                                role="alert"
                            >
                                <i className="mdi mdi-block-helper me-2"></i> {errorMessage}
                            </UncontrolledAlert>
                        }
                        <FormGroup className="mb-0" style={{ float: 'right', marginTop: "1rem" }}>
                            <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                            >
                                Valider
                            </Button>
                        </FormGroup>
                    </AvForm>
                </div>
            </Modal>
        </>
    )
}

export default ReportComponent