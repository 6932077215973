import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Col, Modal, Row, UncontrolledTooltip } from 'reactstrap'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getReferencedProductsByStore } from 'store/referencedProduct/services'
import { findStockByStore, findStockDays, findStockSetting } from 'store/stock/services'
import pptxgen from "pptxgenjs";
import MButton from '@mui/material/Button';
import image from 'assets/images/layouts/Image1.jpg'
import image2 from 'assets/images/layouts/Image2.jpg'
import image3 from 'assets/images/layouts/Image3.jpg'
import dayjs from 'dayjs'
import GetAppIcon from '@mui/icons-material/GetApp';
import { isUndefined, set } from 'lodash'
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { AvField } from 'availity-reactstrap-validation';
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17';
import { Box, Button, Checkbox } from '@mui/material';
import TablePaginationActions from 'components/paginationMui';
import LoadingComponent from 'shared/LoadingComponent';
const StockHistory = (props) => {

    const { store, } = props
    const [modal_standard, setmodal_standard] = useState(false)
    const [rfp, setRfp] = useState([])
    const [data, setData] = useState([])
    const [stockHistory, setStockHistory] = useState([])
    const [stockSetting, setStockSetting] = useState({})
    const [tableDays, setTableDays] = useState([])
    const [load, setLoad] = useState(true)


    //console.log(stores)

    const getRfp = async () => {
       await getReferencedProductsByStore(store.id).then((data) => {
            let dataRfp=[]
            //data.filter((elem)=> elem.product)
            data.forEach(element => {
                dataRfp.push(element)
            });
            setRowsPerPage(dataRfp?.length)
            setRfp(dataRfp)
            setData(dataRfp)

        })
        setLoad(false)

    }

    const getStockHistory = async () => {
        await findStockSetting().then(async (data) => {
            setStockSetting(data[0]);
            await findStockDays(store.id, data[0].days).then((data) => {
                setTableDays(data)
            })
           await findStockByStore(store.id, data[0].date).then((data) => {
                setStockHistory(data)
            })
        })
    }

    async function tog_standard() {
        
        setmodal_standard(!modal_standard)
        
        if (!modal_standard) {
            // console.log(rfp)
            // console.log(rfpAll)
            
            setRfp([])
            setTableDays([])
            setStockSetting()
            setStockHistory([])
            // stores.map(store=>{
                
            //     getStockHistoryAll(store.id)

            //     getRfpAll(store.id)
            //  })
             
            getStockHistory()
            await  getRfp()
            
            // setTimeout(() => {
            //     $('#datatable').DataTable({
            //         "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]]
            //     })
            // }, 1000);
        }
    }

    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };
    const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)

          const handleSearch = (search) => {
            //setCancelB(false)
            //console.log("search_______________________",search)
            if(search){
            const list=data.filter((element)=>{
              
                
                return (
                  (element?.product?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
                  || (element?.product?.barcode.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                //   || (element?.product?.internalCodes.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                //   || (element?.product?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        )
              
            })
            setRfp(rfp=>[...list])
            setRowsPerPage(list.length)
          }else{
            setRfp(rfp=>[...data])
            setRowsPerPage(data.length)
          }
          };
          const headerRenderer = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => {return (
            <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} 
                
            </div>
          );}
          const headerRowRenderer = ({
            className,
            columns,
            style
          }) => (
            <div
              className={className+' bg-light '}
              role='row'
              style={style}
            >
              {columns} 
              
            </div>
          )
          function filterData(params) {
            //console.log('list_____________________',params)
            if(!filter){
              setFilter(filter=> true)
            }
            if(params==="label"){
                //console.log('list_____________________',params)

                if(desc){
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()>b.product.label.toUpperCase())-(a.product.label.toUpperCase()<b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.label.toUpperCase()<b.product.label.toUpperCase())-(a.product.label.toUpperCase()>b.product.label.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
              else if(params==="barcode"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.barcode.toUpperCase()>b.product.barcode.toUpperCase())-(a.product.barcode.toUpperCase()<b.product.barcode.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.barcode.toUpperCase()<b.product.barcode.toUpperCase())-(a.product.barcode.toUpperCase()>b.product.barcode.toUpperCase()))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
            //   else if(params==="internalCodes"){
            //     if(desc){
            //       const list=data.sort((a,b)=> (a.internalCodes.name.toUpperCase()>b.internalCodes.name.toUpperCase())-(a.internalCodes.name.toUpperCase()<b.internalCodes.name.toUpperCase()))
            //       setDesc(desc=> !desc)
            //       setRfp(rfp => [...list])
            //     }else{
            //       const list=data.sort((a,b)=> (a.internalCodes.name.toUpperCase()<b.internalCodes.name.toUpperCase())-(a.internalCodes.name.toUpperCase()>b.internalCodes.name.toUpperCase()))
            //       setDesc(desc=> !desc)
            //       setRfp(rfp => [...list])
            //     }
            //   }
              else if (params==="typology"){
                if(desc){
                  const list=data.sort((a,b)=> (a.product.typology>b.product.typology)-(a.product.typology<b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }else{
                  const list=data.sort((a,b)=> (a.product.typology<b.product.typology)-(a.product.typology>b.product.typology))
                  setDesc(desc=> !desc)
                  setRfp(rfp => [...list])
                }
              }
            
          }

          const memoDays=useMemo(()=>tableDays.length>0&&tableDays.map((day, index) => {
            let width=1100;
            return (
                <Column key={index} label={new Date(day.date).toUTCString().slice(5, 16)} dataKey={new Date(day.date).toUTCString().slice(5, 16)}  width={width-200}  cellRenderer={({cellData,rowData}) =>  {
                
                    if (stockSetting.stockManagement === "StockOut") {
                        if (!rowData.available) {
                            return (<Button key={index} size='large' disabled style={{ backgroundColor: "grey",width:width-1000,height:25 ,borderRadius:0}} />)
                        } else {
                            let condition = stockHistory.find((element) =>
                                element.productId === rowData.productId &&
                                new Date(element.updatedAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                            )?.stockOut === true
                            return (<Button key={index} size='large' disabled style={{ backgroundColor: condition && "#DE5555",width:width-1000,height:25 ,borderRadius:0}} />)
                        }
                    } 
                    else {
                        if (!rowData.available) {
                            return (
                                <Button key={index} size='large' disabled style={{ backgroundColor: condition && "grey", color: condition && "white",width:width-1000,height:25 ,borderRadius:0}}>
                                    {
                                        stockHistory.find(
                                            (element) =>
                                                element.productId === rowData.productId &&
                                                new Date(element.updatedAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                        )?.quantity
                                    }
                                </Button>
                            )
                        } else {
                            let condition = stockHistory.find((element) =>
                                element.productId === rowData.productId &&
                                new Date(element.updatedAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                            )?.quantity === 0
                            return (
                                <Button key={index} style={{ backgroundColor: condition && "#DE5555", color: condition && "white" ,width:width-1000,height:25,borderRadius:0}}>
                                    {
                                        stockHistory.find(
                                            (element) =>
                                                element.productId === rowData.productId &&
                                                new Date(element.updatedAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                        )?.quantity
                                    }
                                </Button>
                            )
                        }
                    }
                }}/>

                
       ) }),[stockHistory])
    return (
        <>
            <Link
                to="#"
                onClick={() => { tog_standard() }}
                style={{ fontSize: "1.3rem", marginRight: "0rem" }}
            >
                <i className="bx bx-list-ul" id="stockouthistory" />
                {/* <UncontrolledTooltip placement="top" target="stockouthistory">
                    Stock history
                </UncontrolledTooltip> */}
            </Link>
            <Modal
                isOpen={modal_standard}
                //size="lg"
                style={{padding:'20px 50px 5px 20px'}}
                className='modal-fullscreen'
                toggle={() => { tog_standard() }}
                
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        StockOut history ({store.name})
                    </h5>
                    <button
                        type="button"
                        onClick={() => { setmodal_standard(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    
                </div>

                <div >
                <input
              className="m-1"
             size={25}
              placeholder="search"
              type="text"
              onChange={(e) => { handleSearch(e.target.value) }}
          />
                {load && <LoadingComponent/>}
                    {!load&& rfp.length>0 &&<div className="table-rep-plugin" style={{ marginTop: "1rem" }}>
                        <Box
                            className=" mb-0"
                            //data-pattern="priority-columns"
                            sx={{
                              display: "flex",
                              //flexDirection: "column",
                              //height: 700,
                              ///overflowX:'scroll'
                            }}
                            style={{height:620,width:'100%'}}
                        >

          
{<AutoSizer>

{({height, width}) => (
  <>

  {/* <TextField placeholder='search...' style={{alignSelf:'flex-end',width:200,}} size='small' onChange={(e)=>handleSearchStores(e.target.value)}/> */}

  <Table
  gridStyle={{
    direction: 'inherit',
    overflowX: "scroll",
  }}
  width={width}
  headerRowRenderer={headerRowRenderer}
  height={height-70}
  headerHeight={30}
  rowHeight={50}
  rowCount={rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
  rowGetter={({index}) => rfp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
  
  >    
    {/* <Column label="Image" dataKey="path" width={width}  cellRenderer={({cellData,rowData}) => <img src={URL+rowData.product.path} style={{ width: "4rem", height: "4rem", }} />}/>                                        */}
    <Column label="internalCodes" dataKey="internalCodes"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.internalCodes?.find((element) => element.storeGroupId === store.storeGroupId)?.code}</h6>}/>

    <Column label="barcode" dataKey="barcode"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6 >{rowData?.product?.barcode}</h6>}/>

    <Column label="label" dataKey="label"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.product?.label}</h6>}/>
    {/* <Column label="Typology" dataKey="typology"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData.product?.typology}</h6>}/> */}
    {memoDays}
    
 </Table>

          

       </>
)} 


</AutoSizer>  
}                  
                        </Box>
                        <TablePagination
            component="div"
            //style={{width:1100}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: rfp.length }]}
            colSpan={3}
            count={rfp.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
          />
                    </div>}
                </div>
            </Modal>
        </>
    )
}
export default StockHistory