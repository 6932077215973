import { getStoreGroups, getStoreGroupsFilter } from "./services";

export const GET_STORE_GROUP = "GET_STORE_GROUP"
export const GET_STORE_GROUP_FILTER = "GET_STORE_GROUP_FILTER"


/**
 * 
 * @returns StoreGrp list
 */
export const getStoreGroupsAsync = async () => {
  try {
    const res = await getStoreGroups();
    return {
      type: GET_STORE_GROUP,
      payload: { storeGroups: res },
    }
  } catch (error) {
    return error
  }
}

/**
 * 
 * @returns StoreGrp filter list
 */
export const getStoreGroupsFilterAsync = async () => {
  try {
    const res = await getStoreGroupsFilter();
    return {
      type: GET_STORE_GROUP_FILTER,
      payload: { storeGroupsFilter: res },
    }
  } catch (error) {
    return error
  }
}