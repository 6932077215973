import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper, Polygon, Polyline, } from 'google-maps-react';
import { useEffect } from 'react';
import LoadingComponent from './LoadingComponent';
import person from "../assets/images/personMap.png"
//import { DirectionsRenderer } from 'react-google-maps';


function Maps(props) {

  const { lat, lng,destination } = props;

  const directionsService = new google.maps.DirectionsService();
  const [direction, setDirection] = useState()

const origin = { lat: lat, lng: lng };


  //console.log("coordonné ",lat,lng)
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  useEffect(()=>{
    
    if(latitude!=lat&&longitude!=lng){
        console.log("thuffick_____________",lat,lng)
        setLatitude(lat)
        setLongitude(lng)
        // directionsService.route(
        //     {
        //         origin: new google.maps.LatLng(41.8507300, -87.6512600),
        //         destination: new google.maps.LatLng(41.8525800, -87.6514100),
        //       travelMode: google.maps.TravelMode.DRIVING
        //     },
        //     (result, status) => {
        //       if (status === google.maps.DirectionsStatus.OK) {
        //         console.log(`directions ________________`,result?.routes);
        //         // console.error(`valid`);
        //           setDirection(result)
        //         // this.setState({
        //         //   directions: result
        //         // });
        //       } else {
        //         console.error(`error fetching directions ${result}`);
        //       }
        //     }
        //   );
    }
  },[lat,lng])
  const triangleCoords = [
    {lat: latitude, lng: longitude},
    destination
  ];
  return (
   latitude>0 && longitude >0 && <div>
      <Map
      //yesIWantToUseGoogleMapApiInternals
      center={{
        lat: latitude,
        lng: longitude,
      }}
      google={props.google}
      zoom={18}
      
      //centerAroundCurrentLocation
      initialCenter={{
        lat: latitude,
        lng: longitude,
      }}
      
    >
     {/* {direction&&   <DirectionsRenderer
      directions={direction}
 />} */}
 {/* <Polyline
          paths={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#0000FF"
          fillOpacity={0.35} /> */}
      <Marker 
                
                position={{lat: latitude, lng: longitude}}
                icon={{
                  url: person,
                  anchor: new google.maps.Point(32,32),
                  scaledSize: new google.maps.Size(64,64)
                }}
                name={'Current location'}/>
    <Marker 
                
                position={destination}
                // icon={{
                //   url: person,
                //   anchor: new google.maps.Point(32,32),
                //   scaledSize: new google.maps.Size(64,64)
                // }}
                name={'store'}/>
    </Map>
    </div>
  )
}
const LoadingContainer = (props) => (
    <div><LoadingComponent/></div>
  )
export default GoogleApiWrapper({
   //apiKey: "AIzaSyDyIUz248P92vDZF_JALddrNxCcT5nu86o"
  //apiKey: "AIzaSyCFBTspt-i0CI5ZNfCo77KR_zBucGgqNiU"
  apiKey: "AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8",
  LoadingContainer:LoadingContainer,
  
  
})(Maps)