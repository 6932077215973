
/*global google*/

import socketIOClient from "socket.io-client"
import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL;
export let api;
// const google = window.google 

// export const directionsService = new google.maps.DirectionsService()


/**
 * token from localStorage
 */
const token = localStorage.getItem('authUser')
//console.log('consts____________________________',token)
/**
 * config axios
 */
if (token) {
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
} else {
  api = axios.create({
    baseURL: API_URL,
  })
 
}

/**
 * socket client
 */
export const socket = socketIOClient(API_URL)