import React from 'react'
  // import './style.css'
const Progress_bar = ({bgcolor,progress,height}) => {
   let progressColor=[]
  if(isNaN(progress)){
    progress=0
  }
  if(progress>=0 && progress<20 ){
    progressColor=['#E32712', '#D62511', '#BD2210', '#961A0C']
  }
  if(progress>=20 && progress<40 ){
    progressColor=['#F5CF0D', '#DEC600']
  }
  if(progress>=40 && progress<60 ){
    progressColor=['#B5DE0B', '#5EDE0B', '#30FF00']
  }
  if(progress>=60 && progress<80 ){
    progressColor=['#38F000', '#32D600', '#2DBD00', '#2DC200']
  }
  if(progress>=80 && progress<=100 ){
    progressColor=['#00DFE6', '#00CFD6', '#00BBC2', '#00B4BA']
  }
  if(progress>=100 ){
    progressColor=['#00DFE6', '#00CFD6', '#00BBC2', '#00B4BA']
    progress=100
  }
    const Parentdiv = {
      height: 25,
      width: '100%',
      backgroundColor: "#D1D1CF",
      borderRadius: 0,
      margin: 0
      }
      
      const Childdiv = {
        height: '100%',
        width: progress ?`${progress}%` :`0%`,
        borderRadius: 0,
        textAlign: 'center',
        background: `linear-gradient(${-45}deg, ${progressColor})`,
        backgroundSize: '400% 400%',
        animation: 'gradient 3s ease infinite'
      }
      
      const progresstext = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold',
        fontSize:'16px',
        
      }
        
    return (
      <div  style={Parentdiv}>
      <div className='prog'  style={Childdiv}>
        <span  style={progresstext}>{ progress ?`${progress}%`:`0%`}</span>
      </div>
    </div>
   
    )
}
  
export default Progress_bar;