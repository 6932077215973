import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from 'pages/Performance/components/tabPanel';


  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
function ProductTab({tab1,tab2,tab3}) {
    const theme = useTheme();
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
  return (
    < >
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Output Products" {...a11yProps(0)} />
        <Tab label="Family Rupture" {...a11yProps(1)} />
        <Tab label="Category Rupture" {...a11yProps(2)} />

      </Tabs>
    </AppBar>
    <SwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={value}
      onChangeIndex={handleChangeIndex}
    >
      <TabPanel value={value} index={0} dir={theme.direction}>
      {tab1}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
      {tab2}
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
      {tab3}
      </TabPanel>

    </SwipeableViews>
    </>
  )
}

export default ProductTab