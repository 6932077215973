import React, { useCallback, useEffect, useState, useRef, forwardRef, useMemo } from 'react'
import { Row, Card, CardBody, Col, Button, Table } from "reactstrap"
import { Box, Modal, TablePagination, TextField, Typography } from '@mui/material';
import PieChart from 'pages/Performance/components/PieChart';
//import Table from '@mui/material/Table';
import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Progress_bar from 'pages/Performance/components/progress';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DataTableVisit from './DataTableVisit';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useDownloadExcel } from 'react-export-table-to-excel';
import GetAppIcon from '@mui/icons-material/GetApp';
import MButton from '@mui/material/Button';
import pptxgen from "pptxgenjs";
import dayjs from 'dayjs';
import logoStore from "../../../assets/images/store.png"

//import $ from 'jquery';
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";

const DataTableSecondRef = forwardRef(function DataTableSecond({ data },ref) {
  console.log("part 2")
  const [stores, setStores] = useState([])
  //console.log(data)
  // useEffect(()=>{
  //   setStore([...data])
    

  // },[data])
  useEffect(()=>{
    if(data?.length>0){
      setRowsPerPage(data.length)
      setStores(data)
    }
  },[data])

  const [lastVisit, setLastVisit] = useState('')

  const [visit, setVisit] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // useEffect(()=>{
  //   handleChangePage("",50)
  // },[])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal Table 3 
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const [storeList, setStoreList] = useState([]);
  const [name, setName] = useState();
  const [barcode, setBarcode] = useState();

  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleClickTop = (data, name, barcode, visit) => (event) => {
//console.log("handle-------",data)
    setAnchorE2(event.currentTarget);
    setStoreList(data)
    setName(name.toUpperCase())
    setBarcode(barcode)
    setVisit([...data])
    setLastVisit(visit)
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: 720,

  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: ref.current,
    filename: 'Users table',
    sheet: 'Users'
  })

  // useEffect(async () => {
  //   console.log('data: ', store)
  //   //setProducts([...data])

  // }, [])

  const memoStores=useMemo(()=>stores?.length>0 && stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
    <StyledTableRow key={row.id + Math.random(0, 100000)}>
      {/* <StyledTableCell component="th" scope="row">
        <Button variant="outlined" color='light' size='large' style={{ fontSize: '15px' }}> {row.id}</Button>
      </StyledTableCell> */}

      <StyledTableCell align="left"><img width={60} src={URL+row.image} onError={(e) => { e.target.src = logoStore }}/></StyledTableCell>
      <StyledTableCell align="left"><Button variant="outlined" color='primary' size='large' style={{ fontSize: '15px' }}> {row.name}</Button></StyledTableCell>
      <StyledTableCell align="left"><PieChart nbStock={row.length} nbStockOut={row.nbStockOut} /></StyledTableCell>
      <StyledTableCell align="left" ><Progress_bar bgcolor="orange" progress={row.pourcentage} /></StyledTableCell>
      <StyledTableCell align="left"><Button variant="outlined" color='success' size='large' style={{ fontSize: '15px' }}> {dayjs(row.lastVisit).format('YYYY-MM-DD HH:mm:ss')}</Button></StyledTableCell>
      <StyledTableCell align="left"><PieChart nbStock={row.nbStockL} nbStockOut={row.nbStockOutL} /></StyledTableCell>
      <StyledTableCell align="left"><Button variant="outlined" color='light' size='large' style={{ fontSize: '15px' }}> {row.gouvernement}</Button></StyledTableCell>
      <StyledTableCell align="left"><Button variant="outlined" color='light' onClick={handleClickTop(row.listVisit, row.name, row.image, dayjs(row.lastVisit).format('YYYY-MM-DD HH:mm:ss'))}><InventoryOutlinedIcon fontSize='large' /></Button></StyledTableCell>

    </StyledTableRow>
  )),[stores])


  return (
    <div>

      {stores.length>0&&<Paper sx={{ width: '100%' }}>
        {/* <Button  variant="outlined" color='light' size='large' onClick={exportPresentation} style={{fontSize:'15px'}}> presentation</Button> */}
        <TableContainer sx={{
          height: 530
        }}>
          <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                        <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                        >
          <Table  sx={{ minWidth: 700, height: "max-content" }} id='tableElementId' stickyheader="true" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>Id</StyledTableCell> */}
                <StyledTableCell align="left" >Image</StyledTableCell>
                <StyledTableCell align="left">Store</StyledTableCell>
                <StyledTableCell align="left">Taux</StyledTableCell>
                <StyledTableCell align="left">Pourcentage</StyledTableCell>
                <StyledTableCell align="left">Last Visit</StyledTableCell>
                <StyledTableCell align="left">Taux (Last Visit)</StyledTableCell>
                <StyledTableCell align="left">Gouvernement</StyledTableCell>
                <StyledTableCell align="left">Actions</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {memoStores}
            </TableBody>
          </Table>
          </div>
          </div>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 50,100, { label: 'All', value: stores.length }]}
          component="div"
          count={stores.length}
          rowsPerPage={rowsPerPage}
          page={page}
          
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <MButton css={{ backgroundColor: 'blue' }} onClick={onDownload} color='primary' aria-describedby='chart' style={{ fontSize: '15px' }} className=''><GetAppIcon size='large' /> &nbsp; Export</MButton> */}
      </Paper>}
      {open2&&<Modal
        keepMounted
        open={open2}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={modalStyle}>

          <Typography id="keep-mounted-modal-title" variant={"body2"} component={"span"}>

            <div className="d-flex justify-content-between"><h3> <img width={50} src={URL+barcode} onError={(e) => { e.target.src = logoStore }}/> </h3> <h3 align='center'><Button size="medium" variant="text" color='primary' style={{ fontSize: '15px' }}><LocalMallIcon fontSize='medium' /> {name}</Button> </h3>

              <Button variant="outlined" color='light' style={{ float: 'right', marginBottom: '5px' }} onClick={handleClose} ><CloseIcon /></Button>

            </div>

          </Typography>

          <DataTableVisit data={visit} lastVisit={lastVisit} />
        </Box>
      </Modal>}
    </div>
  )
})

export default DataTableSecondRef