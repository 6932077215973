import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import './style.css'
import MButton from '@mui/material/Button';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import { isNull } from 'lodash';

// ==============================|| REPORT AREA CHART ||============================== //


const PassedTimeChart = ({data}) => {

    const dataList=data
    //console.log(dataList)
    //list totale commande
    const totalCmd=[]  
    const tempPasse=[]
    const moyenPE=[]
    const moyenPS=[]
    const nbCommandes=[]
    const [showData,setShowData]=useState(false)
    //list marchandizer
    const listMarchandizer=[]
    dataList.map((d,index)=>{
        //console.log(d.dateEntre)
        if(isNaN(d.dateEntre)||isNaN(d.dateSortie)||isNaN(d.tempPasse)||isNull(d.dateEntre)||isNull(d.dateSortie)||isNull(d.tempPasse)){
            
            moyenPE.push(0)
            moyenPS.push(0)
            tempPasse.push(0)
        }else{
            
            moyenPE.push(d.dateEntre)
            moyenPS.push(d.dateSortie)
            tempPasse.push(d.tempPasse)
            
        }
        nbCommandes.push(d.nbCmd)
        totalCmd.push(d.totalCmd)
        listMarchandizer.push(d.name)

    })
    //console.log(moyenPE)
    // chart options

const areaChartOptions =  {
    chart: {
        height: 350,
        type: 'line',
        toolbar: {
            show: true
        },
        stacked: false,
    },
    dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    dataLabels: {
        enabled: true
    },
    stroke: {
        //curve: 'smooth',
        width: [1, 1, 1]
    },
    plotOptions: {
        bar: {
          columnWidth: "80%"
        }
      },
    fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
    grid: {
        strokeDashArray: 40
    },
    xaxis: {
        
        categories:listMarchandizer,
        borderColor: '#999',
        yAxisIndex: 0,
        tickAmount: 6,
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: true
        },
        label: {
            show: true,
            text: 'Rally',
            style: {
              color: "#fff",
              background: '#775DD0'
            }
          }
    },
    yaxis: [
    //     {
    //     title: {
    //         text: "Montant Commande",
    //         rotate: -90,
    //         offsetX: 0,
    //         offsetY: 0,
    //         style: {
    //             color: undefined,
    //             fontSize: '12px',
    //             fontFamily: 'Helvetica, Arial, sans-serif',
    //             fontWeight: 600,
    //             cssClass: 'apexcharts-yaxis-title',
    //         },
    //     },
    //     show: true,
    //     showForNullSeries: true,
    //     // stroke: {
    //     //     curve: 'smooth',
    //     // },
    //     axisBorder: {
    //         show: true,
    //         color: '#008FFB',
    //         offsetX: 0,
    //         offsetY: 0,
    //     },
    //     axisTicks: {
    //         show: true,
    //         borderType: 'solid',
    //         color: '#008FFB',
    //         width: 6,
    //         offsetX: 0,
    //         offsetY: 0
    //     },

    //     crosshairs: {
    //         show: true,
    //         position: 'back',
    //         stroke: {
    //             color: '#b6b6b6',
    //             width: 1,
    //             dashArray: 0,
    //         },
    //     },
    // },
    {
        
        show: true,
        showForNullSeries: true,
        opposite: false,
        title: {
            text: "Moyenne temps passé (heure)"
        },
        max:Math.max(tempPasse),
        min:Math.min(tempPasse),
        
        axisBorder: {
            show: true,
            color: '#008FFB',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#008FFB',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },

        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    {
        
        show: true,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Moyenne Pointage Entré (minute)"
        },
        max:Math.max(moyenPE).toPrecision(2),
        min:Math.min(moyenPE).toPrecision(2),
        
        axisBorder: {
            show: true,
            color: '#F09000',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#F09000',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },

        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    {
        show: true,
        showForNullSeries: true,
        opposite: true,
        title: {
            text: "Moyenne Pointage Sortie (minute)"
        },
        max:Math.max(moyenPS).toPrecision(2),
        min:Math.min(moyenPS).toPrecision(2),
        axisBorder: {
            show: true,
            color: '#FF1654',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#FF1654',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
    // {
    //     show: true,
    //     showForNullSeries: true,
    //     opposite: true,
    //     title: {
    //         text: "Nombre de commandes"
    //     },
    //     max: Math.max(...nbCommandes)+Math.max(...nbCommandes)/2,
    //     min:0,
    //     axisBorder: {
    //         show: true,
    //         color: '#CB52DE',
    //         offsetX: 0,
    //         offsetY: 0
    //     },
    //     axisTicks: {
    //         show: true,
    //         borderType: 'solid',
    //         color: '#CB52DE',
    //         width: 6,
    //         offsetX: 0,
    //         offsetY: 0
    //     },
    //     crosshairs: {
    //         show: true,
    //         position: 'back',
    //         stroke: {
    //             color: '#b6b6b6',
    //             width: 1,
    //             dashArray: 0,
    //         },
    //     },
    // },
   ],
    markers: {

        discrete: [{
            seriesIndex: 0,
            dataPointIndex: 7,
            fillColor: '#e3e3e3',
            strokeColor: '#fff',
            size: 5,
            shape: "circle" // "circle" | "square" | "rect"
          }, {
            seriesIndex: 2,
            dataPointIndex: 11,
            fillColor: '#f7f4f3',
            strokeColor: '#eee',
            size: 4,
            shape: "circle" // "circle" | "square" | "rect"
          }]
      },
    
};
    
    const theme = useTheme();

    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);
    //const [options2, setOptions2] = useState(areaChartOptions2);

    //toggle series :
    function toggleSeries(){
        setShowData(true)
    }


    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            
            colors: ['#008FFB', '#F09000', '#FF1654','#0CF02A','#D998E3'],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                },
                type: 'string',
                categories: listMarchandizer,
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },

            tooltip: {
                theme: 'dark',
                followCursor: true,
                //shared: false,
                //intersect: true,

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                paddingBottom:20
              },

            title: {
                text: 'Time Graph',
                align: 'center',
                margin: 20,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                fontSize:  '20px',
                fontWeight:  'bold',
                color:  '#263238'
                },
              },

        }));

    }, [primary, secondary, line, theme]);

    const [series] = useState([
       
        {
            name: 'Moyenne temps passé (heure)',
            data: tempPasse,
            type: 'column',
        },
        {
            name: 'Moyenne Pointage Entré (minute)',
            data: moyenPE,
            type: 'area',
        },
        {
            name: 'Moyenne Pointage Sortie (minute)',
            data: moyenPS,
            type: 'area',
        },

        
    ]);
  

    return (
        <ReactApexChart options={options}  series={series} />
    )
};

export default PassedTimeChart;


///////tab content/////

