import React, { memo, useEffect, useState } from 'react'
import Breadcrumbs from "components/Common/Breadcrumb"
import { MetaTags } from 'react-meta-tags'
import { Button, Card, CardBody, CardTitle, Col, Row,List, } from 'reactstrap'
import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import CreateDisplay from './createComponents'
import { useDispatch, useSelector } from 'react-redux'
import { getDisplayListAsync } from 'store/display/actions'
import DisplayDetail from './detailComponents'
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import Swal from 'sweetalert2'
import { deleteDisplayById } from 'store/display/services'
import UpdateDetail from './updateComponents'
import { Oval } from  'react-loader-spinner'
import MButton from '@mui/material/Button';
import pptxgen from "pptxgenjs";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { Box, Icon, Modal, Paper, TableFooter, TablePagination, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import MTable from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import image from 'assets/images/layouts/Image1.jpg'
import image2 from 'assets/images/layouts/Image2.jpg'
import LoadingComponent from 'shared/LoadingComponent'
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17'
import TablePaginationActions from 'components/paginationMui'
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import DataTable from './dataTable'

const Display = () => {

    const dispatch = useDispatch()

    const getDisplayList = async () => {
      return dispatch(await getDisplayListAsync()).payload.displayList;
        
    }

    
    const {data,status,isLoading,isFetching} =useQuery('displays', getDisplayList,{
      refetchOnWindowFocus:false,
      //refetchInterval:0
       //staleTime:'infinity',
      // initialData:undefined
      //refetchOnMount:false
      cacheTime:7200000
    })
    
    //console.log("cashe---------------",{isLoading,isFetching},data?.length)


    
    return (
        <div className="page-content">

{isLoading && (
               <LoadingComponent/>
    )}
    {!isLoading && (
        <DataTable data={data} /> 
            )}
        </div>
    )
}
export default memo(Display)