import { getVisitDashboard, getVisits, getVisitsPerformance } from "./services";

export const GET_VISITS = "GET_VISITS"
export const GET_VISITS_Performance = "GET_VISITS_Performance"


/**
 * 
 * @returns Visit list
 */
export const getVisitsAsync = async () => {
  try {
    const res = await getVisits();
    return {
      type: GET_VISITS,
      payload: { visits: res },
    }
  } catch (error) {
    return error
  }
}



/**
 * 
 * @returns visit list for Display Data
 */
 export const getVisitList = async () => {
  const res = await getVisits();
  return {
    type: GET_VISITS,
    payload: { visits: res },
  }
}


/**
 * 
 * @returns users list
 */
export const getVisitDashboardAsync = async () => {
  const res = await getVisitDashboard();
  return {
    type: GET_VISITS,
    payload: { visits: res },
  }
}


/**
 * 
 * @returns visit list for performance
 */
export const getVisitPerformanceAsync = async () => {
  const res = await getVisitsPerformance();
  //console.log("visits_________________________",res.length)
  return {
    type: GET_VISITS_Performance,
    payload: { visitPerf: res },
  }
}


