import React, { useCallback, useEffect, useState, useRef, memo } from 'react'
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Link } from 'react-router-dom';
import {
    DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridPrintExportMenuItem,
    GridSearchIcon,
    GridToolbarQuickFilter,
    GridRow,
    GridColumnHeaderSeparator,
} from '@mui/x-data-grid';
import { Autocomplete, Box, Checkbox, Chip, FormControl, gridClasses, IconButton, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, makeStyles, MenuItem, Modal, OutlinedInput, Popover, Select, Stack, TextField, Typography } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import { Row, Card, CardBody, Col, Button, UncontrolledTooltip } from "reactstrap"
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import TablePaginationActions from 'components/paginationMui';
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

//Export
const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

const JsonExportMenuItem = (props) => {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                const jsonString = getJson(apiRef);
                const blob = new Blob([jsonString], {
                    type: 'text/json',
                });

                exportBlob(blob, 'DataGrid_demo.json');

                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export JSON
        </MenuItem>
    );
};

JsonExportMenuItem.propTypes = {
    hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem />
        <JsonExportMenuItem />
    </GridToolbarExportContainer>

    
);
const CustomToolbar = (props) => (
    
    <GridToolbarContainer {...props}  style={{width:'100%'}}>
        <Col>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
        </Col>
        

        <GridToolbarQuickFilter  />
        
    </GridToolbarContainer>
);


function SelectProduct(props) {
    const columns = [
        // { field: 'id', headerName: 'id', width: 50},
        //{ field: 'path', headerName: 'Image', width: 100, editable: false, renderCell: params => <img width={50} src={params.row.path} /> },
        { field: 'barcode', headerName: 'Barcode', width: 200, editable: true, renderCell: params => <><HorizontalSplitSharpIcon fontSize='small' />  {params.row.barcode}</>},
        { field: 'label', headerName: 'Label', align: 'left', width: 270, renderCell: params => params.row.label },
        { field: 'brand', headerName: 'Brand', align: 'left', editable: true, width: 200, renderCell: params => params.row.brand?.name },
        { field: 'category', headerName: 'Category', align: 'left', width: 200, renderCell: params => params.row.category?.name },
        { field: 'family', headerName: 'Family', align: 'left', width: 200, renderCell: params => params.row.category?.family?.name },
        { field: 'typology', headerName: 'Typology', align: 'left', width: 200, renderCell: params => params.row.typology },
        // //{ field: 'storeGrp', headerName: 'StoreGrp', align: 'left', editable: true, width: 150, renderCell: params => showCellStoreGrp(params.row.storeGrp) },
        // //{ field: 'gouvernement', headerName: 'Gouvernement',align:'center', width: 100 ,renderCell:params=><DateNumbers number={params.row.tempPasse}/>},

        //{ field: 'action', headerName: 'Actions', align: 'center', width: 100, editable: false, renderCell: params => <Button variant="outlined" color='primary' onClick={()=>selectedItem(params.row.path)}>Select...</Button> },

    ];

    //table options
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ['label'],
    });

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);

    }, []);

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const { chosenProducts, setChosenProducts,dataProducts } = props
    const dispatch = useDispatch();
    const [products, setProducts] = useState([])
    const [data, setData] = useState([])
    const [checkedAll, setCheckedAll] = React.useState(false);

    const [ListIds, setListIds] = useState([])


    // const getProducts = async () => {
        
    // }


    
    //console.log(ListIds)
    function deleteSelected(id){
        //console.log("id_____________________________",id)
        let listSelected=chosenProducts.filter((prduct, i) => prduct.id !== id)
        let list= products.map((elem)=>{
            if(id===elem.id){
                elem.selected=false
            }
            return elem

        })
        
        setChosenProducts(chosenProducts=>[...listSelected])

        setProducts(products=>[...list])
        // setChosenProducts(chosenProducts.filter((prduct, i) => i !== id))
        // let list= products.map((elem)=>{
        //     if(id===elem.id){
        //         elem.selected=false
        //     }
        //     return elem
    
        // })
        // setProducts(products=>[...list])
        // setListIds(ListIds.filter((p,i) => i !== id))
    }

    function onSelectAllClick(params) {
        //console.log("onSelectAllClick______________________",params)
       if(params==="All"){
        if(checkedAll){
    
            let list= products.map((elem)=>{
                elem.selected=false
                return elem
        
            })
            setChosenProducts(chosenProducts=>[])
            setProducts(products=>[...list])
        }else{
            let list= products.map((elem)=>{
                elem.selected=true
                return elem
        
            })
            setChosenProducts(chosenProducts=>[...list])
    
            setProducts(products=>[...list])
        }
        setCheckedAll(checkedAll=>!checkedAll)
    
       }else{
        let listSelected=[...chosenProducts]
        let list= products.map((elem)=>{
            if(elem.id===params){
                if(elem?.selected){
                    elem.selected=false
                    listSelected=listSelected.filter((el)=>el.id!==elem.id)
                }else{
                    elem.selected=true
                    listSelected.push(elem)
    
                }
            }
            return elem
    
        })
        
        setChosenProducts(chosenProducts=>[...listSelected])
    
        setProducts(products=>[...list])
       }
    }

    function selectedItem(ids) {
        //console.log(ids);
        const selectedIDs = new Set(ids);
        const selectedRowData = products.filter((row) =>
            selectedIDs.has(row.id));
        //console.log(selectedIDs);
        setChosenProducts(selectedRowData)
        setListIds(ids)
    }

    useEffect( () => {

        //await getProducts()
        if(dataProducts?.length>0){setRowsPerPage(dataProducts?.length)
        setProducts([...dataProducts]);
        setData([...dataProducts])}
        
    }, [dataProducts])

    const [pageSize, setPageSize] = React.useState(25);

    const MemoizedRow = memo(GridRow);
    const ItemContainer = styled.div`
  padding: 0.1rem;
  width: 33%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`

const ItemWrapper = styled.div`
  flex: 1;
  text-align: center;
  font-size: 60%;
  padding: 0.1rem 0.1rem;
  border: 1px solid var(gray);
  white-space: nowrap;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;`
///--------------------------------------------------
const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }
  const columnsVirtuso = [
    // {
    //   width: 120,
    //   label: 'id',
    //   dataKey: 'id',
    //   //numeric: true,
    // },
    {
        width: 80,
        label: '',
        dataKey: 'Selection',
        //numeric: true,
      },
    // {
    //   width: 80,
    //   label: 'Image',
    //   dataKey: 'path',
    // },
    {
      width: 200,
      label: 'Barcode',
      dataKey: 'barcode',
      //numeric: true,
    },
    {
      width: 200,
      label: 'Label',
      dataKey: 'label',
    },
    {
      width: 200,
      label: 'Brand',
      dataKey: 'Brand',
    },
    {
      width: 200,
      label: 'Category',
      dataKey: 'Category',
    },
    {
      width: 200,
      label: 'Typology',
      dataKey: 'typology',
    },
    // {
    //   width: 200,
    //   label: 'Action',
    //   dataKey: 'Action',
    //   //numeric: true,
    // },
   
    
  ];
  
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [page, setPage] = React.useState(0);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function fixedHeaderContent() {
    return (
      <TableRow style={{height:5}}>
        {columnsVirtuso.map((column,index) => 
          {
            if(column.dataKey==="Action" ||column.dataKey==="path"){
              return  <TableCell
              key={column.dataKey+index}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
              {column.label}
            </TableCell>
            }else if(column.dataKey==="Selection"){
                return  <TableCell
                key={column.dataKey}
                variant="head"
                //align={'justify'}
                style={{ color:"white", }}
                sx={{
                  backgroundColor: '#4F6973',
                }}
              >
                <Checkbox
                  //color="secondary"
                  style={{color:'white'}}
                  //indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={checkedAll}
                  onChange={()=>onSelectAllClick('All')}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
              }else{
              return  <TableCell
              onClick={()=>filterData(column.dataKey)}
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
                <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>
  
            </TableCell>
            }
          }
      )}
      </TableRow>
    );
  }
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} selected={_item?.selected}/>,
    TableBody: React.forwardRef((props, ref) => (
    <>
    <TableBody {...props} ref={ref} />
    
  
    </>)),
    // TableFooter :React.forwardRef((props, ref) => 
    
    
    // )
  };
  const URL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3303"
        : "https://www.devtest21-api.g2m-tn.com";
        
  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columnsVirtuso.map((column) => {
          //console.log("column______________________",row.id)
          

            if(column.dataKey==="path"){
             return (<TableCell
            key={column.dataKey+row.id}
            align={column.numeric || false ? 'right' : 'left'}
            >
              <img src={URL+row[column.dataKey]} style={{ width: "4rem", height: "4rem" }} />
            </TableCell>)
            }else if(column.label==="Barcode") {
                return (<TableCell
                  key={column.dataKey+row.id}
                  align={column.numeric || false ? 'right' : 'left'}
                  >
                  <HorizontalSplitSharpIcon fontSize='small' />  {row.barcode}
                  </TableCell>)
              }  else if(column.label==="Brand") {
              return (<TableCell
                key={column.dataKey+row.id}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row.brand.name}
                </TableCell>)
            }else if(column.label==="Category") {
              return (<TableCell
                key={column.dataKey+row.id}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row.category.name}
                </TableCell>)
            }else if(column.label==="") {
                return (<TableCell padding="checkbox" key={column.dataKey+row.id}>
                <Checkbox
                   style={{marginLeft:10}} 
                  color="primary"
                  key={column.dataKey+row.id}
                  //indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={row?.selected}
                  onChange={()=>onSelectAllClick(row.id)}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>)
              }
           
            else{
              return (<TableCell
                key={column.dataKey+row.id}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row[column.dataKey]}
                </TableCell>)
            }
        }
         
        )}
      </React.Fragment>
    );
  }
  const handleSearch = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=data.filter((element)=>{
      
        return (
          (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.barcode?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
)
      
    })
    setProducts(products=>[...list])
    setRowsPerPage(list.length)

  }else{
    setProducts(products=>[...data])
    setRowsPerPage(data.length)
  }
  };
  function filterData(params) {
    //console.log('list_____________________',params)
    if(!filter){
      setFilter(filter=> true)
    }
    if(params==="label" || params==="barcode" ){
      
      if(desc){
        const list=products.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }else{
        const list=products.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }
    }else{
      if(params==="Brand"){
        if(desc){
          const list=products.sort((a,b)=> (a.brand.name.toUpperCase()>b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()<b.brand.name.toUpperCase()))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }else{
          const list=products.sort((a,b)=> (a.brand.name.toUpperCase()<b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()>b.brand.name.toUpperCase()))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }
      }else if(params==="Category"){
        if(desc){
          const list=products.sort((a,b)=> (a.category.name.toUpperCase()>b.category.name.toUpperCase())-(a.category.name.toUpperCase()<b.category.name.toUpperCase()))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }else{
          const list=products.sort((a,b)=> (a.category.name.toUpperCase()<b.category.name.toUpperCase())-(a.category.name.toUpperCase()>b.category.name.toUpperCase()))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }
      }else if (params==="typology"){
        if(desc){
          const list=products.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }else{
          const list=products.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
          setDesc(desc=> !desc)
          setProducts(products => [...list])
        }
      }
    }
    
  }
    return (
        <div>
            <Row >
                
                {chosenProducts.length>0 && <VirtuosoGrid
                    style={{ height: 100,width:'100%' }}
                    data={chosenProducts}
                    totalCount={chosenProducts.length}
                    overscan={0}
                    components={{
                        Item: ItemContainer,
                        List: ListContainer,
                        ScrollSeekPlaceholder: ({ height, width, index }) => (
                        <ItemContainer>
                            <ItemWrapper>{'-'}</ItemWrapper>
                        </ItemContainer>
                        ),
                    }}
                    itemContent={(index,elem) => <ItemWrapper>
                        <Chip
                        key={elem.id}
                        label={elem?.label}
                        style={{fontSize:15}}
                        //onClick={handleClick}
                        onDelete={()=>deleteSelected(elem.id)}
                        deleteIcon={<DeleteIcon style={{color:'red'}}/>}
                        variant="outlined"
                    /></ItemWrapper>}
                    // scrollSeekConfiguration={{
                    //     enter: velocity => Math.abs(velocity) > 200,
                    //     exit: velocity => Math.abs(velocity) < 30,
                    //     change: (_, range) => console.log({ range }),
                    // }}
                    />}
            </Row>
            <div
                className=" mb-0"
                data-pattern="priority-columns"
                style={{height:600}}
            >
                              <TextField placeholder='search...' style={{padding:1,marginBottom:5,alignSelf:'flex-end',fontSize:10}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>
                {products.length>0&&<Box sx={{ height: 600, width: '100%', }} >

<TableVirtuoso
    data={products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
    components={VirtuosoTableComponents}
    
    fixedHeaderContent={fixedHeaderContent}
    itemContent={rowContent}
  />
   <Row>
    <Col >

      <TablePagination
        component="div"
        //style={{width:1100}}
        rowsPerPageOptions={[10, 50,100, { label: 'All', value: products.length }]}
        colSpan={3}
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        //ActionsComponent={TablePaginationActions}
      />

    </Col>
    
   </Row>
                   {/* {products.length>0 && <DataGrid 
                    rows={products}
                    columns={columns}
                    
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                        slots={{
                            row: MemoizedRow,
                            //columnHeaders: MemoizedColumnHeaders,
                          }}
                          columnBuffer={3} 
                          columnThreshold={3}
                        //loading={!products}
                        density='standard'
                        
                        //pinnedColumns={pinnedColumns}
                        getRowSpacing={getRowSpacing}
                        // sx={{
                        //     [`& .${gridClasses.row}`]: {
                        //         bgcolor: (theme) =>
                        //             theme.palette.mode === 'light' ? grey[500] : grey[100],
                        //     }, overflowY: 'scroll'
                        // }}
                        
                        getRowId={row => row.id}
                        //onPinnedColumnsChange={handlePinnedColumnsChange}
                        
                        rowHeight={60}
                        checkboxSelection
                        selectionModel={ListIds}
                        onSelectionModelChange={params => selectedItem(params)}
                        disableSelectionOnClick
                       // experimentalFeatures={{ newEditingApi: true }}
                        components={{
                            Toolbar: CustomToolbar,
                            //value: searchText,
                            //LoadingOverlay: LinearProgress,
                        }}

                    />} */}
                    </Box>}
                
            </div>
        </div>
    )
}

export default SelectProduct