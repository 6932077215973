import React, { memo, useEffect, useState } from 'react'
import Breadcrumbs from "components/Common/Breadcrumb"
import { MetaTags } from 'react-meta-tags'
import { Button, Card, CardBody, CardTitle, Col, Row,List, } from 'reactstrap'
import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import CreateDisplay from './createComponents'
import { useDispatch, useSelector } from 'react-redux'
import { getDisplayListAsync } from 'store/display/actions'
import DisplayDetail from './detailComponents'
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import Swal from 'sweetalert2'
import { deleteDisplayById } from 'store/display/services'
import UpdateDetail from './updateComponents'
import { Oval } from  'react-loader-spinner'
import MButton from '@mui/material/Button';
import pptxgen from "pptxgenjs";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { Box, Icon, Modal, Paper, TableFooter, TablePagination, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import MTable from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import image from 'assets/images/layouts/Image1.jpg'
import image2 from 'assets/images/layouts/Image2.jpg'
import LoadingComponent from 'shared/LoadingComponent'
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17'
import TablePaginationActions from 'components/paginationMui'
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { set } from 'lodash'
import moment from 'moment'
import queryClient from 'queryClient'

const dataTableDisplays = ({data}) => {

    const [displayList, setDisplayList] = useState([])
    const [displays, setDisplays] = useState([])

    const deleteDisplay = (id, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete display!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDisplayById(id)
                    .then(async () => {
                        // setDisplayList(displayList.filter((display, i) => i !== index))
                        queryClient.invalidateQueries('displays')
                        Swal.fire(
                            'Deleted!',
                            'Display deleted!',
                            'success'
                        )
                    })
            }
        })
    }
    //getDataDisplay()
    useEffect(async () => {
        setRowsPerPage(data?.length)
        setDisplays(data)
        setDisplayList(data)
        // setTimeout(() => {
        //     $('#displayTable').DataTable()
        // }, 300);
    }, [data])

   
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onPageChange: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
    };
    const [desc, setDesc] = useState(false)
    const [filter, setFilter] = useState(false)
  
    const sort_by = (field, reverse, primer) => {
  
      const key = primer ?
        function(x) {
          return primer(x[field])
        } :
        function(x) {
          return x[field]
        };
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }
  
    const handleSearch = (search) => {
      //setCancelB(false)
      //console.log("search_______________________",moment(search).isValid())
      let chekDate
      if(moment(search,moment.ISO_8601,true).isValid()){
        //console.log("hey_______________________",)
        chekDate=new Date(search).toISOString().substring(0,10)
      }
      if(search){
      const list=displays.filter((element)=>{
        
        //console.log("element_______________________",new Date(element?.createdAt?.substring(0,10)))
          return (
            (element?.store?.name.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
            || (element?.user.first_name.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
            || (element?.user.last_name.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
            || (chekDate &&( new Date(element?.createdAt).toISOString().substring(0,10)===new Date(search).toISOString().substring(0,10) ))
            // || (dayjs(element?.createdAt).format('YYYY-MM-DD HH:mm:ss').toString().toLowerCase().includes(search.toString().toLowerCase(search)) )

            ||(element?.store?.type.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 

  )
        
      })
      setDisplayList(displayList=>[...list])
      setRowsPerPage(list.length)
    }else{
      setDisplayList(displayList=>[...displays])
      setRowsPerPage(displays.length)
    }
    };
    function filterData(params) {
      //console.log('list_____________________',params)
      if(!filter){
        setFilter(filter=> true)
      }
      if(params==="store"){
        if(desc){
            const list=displays.sort((a,b)=> (a.store.name.toUpperCase()>b.store.name.toUpperCase())-(a.store.name.toUpperCase()<b.store.name.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }else{
            const list=displays.sort((a,b)=> (a.store.name.toUpperCase()<b.store.name.toUpperCase())-(a.store.name.toUpperCase()>b.store.name.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }
      }else if(params==="user"){
        if(desc){
            const list=displays.sort((a,b)=> (a.user.first_name.toUpperCase()>b.user.first_name.toUpperCase())-(a.user.first_name.toUpperCase()<b.user.first_name.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }else{
            const list=displays.sort((a,b)=> (a.user.first_name.toUpperCase()<b.user.first_name.toUpperCase())-(a.user.first_name.toUpperCase()>b.user.first_name.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }
      }else if(params==="Type"){
        if(desc){
            const list=displays.sort((a,b)=> (a.store.type.toUpperCase()>b.store.type.toUpperCase())-(a.store.type.toUpperCase()<b.store.type.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }else{
            const list=displays.sort((a,b)=> (a.store.type.toUpperCase()<b.store.type.toUpperCase())-(a.store.type.toUpperCase()>b.store.type.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }
      }else if(params==="date"){
        if(desc){
            const list=displays.sort((a,b)=> (new Date(a?.createdAt)>new Date(b?.createdAt))-(new Date(a?.createdAt)<new Date(b?.createdAt)))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }else{
            const list=displays.sort((a,b)=> (new Date(a?.createdAt)<new Date(b?.createdAt))-(new Date(a?.createdAt)>new Date(b?.createdAt)))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }
      }else {
        if(desc){
            const list=displays.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }else{
            const list=displays.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
            setDesc(desc=> !desc)
            setDisplayList(displayList => [...list])
          }
      }
      
    }
    function rowRenderer({
      id, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
    }) {
      return (
        <div key={id} style={style}>
         
        
        
          {/* {marchandizer[index].label} */}
        </div>
      );
    }
    const headerRenderer = ({
      columnData,
      dataKey,
      disableSort,
      label,
      sortBy,
      sortDirection
    }) => (
<div className='filter' onClick={()=>(dataKey!='path') &&(dataKey!='Action')&&filterData(dataKey)}> 
    {(dataKey!='path') &&(dataKey!='Action')&&filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} onClick={()=>filterData(dataKey)}></i>)}
      {(dataKey==='path') ||(dataKey==='Action')? label:dataKey}
     </div>    );
    const headerRowRenderer = ({
      className,
      columns,
      style
    }) => (
      <div
      
        className={className+'  text-light rounded h-40 '}
        role='row'
        style={{...style,backgroundColor:'#4F6973'}}
      >
        {columns} 
      </div>
    )
    return (
     
        <div>
            <MetaTags>
                <title>Display list</title>
            </MetaTags>
            <div className="container-fluid">

                <Breadcrumbs title="Display" breadcrumbItem="Display List" />
                <Card>
                    <CardBody>

                        <Row>
                        {/* <MButton style={{color:'crimson'}}   aria-describedby='chart' onClick={exportPresentation} className='mb-2' ><CoPresentIcon/>&nbsp; Preview Presentation</MButton> */}

                            <Col>
                                <CardTitle>Display List</CardTitle>
                            </Col>
                            <Col>
                                {/* <CreateDisplay
                                    getAsyncDisplayList={getAsyncDisplayList}
                                /> */}

                            </Col>
                        </Row>
                        <TextField placeholder='search...YYYY/MM/DD' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

                        {displayList?.length>0 &&<div className="table-rep-plugin" style={{ marginTop: "1rem" }}>
                            {/* <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                            >
                                <Table
                                    id='displayTable'
                                    className="table table-striped table-bordered"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Store</Th>
                                            <Th>Merchandiser</Th>
                                            <Th>Type</Th>
                                            <Th>Date</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {displayList?.map((display, index) => (
                                            <Tr key={index}>
                                                <Td>{display?.store?.name}</Td>
                                                <Td>{display?.user?.first_name + ' ' + display.user.last_name}</Td>
                                                <Td>{display?.displayType?.name}</Td>
                                                <Td>{new Date(display.createdAt).toUTCString().slice(0, 22)}</Td>
                                                <Td>
                                                    {display && <DisplayDetail
                                                        display={display}
                                                    />}
                                                    {new Date(display?.createdAt).toUTCString().slice(0, 16) === new Date().toUTCString().slice(0, 16) &&
                                                        <>
                                                            <UpdateDetail
                                                                display={display}
                                                                getAsyncDisplayList={getAsyncDisplayList}
                                                            />
                                                            <i
                                                                className="bx bx-trash"
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '1.2rem' }}
                                                                onClick={() => { deleteDisplay(display.id, index) }}
                                                            />
                                                        </>
                                                    }
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                
                                </Table>
                                
                            </div> */}
<Box sx={{ height: 700, width: '100%' }}>
<AutoSizer >
      {({height, width}) => (
        <>
        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={width}
        headerRowRenderer={headerRowRenderer}
        height={height}
        headerHeight={40}
        rowHeight={60}
        rowCount={displayList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => displayList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          <Column label="Store" dataKey="store"   width={1000} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6>{cellData.name}</h6>}/>
          <Column label="User" dataKey="user"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData.first_name+" "+cellData.last_name}</h6>}/>
          <Column label="Type" dataKey="Type"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{rowData.store.type}</h6>}/>
          <Column label="Date" dataKey="date"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  <h6>{new Date(rowData.createdAt).toUTCString().slice(0, 22)}</h6>}/>

          <Column label="Actions" headerRenderer={headerRenderer}    width={width}  dataKey="Action"    cellRenderer={({cellData,rowData}) =>  
          
          <div>
             {rowData && <DisplayDetail
                                                        display={rowData}
                                                    />}
                                                    {new Date(rowData?.createdAt).toUTCString().slice(0, 16) ===new Date().toUTCString().slice(0, 16)&&
                                                        <>
                                                            <UpdateDetail
                                                                display={rowData}

                                                            />
                                                            <i
                                                                className="bx bx-trash"
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '1.5rem' }}
                                                                onClick={() => { deleteDisplay(rowData.id, rowData.id) }}
                                                            />
                                                        </>
                                                    }                                                        
            </div>
          }/> 

          
       </Table>
 
             </>
      )} 
     
     
    </AutoSizer>
   
    </Box>   
    <TablePagination
                  component="div"
                  //style={{width:1100,marginTop:10}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: displayList.length }]}
                  colSpan={3}
                  count={displayList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  //ActionsComponent={TablePaginationActions}
                />
                        </div>}
                    </CardBody>
                </Card>
            </div>
            
            </div>
    )
}
export default memo(dataTableDisplays)