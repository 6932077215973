import React, { useEffect, useState } from 'react'
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import StoreDetail from './StoreDetail';
import TablePaginationActions from 'components/paginationMui';
import { Col, Row } from 'reactstrap';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import { TableVirtuoso } from 'react-virtuoso';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { AvField } from 'availity-reactstrap-validation';
import { AutoSizer,Column,Table } from 'react-virtualized-reactv17';
import { Checkbox } from '@mui/material';

const StoresTable = (props) => {

    const { data, i, chosenStores, setChosenStores, setCol } = props
    const [tableSelected, setTableSelected] = useState(false)
    const [selectedStores, setSelectedStores] = useState([])
    const [stores, setStores] = useState([])

    useEffect(() => {
        // $('#mydatatable' + i).DataTable()
        if(data?.length>0){setRowsPerPage(data?.length)
        setStores([...data])}
    }, [data])

    const handleCheck = () => {
        if (!tableSelected) {
            setSelectedStores(stores)
            stores.forEach((store) => {
                if (chosenStores.findIndex((element) => element.id === store.id) < 0) {
                    setChosenStores(chosenStores => [...chosenStores, store])
                }
            })
            setTableSelected(!tableSelected)
        } else {
            stores.forEach((store) => {
                setChosenStores(chosenStores.slice(chosenStores.findIndex((element) => element.id === store.id), 1))
            })
            setSelectedStores([])
            setTableSelected(!tableSelected)
        }
    }

    const checkStore = (store) => {
        //console.log('store_____________________________',store)
        if (selectedStores.find(el => el.id === store.id)) {
            setSelectedStores(selectedStores.filter(el => el.id !== store.id))
            setChosenStores(chosenStores.filter(el => el.id !== store.id))
        } else {
            setSelectedStores(selectedStores => [...selectedStores, store])
            setChosenStores(chosenStores => [...chosenStores, store])
        }
    }

    useEffect(() => {
        if (chosenStores.length === 0) {
            setSelectedStores([])
            setCol(0)
        }

        //console.log(chosenStores)
    }, [chosenStores])

    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    

    const URL = process.env.NODE_ENV === "development"
          ? "http://localhost:3303"
          : "https://www.devtest21-api.g2m-tn.com";

          const [storeGrp, setStoreGrp] = useState([]);

          const [checkedAll, setCheckedAll] = React.useState(false);

          const handleSearchStores = (search) => {
            //setCancelB(false)
            //console.log("search_______________________",data.length,stores.length)
            if(search){
            const list=data.filter((element)=>{
              
                
                return (
                  (element?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
                  || (element?.address?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.governorate?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.email?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
                  || (element?.phone_number?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        
        )
              
            })
            setStores(stores=>[...list])
            setRowsPerPage(list?.length )
          }else{
            setStores(stores=>[...data])
            setRowsPerPage(data?.length )

          }
          };
          const headerRenderer = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => (
            <div className='filter' onClick={()=>filterData(dataKey)}> {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} onClick={()=>filterData(dataKey)}></i>)} {dataKey} </div>
          );
          const headerRowRenderer = ({
            className,
            columns,
            style
          }) => (
            <div
              className={className+' bg-light '}
              role='row'
              style={style}
            >
              {columns} 
            </div>
          )
          function filterData(params) {
            //console.log('list_____________________',params)
            if(!filter){
              setFilter(filter=> true)
            }
            if(desc){
              const list=stores.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
              setDesc(desc=> !desc)
              setStores(stores => [...list])
            }else{
              const list=stores.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
              setDesc(desc=> !desc)
              setStores(stores => [...list])
            }
            
          }
          const headerRendererCheckbox = ({
            columnData,
            dataKey,
            disableSort,
            label,
            sortBy,
            sortDirection
          }) => {
            //console.log("r_____________________",columnData)
            return      (
            <Checkbox
            
            //color="secondary"
            //style={{color:'white'}}
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={tableSelected}
            onChange={()=> handleCheck() }
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />    )};

          function onSelectAllClick(params) {
            //console.log("onSelectAllClick______________________",params)
           if(params==="All"){
            if(checkedAll){
        
                let list= stores.map((elem)=>{
                    elem.selected=false
                    return elem
            
                })
                //console.log("check______________________",list[0])
                
              stores.forEach((store) => {
                setChosenStores(chosenStores.slice(chosenStores.findIndex((element) => element.id === store.id), 1))
            })
                //setSelectedStores(selectedStores=>[])
                setStores(stores=>[...list])
            }else{
                let list= stores.map((elem)=>{
                    elem.selected=true
                    return elem
            
                })
                //console.log("check______________________",list[0])
      
                //setSelectedStores(selectedStores=>[...list])
                stores.forEach((store) => {
                  if (chosenStores.findIndex((element) => element.id === store.id) < 0) {
                      setChosenStores(chosenStores => [...chosenStores, store])
                  }
              })
                setStores(stores=>[...list])
            }
            setCheckedAll(checkedAll=>!checkedAll)
        
           }else{
            let listSelected=[...chosenStores]
            let list= stores.map((elem)=>{
                if(elem.id===params){
                    if(elem?.selected){
                        elem.selected=false
                        //listSelected=listSelected.filter((el)=>el.id!==elem.id)
                        setChosenStores(chosenStores.filter(el => el.id !== elem.id))
      
                    }else{
                        elem.selected=true
                        //listSelected.push(elem)
                        setChosenStores(chosenStores => [...chosenStores, elem])
      
                    }
                }
                return elem
        
            })
            
            //setSelectedStores(selectedStores=>[...listSelected])
        
            setStores(stores=>[...list])
           }
        }
          const [desc, setDesc] = useState(false)
          const [filter, setFilter] = useState(false)
        
          const sort_by = (field, reverse, primer) => {
        
            const key = primer ?
              function(x) {
                return primer(x[field])
              } :
              function(x) {
                return x[field]
              };
          
            reverse = !reverse ? 1 : -1;
          
            return function(a, b) {
              return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
            }
          }
    return (
        <div className="accordion-body">
          <input
                    className="mb-1"
                   size={25}
                    placeholder="search"
                    type="text"
                    onChange={(e) => { handleSearchStores(e.target.value) }}
                />
            {stores.length>0 &&<div className="table-rep-plugin" style={{ marginTop: "0rem" }}>
                <div
                    //className="table-responsive mb-0"
                    data-pattern="priority-columns"
                    style={{height:500,}}
                >
                    {/* <Table
                        id={"mydatatable" + i}
                        className="table table-striped table-bordered"
                    >
                        <Thead>
                            <Tr>
                                <Th className="text-center">
                                    <input type="checkbox"
                                        checked={tableSelected}
                                        onChange={() => { handleCheck() }}
                                    />
                                </Th>
                                <Th>Name</Th>
                                <Th>Address</Th>
                                <Th>Governorate</Th>
                                <Th>Actons</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stores?.map((store, index) => (
                                <Tr key={index}>
                                    <Td className="text-center">
                                        <input type="checkbox"
                                            checked={selectedStores.find(element => element.id === store.id)}
                                            onChange={() => { checkStore(store) }}
                                        />
                                    </Td>
                                    <Td> {store.name} </Td>
                                    <Td> {store.address} </Td>
                                    <Td> {store.governorate} </Td>
                                    <Td>
                                        <StoreDetail
                                            storeId={store.id}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table> */}
      <AutoSizer>

      {({height, width}) => (
        <>
        
        {/* <TextField placeholder='search...' style={{alignSelf:'flex-end',width:200,}} size='small' onChange={(e)=>handleSearchStores(e.target.value)}/> */}

        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={width}
        headerRowRenderer={headerRowRenderer}
        height={height-70}
        headerHeight={30}
        rowHeight={50}
        rowCount={stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => stores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          {/* <Column label="Image" dataKey="path" width={500}  cellRenderer={({cellData}) => <img src={URL+cellData} style={{ width: "4rem", height: "4rem", }} />}/>  */}
          <Column label="select" dataKey="selected"   width={500} headerRenderer={headerRendererCheckbox}  cellRenderer={({cellData,rowData}) =>  
          <Checkbox
              //color="secondary"
              //style={{color:'white'}}
              //indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={selectedStores.find(element => element.id === rowData.id)?true:false}
              onChange={() => { checkStore(rowData) }}

            />
        
          }
            />
            
                                            
          <Column label="name" dataKey="name"   width={width} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Address" dataKey="address"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData}</h6>}/>
          <Column label="Governorate" dataKey="governorate"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Email" dataKey="email"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Phone_number" dataKey="phone_number"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>

          <Column label="Actions"    width={width}  dataKey="Action"    cellRenderer={({cellData,rowData}) =>  
          
          <StoreDetail
                                            storeId={rowData.id}
                                        />
          }/> 

          
       </Table>
              
             </>
      )} 
     
     
    </AutoSizer>   

                


                </div>
                <TablePagination
                  component="div"
                  //style={{width:1100}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: stores.length }]}
                  colSpan={3}
                  count={stores.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  //ActionsComponent={TablePaginationActions}
                />
            </div>}
        </div>
    )
}

export default StoresTable
