import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import avatarMale from "../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../assets/images/users/female-profile-pic.jpg"
import StarIcon from '@mui/icons-material/Star';
import "./welcomeCom.scss"
import image from 'assets/images/layouts/image001.jpg'
//import { isNull, isUndefined } from "lodash";
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
const WelcomeComp = (props) => {

  const { connectedUser, visits } = props
  const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
  const [nbPlannedVisits, setNbPlannedVisits] = useState(0)
  const listStore = []


  const getData = () => {
    visits?.map((elem) => {
      let planned = 0
      let realised = 0
      //let result = display?.find(({ visitId }) => visitId === elem?.id);
      let exist = false
      //console.log(elem, listStore)
      if (listStore.length === 0) {
        if (elem?.displays?.length) {
          realised++
        }
        if (elem?.planned) {
          planned++
        }

        listStore.push({ name: elem?.store?.name, planned: planned, realised: realised, path: elem?.store?.path })

      } else {
        for (let index = 0; index < listStore.length; index++) {
          if (listStore[index].name === elem?.store?.name) {

            if (elem?.displays?.length) {
              realised++
            }
            if (elem?.planned) {
              planned++
            }
            //listStore[index].
            listStore[index].planned += planned
            listStore[index].realised += realised
            exist = true
          }
        }
        if (exist === false) {
          if (elem?.displays?.length) {
            realised++
          }
          if (elem?.planned) {
            planned++
          }
          listStore.push({ name: elem?.store?.name, planned: planned, realised: realised, path: elem?.store?.path })
        }

      }

    })

    //---------------------------
    
    setStoreTop(listStore.length > 0 ?
      listStore?.reduce((a, b) => {
        if ((a?.planned >= b?.planned) && (a?.realised >= b?.realised)) {
          return a
        } else
          return b
      }) : {})
  }

 
  const [storeTop, setStoreTop] = useState()

  useEffect(()=>{
    getData()
  },[visits])
  //visits.filter((a, b) => Math.max(a?.order))



  //const storeTop = ''



  // console.log(visits.reduce((a, b) => {
  //   if (a?.order > b?.order) {

  //     return a
  //   }
  // }))


  const [avatar, setAvatar] = useState(null)
  let realisedVisits = 0
  let plannedVisits = 0
  //console.log(listStore)

  // useEffect(() => {
  //   console.log(visits)
  //   console.log(listStore)


  // }, [visits])

  useEffect(() => {


    // visits.forEach(visit => {

    //   if ((visit?.store?.name === storeTop?.store?.name) && (storeTop)) {
    //     if (visit?.planned) {
    //       plannedVisits = plannedVisits + 1
    //     }
    //     // if (visit?.surveyResponses?.length > 0) {
    //     //   realisedVisits = realisedVisits + 1
    //     // }
    //     if (!isNull(visit?.end)) {
    //       realisedVisits = realisedVisits + 1
    //     }
    //   }
    // });
    // setNbRealisedVisits(realisedVisits);
    // setNbPlannedVisits(plannedVisits)


    if (connectedUser?.gender === 'M' && !connectedUser?.profile_picture) {
      setAvatar(avatarMale);
    } else if (connectedUser?.gender === 'F' && !connectedUser?.profile_picture) {
      setAvatar(avatarFemale);
    }
    else {
      setAvatar(connectedUser?.profile_picture)
    }
  }, [connectedUser])



  return (
    <React.Fragment>

      <Card className="overflow-hidden" style={{ width: "100%" }}>
        <div className="bg-primary bg-soft">
          <Row >
            <div style={{ marginBottom: storeTop?.path ? "180px" : "190px" }}>
            
              <img
              key={2}
                src={URL+storeTop?.path}
                className="d-block img-fluid image2"
                onError={(e) => { e.target.src=image }}
                style={{ height: "12rem", width: "100%", }}
              />
              {/* {storeTop?.path && <StarIcon className="image1" style={{ fontSize: "50px", color: "#FFFF00", }} />} */}

            </div>


          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>

            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{connectedUser?.first_name + " " + connectedUser?.last_name}</h5>
              <p className="text-muted mb-0 text-truncate">{connectedUser?.role?.name}</p>
            </Col>
            <Col sm="8">
              <div className="pt-4">
                <Row style={{ marginBottom: "1rem" }}>
                  <h5 className="font-size-15">{"Information du " + new Date().getDate() + "-" + parseInt((new Date().getMonth()) + 1) + "-" + new Date().getFullYear()}</h5>



                </Row>
                {storeTop?.path ? <Row>
                  <Col xs="4">
                    <h5 className="font-size-15">{storeTop?.planned}</h5>
                    <p className="text-muted mb-0">Visites plannifiées</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">{storeTop?.realised}</h5>
                    <p className="text-muted mb-0">Visites réalisées</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">Best store</h5>
                    <p className="text-muted mb-0">{storeTop?.name}</p>

                  </Col>

                </Row> :
                  <Col xs="12" style={{ marginLeft: 70 }} >
                    <br />

                    <p className="text-muted mb-0 font-size-15">No visits today...</p>


                  </Col>}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
