import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Modal } from "reactstrap"
import DisplayBody from './DisplayBody'
import { getDisplayListHistory } from 'store/display/services'
import LoadingComponent from 'shared/LoadingComponent'

const DisplayDetail = (props) => {

    const { display } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)

    const [displays, setDisplays] = useState()

    useEffect(async ()=>{
        if(display?.id&& modal_fullscreen){
           await getDisplayListHistory(display?.id).then((result)=>{
                setDisplays({...display,...result[0]})
                //console.log("data_______________________",{...display,...result[0]})
            })
            
            
        }
    },[display,modal_fullscreen])
    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        //document.body.classList.add("no_padding")
    }

    return (
        <>
            <Link
                to="#"
                style={{ fontSize: "1.5rem", marginRight: "1rem" }}
                onClick={() => { tog_fullscreen() }}
            >
                <i className="mdi mdi-eye-minus-outline" id="detailtooltip" />
            </Link>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        {"Display detail"}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_fullscreen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row justify-content-center">
                        {displays&& modal_fullscreen ?<div className="col-xl-9">
                            <div className="text-center">
                                <div className="mb-4">
                                    <Link
                                        to="#"
                                        className="badge bg-light font-size-12"
                                    >
                                        <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                        {displays.displayType.name}
                                    </Link>
                                </div>
                                <h4>{"Merchandiser : " + displays.user.first_name + " " + displays.user.last_name}</h4>
                                <h4>{"Store : " + displays.store.name + ", " + displays.store.address + " - " + displays.store.governorate}</h4>
                                <p className="text-muted mb-4">
                                    <i className="mdi mdi-calendar me-1"></i> {new Date(displays.createdAt).toUTCString().slice(0, 22)}
                                </p>
                            </div>
                            {(displays.category?.name || displays.brand?.name) &&
                                <>
                                    <hr />
                                    <div className="text-center">
                                        <Row>
                                            {displays.category?.name &&
                                                <Col>
                                                    <div>
                                                        <p className="text-muted mb-2">Category</p>
                                                        <h5 className="font-size-15">{displays.category?.name}</h5>
                                                    </div>
                                                </Col>
                                            }
                                            {displays.brand?.name &&
                                                <Col>
                                                    <div className="mt-4 mt-sm-0">
                                                        <p className="text-muted mb-2">Brand</p>
                                                        <h5 className="font-size-15">{displays.brand?.name}</h5>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                    <hr />
                                </>
                            }
                            <DisplayBody
                                sections={displays.displayType.displaySections}
                                displayData={displays.displayData}
                                customValues={displays.displayCustomFieldValues}
                            />
                        </div>:<LoadingComponent/>}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DisplayDetail
