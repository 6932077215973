import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import ReactEcharts from "echarts-for-react"
import { isNull } from "lodash"

const SocialSource = (props) => {

  const { visits } = props
  const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
  const [nbPlannedVisits, setNbPlannedVisits] = useState(0)
  let realisedVisits = 0
  let plannedVisits = 0

  useEffect(() => {
    visits.forEach(visit => {
      plannedVisits++
      // if (visit?.planned) {
      //   plannedVisits = plannedVisits + 1
      // }
      // if (visit?.surveyResponses?.length > 0) {
      //   realisedVisits = realisedVisits + 1
      // }
      // if (!isNull(visit?.end)) {
      //   realisedVisits = realisedVisits + 1
      // }
      if(visit?.displays?.length>0){
        realisedVisits++
      }
    });
    setNbRealisedVisits(realisedVisits);
    setNbPlannedVisits(plannedVisits)
  }, [visits])

  const getOption = () => {
    //console.log(nbRealisedVisits, nbPlannedVisits)
    return {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      series: [
        {
          radius: '80%',
          name: "Business indicator",
          type: "gauge",
          detail: {
            formatter: "{value}%",
            textStyle: {
              fontSize: 20,

            },
          },
          axisLine: {
            lineStyle: {
              color: [
                [nbRealisedVisits / nbPlannedVisits, "#0d6efd"],
                [1, "#dc3545"],
              ],
              width: 20,
            },
          },
          data: [{ value: nbRealisedVisits == 0 && nbRealisedVisits == 0 ? 0 : parseFloat((nbRealisedVisits / nbPlannedVisits) * 100).toFixed(2) }],


        },
      ],
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Taux de réalisation journalier</CardTitle>
        <div className="text-center">
          <div className="avatar-sm mx-auto mb-4" style={{ marginTop: "3rem" }}>
            <i className="bx bxs-pie-chart-alt text-primary display-4"></i>
          </div>
          <p className="font-16 text-muted mb-2"></p>
          <h5>Performances</h5>
        </div>
        <Row className="mt-4">
          <ReactEcharts option={getOption()} />
        </Row>
        <div className="text-center">
          <h5 className="mb-4">
            Visites réalisées - Visites planifiées
          </h5>
          <h5>
            {nbRealisedVisits} - {nbPlannedVisits}
          </h5>
        </div>
      </CardBody>
    </Card>
  )
}

export default SocialSource