import React, { useCallback, useEffect, useState } from 'react'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { MetaTags } from 'react-meta-tags';
import { Row, Card, CardBody,Col } from "reactstrap"
import MButton from '@mui/material/Button';
import { Box, } from '@mui/material';
import { getProductsAsync } from "store/product/actions";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import { getStocksAsync } from 'store/stock/actions'
import { getStoresAsync } from "store/pos/actions";
import dayjs from 'dayjs';
import DataTableStores from './components/DataStructure';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import DataStructure from './components/DataStructure';
import { getStoreOutput } from 'store/pos/services';
function OutputStore() {
  const connectedUser = useSelector(state => state.User?.user)
  const dispatch = useDispatch();
  const [loader, setLoder] = useState(false)
  const [products, setProducts] = useState([])
  const [stores, setStores] = useState([])
  const [stocks, setStocks] = useState([])
  const [productData, setProductData] = useState([])
  const [storeData, setStoreData] = useState([])

  const ListProduct=[]
  const ListStore=[]

    //stocks
    const getStoreOutputs = async (dateStart,dateEnd) => {
      ListProduct.length=0;
      ListStore.length=0
      setProducts([])
      setStores([])

      await  getStoreOutput(dayjs(dateStart).format('YYYY-MM-DD'),dayjs(dateEnd).format('YYYY-MM-DD')).then((data)=>{

        setProducts([...data[0].ListProduct])
        setStores([...data[0].listStore])

      })
      
    }
    

  return (
    <div className="page-content">
      <Card>
                        <CardBody>
                            <Row>
                                <MButton css={{backgroundColor:'blue'}} color='primary' aria-describedby='chart' onClick={()=>{window.location.reload()}}><LocalMallOutlinedIcon/> &nbsp; Output Stores</MButton>
                            </Row>
                        </CardBody>
                    </Card>
     
        {(
            <div><MetaTags>
                    <title>Output Stores</title>
                </MetaTags>
                <div className="container-fluid">
                    
                   

   { <Box sx={{ height: 960, width: '100%' ,marginBottom:20}}>
      
  
                      <DataStructure dataProduct={products} dataStore={stores}  getStoreOutputs={getStoreOutputs}/>
                        
    </Box>}

                </div>
              </div>
                
                
                
                )}
    </div>
  )
}

export default OutputStore