import React, { useCallback, useEffect, useState,useRef, useMemo } from 'react'

import { Row, Card, CardBody,Col,Button, Table,  } from "reactstrap"
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import { Autocomplete, Box, Chip, FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Popover, Select, Stack, styled, TableFooter, TextField, Typography } from '@mui/material';
import { DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport ,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbarQuickFilter,
  GridRow,
  } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
import pptxgen from "pptxgenjs";
import { get } from 'lodash';
// import { AutoSizer ,Column,Grid,Table} from 'react-virtualized-reactv17';
import { Link } from 'react-router-dom';
import LoadingComponent from 'shared/LoadingComponent';
import TablePaginationActions from 'components/paginationMui';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import $ from 'jquery';
import { isEmpty } from 'lodash';

// import {
//   Grid,
//   Table,
//   TableHeaderRow,
//   TableFixedColumns,
//   TableSelection,
// } from '@devexpress/dx-react-grid-material-ui';import {
//   SelectionState,
//   IntegratedSelection,
// } from '@devexpress/dx-react-grid';

import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import {  Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import BaseTable, { Column } from 'react-base-table'
import 'react-base-table/styles.css'
import logoDark from "../../../assets/images/product.png"

const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";


function DataTableStoreV3({products,stores,getStoreOutputs}) {
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(()=>{
    //console.log("data___________________________",products.length,stores.length)
    //setLoader(true)
    if(products?.length>0){
    //setRowsPerPage(products?.length)
    setData([...products])
    setColumns([...stores])
    
  }
   
    //setLoader(false)
  },[products])
  //////////////////////////////////////////////Table///////////////////////////
  
  //Export
  const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
      const row = {};
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value;
      });
      return row;
    });

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2);
  };

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};





//filter Date start end

const [cancelB, setCancelB] = useState(true);



  const cancelFilter = () => {
    //window.location.reload()
    setData(data=>[...products])
    setCancelB(cancelB=>!cancelB)
    setBrand([])
    setFamily([])
    setCategory([])
    setStoreGrp([])

  };

  const handleSearch = async () => {
    // setColumns(columns=> [])
    // setData(data=> [])
   setLoader(loader=>!loader)
   await getStoreOutputs(dateStart,dateEnd)
   setLoader(loader=>!loader)
   //setFiltred(true)
   //setCancelB(false)
   
   
 };
  const handleChangeStart = (newValue) => {
    
    setDateStart(newValue);
    setCancelB(false)
  };
  const handleChangeEnd = (newValue) => {
    
    setDateEnd(newValue);
    setCancelB(false)
  };

  //Filter family category type
  const [family, setFamily] = useState([]);
  const listFamily=[]
  const [category, setCategory] = useState([]);
  const listCategory=[]
  const [brand, setBrand] = useState([]);
  const listBrand=[]
  const [storeGrp, setStoreGrp] = useState([]);
  const listStoreGrp=[]
  const listGrp=[]

  const getListFilter=()=>{
    products.map((el)=>{
     listFamily.push(el.family)
     listCategory.push(el.category)
     listBrand.push(el.brand)
     //console.log(el.storeGrp)
     listStoreGrp.push(el.storeGrp)
     el.storeGrp.map((s)=>{
       listGrp.push(s)
     })
     
   })
  }

  getListFilter()

  const handleFamily = (event,value) => {
   setFamily(value);
   setCancelB(false)
 };

 const handleCategory = (event,value) => {

   
   setCategory(value);
   setCancelB(false)
 };

 const handleBrand = (event,value) => {
   //console.log(value)
   setBrand(value);
   setCancelB(false)
 };

 const handleStoreGrp = (event,value) => {
   setStoreGrp(value);
   //console.log(value)
   setCancelB(false)
 };

 const filter = () => {
   setCancelB(false)
    let newList=[]
    if(products.length>0){
     //filter 1 : family

       if(family.length>0 && !category.length>0 && !brand.length>0 && !storeGrp.length>0){
         //console.log(family)
         products.filter((el) => {
           family.map((f) => {

             if(el.family===f){
              newList.push(el) 
             }

           })
          })
          setData(newList)
        }

     //filter 2 : family + category
     if(family.length>0 && category.length>0 && !brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         family.map((f) => {

           category.map((c) => {

             if(el.family===f && el.category===c){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }
      //filter 3 : family + category +brand
     if(family.length>0 && category.length>0 && brand.length>0){
       //console.log(family)
        products.filter((el) => {
         family.map((f) => {

           category.map((c) => {

             brand.map((b) => {

               if(el.family===f && el.category===c && el.brand===b){
                newList.push(el) 
               }
   
             })
 
           })

         })
        })
        setData(newList)
      }

     //filter 4 : category
     if(!family.length>0 && category.length>0 && !brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         category.map((c) => {
           if(el.category===c){
             newList.push(el) 
            }
         })
        })
        setData(newList)
      }
      
      //filter 5 : category + brand
     if(!family.length>0 && category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {

           category.map((c) => {

             if(el.brand===b && el.category===c){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }

      //filter 6 : brand
     if(!family.length>0 && !category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {
           if(el.brand===b){
             newList.push(el) 
            }
         })
        })
        setData(newList)
      }

       //filter 7 : brand + family
     if(family.length>0 && !category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {

           family.map((f) => {

             if(el.brand===b && el.family===f){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }

      //filter 8 :storeGrp 
      if(!family.length>0 && !category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
         
          let exist=false
          storeGrp.map((s) => {
            el.storeGrp?.map(grp=>{
             if(grp===s){
               exist=true
             }
            })
            
            if(exist)
            newList.push(el) 
          })
         })
         setData(newList)
       }

      //filter 9 :storeGrp + brand
      if(!family.length>0 && !category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            storeGrp.map((s) => {
              el.storeGrp?.map(grp=>{
                if(grp===s && el.brand===b){
                  exist=true 
                  
                }
                
              })
              if(exist)
              newList.push(el) 
              
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 10 :storeGrp + brand + category
      if(!family.length>0 && category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            category.map(c=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s && el.brand===b && el.category===c){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 11 :storeGrp + brand + category + family
      if(family.length>0 && category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            category.map(c=>{
              family.map(f=>{
                storeGrp.map((s) => {
                  el.storeGrp?.map(grp=>{
                    if(grp===s && el.brand===b && el.category===c && el.family===f){
                      exist=true 
                      
                    }
                    
                  })
                  if(exist)
                  newList.push(el) 
                  
                })
              })
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 12 :storeGrp + category
      if(!family.length>0 && category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            category.map(c=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s &&  el.category===c){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
         
          
         })
         setData(newList)
      }

      //filter 13 :storeGrp + family
      if(family.length>0 && !category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            family.map(f=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s &&  el.family===f){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
         
          
         })
         setData(newList)
      }

      //filter 14 :storeGrp + category + family
      if(family.length>0 && category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            category.map(c=>{
              family.map(f=>{
                storeGrp.map((s) => {
                  el.storeGrp?.map(grp=>{
                    if(grp===s &&  el.category===c && el.family===f){
                      exist=true 
                      
                    }
                    
                  })
                  if(exist)
                  newList.push(el) 
                  
                })
              })
            })
            
         
          
         })
         setData(newList)
      }

    }
 };


/////////////////////////////////////////////////////////////////////////////////////////////
///new table///////////////////
const [desc, setDesc] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearchProduct = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=products.filter((element)=>{
      
        
        return (
          (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.brand?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.category?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) 
          || (element?.family?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.barcode?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ))
          
)
      
    })
    setData(data=>[...list])
  }else{
    setData(data=>[...products])
  }
  };

  const handleSearchStore = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=stores.filter((element)=>{
      
        
        return (
          (element.field?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
            // || (element.headerName.toString().toLowerCase().includes("label"))
            // || (element.headerName.toString().toLowerCase().includes("barcode"))
            // || (element.headerName.toString().toLowerCase().includes("brand"))
            // || (element.headerName.toString().toLowerCase().includes("category"))
            // || (element.headerName.toString().toLowerCase().includes("family"))
            // || (element.headerName.toString().toLowerCase().includes("storegrp"))
            //|| (element.headerName.toString().toLowerCase().includes("id"))

          // || (element?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // || (element?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) 
          // || (element?.barcode?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // )
)
      
    })
    setColumns(columns=>[...list])
  }else{
    setColumns(columns=>[...stores])
  }
  };
  
  function filterData(params) {
    
    // if(!filter){
    //   setFilter(filter=> true)
    // }
    if (params==="typology"){
        if(desc){
          setDesc(desc=> !desc)
          const list=data?.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
          setData(data=> [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data?.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
          setData(data=> [...list])
        }
      }else if (params==="Pourcentage" || params==="taux"){
        if(desc){
          setDesc(desc=> !desc)

          const list=data?.sort((a,b)=> (a.pourcentage>b.pourcentage)-(a.pourcentage<b.pourcentage))
          setData(data=> [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data?.sort((a,b)=> (a.pourcentage<b.pourcentage)-(a.pourcentage>b.pourcentage))
          setData(data=> [...list])
        }
      }else {
        if(desc){
          setDesc(desc=> !desc)

          const list=data?.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
          setData(data => [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data?.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
          setData(data => [...list])
        }
       
      } 
    
    
  }





//// table components////////******************* */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columnsVirtuso = [

    {
      width: 90,
      label: 'Image',
      dataKey: 'image',
    },
    {
      width: 120,
      label: 'Barcode',
      dataKey: 'barcode',
    },
    {
      width: 150,
      label: 'Label',
      dataKey: 'label',
    },
    
    {
      width: 120,
      label: 'Brand',
      dataKey: 'brand',
    },
    {
      width: 120,
      label: 'Category',
      dataKey: 'category',
    },
    {
      width: 120,
      label: 'Typology',
      dataKey: 'typology',
    },
    {
      width: 120,
      label: 'Family',
      dataKey: 'family',
    },
    {
      width: 120,
      label: 'StoreGrp',
      dataKey: 'storeGrp',
    },
    // {
    //   width: 80,
    //   label: 'Taux',
    //   dataKey: 'taux',
    // },
    // {
    //   width: 80,
    //   label: 'Action',
    //   dataKey: 'Action',
    //   //numeric: true,
    // },
   
    
  ];
  function showCell(params,store){
    // console.log('params_______',params?.rowData)
    // console.log('store_______',store)
    let visit=''
    let exisit=false

    
    if(params?.rowData?.store?.length>0){
      params?.rowData?.store.map((elems,index)=>{

        if(elems.name===store.name){
          //console.log('found_______',)
          exisit=true
          visit=elems?.lastVisit
          // store?.lastProduct?.map(l=>{
          //   if(l===params?.rowData.id){
          //     visit=store?.lastVisit
              
          //   }
          // })
       
        }
        })
    }

    if(!exisit){
      return <Button style={{width:'100%',fontSize:'13px',height:'80%'}} color='secondary' >HS\...</Button>
    }else{
      if(isEmpty(visit))
      return <Button style={{width:'100%',fontSize:'13px',height:'80%'}} color='danger' >AB\... </Button>
      else
      return <Button style={{width:'100%',fontSize:'13px',height:'80%'}} color='success' >PR\ {visit}</Button>
    }
 }
  




  const headercolumns = [
    
    {
      width: 90,
      label: 'Image',
      dataKey: 'image',
      displayName: 'image',
    },
    {
      width: 120,
      label: 'Barcode',
      displayName: 'barcode',
    },
    {
      width: 150,
      label: 'Label',
      displayName: 'label',
    },
    
    {
      width: 120,
      label: 'Brand',
      displayName: 'brand',
    },
    {
      width: 120,
      label: 'Category',
      displayName: 'category',
    },
    {
      width: 120,
      label: 'Typology',
      displayName: 'typology',
    },
    {
      width: 120,
      label: 'Family',
      displayName: 'family',
    },
    {
      width: 120,
      label: 'StoreGrp',
      displayName: 'storeGrp',
    },
  ];
   const renderHeaderColumns = columnIndex => {
    const addone = headercolumns[columnIndex];
    return <div key={addone.name}>{addone.displayName}</div>;
  };
  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const setbackground =
      rowIndex % 2 === 1 ? "darkbackground" : "lightbackground";
    const setonerow = columnIndex === 0 ? "columewidth" : "";
    if (rowIndex === 0) {
      return (
        <div
          key={key}
          style={style}
          className={`${setbackground} ${setonerow}`}
        >
          {renderHeaderColumns(columnIndex)}
        </div>
      );
    } else {
      return (
        <div
          key={key}
          style={style}
          className={`${setbackground} ${setonerow}`}
        >
          {data[columnIndex]}
        </div>
      );
    }
  };
  const heightcount = 1000;

  const memoColumns=useMemo(()=>columns.length>0&&columns.map((elem, index) => {
    //console.log("col")
    return (
      
        // <Th key={index}>  {elem.field} </Th>
        <StyledTableCellStores  key={index} style={{minWidth:200}} align="left">{elem.field}</StyledTableCellStores>
    )
}),[columns])


const memoData=useMemo(()=>columns.length>0&&columns.map((elem, index) => {
  //console.log("ddd")
  return (
    
    <Column key={index} width={200} align='left' style={{minWidth:200}} cellRenderer={(rowData) =>  showCell(rowData,elem)} />
   
  )
}),[columns])

function getWidth(params) {
  if(params===0 ){
    return 1500
  }else if(params*200.75>1500){
    return params*200.81
  }else{
    return params*200.81+1300
  }
}
  return (
    <div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='row m-3 d-flex justify-content-evenly'>
      <MButton css={{backgroundColor:'blue'}} disabled={cancelB} color='error' aria-describedby='chart' onClick={cancelFilter} className='mb-2' ><EventBusySharpIcon/>&nbsp; cancel filter</MButton>
      
      <DatePicker
      views={['day']}
    label="From"
    value={dateStart}
    inputFormat="YYYY-MM-DD"
    onChange={handleChangeStart}
    renderInput={(params) => <TextField {...params} />}
    className="col-2 "
  />
        <DatePicker 
        views={['day']}
          label="To"
          value={dateEnd}
          inputFormat="YYYY-MM-DD"
          
          onChange={handleChangeEnd}
          renderInput={(params) => <TextField  {...params} />}
          className="col-2"
        />
         <MIButton className="col-2" variant="contained" onClick={handleSearch} size="large">
         <ContentPasteSearchSharpIcon/> &nbsp;Search
        </MIButton>
      </div>
    </LocalizationProvider>

    <Row className='mb-3' style={{marginLeft:0}}>
      <Col className='ml-5' >
      <FormControl sx={{ m: 1, width: 300 }} size="small">

<Autocomplete
        onChange={handleBrand}
        value={brand}
        multiple
        id="brand"
        options={[...new Set(listBrand)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brand"
            placeholder="Brand"
          />
        )}
      />
</FormControl>
      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleCategory}
        value={category}
        multiple
        id="category"
        options={[...new Set(listCategory)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Category"
            placeholder="Category"
          />
        )}
      />
        
      </FormControl>
      
      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleFamily}
        value={family}
        multiple
        id="family"
        options={[...new Set(listFamily)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Family"
            placeholder="Family"
          />
        )}
      />
        
      </FormControl>

      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleStoreGrp}
        value={storeGrp}
        multiple
        id="storeGrp"
        options={[...new Set(listGrp)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Store Group"
            placeholder="Store Group"
          />
        )}
      />
       
      </FormControl>
      </Col>
      <Col style={{marginTop:'6px'}}>
      <MIButton  className="col-7" sx={{height:'58px'}} variant="outlined" onClick={filter} size="large">
         <FilterAltIcon/> Filter
      </MIButton>
        </Col>
    </Row>

    
{loader && <LoadingComponent/>}
<Row >
  <Col  className='col-10'>
    <TextField placeholder='search products...' style={{padding:1,marginBottom:10,alignSelf:'flex-end',backgroundColor:"transparent",margin:5}} size="small"  onInput={(e)=>handleSearchProduct(e.target.value)}/>
  </Col>
  <Col  >
    <TextField placeholder='search stores...' style={{padding:1,marginBottom:10,alignSelf:'flex-end',backgroundColor:"transparent",margin:5}} size="small"  onInput={(e)=>handleSearchStore(e.target.value)}/>
  </Col>
</Row>

{(data.length>0 &&!loader) && columns.length>0&&<>
  <Paper sx={{overflow: 'hidden',height:650,}}  style={{  width:'100%',overflowY:'hidden', overflowX: 'visible',backgroundColor:'transparent'}}>


{/* ///////////////////table ///////////////////// */}
{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
<div style={{ marginTop: "0rem" }}>
                        <div
                            // className="table table-responsive mb-0"
                            // data-pattern="priority-columns"
                            
                        >
                           
                                <Table>
                                <TableHead  >
                                <TableRow hover>
                                  <StyledTableCell   width={200}  style={{minWidth:100,maxWidth:100}} align="left" >Image</StyledTableCell>
                                  <StyledTableCell   width={200} onClick={()=>filterData('label')} style={{minWidth:200,maxWidth:200}} align="left" >{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Label</StyledTableCell>
                                  <StyledTableCell width={200} onClick={()=>filterData('barcode')} style={{minWidth:200,maxWidth:200}} align="left">{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Barcode</StyledTableCell>
                                  <StyledTableCell width={200} onClick={()=>filterData('brand')} style={{minWidth:150,maxWidth:150}} align="left">{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Brand</StyledTableCell>
                                  <StyledTableCell width={200} onClick={()=>filterData('category')} style={{minWidth:150,maxWidth:150}} align="left">{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Category</StyledTableCell>
                                  <StyledTableCell width={200} onClick={()=>filterData('family')} style={{minWidth:150,maxWidth:150}} align="left">{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Family</StyledTableCell>
                                  <StyledTableCell width={200} onClick={()=>filterData('typology')} style={{minWidth:150,maxWidth:150}} align="left">{desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>}Typology</StyledTableCell>
                                  <StyledTableCell width={200}  style={{minWidth:200,maxWidth:200}} align="left">StoreGrp</StyledTableCell>
                                  {memoColumns&& columns.length>0 &&memoColumns}

                                </TableRow>
                              </TableHead>
                                </Table>
                                <BaseTable data={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} rowHeight={80} style={{minWidth:1500}} width={ getWidth(columns.length) }   height={580} headerHeight={0}>
                                <Column key="image" dataKey="image" align='left' width={100} style={{marginLeft:5,minWidth:100,maxWidth:100}} cellRenderer={({cellData,rowData}) =>  <><img src={URL+rowData?.image} onError={(e) => { e.target.src = logoDark }} style={{ width: "100%" }} /></>}/>
                                <Column key="label" dataKey="label" align='left' width={200} style={{minWidth:200,maxWidth:200}}/>
                                <Column key="barcode" dataKey="barcode" align='left' width={200} style={{minWidth:200,maxWidth:200}}   cellRenderer={({cellData,rowData}) =>  <h6><HorizontalSplitSharpIcon fontSize='small' />{rowData?.barcode}</h6>}/>
                                <Column key="brand" dataKey="brand" align='left' width={150} style={{minWidth:150,maxWidth:150}} />
                                <Column key="category" dataKey="category" align='left' width={150} style={{minWidth:150,maxWidth:150}} />
                                <Column key="family" dataKey="family" align='left' width={150} style={{minWidth:150,maxWidth:150}} />
                                <Column key="typology" dataKey="typology" align='left' width={150} style={{minWidth:150,maxWidth:150}} />
                                <Column key="storeGrp" dataKey="storeGrp" align='left' width={200} style={{minWidth:200,maxWidth:200}} cellRenderer={({cellData,rowData}) =>  cellData?.length>0?cellData?.map((elem,index)=>{
                                    return  <li key={index}>{elem}</li>
                                }):<h6 style={{color:'grey'}}>not available in stock</h6> }/>
                                {memoData}
                              </BaseTable>
                                
                              

                        </div>
                    </div>
                    
</Paper> 

                <TablePagination
                  component="div"
                  //style={{width:1100}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: data.length }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  //ActionsComponent={TablePaginationActions}
                />

</>
}
{loader &&<LoadingComponent/>}


    </div>
  )
}

export default DataTableStoreV3;

//************ table properties************ */
// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    
    backgroundColor: '#587480',
    color: theme.palette.common.white,
    cursor:'pointer'

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    
  },
}));
const StyledTableCellStores = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderWidth:1,
    borderColor:'white',
    backgroundColor: '#3E5159',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    
  },
}));


