import * as React from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Autocomplete, Box, FormControl, InputAdornment, InputLabel, StepButton, TextareaAutosize, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2'

import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { upsertPromotion } from "store/promotion/services";
function PromotionForm(props) {
    const { chosenStores, chosenProducts,setOpen } = props
    const [error, setError] = useState(
        {
            type:false,
            amount:false
        })
    
 


    //filter Date start end
    const [dateStart, setDateStart] = useState(dayjs());
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [cancelB, setCancelB] = useState(true);
    const types=['Communiquer','Non Communiquer']
    const [promotion, setPromotion] = useState(
      {
          start:dayjs().format('YYYY-MM-DD HH:mm:ss'),
          end:dayjs().format('YYYY-MM-DD HH:mm:ss'),
          description:'',
          type:types[0],
          consulted:0
          //amount:0,
      })
    const handleChangeStart = (newValue) => {
    
      setPromotion({...promotion, start: newValue.format('YYYY-MM-DD')})
        setCancelB(false)
      };
      const handleChangeEnd = (newValue) => {
        
        setPromotion({...promotion, end: newValue.format('YYYY-MM-DD')})
        
        setCancelB(false)
      };
      
    const saveRef = async () => {
      // console.log(promotion)
      // console.log(chosenProducts,chosenStores)
        // Swal.fire(
        //     'Promotion!',
        //     'Promotion created successfully.',
        //     'success'
        // )
        let creationPromotion = []
        // chosenProducts.forEach((product) => {
        //   chosenStores.forEach((store) => {
        //     creationPromotion.push({ storeId: store.id, productId: product.id, available: true }) 

        //   });
        // });
        
        await upsertPromotion(promotion,chosenStores,chosenProducts).then(() => {
           //console.log('prom___________________________________',data)
            setOpen(false)
           
            Swal.fire(
                'Promotion!',
                'Promotion created successfully.',
                'success'
            )
        }).then((result) => {
          //window.location.reload();
        }).catch((error) => {
          console.log("err prom__________________________",error)
        })
    }
    // useEffect(() => {
        
    // }, [])
  return (
    <div className="actions clearfix" >
    {
       chosenStores.length > 0 && chosenProducts.length > 0 ?
        <Box sx={{ display: 'flex', flexWrap: 'wrap',width:"50%",marginLeft:50, }} noValidate
        autoComplete="off" component="form" >
    <FormControl fullWidth sx={{ m: 1 }} variant="filled" >
        <Row className="mb-5" style={{marginLeft:'5%'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Col className="col-7 ">
        <DatePicker
      views={['day']}
    label="From"
    value={promotion.start}
    inputFormat="YYYY-MM-DD"
    onChange={handleChangeStart}
    renderInput={(params) => <TextField {...params} />}
    
    
  />
        </Col>
        <Col className="col-5">
        <DatePicker 
        views={['day']}
          label="To"
          value={promotion.end}
          inputFormat="YYYY-MM-DD"
          
          onChange={handleChangeEnd}
          renderInput={(params) => <TextField  {...params} />}
          
        />
        </Col>
        
    </LocalizationProvider>
        </Row>
        <Row className='mb-5'>
        
        <Autocomplete 
        
        style={{width:'100%'}}
        onChange={(e)=>{promotion.type=e.target.innerHTML}}
        
        defaultValue={'Communiquer'}
        //multiple
        
        id="type"
        options={types}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            error={error.type}
            label="Promotion Type"
            placeholder="Promotion Type"
          />
        )}
      />
        </Row>
        
    {/* <Row className='mb-5'>
    
        <FilledInput
        id="filled-number"
        placeholder='Amount'
        type="number"
        onChange={(e)=>{promotion.amount=e.target.value}}
        
        error={error.amount}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
                            startAdornment={<InputAdornment position="start">DT</InputAdornment>}
                        />
        
        </Row> */}
            <Row className='mb-5'>
        <TextareaAutosize
            onChange={(e)=>{promotion.description=e.target.value}}
            id='description'
            aria-label="minimum height"
            minRows={8}
            style={{fontSize:'15px',height:100}}
            placeholder="description"
            
        />
        </Row>
        
   
    <Button
                                            className="btn btn-primary"
                                            variant="outlined"
                                            color="primary"
                                            style={{height:50}}
                                            disabled={
                                                
                                                (
                                                    chosenStores.length === 0 ||
                                                    chosenProducts.length === 0
                                                )
                                            }
                                            onClick={() => {
                                                saveRef()
                                            }}
                                        > 
                                            Save
                                        </Button>
    </FormControl>
    
    </Box>
         : (
            <div className="text-center">
                <div className="mb-4">
                    <i className="mdi mdi-alert-outline me-2 text-warning display-4" />
                </div>
                <div>
                    <h5>missing data</h5>
                    <p className="text-muted">
                        please select at least one product and one store
                    </p>
                </div>
            </div>
        )

    }

                            </div>
  )
}

export default PromotionForm