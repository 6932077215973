import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { Row, Card, CardBody, CardTitle, Col } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersAsync } from 'store/order/actions';
import OrderDetail from './components/OrderDetail';
import { Oval } from  'react-loader-spinner'
import { Box, IconButton, TablePagination, TextField } from '@mui/material';
import { Table,AutoSizer, Column } from 'react-virtualized-reactv17';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import LoadingComponent from 'shared/LoadingComponent';

//import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import DataTableOrderList from './components/dataTable';
import { useQuery } from 'react-query';

const OrderList = () => {

    const dispatch = useDispatch();
    const [orders, setOrders] = useState([])
    const [loader, setLoder] = useState(true)
    //const [dataOrders, setDataOrders] = useState([])
    //const dataOrders=useSelector(state=>state.Orders.orders)
    const getOrders = async () => {
      return dispatch(await getOrdersAsync()).payload.orders
 
    }
    
    const {data,status,isLoading,isFetching} =useQuery('orders', getOrders,{
      refetchOnWindowFocus:false,
      //refetchInterval:0
       //staleTime:'infinity',
      // initialData:undefined
      //refetchOnMount:false
      cacheTime:7200000
    })
    
    //console.log("cashe---------------",{isLoading,isFetching},data?.length)
    // useEffect(async () => {
    //     await getList()
    // }, [])

    return (
        <div className="page-content">

{isLoading && (
               <LoadingComponent/>
    )}
        
        {!isLoading && (
        <div><MetaTags>
                <title>Order list</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Orders" breadcrumbItem="Order List" />
                <Card>
                    <CardBody>
                        <Row>
                            <CardTitle>Order List </CardTitle>
                        </Row>
                        <DataTableOrderList data={data}/>
                    </CardBody>
                </Card>
            </div>
            </div>)}
        </div>
    )
}



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default OrderList