import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import "./dashboard.scss"

function PiechartData({ nbStock, nbStockOut }) {


    const state = {
        options: {
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "13px",
                            color: void 0,
                            offsetY: 60,
                        },
                        value: {
                            offsetY: 22,
                            fontSize: "16px",
                            color: void 0,
                            formatter: function (e) {
                                return e + "%"
                            },
                        },
                    },
                },
            },
            colors: ["#198754"],
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    shadeIntensity: 0.15,
                    inverseColors: !1,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91],
                },
            },
            stroke: {
                dashArray: 4,
            },
            labels: ["Taux de réalisation"],
        },
        series: [parseFloat((nbStockOut / nbStock) * 100).toFixed(2)],
    }


    return (
        <>
            <div className="text-center">
            </div>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="radialBar"
                height="200"
                className="apex-charts"
            />
            <div className="text-center">

                {(nbStockOut || nbStock) ? <p>{nbStockOut + "  / " + nbStock}</p>
                    : <p>{0 + "  / " + 0}</p>
                }
            </div>
        </>
    )
}

export default PiechartData