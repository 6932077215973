import React, { memo, useCallback, useEffect, useState } from 'react'
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import { Box, gridClasses, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, TextField, MenuItem, Grid, IconButton, Icon } from '@mui/material';
import {
  DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
  GridColumnHeaders,
  GridRow,
  GridColumnHeaderSeparator,
} from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Paper from '@mui/material/Paper';
import MList from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';
import MuseumIcon from '@mui/icons-material/Museum';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardTitle, Button, UncontrolledTooltip } from "reactstrap"
import Gallery from 'shared/Gallery';
import {AutoSizer, Column, List, SortIndicator, } from 'react-virtualized-reactv17';
import 'react-virtualized-reactv17/styles.css';
import { Td, Tr } from 'react-super-responsive-table';
import '../../../assets/scss/virtualizedTable.scss';
import { InputRow, LabeledInput } from 'assets/table Elements/LabeledInputs';
//virtuso table
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { Image, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { VirtuosoGrid } from 'react-virtuoso';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled'
import logoDark from "../../../assets/images/product.png"

//Export
const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};




const dataTableProduct = ({ data,setProductData }) => {
  const [marchandizer, setMarchandizer] = useState([])


  

  useEffect(async () => {
    if(data?.length>0){
      setRowsPerPage(data?.length)
    setMarchandizer([...data])}
  }, [data])
  const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearch = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=data?.filter((element)=>{
      
        
        return (
          (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
)
      
    })
    setMarchandizer(marchandizer=>[...list])
    setRowsPerPage(list?.length)
  }else{
    setMarchandizer(marchandizer=>[...data])
    setRowsPerPage(data?.length)
  }
  };
  function filterData(params) {
    //console.log('list_____________________',params)
    if(!filter){
      setFilter(filter=> true)
    }
    if(params==="label" ){
      
      if(desc){
        const list=marchandizer.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }else{
        const list=marchandizer.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setMarchandizer(marchandizer => [...list])
      }
    }else{
      if(params==="Brand"){
        if(desc){
          const list=marchandizer.sort((a,b)=> (a.brand.name.toUpperCase()>b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()<b.brand.name.toUpperCase()))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }else{
          const list=marchandizer.sort((a,b)=> (a.brand.name.toUpperCase()<b.brand.name.toUpperCase())-(a.brand.name.toUpperCase()>b.brand.name.toUpperCase()))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }
      }else if(params==="Category"){
        if(desc){
          const list=marchandizer.sort((a,b)=> (a.category.name.toUpperCase()>b.category.name.toUpperCase())-(a.category.name.toUpperCase()<b.category.name.toUpperCase()))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }else{
          const list=marchandizer.sort((a,b)=> (a.category.name.toUpperCase()<b.category.name.toUpperCase())-(a.category.name.toUpperCase()>b.category.name.toUpperCase()))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }
      }else if (params==="typology"){
        if(desc){
          const list=marchandizer.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }else{
          const list=marchandizer.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
          setDesc(desc=> !desc)
          setMarchandizer(marchandizer => [...list])
        }
      }
    }
    
  }

/////////////////////////////////////////////////////////table virtuoso
const columnsVirtuso = [
  // {
  //   width: 120,
  //   label: 'id',
  //   dataKey: 'id',
  //   //numeric: true,
  // },
  {
    width: 80,
    label: 'Image',
    dataKey: 'path',
  },
  {
    width: 200,
    label: 'Label',
    dataKey: 'label',
  },
  {
    width: 200,
    label: 'Brand',
    dataKey: 'Brand',
  },
  {
    width: 200,
    label: 'Category',
    dataKey: 'Category',
  },
  {
    width: 200,
    label: 'Typology',
    dataKey: 'typology',
  },
  {
    width: 200,
    label: 'Action',
    dataKey: 'Action',
    //numeric: true,
  },
 
  
];

const [rowsPerPage, setRowsPerPage] = React.useState(-1);
const [page, setPage] = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
function fixedHeaderContent() {
  return (
    <TableRow>
      {columnsVirtuso.map((column) => 
        {
          if(column.dataKey==="Action" ||column.dataKey==="path"){
            return  <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white" }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
            {column.label}
          </TableCell>
          }else{
            return  <TableCell
            onClick={()=>filterData(column.dataKey)}
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white" }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
              <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>

          </TableCell>
          }
        }
    )}
    </TableRow>
  );
}
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
  <>
  <TableBody {...props} ref={ref} />
  

  </>)),
  // TableFooter :React.forwardRef((props, ref) => 
  
  
  // )
};
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
      
function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columnsVirtuso.map((column) => {
        //console.log("column______________________",column)
          if(column.dataKey==="path"){
           return (<TableCell
          key={column.dataKey}
          align={column.numeric || false ? 'right' : 'left'}
          >
            <img  src={URL+row[column.dataKey]}  
            onError={(e) => {
     e.target.src = logoDark // some replacement image
     
     //e.target.style = 'display:none' // inline styles in html format
  }} 
  style={{ width: "4rem", height: "4rem", }} />
          </TableCell>)
          }  else if(column.label==="Brand") {
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row.brand.name}
              </TableCell>)
          }else if(column.label==="Category") {
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row.category.name}
              </TableCell>)
          }else if(column.label==="Action") {
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              <Row>
                            <Col xl="3" lg="3">
                              
                            <Link to="#">

                                <i
                                    onClick={() => { setProductData(row,"update") }}
                                    className="bx bx-edit-alt"
                                    style={{ fontSize: "1.7rem", cursor: "pointer", float: "left", marginTop: "1.5rem" }}
                                    id="edittooltip"
                                />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                                
                            </Col>
                            <Col xl="3" lg="3">
                            <Link to="#">
                                <i
                                    id="detailtooltip"
                                    className="mdi mdi-eye-minus-outline"
                                    style={{ fontSize: "1.7rem", cursor: "pointer", marginRight: "1rem", float: "left", marginTop: "1.2rem" }}
                                    onClick={() => { setProductData(row,"detail") }}
                                />
                                <UncontrolledTooltip placement="top" target="detailtooltip">
                                    Detail
                                </UncontrolledTooltip>
                            </Link>
                            </Col>
                            <Col xl="3" lg="3">
                                <div style={{ marginTop: "1.5rem" }}>
                                 {/* <Link
                                    to="#"
                                    onClick={() => { setProductData(params.row.productPictures,"gallery") }}
                                    style={{ fontSize: "1.7rem" }}
                                >
                                    <i className="bx bx-images" id="gallery" />
                                    <UncontrolledTooltip placement="top" target="gallery">
                                        Gallery
                                    </UncontrolledTooltip>
                                </Link> */}
                                    <Gallery
                                        pictures={row?.productPictures}
                                    />
                                </div>
                            </Col>
                        </Row>
              </TableCell>)
          }else{
            return (<TableCell
              key={column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row[column.dataKey]}
              </TableCell>)
          }
      }
       
      )}
    </React.Fragment>
  );
}
  return (
    <div style={{height:700}}>
          <TextField placeholder='search...' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

      {marchandizer.length>0&&<Box sx={{ height: 650, width: '100%' }}>
        

    <TableVirtuoso
        data={marchandizer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
       <Row>
        <Col >

          <TablePagination
            component="div"
            //style={{width:1100}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: marchandizer.length }]}
            colSpan={3}
            count={marchandizer.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
          />

        </Col>
        
       </Row>
       </Box> }

      
    </div>

  )
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default memo(dataTableProduct)