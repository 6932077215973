import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, FormGroup, Modal, Row } from 'reactstrap'
import { getReferencedProductsByStore } from 'store/referencedProduct/services'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import ErrorAlert from 'shared/ErrorAlert';
import { upsertOrder } from 'store/order/services'
import MutipleImagesUpload from 'shared/MutipleImagesUpload' 
import { useSelector } from 'react-redux'
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
const NewOrder = (props) => {

    const { store, visitId, tog_order, orderModal, setOrderModal } = props
    const userId = useSelector(state => state.User?.user?.id)
    const [selectedFiles, setselectedFiles] = useState(null)
    const [rfp, setRfp] = useState([])
    const [order, setOrder] = useState({})
    const [orderDetail, setOrderDetail] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)

    const getRfp = () => {
         getReferencedProductsByStore(store.id).then((data) => {
            let list=[]
            data.forEach(element => {
                list.push({ product: element.product, storeId: store.id, productId: element.product.id, quantity: null })
            });
            setRfp(list) 
            $('#datatables').DataTable({
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]]
            })
        })
    }

    const handleUpdate = (id, e) => {
        setRfp((rfp) =>
            rfp.map((element) => {
                return element.productId === id ? { ...element, quantity: parseInt(e.target.value) } : element;
            })
        );
    };

    const createOrderDetail = () => {
        rfp.forEach((element) => {
            if (element.quantity) {
                setOrderDetail(orderDetail => [...orderDetail, element])
            }
        });
    }

    const createOrder = () => {
        if (!order.date || !order.amount || !selectedFiles) {
            setErrorMessage("order fields are required!") 
            setTimeout(() => {
                setErrorMessage(false)
            }, 3000);
        } else {
            upsertOrder({ ...order, userId: userId, storeId: store.id, visitId: visitId }, orderDetail, selectedFiles)
                .then(() => {
                    tog_order()
                })
        }
    }

    useEffect(() => {
        if (orderModal) {
            setselectedFiles(null)
            setOrder({})
            setOrderDetail([])
            setRfp([])
            getRfp()
            
        }
    }, [orderModal])

    const memoData=useMemo(()=>rfp.length>0&&rfp?.map((product, index) => {
        return (
            <Tr key={index} style={{ backgroundColor: product.stockOut ? ("#DE5555") : (""), color: product.stockOut ? ("white") : ("") }}>
                <Td>
                    <img
                        src={URL+product.product.path}
                        style={{ width: "4rem", height: "4rem" }}
                    />
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.label} </p>
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.brand?.name} </p>
                </Td>
                <Td>
                    <p style={{ marginTop: "1.5rem" }}> {product.product.category?.name} </p>
                </Td>
                <Td>
                    <input
                        style={{ marginTop: "1.3rem" }}
                        type="number"
                        onChange={(e) => { handleUpdate(product.product.id, e) }}
                    />
                </Td>
            </Tr>
        )
    }),[rfp])
    return (
        <Modal
            isOpen={orderModal}
            size="xl"
            toggle={() => { tog_order() }}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    New order for {store.name}
                </h5>
                <button
                    type="button"
                    onClick={() => { setOrderModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                    <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                    >
                        <Table id="datatables" className="table table-bordered">
                            <Thead>
                                <Tr>
                                    <Th>Image</Th>
                                    <Th>Label</Th>
                                    <Th>Brand</Th>
                                    <Th>Category</Th>
                                    <Th>Quantity</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {memoData}
                            </Tbody>
                        </Table>
                    </div>
                </div>
                <Row style={{ marginTop: "2rem" }}>
                    <Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            delivery date
                        </label>
                        <div className="col-md-10">
                            <input
                                className="form-control"
                                type="date"
                                onChange={(e) => { setOrder({ ...order, date: e.target.value }) }}
                            />
                        </div>
                    </Col>
                    <Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            total amount
                        </label>
                        <div className="col-md-10">
                            <input
                                className="form-control"
                                type="number"
                                placeholder="total amount"
                                onChange={(e) => { setOrder({ ...order, amount: parseFloat(e.target.value) }) }}
                            />
                        </div>
                    </Col>
                </Row>
                <div style={{ width: "50%", margin: "auto", marginTop: "2rem" }}>
                    <MutipleImagesUpload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                </div>
                {errorMessage &&
                    <div style={{ marginTop: "2rem" }}>
                        <ErrorAlert errorMessage={errorMessage} />
                    </div>
                }
                <FormGroup className="mb-0" style={{ float: 'right', marginTop: "2rem" }}>
                    <Button
                        type="button"
                        color="primary"
                        className="ms-1"
                        onMouseEnter={() => { createOrderDetail() }}
                        onClick={() => { createOrder() }}
                    >
                        Confirm
                    </Button>
                </FormGroup>
            </div>
        </Modal>
    )  
}

export default NewOrder
