import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Row, Card, CardBody, Col, Button } from "reactstrap"
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import { Autocomplete, Box, Chip, FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Popover, Select, Stack,  TextField, Typography } from '@mui/material';
import {
  DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
  GridSearchIcon,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import PieChart from 'pages/Performance/components/PieChart';
import Progress_bar from 'pages/Performance/components/progress';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import DataTableSecond from './DataTableSecond';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ProductTab from './ProductTab';
import DataTauxFilter from './DataTauxFilter';
import DataTauxCategory from './DataTauxCategory';
import PropTypes from 'prop-types';
import { GridRow } from '@mui/x-data-grid';
import { GridColumnHeaderSeparator } from '@mui/x-data-grid';

//virtuso table
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePaginationActions from 'components/paginationMui';
import {TablePagination,Paper} from '@mui/material';
import logoDark from "../../../assets/images/product.png"
import DataTableSecondRef from './DataTableSecond';

const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
//Export
const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};





function DataTableProducts({ data }) {


  const [products, setProducts] = useState([])
  const [filterFamily, setFilterFamily] = useState(false)

  useEffect(()=>{
    if(data.length>0){
      setRowsPerPage(data.length)
      setProducts(data)
    }
  },[])
  const ref = useRef(null); 

  function showCellStoreGrp(params) {
    //console.log("params_________________",params)
    let component = []
    if(params.length>0){
      params.map((elems,index) => {
        component.push(<li key={index} align='left'>{elems}</li>)
      })
    }

    return component.length>0 ?  <div>{component}</div> :<h6>not available in stock</h6>
  }

  //table options



  //filter Date start end
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const [cancelB, setCancelB] = useState(true);


  const cancelFilter = () => {
    getCategoryList()
    getFamiyList()
    setFilterFamily(false)
    setProducts([...data])
    setCancelB(true)
    setDateStart(dayjs())
    setDateEnd(dayjs())
    setFamily([])
    setBrand([])
    setCategory([])
    setStoreGrp([])
    setRowsPerPage(data.length)
  };

  // const handleSearch = () => {
  //   setCancelB(false)
  //   let newList = []
  //   if (data.length > 0) {
  //     if (dateEnd && dateStart) {

  //       data.filter((el) => {

  //         if ((el.start) && (el.end)) {
  //           let start = [...el.start]
  //           let end = [...el.end]
  //           let found = false
  //           for (let index = 0; index < start.length; index++) {
  //             if ((start[index].slice(0, 10) >= dateStart.format('YYYY-MM-DD')) && (((start[index]).slice(0, 10)) <= (dateEnd.format('YYYY-MM-DD')))) {


  //               //setList2([...el])
  //               //console.log(el)
  //               newList.push(el)
  //               break;
  //             }

  //           }
  //           // if(found=true){
  //           //   newList.push(el)
  //           //   console.log(el)
  //           // }



  //         }


  //         return
  //       })
  //       setProducts(newList)
  //     }

  //   }
  //   //setList2(newList)
  //   //console.log(list2)

  // };
  const handleChangeStart = (newValue) => {

    setDateStart(newValue);
    setCancelB(false)
  };
  const handleChangeEnd = (newValue) => {

    setDateEnd(newValue);
    setCancelB(false)
  };
  //Filter family category type
  const [family, setFamily] = useState([]);
  const listFamily = []
  const [category, setCategory] = useState([]);
  const listCategory = []
  const [brand, setBrand] = useState([]);
  const listBrand = []
  const [storeGrp, setStoreGrp] = useState([]);
  const listStoreGrp = []
  const listGrp = []
  const getList = () => {
    //console.log("---------------------------")
    data.map((el) => {
      listFamily.push(el.familly)
      listCategory.push(el.category)
      listBrand.push(el.brand)
      //console.log(el.storeGrp)
      listStoreGrp.push(el.storeGrp)
      el.storeGrp.map((s) => {
        listGrp.push(s)
      })

    })
  }

  const handleFamily = (event, value) => {
    //filter family category
    if (value.length > 0) {
      categoryList.length = 0;
      [...new Set(listCategory)].map(f => {
        let nbStock = 0
        let nbStockOut = 0
        products.map(p => {
          if (p.category === f) {
            value.map(c => {
              if (p.familly === c) {
                nbStock = nbStock + p.nbStock
                nbStockOut = nbStockOut + p.nbStockOut
              }
            })
          }
        })

        categoryList.push({ category: f, nbStock: nbStock, nbStockOut: nbStockOut, pourcentage: Number(parseFloat(((nbStockOut * 100) / nbStock)).toFixed(2)) })
      })
    } else {
      setFilterFamily(false)
      getCategoryList()
    }
    setFilterFamily(true)
    setFamily(value);
    setCancelB(false)
  };

  const handleCategory = (event, value) => {
    //filter family category

    if (value.length > 0) {
      familyList.length = 0;
      [...new Set(listFamily)].map(f => {
        let nbStock = 0
        let nbStockOut = 0
        products.map(p => {
          if (p.familly === f) {
            value.map(c => {
              if (p.category === c) {
                nbStock = nbStock + p.nbStock
                nbStockOut = nbStockOut + p.nbStockOut
              }
            })
          }
        })

        familyList.push({ family: f, nbStock: nbStock, nbStockOut: nbStockOut, pourcentage: Number(parseFloat(((nbStockOut * 100) / nbStock)).toFixed(2)) })
      })
    } else {
      setFilterFamily(false)
      getFamiyList()
    }
    setFilterFamily(true)
    setCategory(value);
    setCancelB(false)
  };

  const handleBrand = (event, value) => {
    //console.log(value)
    setBrand(value);
    setCancelB(false)
  };

  const handleStoreGrp = (event, value) => {
    setStoreGrp(value);
    //console.log(value)
    setCancelB(false)
  };

  const filterColumn = () => {
    setCancelB(false)
    let newList = []
    if (data.length > 0) {
      //filter 1 : family
      if (family.length > 0 && !category.length > 0 && !brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          family.map((f) => {

            if (el.familly === f) {
              newList.push(el)
            }

          })
        })
        setProducts(newList)
        
      }

      //filter 2 : family + category
      if (family.length > 0 && category.length > 0 && !brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          family.map((f) => {

            category.map((c) => {

              if (el.familly === f && el.category === c) {
                newList.push(el)
              }

            })

          })
        })
        setProducts(newList)
      }
      //filter 3 : family + category +brand
      if (family.length > 0 && category.length > 0 && brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          family.map((f) => {

            category.map((c) => {

              brand.map((b) => {

                if (el.familly === f && el.category === c && el.brand === b) {
                  newList.push(el)
                }

              })

            })

          })
        })
        setProducts(newList)
      }

      //filter 4 : category
      if (!family.length > 0 && category.length > 0 && !brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          category.map((c) => {
            if (el.category === c) {
              newList.push(el)
            }
          })
        })
        setProducts(newList)
      }

      //filter 5 : category + brand
      if (!family.length > 0 && category.length > 0 && brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          brand.map((b) => {

            category.map((c) => {

              if (el.brand === b && el.category === c) {
                newList.push(el)
              }

            })

          })
        })
        setProducts(newList)
      }

      //filter 6 : brand
      if (!family.length > 0 && !category.length > 0 && brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          brand.map((b) => {
            if (el.brand === b) {
              newList.push(el)
            }
          })
        })
        setProducts(newList)
      }

      //filter 7 : brand + family
      if (family.length > 0 && !category.length > 0 && brand.length > 0 && !storeGrp.length > 0) {
        //console.log(family)
        data.filter((el) => {
          brand.map((b) => {

            family.map((f) => {

              if (el.brand === b && el.familly === f) {
                newList.push(el)
              }

            })

          })
        })
        setProducts(newList)
      }

      //filter 8 :storeGrp 
      if (!family.length > 0 && !category.length > 0 && !brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {

          let exist = false
          storeGrp.map((s) => {
            el.storeGrp?.map(grp => {
              if (grp === s) {
                exist = true
              }
            })

            if (exist)
              newList.push(el)
          })
        })
        setProducts(newList)
      }

      //filter 9 :storeGrp + brand
      if (!family.length > 0 && !category.length > 0 && brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          brand.map((b) => {
            storeGrp.map((s) => {
              el.storeGrp?.map(grp => {
                if (grp === s && el.brand === b) {
                  exist = true

                }

              })
              if (exist)
                newList.push(el)

            })

          })

        })
        setProducts(newList)
      }

      //filter 10 :storeGrp + brand + category
      if (!family.length > 0 && category.length > 0 && brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          brand.map((b) => {
            category.map(c => {
              storeGrp.map((s) => {
                el.storeGrp?.map(grp => {
                  if (grp === s && el.brand === b && el.category === c) {
                    exist = true

                  }

                })
                if (exist)
                  newList.push(el)

              })
            })

          })

        })
        setProducts(newList)
      }

      //filter 11 :storeGrp + brand + category + family
      if (family.length > 0 && category.length > 0 && brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          brand.map((b) => {
            category.map(c => {
              family.map(f => {
                storeGrp.map((s) => {
                  el.storeGrp?.map(grp => {
                    if (grp === s && el.brand === b && el.category === c && el.familly === f) {
                      exist = true

                    }

                  })
                  if (exist)
                    newList.push(el)

                })
              })
            })

          })

        })
        setProducts(newList)
      }

      //filter 12 :storeGrp + brand + category + family
      if (!family.length > 0 && category.length > 0 && !brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          category.map(c => {
            storeGrp.map((s) => {
              el.storeGrp?.map(grp => {
                if (grp === s && el.category === c) {
                  exist = true

                }

              })
              if (exist)
                newList.push(el)

            })
          })



        })
        setProducts(newList)
      }

      //filter 13 :storeGrp + brand + category + family
      if (family.length > 0 && !category.length > 0 && !brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          family.map(f => {
            storeGrp.map((s) => {
              el.storeGrp?.map(grp => {
                if (grp === s && el.familly === f) {
                  exist = true

                }

              })
              if (exist)
                newList.push(el)

            })
          })



        })
        setProducts(newList)
      }

      //filter 14 :storeGrp + category + family
      if (family.length > 0 && category.length > 0 && !brand.length > 0 && storeGrp.length > 0) {

        data.filter((el) => {
          let exist = false

          category.map(c => {
            family.map(f => {
              storeGrp.map((s) => {
                el.storeGrp?.map(grp => {
                  if (grp === s && el.category === c && el.familly === f) {
                    exist = true

                  }

                })
                if (exist)
                  newList.push(el)

              })
            })
          })



        })
        setProducts(newList)
      }

    }
    setRowsPerPage(newList.length)
  };

  //Modal Table 2 
  const [anchorE2, setAnchorE2] = useState(null);
  // const open2 = Boolean(anchorE2);
  const [open2,setOpen]=useState(false) 
  const [storeList, setStoreList] = useState([]);
  const [name, setName] = useState();
  const [barcode, setBarcode] = useState();
  const handleClose = () => {
    setOpen(null);
  };
  const handleClickTop = (data, name, barcode)  => {
    storeList.length = 0
    setOpen(true);
    data.map((e) => {
      let nbStock = 0
      let nbStockOut = 0
      e.listVisit.map((v) => {
        if (e.lastVisit === v.day) {
          nbStock++
          if (v.stockOut > 0) {
            nbStockOut++
          }
        }
      })
      storeList.push({
        image: e.image, name: e.name, lastVisit: e.lastVisit, gouvernement: e.gouvernement,
        id: e.id, length: e.length, listVisit: e.listVisit, nbStockOut: e.nbStockOut,
        pourcentage: e.pourcentage, nbStockL: nbStock, nbStockOutL: nbStockOut
      })
    })
    //console.log(storeList)
    //setStoreList(data)
    setName(name.toUpperCase())
    setBarcode(barcode.toUpperCase())
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: 720,

  };




  ///Table Family
  const [familyList, setFamilyList] = useState([...new Set(listFamily)]);
  const [categoryList, setCategoryList] = useState([...new Set(listCategory)]);
  //filter family by category
  function getFamiyList() {
    familyList.length = 0;

    [...new Set(listFamily)].map(f => {
      let nbStock = 0
      let nbStockOut = 0
      products.map(p => {
        if (p.familly === f) {
          nbStock = nbStock + p.nbStock
          nbStockOut = nbStockOut + p.nbStockOut
        }
      })

      familyList.push({ family: f, nbStock: nbStock, nbStockOut: nbStockOut, pourcentage: Number(parseFloat(((nbStockOut * 100) / nbStock)).toFixed(2)) })
    })
  }
  //filter category by family
  function getCategoryList() {
    categoryList.length = 0;

    [...new Set(listCategory)].map(f => {
      let nbStock = 0
      let nbStockOut = 0
      products.map(p => {
        if (p.category === f) {
          nbStock = nbStock + p.nbStock
          nbStockOut = nbStockOut + p.nbStockOut
        }
      })

      categoryList.push({ category: f, nbStock: nbStock, nbStockOut: nbStockOut, pourcentage: Number(parseFloat(((nbStockOut * 100) / nbStock)).toFixed(2)) })
    })
  }

  getList()




  useEffect(async () => {
    //filter family by category
    getFamiyList()
    //filter category by family
    getCategoryList()

  }, [])

  /////////////////////////////////////////////////////////table virtuoso
const columnsVirtuso = [

  {
    width: 80,
    label: 'Image',
    dataKey: 'image',
  },
  {
    width: 100,
    label: 'Barcode',
    dataKey: 'barcode',
  },
  {
    width: 140,
    label: 'Label',
    dataKey: 'label',
  },
  
  {
    width: 110,
    label: 'Brand',
    dataKey: 'brand',
  },
  {
    width: 120,
    label: 'Category',
    dataKey: 'category',
  },
  {
    width: 110,
    label: 'Typology',
    dataKey: 'typology',
  },
  {
    width: 100,
    label: 'Family',
    dataKey: 'familly',
  },
  {
    width: 120,
    label: 'StoreGrp',
    dataKey: 'storeGrp',
  },
  {
    width: 80,
    label: 'Taux',
    dataKey: 'taux',
  },
  {
    width: 130,
    label: 'Pourcentage',
    dataKey: 'Pourcentage',
  },
  {
    width: 80,
    label: 'Action',
    dataKey: 'Action',
    //numeric: true,
  },
 
  
];

const [rowsPerPage, setRowsPerPage] = React.useState(-1);
const [page, setPage] = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
function fixedHeaderContent() {
  return (
    <TableRow>
      {columnsVirtuso.map((column) => 
        {
          if(column.dataKey==="Action" ||column.dataKey==="image"||column.dataKey==="storeGrp"){
            return  <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white" }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
            {column.label}
          </TableCell>
          }else{
            return  <TableCell
            onClick={()=>filterData(column.dataKey)}
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width,color:"white" }}
            sx={{
              backgroundColor: '#4F6973',
            }}
          >
              <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>

          </TableCell>
          }
        }
    )}
    </TableRow>
  );
}
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
  <>
  <TableBody {...props} ref={ref} />
  

  </>)),
  // TableFooter :React.forwardRef((props, ref) => 
  
  
  // )
};

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columnsVirtuso.map((column) => {
          if(column.dataKey==="image"){
           return (<TableCell
          key={row.id+column.dataKey}
          align={column.numeric || false ? 'right' : 'left'}
          >
            <img src={URL+row.image} onError={(e) => { e.target.src = logoDark }} style={{ width: "3rem", height: "3rem" }} />
          </TableCell>)
          } else if(column.label==="Action") {
            return (<TableCell
              key={row.id+column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              <h6  onClick={()=>handleClickTop(row.store, row.label, row.barcode)}><ListAltOutlinedIcon fontSize='medium' /></h6> 
              </TableCell>)
          }else if(column.label==="Pourcentage") {
            return (<TableCell
              key={row.id+column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
                <Progress_bar bgcolor="orange" progress={row.pourcentage} />  </TableCell>)
          }else if(column.label==="Barcode") {
                        return (<TableCell
                          key={row.id+column.dataKey}
                          align={column.numeric || false ? 'right' : 'left'}
                          >
                            <HorizontalSplitSharpIcon fontSize='small' />  {row.barcode} </TableCell>)
                      }else if(column.label==="Taux") {

            return (<TableCell
              key={row.id+column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
                  <PieChart nbStock={row.nbStock} nbStockOut={row.nbStockOut} /> </TableCell>)
          }else if(column.label==="StoreGrp") {

            return (<TableCell
              key={row.id+column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
                  {showCellStoreGrp(row.storeGrp)} </TableCell>)
          }else{
            return (<TableCell
              key={row.id+column.dataKey}
              align={column.numeric || false ? 'right' : 'left'}
              >
              {row[column.dataKey]}
              </TableCell>)
          }
      }
       
      )}
    </React.Fragment>
  );
}

const [desc, setDesc] = useState(false)
const [filter, setFilter] = useState(false)

const sort_by = (field, reverse, primer) => {

  const key = primer ?
    function(x) {
      return primer(x[field])
    } :
    function(x) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function(a, b) {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
  }
}

const handleSearch = (search) => {
  //setCancelB(false)
  //console.log("search_______________________",search)
  if(search){
  const list=data.filter((element)=>{
    
      
      return (
        (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
        || (element?.barcode.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )

        || (element?.brand.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        || (element?.category.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        || (element?.familly.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        || (element?.pourcentage?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        || (element?.storeGrp.includes(search.toString().toLowerCase(search)) )


)
    
  })
  setProducts(products=>[...list])
  setRowsPerPage(list.length)
}else{
  setProducts(products=>[...data])
  setRowsPerPage(data.length)

}
};
function filterData(params) {
  //console.log('list_____________________',params)
  if(!filter){
    setFilter(filter=> true)
  }
  if (params==="typology"){
      if(desc){
        const list=products.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }else{
        const list=products.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }
    }else if (params==="Pourcentage" || params==="taux"){
      if(desc){
        const list=products.sort((a,b)=> (a.pourcentage>b.pourcentage)-(a.pourcentage<b.pourcentage))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }else{
        const list=products.sort((a,b)=> (a.pourcentage<b.pourcentage)-(a.pourcentage>b.pourcentage))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }
    }else {
      if(desc){
        const list=products.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }else{
        const list=products.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setProducts(products => [...list])
      }
     
    } 
  
  
}
/////////////////////////////////////////
  return (
    <div >
      <Box sx={{ height: 900, width: '101%' }}>
        {/* filter per Date start end */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='row m-3 d-flex justify-content-evenly'>
            <MButton css={{ backgroundColor: 'blue' }} disabled={cancelB} color='error' aria-describedby='chart' onClick={cancelFilter} className='mb-2' ><EventBusySharpIcon />&nbsp; cancel filter</MButton>
            <DatePicker
              views={['day']}
              label="From"
              value={dateStart}
              inputFormat="YYYY-MM-DD"
              onChange={handleChangeStart}
              renderInput={(params) => <TextField {...params} />}
              className="col-2 "
            />
            <DatePicker
              views={['day']}
              label="To"
              value={dateEnd}
              inputFormat="YYYY-MM-DD"

              onChange={handleChangeEnd}
              renderInput={(params) => <TextField  {...params} />}
              className="col-2"
            />
            <MIButton className="col-2" variant="contained" onClick={handleSearch} size="large">
              <ContentPasteSearchSharpIcon /> &nbsp;Search
            </MIButton>
          </div>
        </LocalizationProvider>
        <Row className='mb-3' style={{ marginLeft: 0 }}>
          <Col className='ml-5' >
            {/* <TextField
        id="input-with-icon-textfield"
        label="Brand"
        onChange={handleBrand}
        placeholder='Search'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
      /> */}
            <FormControl sx={{ m: 1, width: 300 }} size="small">

              <Autocomplete
              size='medium'
                onChange={handleBrand}
                value={brand}
                multiple
                id="brand"
                options={[...new Set(listBrand)]}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Brand"
                    placeholder="Brand"
                  />
                )}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
              <Autocomplete
              size='medium'
                onChange={handleCategory}
                value={category}
                multiple
                id="category"
                options={[...new Set(listCategory)]}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    placeholder="Category"
                  />
                )}
              />

            </FormControl>

          </Col>
          <Col>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
              <Autocomplete
              size='medium'
                onChange={handleFamily}
                value={family}
                multiple
                id="family"
                options={[...new Set(listFamily)]}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Family"
                    placeholder="Family"
                  />
                )}
              />

            </FormControl>

          </Col>
          <Col>
            <FormControl sx={{ m: 1, width: 300, }} size="large">
              <Autocomplete
              size='medium'
                onChange={handleStoreGrp}
                value={storeGrp}
                multiple
                id="storeGrp"
                options={[...new Set(listGrp)]}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Store Group"
                    placeholder="Store Group"
                  />
                )}
              />

            </FormControl>
          </Col>
          <Col style={{ marginTop: '6px' }}>
            <MIButton className="col-5" style={{height:60}}  variant="outlined" onClick={filterColumn} size='medium'>
              <FilterAltIcon /> Filter
            </MIButton>
          </Col>
        </Row>

        <ProductTab
          tab1={
          <Box sx={{ height: 700, width: '100%',marginBottom:10 }} >
                <TextField placeholder='search...' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

           {products.length>0&& <>
            <TableVirtuoso
        data={products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
         />

          <TablePagination
            component="div"
            //style={{width:1100,}}
            rowsPerPageOptions={[10, 50,100, { label: 'All', value: products.length }]}
            colSpan={1}
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
          />
          </>}

            </Box>
          }
          tab2={<DataTauxFilter data={familyList} filterFamily={filterFamily} familyList={category} />}
          tab3={<DataTauxCategory data={categoryList} filterCategory={filterFamily} categoryList={family} />}
        />
      </Box>


      {open2&&<Modal
        keepMounted
        open={open2}
        onClose={handleClose}
        // aria-labelledby="keep-mounted"
        // aria-describedby="keep-mounted"
      >
        <Box sx={modalStyle}>

          <Typography id="keep-mounted-modal-title" variant={"body2"} component={"span"}>
            <div className="d-flex justify-content-between"> <h3 align='center'><Button size="large" variant="text" color='dark' style={{ fontSize: '15px' }}><HorizontalSplitSharpIcon fontSize='medium' /> {barcode}</Button> </h3><h3><Button size="large" variant="text" color='primary' style={{ fontSize: '15px' }}><Inventory2Icon fontSize='medium' /> {name} </Button> </h3>

              <Button variant="outlined" size='medium' color='light' style={{ float: 'right', marginBottom: '5px' }} onClick={handleClose}><CloseIcon /></Button>

            </div>

          </Typography>

          <DataTableSecondRef data={storeList} ref={ref}/>
        </Box>
      </Modal>}
    </div>
  )
}

export default DataTableProducts

