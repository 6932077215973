import React, { memo, useCallback, useEffect, useState } from 'react'
import ProductsList from './index'
import UpdateProduct from './components/UpdateProduct';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import LoadingComponent from 'shared/LoadingComponent';
import ProductDetail from './components/ProductDetail';
import { getBrandsAsync } from 'store/brand/actions';
import Gallery from 'shared/Gallery';
import { useQuery } from 'react-query';

function indexProductHome() {
    const token = localStorage.getItem('authUser')

    const [products, setProducts] = useState([])
    const [loader, setLoder] = useState(true)
    const [render, setRender] = useState(false)

    const dispatch = useDispatch();
    const [visibleUpdate, setVisibleUpdate] = useState(false)
    const [visibleDetail, setVisibleDetail] = useState(false)
    const [visibleGallery, setVisibleGallery] = useState(false)

    const [productTest, setProductTest] = useState({})

    const getProducts = async () => {
        
        return dispatch(await getProductsAsync()).payload.products;
    }
    //console.log("f111111111111111111111111111111111111111111")
   
    useEffect(async () => {
    
      if (token) {
        
        const brands=dispatch(await getBrandsAsync()).payload.brands
  
        //console.log("user___________________",jwtDecode(token).payload.role)
      }
    }, [token])
    const {data,status,isLoading,isFetching} =useQuery('products', getProducts,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
      
      //console.log("cashe---------------",{isLoading,isFetching},data?.length)
    const setProductData=useCallback((params,modal)=>{
        //console.log("f_____________________________",params)
        setProductTest(productTest=> ({...productTest,params}))

         if(modal==="update"){
            setVisibleUpdate(true)
         }else if(modal==="detail"){
            setVisibleDetail(true)
         }else{
            setVisibleGallery(true)
         }
    },[]) 


    
  return (
    <>
    {isLoading && (
           <LoadingComponent />
    )}
    {
        (!isLoading)  && <ProductsList visible={visibleUpdate} productData={data} setProductData={setProductData} setRender={setRender} setVisible={setVisibleUpdate}/>
    }
    {
      visibleUpdate &&  <UpdateProduct
        setVisible={setVisibleUpdate}
        visible={visibleUpdate}
        prod={productTest.params}
        setRender={setRender}
    />
    }
    {visibleDetail&&<ProductDetail
        setVisible={setVisibleDetail}
        visible={visibleDetail}
        product={productTest.params}
                                />}
    {
       visibleGallery && <Gallery 
            setVisible={setVisibleGallery}
            visible={visibleGallery}
            pictures={productTest.params}
        />
    }                            
    </>
  )
}

export default memo(indexProductHome)