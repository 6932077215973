import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import "./dashboard.scss"

class ApexRadial extends Component {
  constructor(props) {
    super(props)

    //console.log(this.props.time)
    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: void 0,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: void 0,
                formatter: function (e) {
                  return e + "%"
                },
              },
            },
          },
        },
        colors: ["#198754"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Taux de réalisation"],
      },
      series: [isNaN(parseFloat((this.props.realisedVisits / this.props.plannedVisits) * 100).toFixed(2))? 0:parseFloat((this.props.realisedVisits>0?1:0 / this.props.plannedVisits) * 100).toFixed(2)],
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="text-center">
        </div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height="200"
          className="apex-charts"
        />
        <div className="text-center">
          <p> <i className="bx bx-calendar" /> {this.props.month + " - " + this.props.day + " - " + this.props.time}</p>
          {((this.props.realisedVisits===0 && this.props.plannedVisits===0)) ? <p>{0 + "  / " + 0}</p>
          
            : <p>{this.props.realisedVisits + "  / " + this.props.plannedVisits}</p>}
        </div>
      </React.Fragment>
    )
  }
}

export default ApexRadial
