import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Modal, UncontrolledAlert } from 'reactstrap'
import { upsertReportSubject } from 'store/reportSubject/services'

function CreateReportSubject(props) {

    const { getSubjects } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [subject, setSubject] = useState({})
    const [errorMessage, setErrorMessage] = useState(null);

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
    }

    const createSubject = async () => {
        await upsertReportSubject(subject)
            .then(() => {
                getSubjects()
                tog_fullscreen()
            })
            .catch((error) => {
                if (error.response.data.message.name === "SequelizeUniqueConstraintError") {
                    setErrorMessage("Objet existant!")
                    setTimeout(() => {
                        setErrorMessage(null)
                    }, 5000);
                } else {
                    setErrorMessage(error.response.data.message.name)
                    setTimeout(() => {
                        setErrorMessage(null)
                    }, 5000);
                }
            })
    }

    return (
        <>
            <Col xl="2" sm="6" style={{ cursor: "pointer" }}>
                <Card style={{ color: "#556EE6", fontSize: "8rem" }} onClick={() => { tog_fullscreen() }} className="text-center">
                    <CardBody style={{ height: "15rem" }}>
                        <i className="bx bx-plus" style={{ fontSize: "6rem" }} />
                    </CardBody>
                    <CardFooter className="bg-transparent border-top">
                        <h5>Nouveau objet</h5>
                    </CardFooter>
                </Card>
            </Col>
            <Modal
                size="md"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Nouveau Objet
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={() => { createSubject() }}>
                        <AvField
                            className="mb-3"
                            name="nom"
                            label="nom"
                            placeholder="nom"
                            type="text"
                            errorMessage="le nom de l'objet est obligatoire."
                            validate={{ required: { value: true } }}
                            onChange={(e) => { setSubject({ ...subject, name: e.target.value }); }}
                        />
                        {errorMessage &&
                            <UncontrolledAlert
                                color="danger"
                                className="alert-dismissible fade show"
                                role="alert"
                            >
                                <i className="mdi mdi-block-helper me-2"></i> {errorMessage}
                            </UncontrolledAlert>
                        }
                        <FormGroup className="mb-0" style={{ float: 'right' }}>
                            <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                            >
                                Create subject
                            </Button>
                        </FormGroup>
                    </AvForm>
                </div>
            </Modal>
        </>
    )
}

export default CreateReportSubject