import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
//import { getVisitList } from 'store/visit/actions';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { formValueSelector } from 'redux-form';


function SurveyAVGStore({data}) {
    //const [stores, setStores] = useState([])
    const ListData=data?.map((elem)=>elem?.avg)
    const ListStores=data?.map((element)=>element?.name)
    const ListVisit=data?.map((element)=>element?.visits?.length)
    //console.log('waiting_________________________',data?.length)
    // useEffect(()=>{
    //     if(stores?.length>0){
    //         console.log('waiting_________________________',stores?.length)

    //         stores?.map((element)=>{
    //             //ListStores.push(element.name)
    //             ListData.push(element.avg)
    //             ListVisit.push(element.visits?.length)
    
    //         })
    //     }
    // },[stores])
    // useEffect(()=>{
    //     setStores([...data])
    // },[data])
    // const getVisit = async () => {
    //     // getVisits().then((data) => {
    //     //   setVisits(data)
    //     // })
    //     return (dispatch(await getVisitList()).payload.visits)

    // }
    // const options = {
        
    //     chart: {
    //     //height: 350,
    //     type: 'bar'
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //     }
    //   },
    //   colors: ['#00E396'],
    //   dataLabels: {
    //     formatter: function(val, opt) {
    //       const goals =
    //         opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
    //           .goals
      
    //       if (goals && goals.length) {
    //         return `${val} / ${goals[0].value}`
    //       }
    //       return val
    //     }
    //   },
    //   legend: {
    //     show: true,
    //     showForSingleSeries: true,
    //     customLegendItems: ['Actual', 'Expected'],
    //     markers: {
    //       fillColors: ['#00E396', '#775DD0']
    //     }
    //   }
    //   };

    // const areaChartOptions =  {
        
    //     chart: {
    //         toolbar: {
    //             show: true
    //         },
    //         stacked: false,
    //     height: 350
    //   },
    //   grid: {
    //     strokeDashArray: 40
    // },
    //   dropShadow: {
    //     enabled: true,
    //     color: '#000',
    //     top: 18,
    //     left: 7,
    //     blur: 10,
    //     opacity: 0.2
    //   },
    // dataLabels: {
    //     enabled: true,
        
    // },
    // stroke: {
    //     //curve: 'smooth',
    //     width: [1, 1, 1]
    // },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //       dataLabels: {
    //         total: {
    //           enabled: true,
    //           offsetX: 0,
    //           style: {
    //             fontSize: '13px',
    //             color:'#ffffff',
    //             fontWeight: 900
    //           }
    //         }
    //     }
    //     }
    //   },
    //   colors: ['#33cc33','#6699ff'],
    //     dataLabels: {
    //       formatter: function(val, opt) {
    //         const goals =
    //           opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
    //             .goals
        
    //         if (goals && goals.length) {
    //           return `${val} / ${goals[0].value}`
    //         }
    //         return val
    //       },
          
    //     },
    //     legend: {
    //       show: true,
    //       showForSingleSeries: true,
    //       customLegendItems: ['Average','Nombre de Visits'],
    //       markers: {
    //         fillColors: ['#00E396', '#6699ff']
    //       }
    //     },
    //   xaxis: {
    //     categories: ListStores,
    //     borderColor: '#999',
    //     yAxisIndex: 0,
    //     tickAmount: 6,
    //     axisBorder: {
    //         show: true
    //     },
    //     axisTicks: {
    //         show: true
    //     },
    //     label: {
    //         show: true,
    //         text: 'Rally',
    //         style: {
    //           color: "#ffffff",
    //           background: '#775DD0'
    //         }
    //       }
    //   },
    //   grid: {
    //     xaxis: {
    //       lines: {
    //         show: true
    //       }
    //     }
    //   },
    //   yaxis: [{
    //     reversed: false,
    //     axisTicks: {
    //       show: true
    //     },
    //     style: {
    //         color: undefined,
    //         fontSize: '12px',
    //         fontFamily: 'Helvetica, Arial, sans-serif',
    //         fontWeight: 600,
    //         cssClass: 'apexcharts-yaxis-title',
    //     },
    //     showForNullSeries: true,
    //     // stroke: {
    //     //     curve: 'smooth',
    //     // },
    //     axisBorder: {
    //         show: true,
    //         color: '#008FFB',
    //         offsetX: 0,
    //         offsetY: 0,
    //     },
    //     axisTicks: {
    //         show: true,
    //         borderType: 'solid',
    //         color: '#008FFB',
    //         width: 6,
    //         offsetX: 0,
    //         offsetY: 0
    //     },

    //     crosshairs: {
    //         show: true,
    //         position: 'back',
    //         stroke: {
    //             color: '#b6b6b6',
    //             width: 1,
    //             dashArray: 0,
    //         },
    //     },
    //   },
    // ],
    //   markers: {

    //     discrete: [{
    //         seriesIndex: 0,
    //         dataPointIndex: 7,
    //         fillColor: '#e3e3e3',
    //         strokeColor: '#fff',
    //         size: 5,
    //         shape: "circle" // "circle" | "square" | "rect"
    //       }, {
    //         seriesIndex: 2,
    //         dataPointIndex: 11,
    //         fillColor: '#f7f4f3',
    //         strokeColor: '#eee',
    //         size: 4,
    //         shape: "circle" // "circle" | "square" | "rect"
    //       }]
    //   },
    //   };
    const areaChartOptions =  {
        chart: {
            height: 350,
            toolbar: {
                show: true
            },
            stacked: false,
        },
        tooltip: {
            theme: 'dark',
            followCursor: true,
            marker: {
                show: true,
            },
            fillSeriesColor:true
            //shared: false,
            //intersect: true,

        },
        dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
        dataLabels: {
            enabled: true
        },
        stroke: {
            //curve: 'smooth',
            width: 1
        },
        plotOptions: {
            bar: {
              columnWidth: "80%",
              horizontal: true,
            }
          },
        fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            }
          },
        grid: {
            strokeDashArray: 40
        },
        xaxis: {
            
            categories:ListStores,
            borderColor: '#999',
            yAxisIndex: 0,
            tickAmount: 6,
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            label: {
                show: true,
                text: 'Rally',
                style: {
                  color: "#fff",
                  background: '#775DD0'
                }
              }
        },
        yaxis: 
        {
            reversed:false,
            show: true,
            showForNullSeries: true,
            opposite: false,
            
            max:10,
            min:0,
            
            axisBorder: {
                show: true,
                color: '#008FFB',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#008FFB',
                width: 6,
                offsetX: 0,
                offsetY: 0
            },
    
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#b6b6b6',
                    width: 1,
                    dashArray: 0,
                },
            },
        },
        
        markers: {
    
            discrete: [{
                seriesIndex: 0,
                dataPointIndex: 7,
                fillColor: '#e3e3e3',
                strokeColor: '#fff',
                size: 5,
                shape: "circle" // "circle" | "square" | "rect"
              }, {
                seriesIndex: 2,
                dataPointIndex: 11,
                fillColor: '#f7f4f3',
                strokeColor: '#eee',
                size: 4,
                shape: "circle" // "circle" | "square" | "rect"
              }]
          },
        
    };    
        const theme = useTheme();
    
        const { primary, secondary } = theme.palette.text;
        const line = theme.palette.divider;
    
        const [options, setOptions] = useState(areaChartOptions);
        useEffect(() => {
            setOptions((prevState) => ({
                ...prevState,
                
                colors: ['#008FFB', '#F09000', '#FF1654','#0CF02A','#D998E3'],
                xaxis: {
                    labels: {
                        style: {
                            colors: [primary]
                        }
                    },
                    type: 'string',
                    categories: ListStores,
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                      opacity: 0.5
                    },
                  },
    
                
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    paddingBottom:20,
                  },
    
                title: {
                    text: 'Average Store Performance',
                    align: 'center',
                    margin: 20,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                    fontSize:  '20px',
                    fontWeight:  'bold',
                    color:  '#263238'
                    },
                  },
    
            }));
    
        }, [primary, secondary, line, theme]);
        // useEffect(() => {
        //     setOptions((prevState) => (
        //         {
        //         ...prevState,
                
        //         areaChartOptions,
               
        //           dropShadow: {
        //             enabled: true,
        //             color: '#000',
        //             top: 18,
        //             left: 7,
        //             blur: 10,
        //             opacity: 0.2
        //           },
        //         grid: {
        //             borderColor: '#e7e7e7',
        //             row: {
        //               colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //               opacity: 0.5
        //             },
        //             xaxis: {
        //                 lines: {
        //                   show: true
        //                 }
        //               }
        //           },
    
        //         tooltip: {
        //             theme: 'dark',
        //             followCursor: true,
        //             //shared: false,
        //             //intersect: true,
    
        //         },
        //         legend: {
        //             position: 'top',
        //             horizontalAlign: 'left',
        //             customLegendItems: ['Actual', 'Expected'],
        //             paddingBottom:20
        //           },
    
        //         title: {
        //             text: 'Average Performance',
        //             align: 'center',
        //             margin: 20,
        //             offsetX: 0,
        //             offsetY: 0,
        //             floating: false,
        //             style: {
        //             fontSize:  '20px',
        //             fontWeight:  'bold',
        //             color:  '#263238'
        //             },
        //           },
        //           markers: {

        //             discrete: [{
        //                 seriesIndex: 0,
        //                 dataPointIndex: 7,
        //                 fillColor: '#e3e3e3',
        //                 strokeColor: '#fff',
        //                 size: 5,
        //                 shape: "circle" // "circle" | "square" | "rect"
        //               }, {
        //                 seriesIndex: 2,
        //                 dataPointIndex: 11,
        //                 fillColor: '#f7f4f3',
        //                 strokeColor: '#eee',
        //                 size: 4,
        //                 shape: "circle" // "circle" | "square" | "rect"
        //               }]
        //           },
                  
    
        //     }));
    
        // }, []);
    

    //console.log(stores)
   
    const [series] = useState([
        {
            name: 'Average',
            
            data: ListData,
        },
        {
            name: 'Nombre de Visits',
            
            data: ListVisit,
            
        },
     
        // {
        //     name: 'Nombre de commandes',
        //     data: nbCommandes,
        //     type: 'bar',
        // },
        // {
        //     name: 'Total Display',
        //     data: totalDisplay,
        //     type: 'bar',
        // },
        
    ]);
    return (
        <Card >
            <CardBody>
                <CardTitle className="mb-4">Taux de ruptures par store</CardTitle>
                
                {data?.length>0 &&  <ReactApexChart height={'500'} options={options} series={series} type='bar' /> }
            </CardBody>
        </Card>
    )
}

export default SurveyAVGStore