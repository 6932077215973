import { api } from "helpers/consts";

/**
 * @returns Mission
 */
export const ajoutermission = async (mission) => {
    const res = await api.post(`/mission/AjoutMission`, mission);
    return res
}
/**
 * @returns Mission list
 */
 export const getMission = async (userId) => {
    if(userId!=undefined)
   {const res = await api.get(`/mission/${userId}`);
    return res.data.data
}
return {mission:[]}
}