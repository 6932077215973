/*global google*/

// import {
//     Box,
//     Button,
//     ButtonGroup,
//     Flex,
//     HStack,
//     IconButton,
//     Input,
//     SkeletonText,
//     Text,
//   } from '@chakra-ui/react'
//   import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import { marker } from "leaflet"
import person from "../../../assets/images/personMap.png"
import motocycle from "../../../assets/images/motcycle.png"
import storeMarker from "../../../assets/images/store-marker-v2.png"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  useGoogleMap,
  MarkerClusterer,
  MarkerClustererF,
  LoadScript,
  InfoWindow
} from "@react-google-maps/api"
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import LoadingComponent from "shared/LoadingComponent"
import { Alert, Backdrop, Button, CircularProgress, Slider, TextField } from "@mui/material"
import MapControl from "shared/MapControl"
import { createPortal } from "react-dom"
import { Column } from "react-virtualized-reactv17"
import StopIcon from "@mui/icons-material/Stop"
import { Image } from "@mui/icons-material"
import dayjs from "dayjs"

const Libraries = ["places"]
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
const MapsHistory = props => {
  const { positionData,  isLoaded,locationStores } = props

  // const [map, setMap] = useState(null)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState("")
  const [duration, setDuration] = useState("")
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [markersData, setMarkersData] = useState([])
  const [routesData, setRoutesData] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [error, setError] = useState()

  const center = useMemo(() => ({ lat: latitude, lng: longitude }), [longitude])
  const storesLocations = useMemo(() => (locationStores), [locationStores])
  const memoList= useMemo(()=> storesLocations?.length > 0 && storesLocations.map((elem)=>{
    //console.log("chççççççççççççççççç")
      return (
        <Marker
          key={elem.id}
          // label={destination?.name}
          clickable
          onClick={(e)=>storeDiscription(elem)}
          title={elem?.name}
          position={{ lat: elem?.lat, lng: elem?.lng }}
          icon={{
            url: storeMarker,
            anchor: new google.maps.Point(40, 40),
            scaledSize: new google.maps.Size(55, 55),
          }}
        >
          
        </Marker>
      )
    }),[storesLocations])

  const memoDirection= useMemo(()=> directionsResponse?.length > 0 &&
  directionsResponse?.map((direction, index) => {
    //console.log("555555555555555")
    return (
      <DirectionsRenderer
        key={index}
        directions={direction}
        options={{ preserveViewport: true }}
      />
    )
  }),[directionsResponse])
  // const center = {
  //     lat: -3.745,
  //     lng: -38.523,
  //   }
  //     const centerFix = {
  //       lat: 44.331429,
  //       lng: -83.045753
  //   }
  //   const {mapTypes}=useGoogleMap()
  const [libraries] = useState(["places"])

  var list = []
  var directionRoutes = []
  // const onLoad=useCallback((map)=>addMarkers(map),[markersData])
  // if (!isLoaded) {
  //   return <LoadingComponent />
  // }
  // const onLoad = useCallback(function callback(map) {
  //     // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //     const bounds = new google.maps.LatLngBounds(center);
  //     map.fitBounds(bounds);

  //     setMap(map)
  //     const markers=markersData.map((pos)=>{
  //         console.log("marker",pos.lat,pos.lng)
  //         const marker=new google.maps.Marker({
  //             position:new google.maps.LatLng(pos.lat,pos.lng)})
  //         return marker;
  //     })

  //   }, [markersData])
  // async function calculateRoute(lat, lng) {
  //   //   if (originRef.current.value === '' || destiantionRef.current.value === '') {
  //   //     return
  //   //   }
  //   // eslint-disable-next-line no-undef

  //   const directionsService = new google.maps.DirectionsService()

  //   const results = await directionsService.route({
  //     optimizeWaypoints: true,
  //     origin: { lat: lat, lng: lng },
  //     //waypoints:[{location:'FP2V+XX8 Nabeul‎ Tunisia'}],
  //     destination: { lat: destination?.lat, lng: destination?.lng },
  //     travelMode: google.maps.TravelMode.TRANSIT,
  //     unitSystem: google.maps.UnitSystem.METRIC,
  //   })
  //   setDirectionsResponse(results)
  //   console.log("data_____________", results.routes)
  //   animateMarkerTo(
  //     markerRef.current.marker,
  //     results.routes[0].overview_path[0]
  //   )
  //   // setDistance(results.routes[0].legs[0].distance.text)
  //   // setDuration(results.routes[0].legs[0].duration.text)
  // }

  async function calculateRouteForArray(lat, lng, destination,prevDate,nextDate) {
    //   if (originRef.current.value === '' || destiantionRef.current.value === '') {
    //     return
    //   }
    // eslint-disable-next-line no-undef

    let directionsService = new google.maps.DirectionsService()

    let results = await directionsService.route({
      optimizeWaypoints: true,
      origin: { lat: lat, lng: lng },
      //waypoints:[{location:'FP2V+XX8 Nabeul‎ Tunisia'}],
      destination: { lat: destination?.lat, lng: destination?.lng },
      travelMode: google.maps.TravelMode.TRANSIT,
      unitSystem: google.maps.UnitSystem.METRIC,
    }).catch((err)=>{
      // alert('failed')
      setError('failed')
      return;
    })

    
    //setDirectionsResponse(results)
    list.push(results)
    let diff=(dayjs(nextDate).diff(dayjs(prevDate))/1000)/(results?.routes[0]?.overview_path?.length-1)
    results?.routes[0]?.overview_path?.map((pos,index) => {
      
      //console.log("diff_____________", diff,results?.routes[0]?.overview_path?.length-1)
      if(index===0){
        directionRoutes.push({pos:pos,date:prevDate})
      }else if(index<results?.routes[0]?.overview_path?.length-1){
        directionRoutes.push({pos:pos,date:dayjs(prevDate).add(diff*index,'seconds').format('YYYY-MM-DD HH:mm:ss')})
      }
      
    })
    //console.log("points_____________", results?.routes[0]?.overview_path?.length)
    // animateMarkerTo(markerRef.current.marker, results.routes[0].overview_path[0])
    // setDistance(results.routes[0].legs[0].distance.text)
    // setDuration(results.routes[0].legs[0].duration.text)
  }
  function clearRoute() {
    setDirectionsResponse(null)
    setDistance("")
    setDuration("")
  }

  useEffect(()=>{
    setHistoryData(positionData)
  },[positionData])

  useEffect(() => {
    if(historyData?.length>0){
    //   console.log("thuffick_____________",
    //   historyData[historyData?.length - 1].lat,
    //   historyData[historyData?.length - 1].lng
    // )
    setLatitude(parseFloat(historyData[historyData?.length - 1].lat))
    setLongitude(parseFloat(historyData[historyData?.length - 1].lng))
    setMarkersData(historyData)}
  }, [historyData])

  useEffect(() => {
    if (longitude > 0&& historyData?.length>0) {
      setTimeout(async () => {
        //calculateRoute(positionData[0].lat, positionData[0].lng)

        for (let i = 0; i < historyData.length; i++) {
          if(error){
            return;
          }
          if (i < historyData.length - 1) {
            
            // console.log(
            //   "direction____________________",
            //   historyData[i]?.lat,
            //   historyData[i]?.lng,
            //   { lat: historyData[i + 1]?.lat, lng: historyData[i + 1]?.lng }
            // )
            await calculateRouteForArray(
              historyData[i].lat,
              historyData[i].lng,
              { lat: historyData[i + 1].lat, lng: historyData[i + 1].lng },historyData[i].date,historyData[i+1].date
            )
            if(error){
              return;
            }
          }
        }
        // console.log(
        //   "direction____________________",
        //   {pos:new google.maps.LatLng(historyData[historyData?.length-1].lat, historyData[historyData?.length-1].lng),date:historyData[historyData?.length-1].date}
        // )
        directionRoutes.push({pos:new google.maps.LatLng(historyData[historyData?.length-1].lat, historyData[historyData?.length-1].lng),date:historyData[historyData?.length-1].date})
        setDirectionsResponse(list)
        setRoutesData([...new Set(directionRoutes)])
        // console.log(
        //   "length___________",
        //   [...new Set(directionRoutes)].length,
        //   directionRoutes[2]?.pos,directionRoutes[3]?.pos
        // )
      }, 500)
    }
  }, [historyData,longitude])
  // const NEW_ZEALAND_BOUNDS = {
  //   north: -34.36,
  //   south: -47.35,
  //   west: 166.28,
  //   east: -175.81,
  // };

  //animation
  function animateMarkerTo(marker, newPosition) {
    //console.log("animate____________", newPosition)
    var options = {
      duration: 250,
      easing: function (x, t, b, c, d) {
        // jquery animation: swing (easeOutQuad)
        return -c * (t /= d) * (t - 2) + b
      },
    }

    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.requestAnimationFrame ||
      window.requestAnimationFrame ||
      window.requestAnimationFrame
    window.cancelAnimationFrame =
      window.cancelAnimationFrame || window.cancelAnimationFrame

    // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    marker.AT_startPosition_lat = marker.getPosition().lat()
    marker.AT_startPosition_lng = marker.getPosition().lng()
    var newPosition_lat = newPosition.lat()
    var newPosition_lng = newPosition.lng()

    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
      if (newPosition_lng > marker.AT_startPosition_lng) {
        newPosition_lng -= 360
      } else {
        newPosition_lng += 360
      }
    }

    var animateStep = function (marker, startTime) {
      var ellapsedTime = new Date().getTime() - startTime
      var durationRatio = ellapsedTime / options.duration // 0 - 1
      var easingDurationRatio = options.easing(
        durationRatio,
        ellapsedTime,
        0,
        1,
        options.duration
      )

      if (durationRatio < 1) {
        marker.setPosition({
          lat:
            marker.AT_startPosition_lat +
            (newPosition_lat - marker.AT_startPosition_lat) *
              easingDurationRatio,
          lng:
            marker.AT_startPosition_lng +
            (newPosition_lng - marker.AT_startPosition_lng) *
              easingDurationRatio,
        })

        // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
        if (window.requestAnimationFrame) {
          marker.AT_animationHandler = window.requestAnimationFrame(
            function () {
              animateStep(marker, startTime)
            }
          )
        } else {
          marker.AT_animationHandler = setTimeout(function () {
            animateStep(marker, startTime)
          }, 17)
        }
      } else {
        marker.setPosition(newPosition)
      }
    }

    // stop possibly running animation
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.AT_animationHandler)
    } else {
      clearTimeout(marker.AT_animationHandler)
    }

    animateStep(marker, new Date().getTime())
  }

  const [value, setValue] = useState()
  const markerRef = React.useRef(null)
  const mapRef = React.useRef(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new google.maps.LatLngBounds(center)
  //   map.fitBounds(bounds)
  //   mapRef.current = map
  // }, [])

  const onClick = React.useCallback(event => {
    animateMarkerTo(markerRef.current.marker, event.latLng)
    //console.log("position_______________", event)
    setValue(event.latLng)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    mapRef.current = null
  }, [])

  // function valueLabelFormat(value) {
  //   console.log("value_______________",value)

  //   return value;
  // }

  const [ticking, setTicking] = useState(false),
    [count, setCount] = useState(0),
    [motion, setMotion] = useState(300),
    [step, setStep] = useState({count:0}),
    [info, setInfo] = useState(false),
    [descriptionData, setDescriptionData] = useState({})
    const tepValue=useMemo(()=>step.count,[step])

  useEffect(() => {
    if (ticking) {
      //console.log("hey_________________", count)
      if (count < routesData.length) {
        setStep({...step,count:count})
        animateMarkerTo(markerRef.current.marker, routesData[count].pos)
      } else {
        setTicking(false)
      }
      const timer = setTimeout(
        () => ticking && setCount(count => count + 1),
        motion
      )
      return () => clearTimeout(timer)
    }
  }, [count, ticking])

function storeDiscription(params) {
  setDescriptionData(params)
  setInfo(true)
}
  
  return (
    <div>
      {/* Google Map Box */}
      {!isLoaded && <LoadingComponent />}
      {/* <Button title='aaaa' onClick={calculateRoute}/> */}
      <Fragment>
        {longitude > 0 && latitude > 0 && isLoaded && (
          <GoogleMap
            //tilt= {0}

            heading={0}
            center={center}
            zoom={18}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            //   ref={map}
            options={{
              //restriction:{strictBounds:true,latLngBounds:NEW_ZEALAND_BOUNDS},
              zoomControl: true,
              streetViewControl: true,
              mapTypeControl: true,
              fullscreenControl: true,
              disableDefaultUI: false,
            }}
            // children={() => <Button>ggggg</Button>}
            //onClick={onClick}
            //   onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* <Autocomplete>
                    <input
                        type="text"
                        placeholder="Input"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-120px",
                            top: "2%"
                        }}
                    />
                </Autocomplete> */}
            {routesData.length > 0 && (
              <Marker
                ref={markerRef}
                position={routesData[0].pos}
                icon={{
                  url: motocycle,
                  anchor: new google.maps.Point(40, 40),
                  scaledSize: new google.maps.Size(70, 70),
                }}
              />
            )}

            {markersData?.length > 0 &&
              markersData.map(pos => {
                return (
                  <Marker
                    title={pos.date}
                    animation={google.maps.Animation.DROP}
                    key={pos.id}
                    position={{ lat: pos.lat, lng: pos.lng }}
                    icon={{
                      url: person,
                      anchor: new google.maps.Point(32, 32),
                      scaledSize: new google.maps.Size(50, 50),
                    }}
                  />
                )
              })}
            {memoList}
            {info&& descriptionData &&
              <InfoWindow
                    position={{ lat: descriptionData?.lat, lng: descriptionData?.lng }}
                    onCloseClick={()=>setInfo(false)}
                  >
                    <div   style={{padding:2,width:180}}>
                    <img
                      src={`${URL+descriptionData?.path}`}
                      style={{width:180,height:80,marginTop:5,marginBottom:5}}
                      alt={descriptionData?.name}
                      loading="lazy"
                    />
                    <Row>
                    <Label style={{fontSize:13,}}>{descriptionData?.name.toUpperCase()} </Label>
                          <p>{descriptionData?.address} 
                          <br/>{descriptionData?.governorate}
                          <br/>{descriptionData?.postal_code}
                          </p>
                           
                            </Row>

                    </div>
                  </InfoWindow>
            }
            {memoDirection}
            {(directionsResponse?.length > 0 || error) && routesData?.length > 0 ? (
              <div
                style={{
                  boxSizing: `border-box`,
                  backgroundColor: "AppWorkspace",
                  border: `1px solid transparent`,
                  width: `90%`,
                  height: `38px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.5)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "24%",
                  marginLeft: "-20%",
                  top: "92%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    justifySelf: "center",
                  }}
                >
                  {/* <input
                    type="number"
                    placeholder="Input"
                    value={motion}
                    step={100}
                    // onChange={(e)=>setMotion(motion=>motion+)}
                    style={{
                      backgroundColor: "#F2F2F2",
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `100px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `10px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      alignSelf: "center",
                    }}
                  /> */}
                  <Button
                    variant="text"
                    size="medium"
                    onClick={() => {
                      setCount(0)
                      setTicking(!ticking)
                    }}
                    style={{ alignSelf: "center", marginRight: 5 }}
                    startIcon={
                      ticking ? (
                        <StopIcon style={{ fontSize: 25 }} />
                      ) : (
                        <PlayArrowIcon style={{ fontSize: 25 }} />
                      )
                    }
                  ></Button>
                  <Slider
                    style={{ alignSelf: "center" }}
                    //color="secondary"
                    //scale={value => 1}
                  
                    step={1}
                    size="medium"
                    about="ttt"
                    // getAriaLabel={(value)=>value+'hey'}
                    value={tepValue}
                    
                    onChange={(e, v) => {
                      //console.log("value________________", v)
                      setStep({...step,count:v})
                      animateMarkerTo(markerRef.current.marker, routesData[v].pos)
                    }}
                    // value={[1,2,3]}
                    // marks={(value)=>'value: '+value}
                    marks
                    max={routesData.length - 1}
                    min={0}
                    valueLabelDisplay="auto"
                    //getAriaValueText={(value,index)=>'hey'}
                    valueLabelFormat={(value,index)=>routesData[value].date}
                  />
                </div>
              </div>
            ) : (
              <Backdrop
                sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
                open={true}
                
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {/* {value&&<Marker
      ref={markerRef}
        icon={{
          url: motocycle,
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(70, 70),
        }}
        position={center}
      />} */}
            {/* <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
      <div>That was easy</div>
    </MapControl> */}
          </GoogleMap>
        )}
      </Fragment>
    </div>
  )

  // function addMarkers(map) {

  //     const markers= markersData.map((pos)=>{
  //         console.log("marker",pos.lat,pos.lng)
  //         const marker=new google.maps.Marker({
  //             position:new google.maps.LatLng(pos.lat,pos.lng)})
  //         return marker;
  //     })

  //   }
}

//      function loader() {
//     const { isLoaded } = useJsApiLoader({
//   googleMapsApiKey: 'AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8',
//   libraries: ['places'],
// })
//     return isLoaded
//   }
export default memo(MapsHistory)
