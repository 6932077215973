import * as React from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, StepButton } from '@mui/material';
import { useState } from 'react';
import SelectStore from './SelectStore';
import SelectProduct from './SelectProduct';
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import StorefrontSharpIcon from '@mui/icons-material/StorefrontSharp';
import LocalMallSharpIcon from '@mui/icons-material/LocalMallSharp';
import PromotionManagement from './PromotionManagement';
import PromotionForm from './PromotionForm';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import { getStoresAsync } from 'store/pos/actions';
import { getStoreGroupsAsync } from 'store/storeGroup/actions';
////stepper parameters



const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <LocalMallSharpIcon />,
        2: <StorefrontSharpIcon />,
        3: <ProductionQuantityLimitsSharpIcon />,
        4: <AddAlertIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Select Products', 'Select Stores','Promotion Management', 'Create Promotion'];


////

function CreatePromotion({setOpen}) {
    const [chosenStores, setChosenStores] = useState([])
    const [chosenProducts, setChosenProducts] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const dispatch = useDispatch();

    const handleStep = (step) => () => {
        setActiveStep(step);

    };
    const getProducts = async () => {
        return dispatch(await getProductsAsync()).payload.products
        
    }


    const getStoreGroups = async () => {
        return dispatch(await getStoreGroupsAsync()).payload.storeGroups
    }
    // useEffect(() => {
    //     //console.log(chosenProducts)
    // }, [chosenProducts])
    const products =useQuery('promotions_products', getProducts,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
      const storesGrp =useQuery('promotions_stores_groups', getStoreGroups,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
      //console.log("cashe---------------",storesGrp.isLoading,storesGrp.isFetching,storesGrp.data?.length)

      
    return (
        <div>


            <Stack sx={{ width: '100%' }} spacing={4} >

                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]} >
                            <StepLabel style={{ cursor: 'pointer' }} StepIconComponent={ColorlibStepIcon} onClick={handleStep(index)}>{label}</StepLabel>

                        </Step>
                    ))}
                </Stepper>
                <hr />
                {!products.isLoading &&<Box sx={{ height: '100%', }}>
                    {activeStep === 0 && <SelectProduct
                        dataProducts={products?.data}
                        chosenProducts={chosenProducts}
                        setChosenProducts={setChosenProducts}
                    />

                    }
                    {!storesGrp.isLoading && activeStep === 1 && <SelectStore
                        dataStores={storesGrp?.data}
                        chosenStores={chosenStores}
                        setChosenStores={setChosenStores}
                    />

                    }

                    {activeStep === 2 && <PromotionManagement 
                    chosenProducts={chosenProducts}
                    setChosenProducts={setChosenProducts}
                    chosenStores={chosenStores}
                    setChosenStores={setChosenStores}
                    />

                    }

                    {activeStep === 3 && <PromotionForm 
                    chosenProducts={chosenProducts}

                    chosenStores={chosenStores}

                    setOpen={setOpen}

                    />

                    }   

                </Box>}
            </Stack>
        </div>
    )
}

export default CreatePromotion