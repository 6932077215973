import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Row, Col, Card, CardBody, CardTitle, Button, UncontrolledTooltip } from "reactstrap"


function listData(props) {
    //console.log("f33333333333333333333333333333333333")
    const {setProductData,products}=props;
    return (
        <Tbody>
        {products?.map((product, index) => {
            //console.log("f33333333333333333333333333333333333")
            return (
                <Tr key={index}>
                    <Td>
                        <img
                            src={product?.path}
                            style={{ width: "4rem", height: "4rem" }}
                        />
                    </Td>
                    <Td>
                        <p style={{ marginTop: "1.5rem" }}> {product?.label} </p>
                    </Td>
                    <Td>
                        <p style={{ marginTop: "1.5rem" }}> {product?.brand?.name} </p>
                    </Td>
                    <Td>
                        <p style={{ marginTop: "1.5rem" }}> {product?.category?.name} </p>
                    </Td>
                    <Td>
                        <p style={{ marginTop: "1.5rem" }}> {product?.typology} </p>
                    </Td>
                    <Td>
                        <Row>
                            <Col xl="3" lg="3">
                            <Link to="#">
                                <i
                                    onClick={() => { setProductData(product,"update") }}
                                    className="bx bx-edit-alt"
                                    style={{ fontSize: "1.3rem", cursor: "pointer", float: "left", marginTop: "1.5rem" }}
                                    id="edittooltip"
                                />
                                {/* <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip> */}
                            </Link>
                                {/* <UpdateProduct
                                    prod={product}
                                    getProducts={getProducts}
                                /> */}
                            </Col>
                            <Col xl="3" lg="3">
                            <Link to="#">
                                <i
                                    id="detailtooltip"
                                    className="mdi mdi-eye-minus-outline"
                                    style={{ fontSize: "1.3rem", cursor: "pointer", marginRight: "1rem", float: "left", marginTop: "1.2rem" }}
                                    onClick={() => { setProductData(product,"detail") }}
                                />
                                {/* <UncontrolledTooltip placement="top" target="detailtooltip">
                                    Detail
                                </UncontrolledTooltip> */}
                            </Link>
                            </Col>
                            {/* <Col xl="3" lg="3">
                                <div style={{ marginTop: "1.2rem" }}>
                                    <Gallery
                                        pictures={product?.productPictures}
                                    />
                                </div>
                            </Col> */}
                        </Row>
                    </Td>
                </Tr>
            )
        })}
    </Tbody>
      )
}

export default listData

