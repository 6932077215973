//import { getBrands} from "./services";
import { getStocks} from "./services";
export const GET_BRANDS = "GET_BRANDS";
// /**
//  * 
//  * @returns Brand list
//  */
// export const getBrandsAsync = async () => {
//   try {
//     const res = await getBrands();
//     return {
//       type: GET_BRANDS,
//       payload: { brands: res },
//     }
//   } catch (error) {
//     return error
//   }
// }

export const GET_STOCKS = "GET_STOCKS"
/**
 * 
 * @returns Stocks list
 */
 export const getStocksAsync = async () => {
  try {
    const res = await getStocks();
    return {
      type: GET_STOCKS,
      payload: { stocks: res },
    }
  } catch (error) {
    return error
  }
}

