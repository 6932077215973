import React, { useEffect, useState } from 'react'

function ExportPresentation({history,rfp}) {
    const [daysList, setDaysList] = useState([])
    const [historyList, setHistoryList] = useState([history])
    const [rfpAll, setRfpAll] = useState([rfp])
    const [stockSetting, setStockSetting] = useState({})

//console.log(historyList)
  return (
    <div>ExportPresentation...</div>
  )
}

export default ExportPresentation