import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Tbody, Thead, Tr, Th, Td } from "react-super-responsive-table"
import { Modal } from 'reactstrap';
import { getSurveysAsync } from 'store/survey/actions';

function SelectSurvey(props) {

    const { storeId, visitId,reportsModal,tog_reports } = props
    const dispatch = useDispatch();
    const [modal_standard, setmodal_standard] = useState(false)
    const [surveys, setSurveys] = useState([])
    
    const getSurveys = async () => {
        setSurveys(dispatch(await getSurveysAsync()).payload.surveys)
        // setSurveys([
        //     {
        //       id: 0,
        //       name: "first survey",
        //       questionCategories: [
        //         {
        //           id: 0,
        //           name: "category 1",
        //           questionSubCategories: [{ id: 0, name: "subCategory 1",questions:[
        //               {
        //                   id: 0, 
        //                   name: "question 1",
        //                   coef:3,
        //                   required:true,
        //                   images:true,
        //                   imagesRequired:true
        //               }
        //           ] }],
        //         },
        //       ],
        //     },
        //   ])
    }

    const tog_standard = () => {
        setmodal_standard(!modal_standard)
    }

    useEffect(async () => {
        if(reportsModal){
           
            await getSurveys()
        }
        
    }, [reportsModal]);

    return (
        <>
            {/* <Link style={{ marginLeft: "1.5rem", color: "black" }} to="#" onClick={() => { tog_standard() }}>
                <i className="bx bx-edit text-success me-1" />
                Questionnaires
            </Link> */}
            <Modal
                isOpen={reportsModal}
                toggle={() => { tog_reports() }}
                size='md'
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="reportsModal">
                        Questionnaires
                    </h5>
                    <button
                        type="button"
                        onClick={() => tog_reports()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                        <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                        >
                            <Table
                                id="mydatatable"
                                className="table table-striped table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Questionnaire</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {surveys.length>0&&surveys.map((survey, index) => (
                                        <Tr key={index}>
                                            <Td> {survey.name} </Td>
                                            <Td>
                                                <Link to={"surveyResponse/" + survey.id + "/" + storeId + "/" + visitId} target="_blank">selectionner ...</Link>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default SelectSurvey;