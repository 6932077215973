import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { Row, Card, CardBody, CardTitle, Col } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersAsync } from 'store/order/actions';
import OrderDetail from './OrderDetail';
import { Oval } from  'react-loader-spinner'
import { Box, IconButton, TablePagination, TextField } from '@mui/material';
import { Table,AutoSizer, Column } from 'react-virtualized-reactv17';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import LoadingComponent from 'shared/LoadingComponent';

//import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import moment from 'moment';

const DataTableOrderList = ({data}) => {

    const dispatch = useDispatch();
    const [orders, setOrders] = useState([])
    const [loader, setLoder] = useState(true)
    //const [dataOrders, setDataOrders] = useState([])
    const dataOrders=useSelector(state=>state.Orders.orders)
    // const getOrders = async () => {
    //   setOrders(dispatch(await getOrdersAsync()).payload.orders);
    //   setLoder(false)
    //   ///setDataOrders(dispatch(await getOrdersAsync()).payload.orders);
        
        
    //     //console.log(orders)
    // }
    // const getList = async () => {
    //     await getOrders()
    // }

    useEffect( () => {
      if(data?.length>0){
        setRowsPerPage(data?.length)
        setOrders([...data])}
    }, [data])

    const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

    const handleSearch = (search) => {
        //setCancelB(false)
        //console.log("search_______________________",dataOrders.length)
        let chekDate
      if(moment(search,moment.ISO_8601,true).isValid()){
        //console.log("hey_______________________",)
        chekDate=new Date(search).toISOString().substring(0,10)
      }
        if(search){
        const list=dataOrders.filter((element)=>{
          
            
            return (
              (element?.code?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
              || (element?.user?.first_name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
              || (element?.store?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
              || (element?.amount?.toString().includes(search.toString().toLowerCase(search)) )
              || (chekDate &&( new Date(element?.date).toISOString().substring(0,10)===new Date(search).toISOString().substring(0,10) ))

    )
          
        })
        setOrders(orders=>[...list])
        setRowsPerPage(list.length)
        
      }else{
        setOrders(orders=>[...dataOrders])
        setRowsPerPage(dataOrders.length)
      }
      };
      function filterData(params) {
        //console.log('list_____________________',params)
        if(!filter){
          setFilter(filter=> true)
        }
        if(params==="code" || params==="code"){
          
          if(desc){
            const list=orders.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
            setDesc(desc=> !desc)
            setOrders(orders => [...list])
          }else{
            const list=orders.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
            setDesc(desc=> !desc)
            setOrders(orders => [...list])
          }
        }else{
          if(params==="store"){
            if(desc){
              const list=orders.sort((a,b)=> (a.store.name.toUpperCase()>b.store.name.toUpperCase())-(a.store.name.toUpperCase()<b.store.name.toUpperCase()))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }else{
              const list=orders.sort((a,b)=> (a.store.name.toUpperCase()<b.store.name.toUpperCase())-(a.store.name.toUpperCase()>b.store.name.toUpperCase()))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }
          }else if(params==="user"){
            if(desc){
              const list=orders.sort((a,b)=> (a.user.first_name.toUpperCase()>b.user.first_name.toUpperCase())-(a.user.first_name.toUpperCase()<b.user.first_name.toUpperCase()))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }else{
              const list=orders.sort((a,b)=> (a.user.first_name.toUpperCase()<b.user.first_name.toUpperCase())-(a.user.first_name.toUpperCase()>b.user.first_name.toUpperCase()))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }
          }else if(params==="amount"){
            if(desc){
              const list=orders.sort((a,b)=> (a.amount>b.amount)-(a.amount<b.amount))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }else{
              const list=orders.sort((a,b)=> (a.amount<b.amount)-(a.amount>b.amount))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }
          }else if(params==="id"){
            if(desc){
              const list=orders.sort((a,b)=> (a.id>b.id)-(a.id<b.id))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }else{
              const list=orders.sort((a,b)=> (a.id<b.id)-(a.id>b.id))
              setDesc(desc=> !desc)
              setOrders(orders => [...list])
            }
          }else if(params==="date"){
            if(desc){
                const list=orders.sort((a,b)=> (new Date(a?.date)>new Date(b?.date))-(new Date(a?.date)<new Date(b?.date)))
                setDesc(desc=> !desc)
                setOrders(orders => [...list])
              }else{
                const list=orders.sort((a,b)=> (new Date(a?.date)<new Date(b?.date))-(new Date(a?.date)>new Date(b?.date)))
                setDesc(desc=> !desc)
                setOrders(orders => [...list])
              }
          }
        }
        
      }

      const headerRenderer = ({
        columnData,
        dataKey,
        disableSort,
        label,
        sortBy,
        sortDirection
      }) => (
        <div className='filter' onClick={()=>(dataKey!='path') &&(dataKey!='Action')&&filterData(dataKey)}> 
            {(dataKey!='path') &&(dataKey!='Action')&&filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} onClick={()=>filterData(dataKey)}></i>)}
              {(dataKey==='path') ||(dataKey==='Action')? label:dataKey}
             </div>    );
      const headerRowRenderer = ({
        className,
        columns,
        style
      }) => (
        <div
        className={className+' text-light rounded h-90 '}
          role='row'
          style={{...style,backgroundColor:'#4F6973'}}
        >
          {columns} 
        </div>
      )
      const [rowsPerPage, setRowsPerPage] = React.useState(-1);
      const [page, setPage] = React.useState(0);
      
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
      };

    return (
         <>
            <TextField placeholder='search...YYYY/MM/DD' style={{padding:1,marginBottom:10,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>

            {orders.length>0 &&<>
            <div className="table-rep-plugin" style={{ marginTop: "1rem",marginBottom: "2rem",height:700 }}>
                                    
                                    <Box sx={{ height: 650, width: '100%' }}>
                                        <AutoSizer>
                                        {({height, width}) => (
                                        <>
                                        <Table
                                        gridStyle={{
                                        direction: 'inherit'
                                        }}
                                        width={width}
                                        headerRowRenderer={headerRowRenderer}
                                        height={height}
                                        headerHeight={40}
                                        rowHeight={60}
                                        rowCount={orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
                                        rowGetter={({index}) => orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
                                        >
                                        {/* <Column label="id" dataKey="id"   width={2000} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6><><HorizontalSplitSharpIcon fontSize='small' /> {cellData}</></h6>}/> */}
                        
                                        <Column label="Code" dataKey="code"   width={2000} headerRenderer={headerRenderer}   cellRenderer={({cellData}) =>  <h6><><HorizontalSplitSharpIcon fontSize='small' />{cellData}</></h6>}/>
                                        <Column label="Store" dataKey="store"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData?.name}</h6>}/>
                                        <Column label="Total Amont" dataKey="amount"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
                                        <Column label="Delivery Date" dataKey="date"   width={width}  headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6>{new Date(cellData).toUTCString().slice(0, 16)}</h6>}/>
                                        <Column label="User" dataKey="user"   width={width}   headerRenderer={headerRenderer}  cellRenderer={({cellData}) =>  <h6 >{cellData?.first_name+' '+cellData?.last_name}</h6>}/>
                        
                                        <Column label="Actions"    width={width}  dataKey="Action"  headerRenderer={headerRenderer}  cellRenderer={({cellData,rowData}) =>  
                        
                                        <div>
                                        <OrderDetail
                                        order={rowData}
                                        />
                                        </div>
                                        }/> 
                        
                        
                                        </Table>
                                      
                                        
                                        
                                        </>
                                        )} 
                        
                                        </AutoSizer>  
                                    </Box>
                                    
                            </div>
                            <TablePagination
                                        component="div"
                                        //style={{width:1100}}
                                        rowsPerPageOptions={[10, 50,100, { label: 'All', value: orders.length }]}
                                        colSpan={3}
                                        count={orders.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        //ActionsComponent={TablePaginationActions}
                                        />
                                        </>}
                            </>
    )
}



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default DataTableOrderList;