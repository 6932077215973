import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useDispatch } from "react-redux";
import { getStoresAsync } from "store/pos/actions";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UpdateModal from "./components/UpdateModal";
import $ from 'jquery';
import CreateModal from "./components/CreateModal";
import DetailModal from "./components/DetailModal";
import StoreHistory from "./components/history/History"
import Gallery from "shared/Gallery";
import { getStoreGroupsAsync } from "store/storeGroup/actions";
import { Oval } from  'react-loader-spinner'
import DataTable from './components/dataTable';
import LoadingComponent from "shared/LoadingComponent";
import { useQuery } from "react-query";

export default function PosList() {

    const dispatch = useDispatch();
    const [stores, setStores] = useState([])
    const [storeGroups, setStoreGroups] = useState([])
    const [loader, setLoder] = useState(true)


    const getStores = async () => {
        return dispatch(await getStoresAsync()).payload;
    }

    const getList = async () => {
        await getStores().then((res) => {
            setStores(res.stores);
            setLoder(false)
        }).catch(() => {
            setStores([])
        })
    }

    const getStoreGroups = async () => {
        setStoreGroups(dispatch(await getStoreGroupsAsync()).payload.storeGroups)
    }

    const {data,status,isLoading,isFetching} =useQuery('stores', getStores,{
        refetchOnWindowFocus:false,
        //refetchInterval:0
         //staleTime:'infinity',
        // initialData:undefined
        //refetchOnMount:false
        cacheTime:7200000
      })
      
    //console.log("cashe---------------",{isLoading,isFetching},data?.stores?.length)

    useEffect(async () => {
        getStoreGroups()
        //await getList()
        // $('#mydatatable').DataTable()
    }, [])

    return (
        <div className="page-content">
            {isLoading && (
                <LoadingComponent />
    )}
            {!isLoading  && (
                <div>
            <MetaTags>
                <title>Store list</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Stores" breadcrumbItem="Store List" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Store List </CardTitle>
                                <CreateModal
                                    getList={getList}
                                    storeGroups={storeGroups}
                                />
                                {
                                    <div className="table-rep-plugin" style={{ marginTop: "1rem",marginBottom: "2rem" }}>
                                        <DataTable data={data?.stores} getList={getList} storeGroups={storeGroups} />
                                    </div>
                                    
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div></div>)}
        </div>
    )
}