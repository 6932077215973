import React, { useCallback, useEffect, useState, useRef } from 'react'
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getProductsAsync } from 'store/product/actions';
import { getStoresAsync } from "store/pos/actions";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Link } from 'react-router-dom';
import {
    DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridPrintExportMenuItem,
    GridSearchIcon,
} from '@mui/x-data-grid';
import { Autocomplete, Box, Chip, FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Popover, Select, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import { Row, Card, CardBody, Col, Button } from "reactstrap"
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import { Collapse } from 'reactstrap'
import StoreList from './storeList';
import ProductList from './productList';
import Swal from 'sweetalert2'

import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';

function PromotionManagement(props) {
    const { chosenStores, setChosenStores, chosenProducts, setChosenProducts } = props
    const [col, setCol] = useState(0)
   
  return (
    <div>
        {chosenStores.length > 0 && chosenProducts.length > 0 ? (
            <>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={col === 1 ? ("accordion-button fw-medium") : ("accordion-button fw-medium collapsed")}
                            type="button"
                            style={{ cursor: "pointer" }}
                            onClick={() => { if (col !== 1) { setCol(1) } else { setCol(0) } }}
                        >
                            Store list
                        </button>
                    </h2>
                    <Collapse isOpen={col === 1} className="accordion-collapse">
                        <StoreList
                            chosenStores={chosenStores}
                            setChosenStores={setChosenStores}
                        />
                    </Collapse>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={col === 2 ? ("accordion-button fw-medium") : ("accordion-button fw-medium collapsed")}
                            type="button"
                            style={{ cursor: "pointer" }}
                            onClick={() => { if (col !== 2) { setCol(2) } else { setCol(0) } }}
                        >
                            Product list
                        </button>
                    </h2>
                    <Collapse isOpen={col === 2} className="accordion-collapse">
                        <ProductList
                            chosenProducts={chosenProducts}
                            setChosenProducts={setChosenProducts}
                        />
                    </Collapse>

                </div>
            </>
        ) : (
            <div className="text-center">
                <div className="mb-4">
                    <i className="mdi mdi-alert-outline me-2 text-warning display-4" />
                </div>
                <div>
                    <h5>missing data</h5>
                    <p className="text-muted">
                        please select at least one product and one store
                    </p>
                </div>
            </div>
        )
        }
    </div>
)
}

export default PromotionManagement