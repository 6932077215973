import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import { useDispatch } from 'react-redux'
import { getStoresAsync } from 'store/pos/actions'
import { getDisplayListAsync } from 'store/display/actions'
import StockHistory from './components/StockHistory'
import NewOrder from './components/NewOrder'
import UpdateStock from './components/UpdateStock'
import StockSettings from './components/StockSettings'
import { Oval } from  'react-loader-spinner'
import MButton from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import pptxgen from "pptxgenjs";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import image from 'assets/images/layouts/Image1.jpg'
import image2 from 'assets/images/layouts/Image2.jpg'
import image3 from 'assets/images/layouts/Image3.jpg'
import dayjs from 'dayjs'
import { findStockByStore, findStockDays, findStockSetting, getStoreStocks } from 'store/stock/services'
import { Box, TablePagination, TextField } from '@mui/material'
import {Table, AutoSizer, Column } from 'react-virtualized-reactv17'
import LoadingComponent from 'shared/LoadingComponent'
import DataTableStockout from './components/dataTable'
import { useQuery } from 'react-query'

const Stockout = () => {

    const dispatch = useDispatch();
    const [stores, setStores] = useState([])
    const [storesData, setStoresData] = useState([])

    


    const {data,status,isLoading,isFetching} =useQuery('stocks', getStoreStocks,{
      refetchOnWindowFocus:false,
      //refetchInterval:0
       //staleTime:'infinity',
      // initialData:undefined
      //refetchOnMount:false
      cacheTime:7200000
    })
    
    //console.log("cashe---------------",{isLoading,isFetching},data?.length)
    

    
    return (
        <div className="page-content">

{isLoading && (

  <LoadingComponent/>
    )}
    {!isLoading && (
        <div>
            <MetaTags>
                <title>StockOut</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Stock management" breadcrumbItem="StockOut" />
                <Card>
                    <CardBody>
                        <Row>

                            <Col>
                                <CardTitle>StockOut </CardTitle>
                            </Col>
                            <Col>
                                <StockSettings/>
                            </Col>
                        </Row>
                        <DataTableStockout data={data} />
                    </CardBody>
                </Card>
            </div>
            </div>)}
        </div>
    )
}

export default Stockout
