import PropTypes from 'prop-types'
import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"
import { withTranslation } from "react-i18next"
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions"
import { Button } from 'reactstrap'
import { Backdrop, CircularProgress } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import PromotionNotification from 'components/CommonForBoth/TopbarDropdown/PromotionNotification';
import { getPromotionByDate } from 'store/promotion/services'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { getNotificationList } from 'store/notification/services'
const token = localStorage.getItem('authUser')

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Header = props => {
  const connectedUser = useSelector(state => state.User.user)
  const [limit, setLimit] = useState(4)
  const [offset, setOffset] = useState(0)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile)
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile)
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const getNotifications = async () => {
      
    return   getNotificationList(connectedUser?.id, limit, offset)   

     //return dispatch(await getPromotionByDateAsync(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'))).payload.promotion;
   }
  const getPromotions = async () => {
      
    return  getPromotionByDate(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'))
     //return dispatch(await getPromotionByDateAsync(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'))).payload.promotion;
   }
   const promotions =useQuery('promotions', getPromotions,{
    refetchOnWindowFocus:false,
    //refetchInterval:0
     //staleTime:'infinity',
    // initialData:undefined
    //refetchOnMount:false
    cacheTime:7200000,
    skip:!token,
    // enabled:token? true:false
    })
 //console.log("cashe---------------",promotions.isLoading,promotions.isFetching,promotions?.data?.length)
  // const notifications =useQuery('notifications', getNotifications,{
  //   refetchOnWindowFocus:false,
  //   //refetchInterval:0
  //    //staleTime:'infinity',
  //   // initialData:undefined
  //   //refetchOnMount:false
  //   cacheTime:7200000
  // })
  //console.log("cashe---------------",promotions?.data)
  return (
    <React.Fragment>
      <header id="page-topbar" style={{ zIndex: "1000" }}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"/>
            </button>
          </div>
          
          <div className="d-flex">
          {!promotions.isLoading && promotions?.data&&<PromotionNotification dataPromotions={promotions?.data} />}
            <LanguageDropdown />
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"/>
              </button>
            </div>
         
            
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
