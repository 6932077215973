import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col } from 'reactstrap';
import DateNumbers from './dateNumbers';
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import Progress_bar from './progress';
import { Box, gridClasses, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, TextField, MenuItem, Grid } from '@mui/material';
import {
  DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
} from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Paper from '@mui/material/Paper';
import MList from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';
import MuseumIcon from '@mui/icons-material/Museum';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import PieChart from './PieChart';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import PiechartData from './PiechartData';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import {List} from 'react-virtualized-reactv17';
import { GridRow } from '@mui/x-data-grid';
import { GridColumnHeaders } from '@mui/x-data-grid/internals';
import avatarMale from "../../../assets/images/users/male-profile-pic.jpg"

//Export
const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};
const URL =
process.env.NODE_ENV === "development"
  ? "http://localhost:3303"
  : "https://www.devtest21-api.g2m-tn.com";
const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
    <GridPrintExportMenuItem />
    <JsonExportMenuItem />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <Col>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
        </Col>
        

        <GridToolbarQuickFilter  />
  </GridToolbarContainer>
);

const dataTable = ({ data }) => {
  const [marchandizer, setMarchandizer] = useState([])
  const [loader, setLoader] = useState(true)
  const columns = [
    //{ field: 'id', headerName: 'id', width: 50 },
    { field: 'image', headerName: 'Image', width: 70, renderCell: (params) => { return (<img style={{width:50,height:50}} onError={(e)=>e.target.src=avatarMale} src={process.env.REACT_APP_API_URL+params?.row?.image}/>) } },
    { field: 'name', headerName: 'Merchandiser', width: 160, editable: false, renderCell: (params) => { return (<h6 style={{fontWeight:'bold',color:'Highlight'}} >{params?.row?.name}</h6>) } },
    { field: 'dateEntre', headerName: 'M.P Entrée (min)', align: 'center', width: 120, renderCell: (params) => { return (<DateNumbers number={params.row.dateEntre} />) } },
    { field: 'dateSortie', headerName: 'M.P Fin (min)', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.dateSortie} />) } },
    { field: 'tempPasse', headerName: 'T.M Passé (h)', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.tempPasse} />) } },
    { field: 'top', headerName: 'Top 3', width: 60, align: 'center', renderCell: (params) => { return (<MButton color='info' aria-describedby='top' onClick={handleClickTop('right', params.row.top)}>top 3</MButton>) } },
    { field: 'totalCmd', headerName: 'T.M Payé', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.totalCmd} />) } },
    { field: 'nbCmd', headerName: 'Nb Commande', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.nbCmd} />) } },
    { field: 'strCmdList', headerName: 'Magazins/commande', width: 90, renderCell: (params) => { return (<MButton aria-describedby='store' color='warning' onClick={handleClick('right', params.row.strCmdList)}>stores</MButton>) } },
    { field: 'nbrDisplay', headerName: 'T.D', align: 'center', width: 60, renderCell: (params) => { return (<DateNumbers number={params.row.nbrDisplay} />) } },
    { field: 'moyDisplay', headerName: 'M.D/Magazin', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.moyDisplay} />) } },
    { field: 'nbrVisitP', headerName: 'Nbr Visit P|E', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.nbrVisitP} />) } },
    { field: 'nbrVisitE', headerName: 'Nbr Visit E|P', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.nbrVisitE} />) } },
    {
      field: 'performance',
      headerName: 'Visite performence', align: 'center',
      width: 100,
      renderCell: (params) => { return (<Progress_bar bgcolor="orange" progress={params.row.performance} />) }
    },
    {
      field: 'taux', headerName: 'Taux Ruptures', width: 100, height: 800, align: 'center', renderCell: (params) => {
        return (

          <PieChart nbStock={params.row.nbStock} nbStockOut={params.row.nbStockOut} />
          //<PiechartData nbStock={params.row.nbStock} nbStockOut={params.row.nbStockOut} />

        )
      }
    },
    // { field: 'start', headerName: 'Start Date', align: 'center', width: 100, renderCell: (params) => { return (<MButton aria-describedby='date' color='success' onClick={handleClickDate('left', params.row.start)}>start</MButton>) } },
    // { field: 'end', headerName: 'End Date', width: 100, renderCell: (params) => { return (<MButton aria-describedby='date' color='success' onClick={handleClickDate('left', params.row.end)}>end</MButton>) } },
    // // { field: 'nbStock', headerName: 'nbStock',align:'center', width: 100,renderCell:params=><DateNumbers number={params.row.nbStock} /> }, 
    { field: 'nbStockOut', headerName: 'nbStockOut', align: 'center', width: 100, renderCell: (params) => { return (<DateNumbers number={params.row.nbStockOut} />) } },
 
  ];
  //popper stores
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [storeList, setStoreList] = useState(['']);


  const handleClick = (newPlacement, storeData) => (event) => {
    setAnchorEl(event.currentTarget);
    setStoreList(storeData)

  };

  //popper Top List
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const [storeListTop, setStoreListTop] = useState([]);

  const handleClickTop = (newPlacement, Data) => (event) => {
    setAnchorE2(event.currentTarget);
    setStoreListTop(Data)

  };

  //popper Date List
  const [anchorE3, setAnchorE3] = useState(null);
  const open3 = Boolean(anchorE3);
  const [dateList, setDateList] = useState([]);

  const handleClickDate = (newPlacement, Data) => (event) => {
    setAnchorE3(event.currentTarget);
    setDateList([...new Set(Data)])

  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
  };

  //filter Date start end
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const [cancelB, setCancelB] = useState(true);

  const handleFilter = () => {
    setMarchandizer([...data])
    setCancelB(true)
    setDateStart(dayjs())
    setDateEnd(dayjs())
  };

  const handleSearch = () => {
    setCancelB(false)
    let newList = []
    if (data.length > 0) {
      if (dateEnd !== '' && dateStart !== '') {

        data.filter((el) => {

          if ((el.start != '') && (el.end != '')) {
            let start = [...el.start]
            let end = [...el.end]

            for (let index = 0; index < start.length; index++) {
              if (start[index].slice(0, 10) >= dateStart.format('YYYY-MM-DD') && end[index].slice(0, 10) <= dateEnd.format('YYYY-MM-DD')) {

                newList.push(el)
                // console.log(el)
                break;
              }

            }

          }

          return
        })
        setMarchandizer(newList)
      }

    }
  };
  const handleChangeStart = (newValue) => {
    //console.log(newValue)
    setDateStart(newValue);
    setCancelB(false)
  };
  const handleChangeEnd = (newValue) => {

    setDateEnd(newValue);
    setCancelB(false)
  };

  //table options
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ['Merchandiser'],
  });

  const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
    setPinnedColumns(updatedPinnedColumns);
  }, []);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);


  //filter list
  const filterList = () => {
    if (storeListTop.length > 0) {
      storeListTop?.reduce((acc, shot) => acc = acc > shot.temps ? acc : "")
    }
  }

  useEffect(async () => {
    //console.log('data: ', data[0])
    //let active = true;
    setMarchandizer([...data])
    // (async () => {
    //   setLoader(true)
    //   let list = []
    //   data.map(d => {
    //     for (let index = 0; index < 500; index++) {
    //       list.push(
    //         { id: Math.random(0, 100000), name: d.name, dateEntre: d.dateEntre, dateSortie: d.dateSortie },

    //       )

    //     }
    //   })
    //   setMarchandizer([...list])
    //   setLoader(false)
    // })()
    // return () => {
    //   active = false
    // }
    setLoader(false)
  }, [])
  const list = [
    {id:0,name:'Brian Vaughn'}
    // And so on...
  ];
  function rowRenderer({
    id, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <div key={id} style={style}>
        {marchandizer[index].name}
      </div>
    );
  }
  const MemoizedRow = React.memo(GridRow);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);
  return (
    <div>
      <Box sx={{ height: 600, width: '100%' }}>
        {/* filter per Date start end */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='row m-3 d-flex justify-content-evenly'>
            <MButton css={{ backgroundColor: 'blue' }} disabled={cancelB} color='error' aria-describedby='chart' onClick={handleFilter} ><EventBusySharpIcon />&nbsp; cancel filter</MButton>

            <DatePicker
              views={['day']}
              label="Start Date"
              value={dateStart}
              inputFormat="YYYY-MM-DD"
              onChange={handleChangeStart}
              renderInput={(params) => <TextField {...params} />}
              className="col-2 "
            />
            <DatePicker
              views={['day']}
              label="End Date"
              inputFormat="YYYY-MM-DD"
              value={dateEnd}
              onChange={handleChangeEnd}
              renderInput={(params) => <TextField  {...params} />}
              className="col-2"
            />
            <MIButton className="col-2" variant="contained" onClick={handleSearch} size="large">
              <ContentPasteSearchSharpIcon /> &nbsp;Search
            </MIButton>
          </div>
        </LocalizationProvider>
        <DataGrid
          //loading={loader}
          density='comfortable'
          slots={{
            row: MemoizedRow,
            // columnHeaders: MemoizedColumnHeaders,
          }}
          columnBuffer={2} 
          columnThreshold={2}
          rows={marchandizer}
          columns={columns}
          pinnedColumns={pinnedColumns}
          getRowSpacing={getRowSpacing}
          //paginationMode="server"
          // sx={{
          //   [`& .${gridClasses.row}`]: {
          //     bgcolor: (theme) =>
          //       theme.palette.mode === 'light' ? grey[200] : grey[900],
          //   },
          // }}
          //getRowId={row => row.id}
          //onPinnedColumnsChange={handlePinnedColumnsChange}
          // initialState={{
          //   columns: {
          //     columnVisibilityModel: {
          //       // Hide columns status and traderName, the other columns will remain visible
          //       id: false,
          //       start: true,
          //       end: true,

          //     },
          //     pinnedColumns: { left: ['name'] }
          //   },
          //   filter: {
          //     filterModel: {
          //       items: [],
          //       quickFilterLogicOperator: GridLinkOperator.And,

          //     },
          //   },
          // }}
          rowHeight={50}
          // checkboxSelection
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            //LoadingOverlay: LinearProgress,
          }}
          // componentsProps={{
          //   toolbar: {
          //     //showQuickFilter: true,
          //     //quickFilterProps: { debounceMs: 100 },
          //   },
          // }}
        />
        {/* <List
    width={300}
    height={300}
    rowCount={marchandizer.length}
    rowHeight={20}
    rowRenderer={rowRenderer}
  />, */}
      </Box>
      <MetaTags>
        <title>Performance</title>
      </MetaTags>

      {/* popper  store*/}
      <Popover id='store' open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}



      >
        <Paper style={{maxHeight:400}}>
          {storeList?.map((s, index) => {
            return (
              <MList key={index}
                sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
                
                
                aria-label="contacts"
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <MuseumIcon />
                    </ListItemIcon>
                    <ListItemText primary={s} />
                  </ListItemButton>
                </ListItem>

              </MList>
            )
          })}
        </Paper>
      </Popover>


      {/* popper  top*/}

      <Popover
        id='top'
        open={open2}
        anchorEl={anchorE2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Paper style={{maxHeight:400}}>

          {
            //filter list
            filterList()
          }
          {
            //map top 3 stores
            storeListTop?.slice(0, 3).map((t, ind) => {

              //console.log("store ",ind,": ",t)
              return (
                <MList key={ind}
                  sx={{ width: '100%', maxWidth: 1000, bgcolor: 'background.paper' }}

                >
                  <ListItem disablePadding >
                    <ListItemButton css={{ backgroundColor: 'red' }}>
                      <ListItemIcon>
                        <StarIcon />
                      </ListItemIcon>
                      <ListItemText primary={t.name} secondary={t.temps + " h"} />
                      {/* <span>{t.name} {t.temps+" h"}</span> */}
                    </ListItemButton>
                  </ListItem>

                </MList>
              )
            })}
        </Paper>
      </Popover>

      {/* popper  date List*/}
      <Popover id='date'
        open={open3}
        anchorEl={anchorE3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}



      >
        <Paper>
          {dateList?.map((s, index) => {
            return (
              <MList key={index}
                sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
                aria-label="contacts"
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <EventNoteSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={s.slice(0, 10)} secondary={s.slice(11, 19)} />
                  </ListItemButton>
                </ListItem>

              </MList>
            )
          })}
        </Paper>
      </Popover>
    </div>

  )
}

export default dataTable