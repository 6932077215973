import dayjs from "dayjs";
import { api } from "helpers/consts";



/**
 * @returns Visit list by userId(merchandiser)
 */
export const getVisitByUser = async (userId, from, to) => {
    //console.log("date____________________________",from,to)
    const res = await api.get(`/visit/${userId}/${from}/${to}`);
    return res.data.data
};

/**
 * @param {[Visit]} visits array of visit 
 */
export const upsertVisit = async (visits) => {
    const res = await api.post(`/visit`, visits);
    return res
};

/**
 * @param {Integer} id visitId 
 */
export const deleteVisitById = async (id) => {
    const res = await api.delete(`/visit/${id}`);
    return res
};

/***********Nouveau */
/**
 * 
 * @returns visit list
 */
export const getVisits = async () => {
    const res = await api.get(`/visit`);
    return res.data.data
};
/**
 * 
 * @returns visit list
 */
export const getVisitDashboard = async () => {
    const res = await api.get(`/visit/dashboard`);
    return res.data.data
};
/**
 * 
 * @returns visit list
 */
export const getVisitDashboardCaching = async () => {
    
    return await api.get(`/visit/dashboard`);
};

/**
 * 
 * @returns visit list
 */
export const getVisitsPerformance = async () => {
    const res = await api.get(`/visit/performance`);
    return res.data.data
};