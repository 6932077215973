import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from "components/Common/Breadcrumb"
import LoadingComponent from 'shared/LoadingComponent'
import CreateReportSubject from './components/CreateReportSubject'
import { getReportSubjects } from 'store/reportSubject/services'
import subjectImage from "../../assets/images/reportSubject.png"

function ReportSubject() {

    const [subjects, setSubjects] = useState([])

    const getSubjects = () => {
        getReportSubjects()
            .then((data) => {
                setSubjects(data);
            })
        
    }

    useEffect(() => {
        getSubjects()
    }, [])

    return (
        <div className="page-content" >
            <MetaTags>
                <title>Objet CR</title>
            </MetaTags>
            <Container fluid >
                <Breadcrumbs title="Paramétrages" breadcrumbItem="Objet CR" />
                <CreateReportSubject
                            getSubjects={getSubjects}
                        />
                {subjects?.length > 0 &&(
                    <Row>
                        
                        {subjects.map((subject, index) => (
                            <Col key={index} xl="2" sm="6" style={{ cursor: "pointer" }}>
                                <Card style={{ color: "#556EE6", fontSize: "8rem" }} className="text-center">
                                    <CardBody style={{ height: "15rem" }}>
                                        <img
                                            style={{ width: "100%", height: "100%" }}
                                            src={subjectImage}
                                        />
                                    </CardBody>
                                    <CardFooter className="bg-transparent border-top">
                                        <h5>{subject.name}</h5>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </div>
    )
}

export default ReportSubject