import React, { useCallback, useEffect, useState,useRef } from 'react'
import { Row, Card, CardBody,Col,Button } from "reactstrap"
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';
import DataTableStore from './DataTableStore';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import DataTableStoreV2 from './DataTableStoreV2';
import DataTableStoreV3 from './DataTableStoreV3';

function DataStructure({dataProduct,dataStore,getStoreOutputs }) {

  // const [products, setProducts] = useState([...dataProduct])
  const [filtred, setFiltred] = useState(false)

  // const [stores, setStores] = useState([...dataStore])
  const [dataTable, setDataTable] = useState([])

  const [columns, setColumns] = useState([])


  const Data=[]
  //console.log("list___________________________",dataProduct.length,dataStore.length,Data.length)

  function showCellStoreGrp(params){
    let component=[]
    params.map((elems)=>{
      component.push( <li align='left'>{elems}</li> ) 
    })

    return component.length>0 ? <div>{component}</div> :<h6>not available in stock</h6>
 }

 function showCell(params,store){
    //console.log(store)
    let visit=''
    let exisit=false

    
    params.row.store.map((elems,index)=>{
    if(elems.name===store.name){
      exisit=true
      store?.lastProduct?.map(l=>{
        if(l===params.row.id){
          visit=store.lastVisit
          
        }
      })
   
    }
    })

    if(!exisit){
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='secondary' >HS\...</Button>
    }else{
      if(isEmpty(visit))
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='danger' >AB\... </Button>
      else
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='success' >PR\ {visit}</Button>
    }
 }
  function getStores(){
    //console.log("d_____________",dataStore.length)
    Data.length=0
    let listColumn=[ 
      //{ field: 'id', headerName: 'id', width: 50 },
      //{ field: 'image', headerName: 'Image', width: 100,align:'left',height:1050,editable: false,renderCell:params=><img width={90} src={params.row.image} /> },
      // { field: 'barcode', headerName: 'Barcode', width: 150,editable: true,renderCell:params=><><HorizontalSplitSharpIcon fontSize='small' />{params.row.barcode}</>},
      // //{ field: 'label', headerName: 'Label',align:'left', width: 180,renderCell:params=>params.row.label  },
      // { field: 'brand', headerName: 'Brand',editable:false,align:'left', width: 110,renderCell:params=>params.row.brand   },
      // { field: 'category', headerName: 'Category',align:'left', width: 110,renderCell:params=>params.row.category   },
      // { field: 'family', headerName: 'Family',align:'left', width: 110,renderCell:params=>params.row.family  },
      // { field: 'storeGrp', headerName: 'StoreGrp',align:'left', editable:false,width: 150,renderCell:params=>showCellStoreGrp(params.row.storeGrp)},
    
    ]
    dataStore.map((store)=>{
      listColumn.push( 
        // { field: store.name, headerName: <Button style={{width:'150px',fontSize:'16px'}} color='dark' >{store.name}</Button>,align:'left', width: 200,renderCell:(params)=>
        //   showCell(params,store)  
        //   },   
        {
          field:store.name,
          name:store.name,
          id:store.id,
          // lastProduct:store.lastProduct,
          // lastVisit:store.lastVisit
          // cell:showCell(store,store)
        }
     )
    })
    setColumns([...listColumn])
  }
  function getProducts(){
    //console.log("d_____________",dataProduct.length)
    dataProduct.map((product)=>{
      let lastVisit=''
      //console.log(product?.category)
      dataStore.map((store)=>{
        if(product?.store.length>0){
          product?.store?.map(s=>{
            if(s?.name===store.name){
              lastVisit=s?.lastVisit
              //console.log(s?.lastVisit)
            }
          })
        }
      })

      Data.push({
        id: product.id,
        image:product.image,
        barcode:product.barcode,
        label:product.label,
        lastVisit:lastVisit,
        store:product.store,
        family:product?.family,
        brand:product?.brand,
        category:product?.category,
        storeGrp:product?.storeGrp,
        typology:product.typology
      })
    })
    setDataTable([...Data])
  }
  useEffect(()=>{
       getStores();
      getProducts();
  },[dataStore])

  
   


  return (
    <div>
<Box sx={{ height: 970, width: '100%' }}>
{dataTable && <DataTableStoreV3 products={dataTable}  stores={columns} listStore={dataStore} setFiltred={setFiltred}  getStoreOutputs={getStoreOutputs} />
}
</Box>
        
      
    </div>
  )
}

export default DataStructure