import React, { useEffect, useMemo, useState } from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Modal } from 'reactstrap'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getReferencedProductsByStore } from 'store/referencedProduct/services'
import { findStockByStore, findStockDays, findStockSetting } from 'store/stock/services'
import LoadingComponent from 'shared/LoadingComponent';

const StockHistory = (props) => {

    const { store } = props
    const [modal_standard, setmodal_standard] = useState(false)
    const [rfp, setRfp] = useState([])
    const [stockHistory, setStockHistory] = useState([])
    const [stockSetting, setStockSetting] = useState({})
    const [tableDays, setTableDays] = useState([])
    const getRfp = () => {
        getReferencedProductsByStore(store.id).then((data) => {
            let list=[]
            data.forEach(element => {
                list.push(element)
               
            });
            setRfp(list)
        })
    }

    const getStockHistory = () => {
        findStockSetting().then((data) => {
            setStockSetting(data[0]);
            findStockDays(store.id, data[0].days).then((data) => {
                setTableDays(data)
            })
            findStockByStore(store.id, data[0].date).then((data) => {
                setStockHistory(data)
            })
        })
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        if (!modal_standard) {
            setRfp([])
            setStockSetting()
            setStockHistory([])
            setTableDays([])
            getStockHistory()
            getRfp()
            
        }
    }

    const memoDays=useMemo(()=>tableDays.length>0 &&tableDays.map((day, index) => {
        return (
            <Th key={index}>{new Date(day.date).toUTCString().slice(5, 16)}</Th>
        )
    }),[tableDays])

    const memoData=useMemo(()=>rfp.length>0 &&rfp.map((product, index) => {
        if (product.available || stockHistory.find((element) => element.productId === product.productId)) {
            return (
                <Tr key={index}>
                    <Td>{product.product?.internalCodes?.find((element) => element.storeGroupId === store.storeGroupId)?.code}</Td>
                    <Td>{product.product.barcode}</Td>
                    <Td>{product.product.label}</Td>
                    {tableDays.map((day, index) => {
                        if (stockSetting.stockManagement === "StockOut") {
                            if (!product.available) {
                                return (<Td key={index} style={{ backgroundColor: "grey" }} />)
                            } else {
                                let condition = stockHistory.find((element) =>
                                    element.productId === product.productId &&
                                    new Date(element.createdAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                )?.stockOut === true
                                return (<Td key={index} style={{ backgroundColor: condition && "#DE5555" }} />)
                            }
                        } else {
                            if (!product.available) {
                                return (
                                    <Td key={index} style={{ backgroundColor: condition && "grey", color: condition && "white" }}>
                                        {
                                            stockHistory.find(
                                                (element) =>
                                                    element.productId === product.productId &&
                                                    new Date(element.createdAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                            )?.quantity
                                        }
                                    </Td>
                                )
                            } else {
                                let condition = stockHistory.find((element) =>
                                    element.productId === product.productId &&
                                    new Date(element.createdAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                )?.quantity === 0
                                return (
                                    <Td key={index} style={{ backgroundColor: condition && "#DE5555", color: condition && "white" }}>
                                        {
                                            stockHistory.find(
                                                (element) =>
                                                    element.productId === product.productId &&
                                                    new Date(element.createdAt).toUTCString().slice(5, 16) === new Date(day.date).toUTCString().slice(5, 16)
                                            )?.quantity
                                        }
                                    </Td>
                                )
                            }
                        }
                    })}
                </Tr>
            )
        }
    }),[rfp,stockHistory])

    useEffect(()=>{
        if(rfp.length>0){
            $('#datatable').DataTable({
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                
            })
        }
    },[rfp])
    return (
        <>
            <button
                type="button"
                className="btn btn-outline-secondary"
                style={{marginLeft: "-0.8rem", height: "2rem", marginBottom: "1rem"}}
                onClick={() => { tog_standard() }}
            >
                Stock
            </button>
            {modal_standard&&<Modal
                isOpen={modal_standard}
                size="xl"
                toggle={() => { tog_standard() }}
                className='modal-fullscreen p-5'
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        StockOut history ({store.name})
                    </h5>
                    <button
                        type="button"
                        onClick={() => { setmodal_standard(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {rfp.length>0?<div className="modal-body">
                    <div className="table-rep-plugin" style={{ marginTop: "0rem" }}>
                        <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                            
                        >
                            <Table
                                id={"datatable"}
                                className="table table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Internal code</Th>
                                        <Th>Barcode</Th>
                                        <Th style={{ width: "25%" }}>Label</Th>
                                        {memoDays}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {memoData}
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>:<LoadingComponent/>}
            </Modal>}
        </>
    )
}
export default StockHistory