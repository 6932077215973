import React, { useCallback, useEffect, useState,useRef, useMemo } from 'react'

import { Row, Card, CardBody,Col,Button,  } from "reactstrap"
import MButton from '@mui/material/Button';
import { MetaTags } from 'react-meta-tags';
import { Autocomplete, Box, Chip, FormControl, gridClasses, InputAdornment, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Popover, Select, Stack, TableFooter, TextField, Typography } from '@mui/material';
import { DataGrid, GridLinkOperator, GridToolbar, GridToolbarContainer, GridToolbarExport ,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPrintExportMenuItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbarQuickFilter,
  GridRow,
  } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MIButton from '@mui/material/Button';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
import pptxgen from "pptxgenjs";
import { get } from 'lodash';
// import { AutoSizer ,Column,Grid,Table} from 'react-virtualized-reactv17';
import { Link } from 'react-router-dom';
import LoadingComponent from 'shared/LoadingComponent';
import TablePaginationActions from 'components/paginationMui';
import HorizontalSplitSharpIcon from '@mui/icons-material/HorizontalSplitSharp';

// import {
//   Grid,
//   Table,
//   TableHeaderRow,
//   TableFixedColumns,
//   TableSelection,
// } from '@devexpress/dx-react-grid-material-ui';import {
//   SelectionState,
//   IntegratedSelection,
// } from '@devexpress/dx-react-grid';

import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { TableVirtuoso } from 'react-virtuoso';
import {AutoSizer, Column,Table } from 'react-virtualized-reactv17';

const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";


      const MIN_TABLE_WIDTH = 200;
function DataTableStoreV2({products,stores,listStore ,setFiltred,getStoreOutputs}) {
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(()=>{
    //console.log("data___________________________",products.length,stores.length)
    //setLoader(true)
    if(products?.length>0){
    setRowsPerPage(products?.length)
    setData([...products])
    setColumns([...stores])}
    //setLoader(false)
  },[products])
  //////////////////////////////////////////////Table///////////////////////////
  
  //Export
  const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
      const row = {};
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value;
      });
      return row;
    });

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2);
  };

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = (props) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });

        exportBlob(blob, 'DataGrid_demo.json');

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
    <GridPrintExportMenuItem/>
    <JsonExportMenuItem />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <Col>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
        </Col>
        

        {/* <GridToolbarQuickFilter  /> */}
  </GridToolbarContainer>
);

  //table options
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ['label'],
});

const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
    setPinnedColumns(updatedPinnedColumns);

}, []);

const getRowSpacing = useCallback((params) => {
    return {
    top: params.isFirstVisible ? 0 : 5,
    bottom: params.isLastVisible ? 0 : 5,
    };
}, []);

//filter Date start end

const [cancelB, setCancelB] = useState(true);

function showCell(params,store){
  //console.log(store)
  let visit=''
  let exisit=false
  
  params.row.store.map((elems)=>{
    
  if(elems.name===store.name){
    exisit=true
    
    
    if(listProd.find(params.row.id)){
      
      visit =store.lastVisit
    }

    
  }
  })

  if(!exisit){
    return <Button style={{width:'150px',fontSize:'16px',height:'75%'}} color='secondary' >HS\...</Button>
  }else{
    if(isEmpty(visit))
    return <Button style={{width:'150px',fontSize:'16px',height:'75%'}} color='danger' >AB\... </Button>
    else
    return <Button style={{width:'150px',fontSize:'16px',height:'75%'}} color='success' >PR\ {visit}</Button>
  }
}

  const cancelFilter = () => {
    //window.location.reload()
    setData(data=>[...products])
    setCancelB(cancelB=>!cancelB)
    setBrand([])
    setFamily([])
    setCategory([])
    setStoreGrp([])

  };

  const handleSearch = async () => {
    // setColumns(columns=> [])
    // setData(data=> [])
   setLoader(loader=>!loader)
   await getStoreOutputs(dateStart,dateEnd)
   setLoader(loader=>!loader)
   //setFiltred(true)
   //setCancelB(false)
   
   
 };
  const handleChangeStart = (newValue) => {
    
    setDateStart(newValue);
    setCancelB(false)
  };
  const handleChangeEnd = (newValue) => {
    
    setDateEnd(newValue);
    setCancelB(false)
  };

  //Filter family category type
  const [family, setFamily] = useState([]);
  const listFamily=[]
  const [category, setCategory] = useState([]);
  const listCategory=[]
  const [brand, setBrand] = useState([]);
  const listBrand=[]
  const [storeGrp, setStoreGrp] = useState([]);
  const listStoreGrp=[]
  const listGrp=[]

  const getListFilter=()=>{
    products.map((el)=>{
     listFamily.push(el.family)
     listCategory.push(el.category)
     listBrand.push(el.brand)
     //console.log(el.storeGrp)
     listStoreGrp.push(el.storeGrp)
     el.storeGrp.map((s)=>{
       listGrp.push(s)
     })
     
   })
  }

  getListFilter()

  const handleFamily = (event,value) => {
   setFamily(value);
   setCancelB(false)
 };

 const handleCategory = (event,value) => {

   
   setCategory(value);
   setCancelB(false)
 };

 const handleBrand = (event,value) => {
   //console.log(value)
   setBrand(value);
   setCancelB(false)
 };

 const handleStoreGrp = (event,value) => {
   setStoreGrp(value);
   //console.log(value)
   setCancelB(false)
 };

 const filter = () => {
   setCancelB(false)
    let newList=[]
    if(products.length>0){
     //filter 1 : family

       if(family.length>0 && !category.length>0 && !brand.length>0 && !storeGrp.length>0){
         //console.log(family)
         products.filter((el) => {
           family.map((f) => {

             if(el.family===f){
              newList.push(el) 
             }

           })
          })
          setData(newList)
        }

     //filter 2 : family + category
     if(family.length>0 && category.length>0 && !brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         family.map((f) => {

           category.map((c) => {

             if(el.family===f && el.category===c){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }
      //filter 3 : family + category +brand
     if(family.length>0 && category.length>0 && brand.length>0){
       //console.log(family)
        products.filter((el) => {
         family.map((f) => {

           category.map((c) => {

             brand.map((b) => {

               if(el.family===f && el.category===c && el.brand===b){
                newList.push(el) 
               }
   
             })
 
           })

         })
        })
        setData(newList)
      }

     //filter 4 : category
     if(!family.length>0 && category.length>0 && !brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         category.map((c) => {
           if(el.category===c){
             newList.push(el) 
            }
         })
        })
        setData(newList)
      }
      
      //filter 5 : category + brand
     if(!family.length>0 && category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {

           category.map((c) => {

             if(el.brand===b && el.category===c){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }

      //filter 6 : brand
     if(!family.length>0 && !category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {
           if(el.brand===b){
             newList.push(el) 
            }
         })
        })
        setData(newList)
      }

       //filter 7 : brand + family
     if(family.length>0 && !category.length>0 && brand.length>0 && !storeGrp.length>0){
       //console.log(family)
        products.filter((el) => {
         brand.map((b) => {

           family.map((f) => {

             if(el.brand===b && el.family===f){
              newList.push(el) 
             }
 
           })

         })
        })
        setData(newList)
      }

      //filter 8 :storeGrp 
      if(!family.length>0 && !category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
         
          let exist=false
          storeGrp.map((s) => {
            el.storeGrp?.map(grp=>{
             if(grp===s){
               exist=true
             }
            })
            
            if(exist)
            newList.push(el) 
          })
         })
         setData(newList)
       }

      //filter 9 :storeGrp + brand
      if(!family.length>0 && !category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            storeGrp.map((s) => {
              el.storeGrp?.map(grp=>{
                if(grp===s && el.brand===b){
                  exist=true 
                  
                }
                
              })
              if(exist)
              newList.push(el) 
              
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 10 :storeGrp + brand + category
      if(!family.length>0 && category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            category.map(c=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s && el.brand===b && el.category===c){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 11 :storeGrp + brand + category + family
      if(family.length>0 && category.length>0 && brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
          brand.map((b) => {
            category.map(c=>{
              family.map(f=>{
                storeGrp.map((s) => {
                  el.storeGrp?.map(grp=>{
                    if(grp===s && el.brand===b && el.category===c && el.family===f){
                      exist=true 
                      
                    }
                    
                  })
                  if(exist)
                  newList.push(el) 
                  
                })
              })
            })
            
          })
          
         })
         setData(newList)
      }

      //filter 12 :storeGrp + category
      if(!family.length>0 && category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            category.map(c=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s &&  el.category===c){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
         
          
         })
         setData(newList)
      }

      //filter 13 :storeGrp + family
      if(family.length>0 && !category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            family.map(f=>{
              storeGrp.map((s) => {
                el.storeGrp?.map(grp=>{
                  if(grp===s &&  el.family===f){
                    exist=true 
                    
                  }
                  
                })
                if(exist)
                newList.push(el) 
                
              })
            })
            
         
          
         })
         setData(newList)
      }

      //filter 14 :storeGrp + category + family
      if(family.length>0 && category.length>0 && !brand.length>0 && storeGrp.length>0){
        
        products.filter((el) => {
          let exist=false
          
            category.map(c=>{
              family.map(f=>{
                storeGrp.map((s) => {
                  el.storeGrp?.map(grp=>{
                    if(grp===s &&  el.category===c && el.family===f){
                      exist=true 
                      
                    }
                    
                  })
                  if(exist)
                  newList.push(el) 
                  
                })
              })
            })
            
         
          
         })
         setData(newList)
      }

    }
 };


const MemoizedRow = React.memo(GridRow);
/////////////////////////////////////////////////////////////////////////////////////////////
///new table///////////////////
const [desc, setDesc] = useState(false)
const [filterTable, setFilterTable] = useState(false)

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearchProduct = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=products.filter((element)=>{
      
        
        return (
          (element?.label?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.brand?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.category?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) 
          || (element?.family?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.barcode?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ))
          
)
      
    })
    setData(data=>[...list])
  }else{
    setData(data=>[...products])
  }
  };

  const handleSearchStore = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=stores.filter((element)=>{
      
        
        return (
          (element.field?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
            || (element.headerName.toString().toLowerCase().includes("label"))
            || (element.headerName.toString().toLowerCase().includes("barcode"))
            || (element.headerName.toString().toLowerCase().includes("brand"))
            || (element.headerName.toString().toLowerCase().includes("category"))
            || (element.headerName.toString().toLowerCase().includes("family"))
            || (element.headerName.toString().toLowerCase().includes("storegrp"))
            //|| (element.headerName.toString().toLowerCase().includes("id"))

          // || (element?.brand?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // || (element?.category?.name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) 
          // || (element?.barcode?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          // )
)
      
    })
    setColumns(columns=>[...list])
  }else{
    setColumns(columns=>[...stores])
  }
  };
  
  function filterData(params) {
    
    // if(!filter){
    //   setFilter(filter=> true)
    // }
    if (params==="typology"){
        if(desc){
          setDesc(desc=> !desc)
          const list=data.sort((a,b)=> (a.typology>b.typology)-(a.typology<b.typology))
          setData(data=> [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data.sort((a,b)=> (a.typology<b.typology)-(a.typology>b.typology))
          setData(data=> [...list])
        }
      }else if (params==="Pourcentage" || params==="taux"){
        if(desc){
          setDesc(desc=> !desc)

          const list=data.sort((a,b)=> (a.pourcentage>b.pourcentage)-(a.pourcentage<b.pourcentage))
          setData(data=> [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data.sort((a,b)=> (a.pourcentage<b.pourcentage)-(a.pourcentage>b.pourcentage))
          setData(data=> [...list])
        }
      }else {
        if(desc){
          setDesc(desc=> !desc)

          const list=data.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
          setData(data => [...list])
        }else{
          setDesc(desc=> !desc)

          const list=data.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
          setData(data => [...list])
        }
       
      } 
    
    
  }





//// table components////////******************* */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columnsVirtuso = [

    {
      width: 90,
      label: 'Image',
      dataKey: 'image',
    },
    {
      width: 120,
      label: 'Barcode',
      dataKey: 'barcode',
    },
    {
      width: 150,
      label: 'Label',
      dataKey: 'label',
    },
    
    {
      width: 120,
      label: 'Brand',
      dataKey: 'brand',
    },
    {
      width: 120,
      label: 'Category',
      dataKey: 'category',
    },
    {
      width: 120,
      label: 'Typology',
      dataKey: 'typology',
    },
    {
      width: 120,
      label: 'Family',
      dataKey: 'family',
    },
    {
      width: 120,
      label: 'StoreGrp',
      dataKey: 'storeGrp',
    },
    // {
    //   width: 80,
    //   label: 'Taux',
    //   dataKey: 'taux',
    // },
    // {
    //   width: 80,
    //   label: 'Action',
    //   dataKey: 'Action',
    //   //numeric: true,
    // },
   
    
  ];
  function showCell(params,store){
    //console.log(store)
    let visit=''
    let exisit=false

    
    params.row.store.map((elems,index)=>{
    if(elems.name===store.name){
      exisit=true
      store?.lastProduct?.map(l=>{
        if(l===params.row.id){
          visit=store.lastVisit
          
        }
      })
   
    }
    })

    if(!exisit){
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='secondary' >HS\...</Button>
    }else{
      if(isEmpty(visit))
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='danger' >AB\... </Button>
      else
      return <Button style={{width:'150px',fontSize:'16px',height:'50%'}} color='success' >PR\ {visit}</Button>
    }
 }
  

  // const memoStoresRows=useMemo(()=>columns.length>0&&columns.map((elem,index)=>{
  //   console.log("store_____",)
  //     return (
  //       <TableCell
        
  //     key={index}
  //     align={'left'}
      
  //     >            
  //       {/* {showCell(row,elem)} */}
  //       {elem.field}
  //       </TableCell>
  //     )
  // }),[columns])
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columnsVirtuso.map((column) => 
          {
            console.log("fixedHeaderContent",)
            if(column.dataKey==="Action" ||column.dataKey==="image"||column.dataKey==="storeGrp"){
              return  <TableCell
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
              {column.label}
            </TableCell>
            }else{
              return  <TableCell
              onClick={()=>filterData(column.dataKey)}
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
                <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>
  
            </TableCell>
            }
          }
      )}
      {memoStores}
      </TableRow>
    );
  }
  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columnsVirtuso.map((column) => {
          console.log("rowContent",)
            if(column.dataKey==="image"){
             return (<TableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
            >
              <img src={URL+row.image} style={{ width: "3rem", height: "3rem" }} />
            </TableCell>)
            } else if(column.label==="Action") {
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                <h6  onClick={handleClickTop(row.store, row.label, row.barcode)}><ListAltOutlinedIcon fontSize='medium' /></h6> 
                </TableCell>)
            }else if(column.label==="Barcode") {
                          return (<TableCell
                            key={column.dataKey}
                            align={column.numeric || false ? 'right' : 'left'}
                            >
                              <HorizontalSplitSharpIcon fontSize='small' />  {row.barcode} </TableCell>)
                        }else if(column.label==="Taux") {
  
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                    <PieChart nbStock={row.nbStock} nbStockOut={row.nbStockOut} /> </TableCell>)
            }else if(column.label==="StoreGrp") {
  
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                    {showCellStoreGrp(row.storeGrp)} </TableCell>)
            }else{
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row[column.dataKey]}
                </TableCell>)
            }
        }
         
        )}
        {memoStoresRows}
      </React.Fragment>
    );
  }
  // const memoData=useMemo(()=>data.length>0&&data.map((row, index) => {
  //   const isItemSelected = isSelected(row.label);
  //   const labelId = `enhanced-table-checkbox-${index}`;
  //   //console.log("visibleRows___________")
  //   return (
  //     <TableRow
  //       hover
  //       // onClick={(event) => handleClick(event, row.label)}
  //       // role="checkbox"
  //       // aria-checked={isItemSelected}
  //       // tabIndex={-1}
  //       key={row.label}
  //       // selected={isItemSelected}
  //       sx={{ cursor: 'pointer' }}
  //     >
  //       {/* <TableCell padding="normal" >
  //         <Checkbox
  //           color="primary"
  //           //checked={isItemSelected}
  //           // inputProps={{
  //           //   'aria-labelledby': labelId,
  //           // }}
  //         />
  //       </TableCell> */}
  //       <TableCell
  //         component="th"
  //         id={labelId}
  //         //scope=""
  //         //padding="normal"
  //         width={'300'}
  //       >
  //         {row.label}
  //       </TableCell>
  //       <TableCell align='left'>{row?.barcode}</TableCell>
  //       <TableCell align='left'>{row?.brand}</TableCell>
  //       <TableCell align='left'>{row?.category}</TableCell>
  //       <TableCell align='left'>{row?.typology}</TableCell>
  //       <TableCell align='left' style={{maxHeight:'20'}}>{row?.storeGrp?.length>0&&row?.storeGrp?.map((elem,i)=>{
  //         return  <li key={i}>{elem}</li>
  //       })}</TableCell>


  //     </TableRow>
  //   );
  // }),[data])
  const headerRenderer = ({
    columnData,
    dataKey,
    disableSort,
    label,
    sortBy,
    sortDirection
  }) => { return (
    <div className='filter' onClick={()=>(dataKey!='path') &&(dataKey!='Action')&&filterData(dataKey)}> 
    {(dataKey!='path') &&(dataKey!='Action')&&(dataKey!='StoreGrp')&&filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10,marginRight:4}} onClick={()=>filterData(dataKey)}></i>)}
      {(dataKey==='path') ||(dataKey==='Action')? label:dataKey}
     </div>
  )};
  const headerRowRenderer = ({
    className,
    columns,
    style
  }) => (
    <div
    
      className={className+' text-light rounded  '}
      role='row'
      style={{...style,backgroundColor:'#4F6973'}}
    >
      {columns} 
    </div>
  )
  // const memoStores=useMemo(()=>columns.length>0&&columns.map((elem,index)=>{
  //   //console.log("store_____",)
  //     return (
  //       <Column key={index} label={elem?.field} dataKey={elem?.field} width={1000}    cellRenderer={() =>  <h6>{elem?.field}</h6>}/>

  //     )
  // }),[columns])

  return (
    <div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='row m-3 d-flex justify-content-evenly'>
      <MButton css={{backgroundColor:'blue'}} disabled={cancelB} color='error' aria-describedby='chart' onClick={cancelFilter} className='mb-2' ><EventBusySharpIcon/>&nbsp; cancel filter</MButton>
      
      <DatePicker
      views={['day']}
    label="From"
    value={dateStart}
    inputFormat="YYYY-MM-DD"
    onChange={handleChangeStart}
    renderInput={(params) => <TextField {...params} />}
    className="col-2 "
  />
        <DatePicker 
        views={['day']}
          label="To"
          value={dateEnd}
          inputFormat="YYYY-MM-DD"
          
          onChange={handleChangeEnd}
          renderInput={(params) => <TextField  {...params} />}
          className="col-2"
        />
         <MIButton className="col-2" variant="contained" onClick={handleSearch} size="large">
         <ContentPasteSearchSharpIcon/> &nbsp;Search
        </MIButton>
      </div>
    </LocalizationProvider>

    <Row className='mb-3' style={{marginLeft:0}}>
      <Col className='ml-5' >
      <FormControl sx={{ m: 1, width: 300 }} size="small">

<Autocomplete
        onChange={handleBrand}
        value={brand}
        multiple
        id="brand"
        options={[...new Set(listBrand)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brand"
            placeholder="Brand"
          />
        )}
      />
</FormControl>
      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleCategory}
        value={category}
        multiple
        id="category"
        options={[...new Set(listCategory)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Category"
            placeholder="Category"
          />
        )}
      />
        
      </FormControl>
      
      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleFamily}
        value={family}
        multiple
        id="family"
        options={[...new Set(listFamily)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Family"
            placeholder="Family"
          />
        )}
      />
        
      </FormControl>

      </Col>
      <Col>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
      <Autocomplete
        onChange={handleStoreGrp}
        value={storeGrp}
        multiple
        id="storeGrp"
        options={[...new Set(listGrp)]}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Store Group"
            placeholder="Store Group"
          />
        )}
      />
       
      </FormControl>
      </Col>
      <Col style={{marginTop:'6px'}}>
      <MIButton  className="col-7" sx={{height:'58px'}} variant="outlined" onClick={filter} size="large">
         <FilterAltIcon/> Filter
      </MIButton>
        </Col>
    </Row>

    
{loader && <LoadingComponent/>}
<Row >
  <Col  className='col-10'>
    <TextField placeholder='search products...' style={{padding:1,marginBottom:10,alignSelf:'flex-end',backgroundColor:"transparent",margin:5}} size="small"  onInput={(e)=>handleSearchProduct(e.target.value)}/>
  </Col>
  <Col  >
    <TextField placeholder='search stores...' style={{padding:1,marginBottom:10,alignSelf:'flex-end',backgroundColor:"transparent",margin:5}} size="small"  onInput={(e)=>handleSearchStore(e.target.value)}/>
  </Col>
</Row>

{(data.length>0 &&!loader) && columns.length>0&&<Paper sx={{overflow: 'hidden',height:650}} style={{  width:'100%',overflowY:'hidden', overflowX: 'visible',backgroundColor:'transparent'}}>

<AutoSizer>
      {({height, width}) => (
        <div >
        <Table
        gridStyle={{
          direction: 'inherit'
        }}
        width={ MIN_TABLE_WIDTH * data.length}
        //headerRowRenderer={headerRowRenderer}
        height={height-70}
        headerHeight={0}
        rowHeight={100}
        rowCount={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
        rowGetter={({index}) => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}
        >    
          {/* <Column label="Image" dataKey="image" width={1000} headerRenderer={headerRenderer}  cellRenderer={({cellData}) => <img src={URL+cellData} style={{ width: "4rem", height: "4rem", }} />}/>  */}
          <Column label="label" dataKey="label"   width={width+3000}    cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Barcode" dataKey="barcode"   width={width+3000}    cellRenderer={({cellData}) =>  <h6 >{cellData}</h6>}/> 
          <Column label="Brand" dataKey="brand"   width={width+3000}     cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Familly" dataKey="family"   width={width+3000}    cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Category" dataKey="category"   width={width+3000}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="Typology" dataKey="typology"   width={width+3000}   cellRenderer={({cellData}) =>  <h6>{cellData}</h6>}/>
          <Column label="StoreGrp" dataKey="StoreGrp"   width={width+3000}    cellRenderer={({cellData,rowData}) =>  <h6>{rowData?.storeGrp}</h6>}/> 
          {columns.length>0&&columns.map((elem,index)=>{
    //console.log("store_____",)
      return (
        <Column key={index} label={elem?.field} dataKey={elem?.field} width={1000}    cellRenderer={() =>  <h6>{elem?.field}</h6>}/>

      )
  })}
          {/* {columns.length>0&&columns.map((elem,index)=>{
    //console.log("store_____",)
      return (
        <Column key={index} label={elem?.field} dataKey={elem?.field} width={width}    cellRenderer={() =>  <h6>{elem?.field}</h6>}/>

      )
  })} */}
  

          
       </Table>
              <Row>
              <Col >
              <TableFooter>
              <TableRow>
                <TablePagination
                  component="div"
                  style={{width:1100}}
                  rowsPerPageOptions={[10, 50,100, { label: 'All', value: data.length }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
              </Col>
              
             </Row>
             </div>
      )} 
     
     
    </AutoSizer>
</Paper>
}
{loader &&<LoadingComponent/>}


    </div>
  )
}

export default DataTableStoreV2;

//************ table properties************ */
// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
  <>
  <TableBody {...props} ref={ref} />
  

  </>)),
  // TableFooter :React.forwardRef((props, ref) => 
  
  
  // )
};

function showCellStoreGrp(params) {
  //console.log("params_________________",params)
  let component = []
  if(params.length>0){
    params.map((elems,index) => {
      component.push(<li align='left' key={index}>{elems}</li>)
    })
  }

  return component.length>0 ?  <div>{component}</div> :<h6>not available in stock</h6>
}
