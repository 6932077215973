/*global google*/

// import {
//     Box,
//     Button,
//     ButtonGroup,
//     Flex,
//     HStack,
//     IconButton,
//     Input,
//     SkeletonText,
//     Text,
//   } from '@chakra-ui/react'
//   import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import person from "../assets/images/personMap.png"

  import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
    InfoWindow,
  } from '@react-google-maps/api'
  import { useEffect, useMemo, useRef, useState } from 'react'
import LoadingComponent from './LoadingComponent'
import { Button, Label, Row } from 'reactstrap'
// const { isLoaded } = useJsApiLoader({
//   googleMapsApiKey: 'AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8',
//   libraries: ['places'],
// })

import storeMarker from "../assets/images/store-marker-v2.png"
const URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3303"
      : "https://www.devtest21-api.g2m-tn.com";
  const MapsApi=(props)=> {

    const { lat, lng,isLoaded ,locationStores} = props;
    

    const [map, setMap] = useState(null)
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState(),
    [info, setInfo] = useState(false),
    [descriptionData, setDescriptionData] = useState({})
    const center = useMemo(() => ({ lat: latitude, lng: longitude }), [longitude])
    const storesLocations = useMemo(() => (locationStores), [locationStores])
    const memoList= useMemo(()=> storesLocations?.length > 0 && storesLocations.map((elem)=>{
      //console.log("chççççççççççççççççç")
        return (
          <Marker
            key={elem.id}
            // label={destination?.name}
            clickable
            onClick={(e)=>storeDiscription(elem)}
            title={elem?.name}
            position={{ lat: elem?.lat, lng: elem?.lng }}
            icon={{
              url: storeMarker,
              anchor: new google.maps.Point(40, 40),
              scaledSize: new google.maps.Size(55, 55),
            }}
          >
            
          </Marker>
        )
      }),[storesLocations])

    const centerFix = {
      lat: 44.331429,
      lng: -83.045753
  }
  function storeDiscription(params) {
    setDescriptionData(params)
    setInfo(true)
  }

    // if (!isLoaded) {
    //   return <LoadingComponent />
    // }
  
    // async function calculateRoute(lat,lng) {
    // //   if (originRef.current.value === '' || destiantionRef.current.value === '') {
    // //     return
    // //   }
    //   // eslint-disable-next-line no-undef
    //   //console.log("data_____________",lat,lng,latitude,longitude)
    //   const directionsService = new google.maps.DirectionsService()
    //   const results = await directionsService.route({
    //     optimizeWaypoints:true,
    //     origin: {lat:latitude,lng:longitude},
    //     destination: { lat: lat, lng: lng },        
    //     travelMode: destination?.mode==="walking"?google.maps.TravelMode.WALKING:google.maps.TravelMode.DRIVING,
    //   },)
    //   setDirectionsResponse(results)
      
    //   // setDistance(results.routes[0].legs[0].distance.text)
    //   // setDuration(results.routes[0].legs[0].duration.text)
    // }
  
    function clearRoute() {
      setDirectionsResponse(null)
      setDistance('')
      setDuration('')
 
    }
    
    useEffect(()=>{
      
      if(latitude!=lat||longitude!=lng){
        //console.log("thuffick_____________",latitude,lat)
        setLatitude(lat)
      setLongitude(lng)
          
      }
      
      
      
    },[lat,lng])

    // useEffect(()=>{

        
    //   if(longitude>0&&latitude>0){
    //     // setTimeout(async () => {
    //     //   await calculateRoute(destination?.lat,destination?.lng)
    //     // }, 200);
    //   }
            
      
      
    // },[longitude])
    // const NEW_ZEALAND_BOUNDS = {
    //   north: -34.36,
    //   south: -47.35,
    //   west: 166.28,
    //   east: -175.81,
    // };

    return (
        <div>
        {/* Google Map Box */}
        {!isLoaded && <LoadingComponent />}
        {/* <Button title='aaaa' onClick={calculateRoute}/> */}
       { longitude>0&& latitude>0&& isLoaded&& <GoogleMap
          tilt= {0}
          heading= {0}
          center={center}
          zoom={18}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          
          options={{
            //restriction:{strictBounds:true,latLngBounds:NEW_ZEALAND_BOUNDS},
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
          }}
          
          //onLoad={map => setMap(map)}
        >
          <Marker 
                  title="merchandiser"
                  position={{ lat: latitude, lng: longitude }} icon={{
                  url: person,
                  anchor: new google.maps.Point(32,32),
                  scaledSize: new google.maps.Size(64,64)
                }}/>
          {/* {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} options={{preserveViewport:true,}}/>
          )} */}
          {memoList}
            {info&& descriptionData &&
              <InfoWindow
                    position={{ lat: descriptionData?.lat, lng: descriptionData?.lng }}
                    onCloseClick={()=>setInfo(false)}
                  >
                    <div   style={{padding:2,width:180}}>
                    <img
                      src={`${URL+descriptionData?.path}`}
                      style={{width:180,height:80,marginTop:5,marginBottom:5}}
                      alt={descriptionData?.name}
                      loading="lazy"
                    />
                    <Row>
                    <Label style={{fontSize:13,}}>{descriptionData?.name.toUpperCase()} </Label>
                          <p>{descriptionData?.address} 
                          <br/>{descriptionData?.governorate}
                          <br/>{descriptionData?.postal_code}
                          </p>
                           
                            </Row>

                    </div>
                  </InfoWindow>
            }
        </GoogleMap>}
      </div>
    )
  }
//      function loader() {
//     const { isLoaded } = useJsApiLoader({
//   googleMapsApiKey: 'AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8',
//   libraries: ['places'],
// })
//     return isLoaded
//   }
  export default MapsApi