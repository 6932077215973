// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider  } from "firebase/app-check";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAG90oZN9XfXmp4dfxFIoVxYZJk2PuzkDg",
  authDomain: "marchandizer-391810.firebaseapp.com",
  projectId: "marchandizer-391810",
  storageBucket: "marchandizer-391810.appspot.com",
  messagingSenderId: "639115727303",
  appId: "1:639115727303:web:9a14d9dba2f5f2d8ee09e2",
  measurementId: "G-V78621RT50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LcBQrYnAAAAAKko9XdvdDYUxsuY3N2Bnczf4Yyh'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   // token:'3CCD217C-C9EA-4F82-A8DE-5C8BC579774C',
//   isTokenAutoRefreshEnabled: true
// });
export const database =getDatabase()
// const analytics = getAnalytics(app);