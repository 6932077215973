import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags';
import { Row, Card, CardBody,Col } from "reactstrap"
import MButton from '@mui/material/Button';

import CoPresentIcon from '@mui/icons-material/CoPresent';

import ExportData from './components/ExportData';
import { getStoresFilterAsync } from 'store/pos/actions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Box, LinearProgress } from '@mui/material';
import { getStoresFilter } from 'store/pos/services';


function Presentation() {

  const dispatch = useDispatch();


//   const getStores = async () => {
//    return  dispatch(await getStoresFilterAsync()).payload.storesFilter
// }
  const {data,status,isLoading,isFetching} =useQuery('presentation_stores', getStoresFilter,{
    refetchOnWindowFocus:false,
    //refetchInterval:0
     //staleTime:'infinity',
    // initialData:undefined
    //refetchOnMount:false
    cacheTime:7200000
  })
  
  //console.log("cashe---------------",{isLoading,isFetching},data?.length)

  return (
    <div className="page-content">
      <Card>
                        <CardBody>
                            <Row>
                                <MButton style={{color:'red'}} ><CoPresentIcon/> &nbsp; Presentation</MButton>
                            </Row>
                        </CardBody>
                    </Card>
                    <MetaTags>
                    <title>Presentation</title>
                </MetaTags>
        {isLoading && (
                    <div>
                      <Box sx={{ width: '300%',height:'200%' }}>
                     
      <LinearProgress />
    </Box>
                    </div>
        )}
        
        {!isLoading &&
        (
            <div style={{height:1100}}>
               
                <ExportData dataStores={data}/>
              </div>
                
                
                
                )}       
    </div>
  )
}

export default Presentation