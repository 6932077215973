import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, FormGroup, Nav, NavItem, NavLink, Row, TabContent, UncontrolledAlert, Progress } from "reactstrap"
import classnames from "classnames"
import SubCategories from './SubCategories';
import { useParams } from 'react-router-dom';
import { getSurveyById } from 'store/survey/services';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { api } from 'helpers/consts';
import ReportComponent from '../reportComponents';

function SurveyResponse() {

    const connectedUser = useSelector(state => state.User?.user?.id);
    const { surveyId, storeId, visitId } = useParams();
    const [customActiveTab, setCustomActiveTab] = useState(0);
    const [survey, setSurvey] = useState(null);
    const [report, setReport] = useState({ reportText: "" });
    const [responses, setResponses] = useState([]);
    const [surveyResponse, setSurveyResponse] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(null);

    const createSurveyResponse = () => {
        let images = []
        let totalCoef = 0
        let totalNote = 0
        Swal.fire({
            title: 'êtes-vous sûr?',
            text: "Voulez vous valider vos réponse?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Valider"
        }).then((result) => {
            if (result.isConfirmed) {
                if (
                    (responses.findIndex((response) => response.rate === null && response.question.required === true) > -1) ||
                    (responses.findIndex((response) => response.images.length === 0 && response.question.imagesRequired === true && response.question.images) > -1 )
                ) {
                    console.log("response:___________________",responses)
                    setErrorMessage("Veuillez répondre à toutes les questions obligatoires!")
                    setTimeout(() => {
                        setErrorMessage(null)
                    }, 5000)
                } else {

                    responses?.forEach((resp) => {
                        resp?.images?.forEach((element) => {
                            images.push(element)
                        });
                    });
                    images = [...new Map(images.map(item => [item["path"], item])).values()]
                    responses?.forEach((resp) => {
                        totalCoef = totalCoef + resp.question.coef
                        totalNote = totalNote + (resp.question.coef * resp.rate)
                    });
                    const formData = new FormData();
                    formData.append('surveyResponse', JSON.stringify({ ...surveyResponse, average: parseFloat(totalNote / totalCoef), responses: responses }))
                    images?.forEach(element => {
                        formData.append(element?.path, element)
                    });
                    formData.append('report', JSON.stringify(report))
                    report?.pictures?.forEach(element => {
                        formData.append('reportPicture', element)
                    });
                    
                    api.post(`/surveyResponse`, formData, {
                        onUploadProgress: progressEvent => {
                            setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100))
                        }
                    }).then(() => {
                        Swal.fire(
                            'Félicitation!',
                            "Vos réponse on été sauvegardé!",
                            'success'
                        ).then(() => {
                            window.close()
                        })
                    }).catch((error) => {
                        if(error.response.data.message){
                            setErrorMessage(error.response.data.message)
                            setTimeout(async () => {
                                setErrorMessage(null)
                            }, 5000)
                        }else{
                            setErrorMessage(error)
                            setTimeout(async () => {
                                setErrorMessage(null)
                            }, 5000)
                        }
                    })
                }
            }
        })
    }

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab)
        }
    }

    useEffect(() => {
        getSurveyById(surveyId).then((data) => {
            data.questionCategories.sort((a, b) => { return a.id - b.id })
            setSurvey(data);
        })
    }, []);

    useEffect(() => {
        setSurveyResponse({
            userId: connectedUser,
            storeId: parseInt(storeId),
            surveyId: parseInt(surveyId),
            visitId: parseInt(visitId)
        })
    }, [connectedUser]);

    return (
        <div className="page-content">
            <MetaTags>
                <title>{survey?.name}</title>
            </MetaTags>
            <Container fluid={true}>
                <Breadcrumbs title="Questionnaires" breadcrumbItem={survey?.name} />
                <Card>
                    <CardBody>
                        {survey ? (
                            <>
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    {survey.questionCategories.map((category, index) => (
                                        <NavItem key={index}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: customActiveTab === index })}
                                                onClick={() => { toggleCustom(index) }}
                                            >
                                                <span className="d-none d-sm-block">{category.name}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                                <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                    {survey.questionCategories.map((category, index) => (
                                        <SubCategories
                                            key={index}
                                            index={index}
                                            responses={responses}
                                            setResponses={setResponses}
                                            subCategories={category.questionSubCategories.sort((a, b) => { return a.id - b.id })}
                                        />
                                    ))}
                                </TabContent>
                                {errorMessage &&
                                    <UncontrolledAlert
                                        color="danger"
                                        className="alert-dismissible fade show"
                                        style={{ width: "81.5%", marginLeft: "17.5%" }}
                                        role="alert"
                                    >
                                        <i className="mdi mdi-block-helper me-2"></i> {errorMessage}
                                    </UncontrolledAlert>
                                }
                                {uploadProgress &&
                                    <Progress
                                        color="primary"
                                        value={uploadProgress}
                                        style={{ height: "1rem", width: "81.5%", marginLeft: "17.5%" }}
                                    >
                                        <h4 style={{ color: "white", marginTop: "0.6rem", fontSize: "1rem" }}>{uploadProgress + " %"}</h4>
                                    </Progress>
                                }
                                <Row style={{ marginTop: "2rem" }}>
                                    <Col>
                                        <span style={{ fontSize: "15px", color: "red", marginLeft: "18rem" }}>* : Obligatoire</span>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-0" style={{ float: 'right', responses: [] }}>
                                            <ReportComponent
                                                setReport={setReport}
                                                report={{ ...report, visitId: parseInt(visitId) }}
                                            />
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="ms-1"
                                                onClick={() => { createSurveyResponse() }}
                                            >
                                                Valider le questionnaire
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div >
    )
}
export default SurveyResponse;