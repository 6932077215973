import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { Badge, Card, CardBody, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import {  Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getUsersList } from 'store/user/actions';
import avatarMale from "../../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../../assets/images/users/female-profile-pic.jpg"
import CreateUser from './CreateUser';
import { Link } from 'react-router-dom';
import { resetPwd, switchStateUser } from 'store/user/services';
import Swal from 'sweetalert2'
import { Oval } from  'react-loader-spinner'
import queryClient from 'queryClient';
import TablePaginationActions from 'components/paginationMui';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Paper, TextField } from '@mui/material';

export default function DataTableUsersList({data}) {

    const [users, setUsers] = useState([])

    //console.log("users______________________________",users)
    const changeStatus = (user) => {
        Swal.fire({
            title: 'Are you sure?',
            text: !user.enabled ? ("Do you want to disable this account?") : ("Do you want to enable this account?"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: !user.enabled ? ('Yes, disable it!') : ('Yes, enable it!')
        }).then((result) => {
            if (result.isConfirmed) {
                switchStateUser(user)
                    .then(() => {
                        Swal.fire(
                            'Updated!',
                            !user.enabled ? ('the account has been disabled.') : ('the account has been enabled.'),
                            'success'
                        )
                        queryClient.invalidateQueries('users') 
                    })
                    
            }
        })
    }

    const resetPassword = (user) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reset password!'
        }).then((result) => {
            if (result.isConfirmed) {
                resetPwd(user).then(() => {
                    Swal.fire(
                        'Updated!',
                        'Password updated & a new password has been sent.',
                        'success'
                    )
                    queryClient.invalidateQueries('users')
                })
            }
        })
    }

    

   

    useEffect(async () => {
      if(data?.length>0){
        setRowsPerPage(data?.length)
        setUsers([...data])}
        
    }, [data])
//-------------------------------------------------------
const columnsVirtuso = [
    // {
    //   width: 120,
    //   label: 'id',
    //   dataKey: 'id',
    //   //numeric: true,
    // },
    {
      width: 100,
      label: 'Image',
      dataKey: 'path',
    },
    {
      width: 200,
      label: 'First Name',
      dataKey: 'first_name',
    },
    {
      width: 200,
      label: 'Last Name',
      dataKey: 'last_name',
    },
    {
      width: 200,
      label: 'Phone_number',
      dataKey: 'phone_number',
    },
    // {
    //   width: 200,
    //   label: 'Status',
    //   dataKey: 'status',
    // },
    {
      width: 200,
      label: 'Action',
      dataKey: 'Action',
      //numeric: true,
    },
   
    
  ];
  
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [page, setPage] = React.useState(0);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const [desc, setDesc] = useState(false)
  const [filter, setFilter] = useState(false)
  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }

  const handleSearch = (search) => {
    //setCancelB(false)
    //console.log("search_______________________",search)
    if(search){
    const list=data?.filter((element)=>{
      
        
        return (
          (element?.first_name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) ) 
          || (element?.last_name?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
          || (element?.phone_number?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
        //   || (element?.typology?.toString().toLowerCase().includes(search.toString().toLowerCase(search)) )
)
      
    })
    setUsers(users=>[...list])
    setRowsPerPage(list?.length)
    
  }else{
    setUsers(users=>[...data])
    setRowsPerPage(data?.length)

  }
  };
  function filterData(params) {
    //console.log('list_____________________',params)
    if(!filter){
      setFilter(filter=> true)
    }
    if(desc){
        const list=users.sort(sort_by(params, false, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setUsers(users => [...list])
      }else{
        const list=users.sort(sort_by(params, true, (a) =>  a?.toUpperCase()))
        setDesc(desc=> !desc)
        setUsers(users => [...list])
      }
    
  }
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columnsVirtuso.map((column) => 
          {
            if(column.dataKey==="Action" ||column.dataKey==="path" ||column.dataKey==="status"){
              return  <TableCell
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
              {'Status'}
            </TableCell>
            }else{
              return  <TableCell
              onClick={()=>filterData(column.dataKey)}
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'left'}
              style={{ width: column.width,color:"white" }}
              sx={{
                backgroundColor: '#4F6973',
              }}
            >
                <div className='filter' > {filter && (desc  ? <i className='fa fa-arrow-up '  style={{alignSelf:'flex-end',fontSize:10}} ></i>:<i className='fa fa-arrow-down'  style={{alignSelf:'flex-end',fontSize:10}} ></i>)} {column.label} </div>
  
            </TableCell>
            }
          }
      )}
      </TableRow>
    );
  }
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
    <>
    <TableBody {...props} ref={ref} />
    
  
    </>)),

  };
  const URL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3303"
        : "https://www.devtest21-api.g2m-tn.com";

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columnsVirtuso.map((column) => {
          //console.log("column______________________",column)
            if(column.dataKey==="path"){
             return (<TableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
            >
                {row.gender === "M" && !row.profile_picture &&
                                                                        <img
                                                                            src={avatarMale}
                                                                            alt=""
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            className=" rounded-circle img-thumbnail"
                                                                        />
                                                                    }
                                                                    {row.gender === "F" && !row.profile_picture &&
                                                                        <img
                                                                            src={avatarFemale}
                                                                            alt=""
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            className=" rounded-circle img-thumbnail"
                                                                        />
                                                                    }
                                                                    {row.profile_picture &&
                                                                        <img
                                                                            src={URL+row.profile_picture}
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            alt=""
                                                                            className=" rounded-circle img-thumbnail"
                                                                        />
                                                                    }
              {/* <img src={URL+row[column.dataKey]} style={{ width: "4rem", height: "4rem" }} /> */}
            </TableCell>)
            }  else if(column.label==="Status") {
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {/* <Badge
                                                                        className={row.enabled ? "font-size-11 badge-soft-success" : "font-size-11 badge-soft-danger"}
                                                                        color={row.enabled ?"success" : "danger"}
                                                                        pill
                                                                        style={{ marginTop: '1rem' }}
                                                                    >
                                                                        {row.enabled ? "enabled": "disabled"}
                                                                    </Badge> */}
                </TableCell>)
            }else if(column.label==="Category") {
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row.category.name}
                </TableCell>)
            }else if(column.label==="Action") {
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                
                <div className="form-check form-switch form-switch-md mb-3" >
                                                                        <input
                                                                            type="checkbox"
                                                                            style={{ cursor: "pointer", marginTop: "1rem" }}
                                                                            className="form-check-input"
                                                                            id="enabletooltip"
                                                                            checked={row.enabled}
                                                                            onChange={() => { changeStatus({ ...row, enabled: !row.enabled }) }}
                                                                        />
                                                                        <UncontrolledTooltip placement="top" target="enabletooltip">
                                                                            change status
                                                                        </UncontrolledTooltip>
                                                                        <Link to="#" onClick={() => {
                                                                            resetPassword({ ...row, id: row.id, first_name: row.first_name, last_name: row.last_name, email: row.email })
                                                                        }}>
                                                                            <i
                                                                                className="bx bx-key"
                                                                                style={{ fontSize: "1.5rem", cursor: "pointer", marginTop: "1rem", marginLeft: "1rem" }}
                                                                                id="edittooltip"
                                                                            />
                                                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                                                reset password
                                                                            </UncontrolledTooltip>
                                                                        </Link>
                                                                    </div>

                </TableCell>)
            }else{
              return (<TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
                >
                {row[column.dataKey]}
                </TableCell>)
            }
        }
         
        )}
      </React.Fragment>
    );
  }
    return (
        <>
        <TextField placeholder='search...' style={{padding:1,alignSelf:'flex-end'}} size="small"  onChange={(e)=>handleSearch(e.target.value)}/>
        {users?.length > 0 ? (
                                    <div className="table-rep-plugin" style={{ marginTop: "1rem" }}>
                                        <div
                                            // className="table-responsive mb-0"
                                            // data-pattern="priority-columns"
                                            style={{height:600}}
                                        >
                                            {/* <Table
                                                id="mydatatable"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th>Picture</Th>
                                                        <Th>First Name</Th>
                                                        <Th>Last Name</Th>
                                                        <Th>Phone number</Th>
                                                        <Th>Status</Th>
                                                        <Th>Actions</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {users.map((user, index) => {
                                                        return (
                                                            <Tr key={index}>
                                                                <Td>
                                                                    {user.gender === "M" && !user.profile_picture &&
                                                                        <img
                                                                            src={avatarMale}
                                                                            alt=""
                                                                            className="avatar-sm rounded-circle img-thumbnail"
                                                                        />
                                                                    }
                                                                    {user.gender === "F" && !user.profile_picture &&
                                                                        <img
                                                                            src={avatarFemale}
                                                                            alt=""
                                                                            className="avatar-sm rounded-circle img-thumbnail"
                                                                        />
                                                                    }
                                                                    {user.profile_picture &&
                                                                        <img
                                                                            src={user.profile_picture}
                                                                            alt=""
                                                                            className="avatar-sm rounded-circle img-thumbnail"
                                                                        />
                                                                    }
                                                                </Td>
                                                                <Td>
                                                                    <p style={{ marginTop: '1rem' }}>{user.first_name}</p>
                                                                </Td>
                                                                <Td>
                                                                    <p style={{ marginTop: '1rem' }}>{user.last_name}</p>
                                                                </Td>
                                                                <Td>
                                                                    <p style={{ marginTop: '1rem' }}>{user.phone_number}</p>
                                                                </Td>
                                                                <Td>

                                                                    <Badge
                                                                        className={user.enabled ? ("font-size-11 badge-soft-success") : ("font-size-11 badge-soft-danger")}
                                                                        color={user.enabled ? ("success") : ("danger")}
                                                                        pill
                                                                        style={{ marginTop: '1rem' }}
                                                                    >
                                                                        {user.enabled ? ("enabled") : ("disabled")}
                                                                    </Badge>
                                                                </Td>
                                                                <Td>
                                                                    <div className="form-check form-switch form-switch-md mb-3" >
                                                                        <input
                                                                            type="checkbox"
                                                                            style={{ cursor: "pointer", marginTop: "1rem" }}
                                                                            className="form-check-input"
                                                                            id="enabletooltip"
                                                                            checked={user.enabled}
                                                                            onChange={() => { changeStatus({ ...user, enabled: !user.enabled }) }}
                                                                        />
                                                                        <UncontrolledTooltip placement="top" target="enabletooltip">
                                                                            change status
                                                                        </UncontrolledTooltip>
                                                                        <Link to="#" onClick={() => {
                                                                            resetPassword({ ...user, id: user.id, first_name: user.first_name, last_name: user.last_name, email: user.email })
                                                                        }}>
                                                                            <i
                                                                                className="bx bx-key"
                                                                                style={{ fontSize: "1.5rem", cursor: "pointer", marginTop: "1rem", marginLeft: "1rem" }}
                                                                                id="edittooltip"
                                                                            />
                                                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                                                reset password
                                                                            </UncontrolledTooltip>
                                                                        </Link>
                                                                    </div>
                                                                </Td>
                                                            </Tr>

                                                        )
                                                    })}
                                                </Tbody>
                                            </Table> */}


<TableVirtuoso
    data={users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
    components={VirtuosoTableComponents}
    fixedHeaderContent={fixedHeaderContent}
    itemContent={rowContent}
  />
   <Row>
    <Col >
      <TablePagination
        component="div"
        //style={{width:1100}}
        rowsPerPageOptions={[10, 50,100, { label: 'All', value: users.length }]}
        colSpan={3}
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        //ActionsComponent={TablePaginationActions}
      />

    </Col>
    
   </Row>
                                        </div>
                                    </div>
                                ) : (
                                    <h1 style={{ width: "30%", margin: "auto", marginTop: "10rem", marginBottom: "10rem" }}>No users available</h1>
                                )}
                                </>
    )
}
