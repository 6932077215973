import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { socket } from "helpers/consts"
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import dayjs from "dayjs"

import { getPromotionByDateAsync } from "store/promotion/actions"
import { getPromotionByDate, updatePromotionsStatus } from "store/promotion/services"
import queryClient from "queryClient"

const PromotionDropdown = (props) => {
  const {dataPromotions}=props

  const dispatch = useDispatch();
  const location = useLocation()
  
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [notConsultedNotifications, setNotConsultedNotifications] = useState([])
  const connectedUser = useSelector(state => state.User.user)
  const [notConsulted, setNotConsulted] = useState(0)
  const [timeOffset, setTimeOffset] = useState(0)
  const [limit, setLimit] = useState(4)
  const [offset, setOffset] = useState(0)

  const [promotions, setPromotions] = useState([])
  var promotionData=[]
    //stocks
    // const getPromotions = async () => {
      
    //  return getPromotionByDate(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'))
    //   //return dispatch(await getPromotionByDateAsync(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'))).payload.promotion;
    // }

    useEffect(()=>{
      

      if(dataPromotions?.length>0){
        setPromotions(promotions=>[...dataPromotions])
      let promotionNot=dataPromotions?.filter((elem)=> !elem.promotion.consulted)
      setNotConsulted(promotionNot?.length)


      }

    
    },[dataPromotions])

    // useEffect(async()=>{
    //   console.log('promotions__________________________________',promotions)
      
      
    //  },[promotions])

     useEffect(() => { 
      //promotionData=promotions;
      //console.log(dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD'),promotionData)
      
      if (connectedUser) {
        let notificationsCons=[]
        let notificationsNotCons=[]
        
        promotions.forEach(notification => {
          //notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
          notificationsCons.push(notification);
          if (!notification.promotion.consulted) {
            notificationsNotCons.push(notification);

          }
        })
        
        setNotConsultedNotifications(notConsultedNotifications => [...notificationsNotCons])
        setNotifications(notifications => [...notificationsCons])

        // socket.on('notificationsCron', () => {
        //   promotions.forEach(notification => {
        //     //notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
        //     setNotifications(notifications => [...notifications, notification])
        //     // if (!notification.consulted) {
        //     //   setNotConsultedNotifications(notConsultedNotifications => [...notConsultedNotifications, notification])
        //     // }
        //   })
        // });
      }
    }, [connectedUser])
  
    useEffect(() => {
      if (!menu) {
        setNotConsultedNotifications([])

        if(notConsultedNotifications.length>0){
          updatePromotionsStatus(notConsultedNotifications).then(()=>{
            
            queryClient.invalidateQueries('promotions')
         })
        }
        // setNotConsulted(promotionData.length)
        // promotionData.forEach(notification => {
        //   notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
        // })
        //setNotifications(notifications)
      } else {
        // setNotConsulted(notifications.length)
        //     notifications.forEach(notification => {
        //       notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
        //       setNotifications(notification)
        //       if (!notification.consulted) {
        //         setNotConsultedNotifications(notConsultedNotifications => [...notConsultedNotifications, notification])
        //       }
        //     })
      }
      
      //setNotConsulted(0)
    }, [menu])
    //console.log(notifications)
  // useEffect(() => {
  //   if (!menu) {
  //     setNotConsultedNotifications([])
  //     updateNotificationsStatus(notConsultedNotifications)
  //       .then(() => {
  //         getNotificationList(connectedUser?.id, limit, 0)
  //           .then((data) => {
  //             setNotConsulted(data.notConsulted)
  //             data.notifications.forEach(notification => {
  //               notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
  //             })
  //             setNotifications(data.notifications)
  //           })
  //       })
  //   } else {
  //     getNotificationList(connectedUser?.id, notifications.length, 0)
  //       .then((data) => {
  //         setNotConsulted(data.notConsulted)
  //         data.notifications.forEach(notification => {
  //           notification.createdAt = new Date(notification.createdAt).setHours(new Date(notification.createdAt).getHours())
  //           setNotifications(data.notifications)
  //           if (!notification.consulted) {
  //             setNotConsultedNotifications(notConsultedNotifications => [...notConsultedNotifications, notification])
  //           }
  //         })
  //       })
  //   }
    
  // }, [menu])

  const memoData=useMemo(()=>notifications.length>0 && menu && connectedUser && notifications.map((notification, index) => {
    //console.log("nn_________________________",notification?.promotion.consulted)
    return (
      <Link key={index} to="#" className="text-reset notification-item">
        <div className="media" style={{ backgroundColor: !notification?.promotion.consulted && '#bcdefb' }}>
          <div className="avatar-xs me-3">
            <span className="avatar-title bg-primary rounded-circle font-size-16">
              <i className="bx bx-cart" />
            </span>
          </div>
          <div className="media-body">
            <h6 className="mt-0 mb-1">
              {notification?.promotion?.type}
            </h6>
            <div className="font-size-12 text-muted">
              <Row >
              
              <p className="mb-1">
              <span style={{fontWeight:'bold',paddingRight:5}}>
                product:   
              </span>
               {notification?.product?.label} 
              </p>
              
              </Row>
              
              <Row>
              <p className="mb-1">
              <span style={{fontWeight:'bold',paddingRight:5}}>
                store:   
              </span>
              {notification?.store?.name}
              </p>
              </Row>
             <Row>
             <p className="mb-0">
                <i className="mdi mdi-clock-outline" />{" "}
                {new Date(notification.createdAt).getDate()}
                {"/" + parseInt(new Date(notification.createdAt).getMonth() + 1)}
                {"/" + new Date(notification.createdAt).getFullYear()}
                {" - " + parseInt(new Date(notification.createdAt).getHours())}
                {+ new Date(notification.createdAt).getMinutes() < 10 ? (<>{":0" + new Date(notification.createdAt).getMinutes()}</>) : (<>{" : " +new Date(notification.createdAt).getMinutes()}</>)}
              </p>
             </Row>
             <hr/>
            </div>
          </div>
        </div>
      </Link>  
    )
  }),[notifications,menu,connectedUser])
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
        
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-promotion-dropdown"
          
        >
            <i className="bx bx-flag bx-tada" ></i>
          {(notConsulted > 0)&& (!menu) &&
            <span className="badge bg-danger rounded-pill">{notConsulted}</span>
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" style={{width:'600px'}}>
          <div className="p-3">
            <Row className="align-items-center">
              <h6 className="m-0"> {props.t("Promotion")} </h6>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {memoData}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
              onClick={() => { setOffset(offset + 4) }}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("voir plus")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(PromotionDropdown)

PromotionDropdown.propTypes = {
  t: PropTypes.any
}