//import { isNull } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Media, Row } from 'reactstrap'

function GeneralInformations(props) {

    const { visits } = props
    const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
    const [nbPlannededVisits, setNbPlannededVisits] = useState(0)
    const [nbSurveys, setNbSurveys] = useState(0)
    const [nbPictures, setNbPictures] = useState(0)
    let realisedVisits = 0
    let plannedVisits = 0
    let pictures = 0
    let surveys = 0

    useEffect(() => {
        if (visits?.length > 0) {
            visits.forEach(visit => {
                //let result = display?.find(({ visitId }) => visitId === visit?.id);

                if (visit?.displays?.length) {
                    realisedVisits++
                    visit?.displays?.map((display) => {
                        pictures += display?.displayData.length
                    })
                    //console.log(pictures)
                }
                if (visit?.planned) {
                    plannedVisits++
                }
                // if (!isNull(visit?.end)) {
                //     realisedVisits = realisedVisits + 1
                // }
                // visit?.surveyResponses?.forEach(surveyResponse => {
                //     surveys = surveys + 1
                //     surveyResponse?.responses.forEach(response => {
                //         pictures = pictures + response?.responsePictures?.length
                //     });
                // });

            });
        }
        setNbRealisedVisits(realisedVisits);
        setNbPlannededVisits(plannedVisits)
        setNbPictures(pictures)
        setNbSurveys(surveys)
    }, [visits])


    return (
        <Row>
                   <h5 align='center' className="mb-2">Today's General Informations</h5>

            <Col md="3">

                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Planned Visits
                                </p>
                                <h4 className="mb-0">{nbPlannededVisits}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-paper-plane font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Accomplished Visits
                                </p>
                                <h4 className="mb-0">{nbRealisedVisits}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-award font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Audits réalisés
                                </p>
                                <h4 className="mb-0">{nbSurveys}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-copy-alt font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Photos prises
                                </p>
                                <h4 className="mb-0">{nbPictures}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-photo-album font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    )
}

export default GeneralInformations