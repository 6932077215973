import { child, get, onValue, ref, set } from 'firebase/database'
import { database } from 'helpers/firebaseConfig'
import React, { useEffect, useRef, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Card, CardBody, CardTitle, Col, Button, Label, Modal } from "reactstrap"
import { getUsersByRole } from 'store/user/services'
import Select from "react-select";
import Maps from 'shared/MapsTracking'
import { Autocomplete, Box, Breadcrumbs, ButtonBase, Divider, LinearProgress, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import MapsApi from 'shared/MapApi'
import dayjs from 'dayjs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { DateRangePicker } from 'react-date-range';
import { getLocationHistoryDataAsync } from 'store/localisationHistory/actions'
import { useQuery } from 'react-query'
import { getStoresFilter } from 'store/pos/services'
import TableStore from './components/TableStore'
import  MButton  from '@mui/material/Button'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { getLocationStores } from 'store/localisationHistory/service'
import MapsHistory from './components/MapHistory'
import MapAnimation from './components/MapAnimation'
import { useJsApiLoader } from '@react-google-maps/api'
import { getStoreStocks } from 'store/stock/services'
{/* <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"></script> */}

const today=dayjs().format('YYYY-MM-DD')
function Tracking() {

  //const requestUri='https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=500&key=AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8'
  // const db = getDatabase();
  const dbRef = ref(database);
  const [store, setStore] = useState({})
  const [user,setUser]=useState()
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const dispatch = useDispatch();
  const [merchandisers,setMerchandisers] = useState([])
  const [selectedMerchandiser, setSelectedMerchandiser] = useState(null)
  const [historyData,setHistoryData]=useState([])
  const [selectedStores, setSelectedStores] = useState()
  const [locationStores, setLocationStores] = useState([])
  const [locationDate, setLocationDate] = useState()

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBpAexI1D_HPIrR9xz_RqAAKDNlYKmW0Q8',
    //libraries: ['places'],
  })

  useEffect(()=>{
    getStoreStocks().then(data=>{
      
      if(data?.length>0){
        let filtredStores=data.filter((elem)=>elem?.lat>0&&elem?.lng>0)
        //console.log("chanhged",filtredStores)
        setLocationStores(filtredStores)
      }else{
        setLocationStores([])
        setHistoryData([])
      }
    })
  },[selectedMerchandiser])

  useEffect(() => {
    getUsersByRole("merchandiser").then((data) => {
      let list=[]
      
        data.forEach(element => {
          list.push({id:element.id, value: element.id, label: element.first_name + " " + element.last_name })
        });
        setMerchandisers(list)
    })
}, [])
  // const getLocationInformation=()=>{
  //   fetch(requestUri, {
  //     //credentials: "include",
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       //Authorization: "Bearer eLrw3eXlljyFRjaul5UoYZLNgpUeapbXSFKmLc5SVaBgv8azUtoKn7B062PjbYoS",
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "*",
  //       //"User-Agent": "any-name"
  //     }
  // })
  // .then((res)=>res.json()).then(data=>{
  //   //console.log("info:___________",data)
  // })
  // }
  const getLocationHistory = async (userId,from, to) => {
    return dispatch(await getLocationHistoryDataAsync(userId,from, to)).payload.localisationHistory

  }
  function success() {
    setTicking(true)
    //handleGetSingleData()
    // setStore({
    //     ...store,
    //     lat: crd.latitude,
    //     lng: crd.longitude
    // })
}
  // const handleSetData=()=>{
  //   set(ref(database, 'marchandisers/' + 'aziz'), {
  //     id:1,
  //     username: 'aziz',
  //     email: 'email@',
  //     profile_picture : 'imageUrl'
  //   });
  // }
  //console.log("hey_________________")
  const handleGetSingleData=async ()=>{
    // setLat(0)
    // setLng(0)
    setLocationDate()
   await get(child(dbRef, `marchandisers/`+selectedMerchandiser+'/location')).then((snapshot) => {
      let found=false
     
      if (snapshot.exists()) {
        
        if(snapshot.val()?.position?.latitude!=lat||snapshot.val()?.position?.longitude!=lng){
        setLat(snapshot.val()?.position?.latitude)
        setLng(snapshot.val()?.position?.longitude)
        //console.log("fuck_________________",snapshot.val()?.date)
        //setStore({lat:snapshot.val()?.destination?.lat,lng:snapshot.val()?.destination?.lng,mode:snapshot.val()?.mode})
        setLocationDate(snapshot.val()?.date)
        
        found=true
        }
      } else {
        setTicking(false)
        setLat(0)
        setLng(0)
        Swal.fire(
          'Waiting',
          'no available location...',
          'error'
      )
        //console.log("No data available");
      }
      
    //   if(!found){
    //   setTicking(false)
    //   setLat(0)
    //   setLng(0)
    // }
    }).catch((error) => {
      //console.error(error);
      
      setTicking(false)
      setLat(0)
      setLng(0)
    });
    
    
  }

  const handleGetHistoryData=async ()=>{
      setLat(0)
      setLng(0)
      setTicking(false)
   await getLocationHistory(selectedMerchandiser,dayjs(selectionRange.startDate).format('YYYY-MM-DD'),dayjs(selectionRange.endDate).format('YYYY-MM-DD')).then((data)=>{
    if(data?.length>0){
      
      
      let positionData=data?.map(elem =>{
        return {id:elem.id,date:dayjs(elem.date).format('YYYY-MM-DD HH:mm:ss'),lat:elem.positionLat,lng:elem.positionLng,mode:elem.mode}
      })
      //console.log('data________________',positionData)
      //setStore({...data[0]?.store,lat:parseFloat(data[0]?.store.lat),lng:parseFloat(data[0]?.store.lng)})
      setHistoryData(positionData)
    }else{
      // setLat(0)
      // setLng(0)
      // setTicking(false)
      //console.log("fuck_________________")
      Swal.fire(
        'No data',
        'no available data...',
        'error'
    )
    }
   })
    
    
  }

  const [ticking, setTicking] = useState(false),
        [count, setCount] = useState(0)
   
   useEffect(() => {
    
      if(ticking){
        
        
        handleGetSingleData()
      const timer = setTimeout(() => ticking && setCount(count=>count+1), 2000)
      return () => clearTimeout(timer)
      
      }

   }, [count,ticking])

   //date picker 
   const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

   function handleSelectDate(ranges) {

    setSelectionRange({...selectionRange,
      startDate:ranges.selection.startDate,endDate:ranges.selection.endDate})
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
   }

   useEffect(() => {
    
    if(locationDate&& dayjs(locationDate).format('YYYY-MM-DD')!=today ){
            //console.log("locationDate:_______________",locationDate,today)
            Swal.fire(
              'no data',
              'no available location for today...',
              'warning'
          )
          setTicking(false)
          setLat(0)
          setLng(0)
          
    }
   }, [locationDate])
   



  return (
    <div className="page-content">
      <MetaTags>
    <title>Tracking</title>
</MetaTags>
<div className="container-fluid">
    <Breadcrumbs title="Tracking" breadcrumbitem="Tracking" />
    <Card>
        <CardBody>
            <Row>
                <CardTitle>Tracking</CardTitle>
            </Row>
            {/* <Button onClick={()=>handleGetSingleData()}>
              click
            </Button> */}
            {merchandisers?.length>0 &&
            <Row style={{ width: "100%", margin: "auto",marginTop:10 }}>
                                        <Col>
                                            <div className="mb-2" style={{backgroundColor:'white'}}>
                                                <Label>Choose a merchandiser</Label>
                                                {/* <Select
                                                    
                                                    placeholder="Choose a merchandiser..."
                                                    options={[...new Set(merchandisers)]}
                                                    classNamePrefix="select2-selection"
                                                    // styles={colourStyles}
                                                    onChange={(e) => { 
                                                      
                                                      setSelectedMerchandiser(e.value); }}
                                                /> */}
                                                <Autocomplete
                                                  size="small"
                                                  id="marchandisers"
                                                  onChange={(event, newValue)=>newValue &&setSelectedMerchandiser(newValue.id)}
                                                  options={[...new Set(merchandisers)].sort((a, b) => -b.label.localeCompare(a.label))}
                                                  //groupBy={option => option?.storeGroup?.toUpperCase()}
                                                  // getOptionLabel={option => option?.label}
                                                  // sx={{ width: 300 }}
                                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                                  renderInput={params => <TextField {...params} label="Select merchandiser..." 
                                                  />}
                                                  
                                                  renderOption={(props, option) => <div key={option.id}>
                                                      <li {...props}>{option.label}</li>
                                                  </div>}
                                                  // renderGroup={params => (
                                                  //   <li key={params.key}>
                                                  //     <GroupHeader>{params.group}</GroupHeader>
                                                  //     <GroupItems>{params.children}</GroupItems>
                                                  //   </li>
                                                  // )}
                                                />
                                            </div>
                                            
                                        </Col>
                                        
                                        {selectedMerchandiser &&<Col>
                                            {/* <div className="mb-2">
                                                
                                                <Label>Choose a store</Label>

                                                    <TableStore dataStore={locationStores} setSelectedStores={setSelectedStores}/>
                                                  
                                                
                                            </div> */}
                                            
                                              <Label>Choose date range</Label>
                                            <div className="mb-1 p-2" >
                                            
                                            <DateRangePicker
                                                  style={{width:800}}
                                                  ranges={[selectionRange]}
                                                  onChange={handleSelectDate}
                                                />
                                                
                                                          
                                            </div>
                                            
                                            
                                        </Col>}
                                        {selectedMerchandiser&&selectionRange&&<div className="mb-1" >
                                                
                                                {/* <Button
                                                className="waves-effect waves-effect waves-light btn-outline"
                                                color="primary"
                                                outline
                                                style={{ width: "98%", height: "3rem", fontSize: "1.3rem", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }}
                                                type="button"
                                                onClick={() => { handleGetHistoryData()}}
                                            >
                                                <i className="bx bx-history" style={{fontSize:18}} data-toggle="modal"></i>
                                                {" "} Search history ...
                                            </Button> */}
                                            <MButton className="waves-effect waves-effect waves-light btn-outline mb-4" variant="contained" size='large' style={{width:'100%'}} onClick={() => { handleGetHistoryData()}} startIcon={<LocalLibraryIcon />}>
                                            {" "} Search location history ...
                                              </MButton>
                                              <Divider />
                                            </div>}
                                        </Row>}
                                        {selectedMerchandiser&&<Row style={{ marginTop: 10}}>
                                        
                                            <Button
                                                className="waves-effect waves-effect waves-light btn-outline"
                                                color="primary"
                                                outline
                                                style={{ width: "98%", height: "3rem", fontSize: "1.3rem", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }}
                                                type="button"
                                                onClick={() => { success()}}
                                            >
                                                <i className="bx bxs-map" data-toggle="modal"></i>
                                                {" "} get current location 
                                            </Button>
                                            {/* <div>{count}</div>
      <button onClick={() => setTicking(false)}>pause</button>
      <button onClick={() => setTicking(true)}>resume</button> */}
                                        </Row>}
                                        
                                        {lat>0 && lng>0 && ticking && dayjs(locationDate).format('YYYY-MM-DD')===dayjs().format('YYYY-MM-DD') &&
                                        
                                            <Row style={{ height: "40rem", width: "100%" }}>
                                              
                                                {/* <Maps lat={lat} lng={lng} 
                                                destination={store}/> */}
                                                <MapsApi lat={lat} lng={lng} isLoaded={isLoaded}
                                                 locationStores={locationStores}/>
                                            </Row>
                                        }
                                        {/* {historyData.length>0 && store &&  !ticking && 
                                          <Row style={{ height: "40rem", width: "100%" }}>
                                              
                                               
                                                <MapsHistory positionData={historyData} destination={store} />
                                            </Row>
                                        } */}
        </CardBody>
    </Card>
    <Modal
                //size='100%'
                style={{padding:'0px 12px 0px 0px'}}
                isOpen={(historyData.length>0  && locationStores.length>0 &&  !ticking)? true:false}
                toggle={() => {setHistoryData([]) }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Tracking History
                    </h5>
                    <button
                        onClick={() => { setHistoryData([]) }}
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row style={{ height: "100%", width: "100%" }}>
                    <MapsHistory positionData={historyData} isLoaded={isLoaded} locationStores={locationStores}/>
                    </Row>
                </div>
            </Modal>
    </div>
    </div>
  )
}

export default Tracking