import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import WelcomeComp from "../WelcomeComp"
import MonthlyEarning from "../MonthlyEarning"
import SocialSource from "../SocialSource"
import { withTranslation } from "react-i18next"
import GeneralInformations from '../GeneralInformations'
import LoadingComponent from 'shared/LoadingComponent'
import { useDispatch } from 'react-redux'
import { getStocksAsync } from 'store/stock/actions'
import TodaySupervisorPerformance from '../TodaySupervisorPerformance'
import SurveyAVGStore from '../SurveyAVGStore'
import { findStockByUserId } from 'store/stock/services'
import { Oval } from 'react-loader-spinner'

const DashboardSupervisor = props => {
  const { connectedUser, visits } = props

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  //const dispatch = useDispatch();

  const getStocks = async () => {
    //console.log("user__________________________",connectedUser?.id)

    return await findStockByUserId(connectedUser?.id)

}
function groupBy(items) {
  return items.reduce((acc, curr) => {
      if (curr.store) {
          const { name } = curr.store;
          const currentItems = acc[name];

          return {
              ...acc,
              [name]: currentItems ? [...currentItems, curr] : [curr]
          };
      }
      return acc;
  }, {});
}

useEffect(async () => {
  await getStocks()
      .then((data) => {

          // Object.values(groupBy(data)).forEach((visits) => {
          //     let avg = 0
          //     console.log(visits)
          //     visits.forEach(visit => {
          //         avg = (avg + visit.average)
          //     });
          //     setData(data => [
          //         ...data,
          //         { name: visits[0].store.name, avg: parseFloat(avg / visits.length).toFixed(2), visits: visits }
          //     ])
          // })
          let listData=[]
          Object.values(groupBy(data)).forEach((visits) => {
              let nbStockOut = 0
              let nbStock = 0
              //console.log(visits)
              visits.forEach(visit => {
                  nbStock++
                  if (visit.stockOut) {
                      nbStockOut++
                  }

              });
              
              // setData(parseFloat(nbStockOut / nbStock).toFixed(2))
              // setStores(visits[0].store.name)
              listData.push({ name: visits[0].store.name, avg: parseFloat(nbStockOut / nbStock).toFixed(2), visits: visits })
              // ListData.push(parseFloat(nbStockOut / nbStock).toFixed(2))
              // ListStores.push(visits[0].store.name)
          })
          setData(data => [...listData])
          setLoading(false)
      })

}, [])
  return (
    <>
      {visits?.length > 0 ? (
        <>
          <Row>
            <Col xl="4">
              <WelcomeComp
                connectedUser={connectedUser}
                //display={display}
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
              />
              <MonthlyEarning
                //display={display}
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                ))}
              />
            </Col>
            <Col xl="8">
              <GeneralInformations
                //display={display}
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
              />
              <Row > 
                {loading&& <LoadingComponent/>}
                  {!loading &&<Col>
                  {data?.length>0 &&<SurveyAVGStore data={data}/>}
                  </Col>}
               
              </Row>
            </Col>
          </Row>
          <Row>
           
            <Col>
            <TodaySupervisorPerformance
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
              />
              <SocialSource
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
                
              />
            </Col>
          </Row>
        </>
      ) : (
        <LoadingComponent />
      )
      }
    </>
  )
}

DashboardSupervisor.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DashboardSupervisor)