import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import CreateProduct from "./components/CreateProduct";
import { useDispatch } from "react-redux";
import { getProductsAsync } from "store/product/actions";
import { getBrandsAsync } from "store/brand/actions";
import UpdateProduct from "./components/UpdateProduct";
import ProductDetail from "./components/ProductDetail";
import { Oval } from  'react-loader-spinner'
import LoadingComponent from "shared/LoadingComponent";
import { Link } from 'react-router-dom'
import ListData from "./listData";
import DataTable from './components/dataTable';

function ProductsList(props) {

    const {setProductData,productData,setRender,visible}=props

    const [products, setProducts] = useState([])
    
    //console.log("f111111111111111111111111111111111111111111")


    useEffect( () => {
        
        //setRender(true)
        //dispatch(await getBrandsAsync()).payload.brands
        setProducts([...productData])
    }, [productData])

    return (
        <div className="page-content">



 
 {products?.length>0 && (
                <div>
    
            <MetaTags>
                <title>Product list</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Products" breadcrumbItem="Product List" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Product List </CardTitle>
                                <CreateProduct  setRender={setRender} />
                                {(products.length > 0)   ? (
                                    <div className="table-rep-plugin" style={{ marginTop: "1rem",marginBottom: "2rem" }}>
                                       
                                            <DataTable data={products} setProductData={setProductData}/>
                                        </div>
                                    
                                ) : (
                                    <h1 style={{ width: "30%", margin: "auto", marginTop: "10rem", marginBottom: "10rem" }}>No products available</h1>
                                )}
                            </CardBody>
                            
                        </Card>
                    </Col>
                </Row>
            </div> 
            
            </div>)}
        </div>
    )
}

export default ProductsList